export const AllInputTabs = [
  "Baseline",
  "Volume Growth Rate",
  "Effort Index",
  "Productivity Improvement Rate",
  "Compensation Input",
  "Automation, Low Cost Location & Role FTE",
  "Cost Input",
];

export const AllOutputTabs = [
  {
    tab: "Projected Volumes",
    subtabs: ["Submissions", "Inforce"],
  },
  {
    tab: "Projected Staffing",
    // subtabs: ["FTE v/s Cost"],
    subtabs: ["FTE", "Cost", "FTE v/s Cost"],
  },
  {
    tab: "Unit Cost",
  },
  {
    tab: "CBA Summary",
    subtabs: ["Business", "Stochastic"],
  },
];

export const regions = [
  "Region 1",
  "Region 2",
  "Region 3",
  "Region 4",
  "Region 5",
  "Region 6",
  "Region 7",
  "Region 8",
];

export const low_cost_locations = [
  "Low Cost Location 1",
  "Low Cost Location 2",
  "Low Cost Location 3",
  "Low Cost Location 4",
  "Low Cost Location 5",
  "Low Cost Location 6",
  "Low Cost Location 7",
];

export const ROLES = ["Role 1", "Role 2", "Role 3"];

// VGR => Volume Growth Rate for Submissions & Inforce
export const VGR_input_data = {
  Region: regions,
  "2021-2020 Submissions": [24, 25, 25, 25, 25, 25, 25, 25],
  "2022-2021 Submissions": [141, 6, 71, 60, 18, 135, -27, 72],
  "2023-2022 Submissions": [10, 7, 8, 5, 4, 2, 20, 15],
  "2024-2023 Submissions": [10, 7, 8, 5, 4, 2, 20, 15],
  "2025-2024 Submissions": [10, 7, 8, 5, 4, 2, 20, 15],
  "2021-2020 Inforce": [6, 4, -5, -1, 1, 0, 6, 7],
  "2022-2021 Inforce": [12, 2, -2, 8, 3, 1, 12, 10],
  "2023-2022 Inforce": [5, 5, 5, 5, 5, 5, 5, 5],
  "2024-2023 Inforce": [5, 5, 5, 5, 5, 5, 5, 5],
  "2025-2024 Inforce": [5, 5, 5, 5, 5, 5, 5, 5],
};

// PIR => Productivity Improvement Rate
export const PIR_input_data = {
  Region: regions,
  2023: [10, 7, 8, 5, 4, 2, 20, 15],
  2024: [10, 7, 8, 5, 4, 2, 20, 15],
  2025: [10, 7, 8, 5, 4, 2, 20, 15],
};

export const Compensation_input_data = {
  Region: [...regions, ...low_cost_locations],
  "COLA %": [3, 4, 5, 2, 3, 6, 7, 9, 2, 3, 3, 4, 3, 7, 6],
  "Role 1": [
    60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 20000, 20000, 20000,
    20000, 20000, 20000, 20000,
  ],
  "Role 2": [
    75000, 75000, 75000, 75000, 75000, 75000, 75000, 75000, 35000, 35000, 35000,
    35000, 35000, 35000, 35000,
  ],
  "Role 3": [
    55000, 55000, 55000, 55000, 55000, 55000, 55000, 55000, 27500, 27500, 27500,
    27500, 27500, 27500, 27500,
  ],
};

export const EffortIndex_input_data = {
  Tasks: [
    "Create Case in CRM",
    "Updating submissions created in CRM",
    "Additional Info Request",
    "Create agreement for brokers",
    "Prepare Underwriting rules",
    "Book policy on Portal",
    "Create template driven policy document",
    "Create policy document",
    "Create broker wording policy document",
    "Produce cover note with company wording",
    "Chase signed copy of policy from client",
    "Scan and index signed copy of policy from client",
    "QA / QC",
    "Create pre-renewal / renewal report",
    "Obtain claims history",
    "Book policy on Portal",
    "Create template driven policy document",
    "Create policy document",
    "Create broker wording policy document",
    "Produce cover note with company wording",
    "Chase signed copy of policy from client",
    "Scan and index signed copy of policy from client",
    "Verification of the validity of the cancellation request",
    "Registration of termination in CRM",
  ],
  "Scenario Type": [
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Submissions",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Inforce",
    "Cancellations",
    "Cancellations",
  ],
  Role: [
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 3",
    "Role 2",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 2",
    "Role 2",
    "Role 2",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 3",
    "Role 2",
    "Role 2",
    "Role 2",
    "Role 3",
  ],
  "Effort Index": [
    5, 5, 5, 5, 5, 15, 5, 10, 10, 10, 10, 10, 5, 10, 15, 20, 5, 10, 10, 10, 10,
    10, 60, 40,
  ],
};

export const Automation_input_data = {
  Region: regions,
  "Productivity Target": [
    "Overall Median",
    "Overall Median",
    "Overall Median",
    "Overall Median",
    "Overall Median",
    "Overall Median",
    "Overall Median",
    "Overall Median",
  ],
  "2023_Role 1": [20, 50, 0, 40, 30, 0, 70, 80],
  "2023_Role 2": [15, 40, 0, 80, 60, 0, 70, 50],
  "2023_Role 3": [5, 50, 20, 40, 30, 0, 70, 40],
  "2024_Role 1": [20, 50, 0, 60, 35, 0, 70, 80],
  "2024_Role 2": [15, 45, 0, 60, 40, 0, 70, 60],
  "2024_Role 3": [5, 50, 40, 50, 30, 0, 70, 50],
  "2025_Role 1": [20, 50, 0, 70, 40, 0, 70, 80],
  "2025_Role 2": [15, 50, 0, 80, 50, 0, 70, 70],
  "2025_Role 3": [5, 50, 60, 50, 30, 0, 70, 50],
};

export const LowCostLocation_input_data = {
  Region: regions,
  "Lower Cost Location": [
    "Low Cost Location 3",
    "Low Cost Location 4",
    "Low Cost Location 4",
    "Low Cost Location 5",
    "Low Cost Location 1",
    "Low Cost Location 6",
    "Low Cost Location 7",
    "Low Cost Location 2",
  ],
  "2023_Role 1": [80, 0, 0, 0, 0, 0, 0, 0],
  "2023_Role 2": [6, 0, 0, 0, 0, 0, 0, 0],
  "2023_Role 3": [50, 0, 10, 0, 0, 0, 0, 0],
  "2024_Role 1": [80, 23, 50, 20, 45, 0, 0, 0],
  "2024_Role 2": [6, 23, 50, 20, 45, 0, 0, 0],
  "2024_Role 3": [50, 0, 20, 0, 0, 0, 0, 0],
  "2025_Role 1": [80, 23, 50, 20, 45, 0, 0, 0],
  "2025_Role 2": [6, 23, 50, 20, 45, 0, 0, 0],
  "2025_Role 3": [50, 0, 30, 0, 0, 0, 0, 0],
};

export const Role_FTE_input_data = {
  Region: [
    "Region 1",
    "Region 1",
    "Region 1",
    "Region 2",
    "Region 2",
    "Region 2",
    "Region 3",
    "Region 3",
    "Region 3",
    "Region 4",
    "Region 4",
    "Region 4",
    "Region 5",
    "Region 5",
    "Region 5",
    "Region 6",
    "Region 6",
    "Region 6",
    "Region 7",
    "Region 7",
    "Region 7",
    "Region 8",
    "Region 8",
    "Region 8",
  ],
  Role: [
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
    "Role 1",
    "Role 2",
    "Role 3",
  ],
  "2022 FTE": [
    2, 6, 7, 12, 6, 10, 16, 1, 14, 7, 4, 12, 2, 4, 23, 1, 8, 5, 2, 2, 6, 7, 58,
    16,
  ],
};

export const Automation_Cost_input_data = {
  "Cost Category": [
    "One Time Cost",
    "Hardware",
    "Software",
    "Platform/App Development",
    "Ongoing Cost",
    "Licenses",
    "Application Maintainance",
  ],
  "Role 1_2023": [2500, 600, 400, 1500, 500, 100, 400],
  "Role 2_2023": [1870, 700, 270, 900, 390, 140, 250],
  "Role 3_2023": [1600, 600, 200, 800, 250, 100, 150],
  "Role 1_2024": [0, 0, 0, 0, 500, 100, 400],
  "Role 2_2024": [0, 0, 0, 0, 390, 140, 250],
  "Role 3_2024": [0, 0, 0, 0, 250, 100, 150],
  "Role 1_2025": [0, 0, 0, 0, 500, 100, 400],
  "Role 2_2025": [0, 0, 0, 0, 390, 140, 250],
  "Role 3_2025": [0, 0, 0, 0, 250, 100, 150],
};

export const LowCostLocation_Cost_input_data = {
  Region: low_cost_locations,
  "Annual Rent": [89, 75, 87, 74, 109, 103, 91],
  "YoY Rent increase": [6, 3, 6, 6, 4, 4, 6],
  "Administrative & Managerial Cost": [20, 16, 12, 14, 20, 22, 10],
  "License cost per FTE": [275, 275, 275, 275, 275, 275, 275],
};

export const region_severance = {
  Region: [
    "Region 1",
    "Region 2",
    "Region 3",
    "Region 4",
    "Region 5",
    "Region 6",
    "Region 7",
    "Region 8",
  ],
  Severance: [3, 3, 3, 3, 3, 3, 3, 3],
};

export const one_time_cost_non_tech = {
  facility_buildup: [500],
  "sq. feet per FTE": [50],
  "Training_Role 1": [0.1],
  "Training_Role 2": [0.12],
  "Training_Role 3": [0.08],
  "Hiring_Role 1": [0.1],
  "Hiring_Role 2": [0.12],
  "Hiring_Role 3": [0.08],
  relocation_bonus: [0.5],
};

export const one_time_cost_tech = {
  "laptop/system": [800],
  security: [500],
  infrastructure: [800],
};

// Staffing Overlap in months
export const staffing_overlap = 4;
// Weighted Average Cost of Capital
export const weighted_avg_cost_capital = 0.12;

export const baseline_input_data = {
  "Region 1": {
    Casualty: [297, 370, 438, 649, 630, 714],
    Energy: [10, 13, 4, 18, 40, 43],
    "Environmental Risks": [30, 38, 45, 3, 5, 5],
    "Financial Lines": [103, 128, 1784, 2785, 2857, 2993],
    Fire: [1, 1, 190, 658, 722, 930],
    Marine: [217, 269, 669, 259, 435, 595],
    PRC: [516, 642, 586, 0, 0, 0],
    SUR: [1, 1, 11, 0, 1, 4],
    "Tech Lines": [247, 307, 536, 538, 533, 543],
    Others: [4, 5, 5, 0, 0, 0],
  },
  "Region 2": {
    Casualty: [1573, 1957, 1738, 3071, 3157, 3226],
    Energy: [8, 10, 9, 4, 2, 1],
    "Environmental Risks": [277, 346, 303, 107, 115, 119],
    "Financial Lines": [1702, 2131, 2958, 4601, 4794, 5065],
    Fire: [8, 10, 257, 1842, 1877, 1806],
    Marine: [458, 573, 699, 494, 616, 651],
    PRC: [2414, 2996, 2338, 0, 0, 0],
    SUR: [52, 65, 63, 2, 34, 51],
    "Tech Lines": [921, 1143, 1417, 1303, 1328, 1290],
    Others: [0, 0, 1, 0, 0, 0],
  },
  "Region 3": {
    Casualty: [883, 1102, 1130, 1745, 1322, 1027],
    Energy: [19, 23, 27, 5, 7, 8],
    "Environmental Risks": [61, 76, 79, 113, 101, 84],
    "Financial Lines": [1057, 1324, 4030, 4879, 4968, 5227],
    Fire: [770, 961, 1027, 1110, 950, 831],
    Marine: [368, 458, 289, 780, 776, 736],
    PRC: [10, 13, 5, 58, 49, 39],
    SUR: [26, 32, 92, 10, 18, 47],
    "Tech Lines": [381, 474, 994, 698, 742, 788],
    Others: [35, 44, 48, 0, 0, 0],
  },
  "Region 4": {
    Casualty: [1131, 1412, 1630, 930, 910, 977],
    Energy: [4, 5, 6, 16, 9, 41],
    "Environmental Risks": [276, 346, 247, 241, 249, 252],
    "Financial Lines": [1815, 2278, 4748, 3168, 3215, 3483],
    Fire: [7, 9, 89, 332, 265, 271],
    Marine: [164, 206, 368, 262, 256, 288],
    PRC: [349, 435, 385, 0, 0, 0],
    SUR: [14, 17, 31, 1, 6, 16],
    "Tech Lines": [197, 246, 393, 243, 231, 243],
    Others: [25, 31, 76, 0, 0, 0],
  },
  "Region 5": {
    Casualty: [1433, 1781, 1855, 1773, 1714, 1643],
    Energy: [2, 3, 2, 28, 32, 28],
    "Environmental Risks": [35, 44, 149, 73, 70, 60],
    "Financial Lines": [856, 1068, 2376, 2723, 2969, 3403],
    Fire: [569, 713, 709, 803, 674, 583],
    Marine: [1512, 1883, 1871, 1549, 1624, 1659],
    PRC: [77, 96, 52, 0, 0, 0],
    SUR: [66, 82, 39, 18, 20, 19],
    "Tech Lines": [255, 317, 346, 508, 442, 402],
    Others: [228, 284, 1, 0, 0, 0],
  },
  "Region 6": {
    Casualty: [126, 157, 239, 326, 352, 326],
    Energy: [4, 5, 6, 5, 3, 3],
    "Environmental Risks": [7, 9, 9, 2, 2, 3],
    "Financial Lines": [232, 288, 815, 878, 866, 899],
    Fire: [100, 126, 152, 155, 144, 146],
    Marine: [56, 70, 168, 103, 111, 127],
    PRC: [8, 10, 49, 0, 0, 0],
    SUR: [0, 0, 72, 0, 0, 2],
    "Tech Lines": [13, 16, 100, 204, 190, 186],
    Others: [2, 3, 0, 0, 0, 0],
  },
  "Region 7": {
    Casualty: [1016, 1264, 697, 224, 203, 234],
    Energy: [14, 17, 3, 1, 3, 3],
    "Environmental Risks": [52, 65, 42, 22, 20, 18],
    "Financial Lines": [1346, 1681, 1631, 192, 272, 344],
    Fire: [826, 1032, 526, 187, 173, 160],
    Marine: [74, 93, 76, 9, 8, 11],
    PRC: [30, 38, 6, 0, 0, 0],
    SUR: [0, 0, 0, 0, 0, 0],
    "Tech Lines": [18, 22, 76, 22, 18, 12],
    Others: [2, 3, 6, 0, 0, 0],
  },
  "Region 8": {
    Casualty: [615, 767, 1282, 840, 858, 780],
    Energy: [8, 10, 18, 0, 2, 12],
    "Environmental Risks": [152, 190, 166, 145, 139, 144],
    "Financial Lines": [6500, 8123, 13994, 13700, 14206, 15776],
    Fire: [3212, 4000, 6424, 2462, 2656, 2776],
    Marine: [842, 1054, 2386, 2198, 2754, 3116],
    PRC: [0, 0, 0, 0, 0, 0],
    SUR: [0, 0, 0, 0, 0, 0],
    "Tech Lines": [337, 422, 848, 1546, 1722, 1896],
    Others: [0, 0, 0, 0, 0, 0],
  },
};
