import React from "react";
import ProjectedVolumes from "./ProjectedVolumes";
import {
  CurrencyFormatterK,
  NumberFormatterRounded,
} from "../../../utils/Utils";
import ProjectedStaffing from "./ProjectedStaffing";
import UnitCost from "./UnitCost";
import CBASummary from "./CBASummary";
import ProjectedStaffingFTECost from "./ProjectedStaffingFTECost";

const OutputScreen = ({ currentOutputTab, subActiveTab, outputData }) => {
  return (
    <div className="p-5 pt-0 border shadow rounded-lg w-full h-full">
      {currentOutputTab === "Projected Volumes" && (
        <ProjectedVolumes
          data={
            subActiveTab === "Submissions"
              ? outputData["submission_projected_volumes"]
              : outputData["inforce_projected_volumes"]
          }
        />
      )}
      {currentOutputTab === "Projected Staffing" &&
        (subActiveTab === "FTE" || subActiveTab === "Cost") && (
          <ProjectedStaffing
            data={
              subActiveTab === "FTE"
                ? outputData["projected_staffing_fte"]
                : outputData["projected_staffing_usd"]
            }
            formatter={
              subActiveTab === "FTE"
                ? NumberFormatterRounded
                : CurrencyFormatterK
            }
            info={subActiveTab === "FTE" ? "FTE" : "Cost"}
          />
        )}
      {currentOutputTab === "Projected Staffing" &&
        subActiveTab === "FTE v/s Cost" && (
          <ProjectedStaffingFTECost
            data={{
              FTE: { ...outputData["projected_staffing_fte"] },
              Cost: { ...outputData["projected_staffing_usd"] },
            }}
            formatter={{
              FTE: NumberFormatterRounded,
              Cost: CurrencyFormatterK,
            }}
          />
        )}
      {currentOutputTab === "Unit Cost" && (
        <UnitCost data={outputData["unit_cost"]} />
      )}
      {currentOutputTab === "CBA Summary" && (
        <CBASummary
          info={subActiveTab === "Business" ? "Business" : "Stochastic"}
          data={
            subActiveTab === "Business"
              ? outputData["cba_business"]
              : outputData["cba_stochastic"]
          }
          IRR={
            subActiveTab === "Business"
              ? outputData["business_irr"]
              : outputData["stochastic_irr"]
          }
          NPV={
            subActiveTab === "Business"
              ? outputData["business_npv"]
              : outputData["stochastic_npv"]
          }
          payBackPeriod={
            subActiveTab === "Business"
              ? outputData["business_payback_period"]
              : outputData["stochastic_payback_period"]
          }
        />
      )}
    </div>
  );
};

export default OutputScreen;
