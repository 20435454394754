import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { UserProvider } from "./context/userContext";

// Power BI App Imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/powerBIAuth/authConfig";
import { Router } from "react-router-dom";
export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance?.getAllAccounts();

if (accounts?.length > 0) {
  msalInstance?.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  // console.log(event);
  if (
    msalInstance?.getAllAccounts().length > 0 &&
    event.EventType === EventType.LOGIN_SUCCESS &&
    event.payload
  ) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance?.setActiveAccount(account);
    console.log("set active Msal User: ", account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // PowerBI wrapper
  <MsalProvider instance={msalInstance}>
    {/* User Context Wrapper */}
    <UserProvider>
      {/* <Router> */}
      <App />
      {/* </Router> */}
    </UserProvider>
  </MsalProvider>
);
