import React, { useState } from "react";
import API from "../../../api/api";
import { ageBin, bmi, bmiBin, getMonth } from "../../../data/staticData";
import RadarChart from "../../utils/RadarChart";
import {
  IL_Engine_formInputs,
  UW_IL_DBP,
  UW_IL_SBP,
} from "../../../data/modules/uwEngine";
import { LuLoader2 } from "react-icons/lu";

const UW_Engine = () => {
  const [inputFormData, setInputFormData] = useState(IL_Engine_formInputs);
  const [scoreData, setScoreData] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (
      e.target?.name === "height" ||
      e.target?.name === "weight" ||
      e.target?.name === "age"
    ) {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (
      e.target?.name === "systolicBP" ||
      e.target?.name === "diastolicBP"
    ) {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
      });
      if (
        e.target?.name === "systolicBP" &&
        (e.target?.value <= 60 || e.target?.value >= 200)
      ) {
        setError("Systolic blood pressure range should be within 60 to 200");
        // console.log(e.target.value);
        return;
      } else {
        setError(null);
      }
      if (
        e.target?.name === "diastolicBP" &&
        (e.target?.value <= 50 || e.target?.value >= 150)
      ) {
        setError("Diastolic blood pressure range should be within 50 to 150");
        // console.log(e.target.value);
        return;
      } else {
        setError(null);
      }
    } else if (e.target?.name === "month") {
      let value = getMonth(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value,
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // function to get score
  const getScore = async (e) => {
    e.preventDefault();
    setLoad(true);
    // If existing case is being edited
    inputFormData.bmi = bmi(inputFormData.height, inputFormData.weight);
    inputFormData.bmiBin = bmiBin(inputFormData.height, inputFormData.weight);
    inputFormData.ageBin = ageBin(inputFormData.age);
    inputFormData.SBP_Bin = UW_IL_SBP(inputFormData.systolicBP);
    inputFormData.DBP_Bin = UW_IL_DBP(inputFormData.diastolicBP);

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    let score;
    let action;

    // api call to get rating and risks
    await API.post("/UW/IL/engine", inputFormData)
      .then(({ data }) => {
        // console.log(data);
        let rating = (data.rating * 100).toFixed(0);
        if (rating <= 9 || rating >= 90) {
          score = data.probability;
        } else {
          score = rating + "%";
        }
        action = data.risk;
      })
      .catch((error) => console.log(error));

    // api call to initiate, get and populate data
    await API.post("/UW/IL/score-calculator", inputFormData)
      .then(({ data }) => {
        let scores = data.scores;
        let totalScore = 0;
        let keys = {};

        Object.entries(scores).forEach(([key, value]) => {
          totalScore += value["Score"];
          // console.log(key, value["Percentile Rank"]);
          keys[key] = value["Percentile Rank"];
        });

        let scoreData = [
          {
            score: score,
            action: action,
          },
          {
            Age: keys["age"],
            BMI: keys["bmi"],
            Gender: keys["gender"],
            Exercise: keys["exercise"],
            Smoking: keys["smoking"],
            "Medical History": keys["medicalHistory"],
            "Motor Vehicle Violation": keys["motorViolation"],
            "Systolic Blood Pressure": keys["sbp"],
            "Diastolic Blood Pressure": keys["dbp"],
          },
        ];
        setLoad(false);
        setScoreData(scoreData);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  const resetForm = () => {
    setInputFormData(IL_Engine_formInputs);
  };

  return (
    <div className="flex h-max gap-10">
      <div className="w-fit p-7 bg-white rounded-lg shadow border text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-5 justify-between">
              {/* Age */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="age">
                  Age:
                </label>
                <input
                  type="text"
                  name="age"
                  id="age"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.age || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
              {/* Height */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="height">
                  Height (inches):
                </label>
                <input
                  type="text"
                  name="height"
                  id="height"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.height || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
              {/* Weight */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="weight">
                  Weight (lbs):
                </label>
                <input
                  type="text"
                  name="weight"
                  id="weight"
                  value={inputFormData.weight || ""}
                  maxLength={3}
                  onChange={(e) => handleOnChangeInput(e)}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
            </div>
            <div className="flex gap-10">
              {/* Gender */}
              <div className="flex flex-col gap-1 w-[115px]">
                <label className="font-semibold" htmlFor="gender">
                  Gender:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value={"Male"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value={"Female"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="other"
                      value={"Others"}
                      checked={inputFormData.gender === "Others"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              </div>
              {/* Exercise Habit */}
              <div className="flex flex-col gap-1 w-[115px]">
                <label className="font-semibold" htmlFor="exercise">
                  Exercise Habit:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="exercise"
                      id="ex_yes"
                      value={"Exercise"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="ex_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="exercise"
                      id="ex_no"
                      value={"No Exercise"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="ex_no">No</label>
                  </div>
                </div>
              </div>
              {/* Smoking Habit */}
              <div className="flex flex-col gap-1 w-[115px]">
                <label className="font-semibold" htmlFor="smoking">
                  Smoking Habit:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="smoking"
                      id="smoke_yes"
                      value={"Smoking"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="smoke_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="smoking"
                      id="smoke_no"
                      value={"No Smoking"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="smoke_no">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between">
              {/* Genetic Family Medical History */}
              <div className="flex flex-col gap-1 w-fit">
                <label className="font-semibold w-fit" htmlFor="medicalHistory">
                  Genetic Family Medical History:
                </label>
                <div className="flex gap-5">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="medicalHistory"
                      id="med_yes"
                      value={"Medical History"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="med_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="medicalHistory"
                      id="med_no"
                      value={"No Medical History"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="med_no">No</label>
                  </div>
                </div>
              </div>
              {/* Motor Vehicle Violation Record */}
              <div className="flex flex-col gap-1 w-fit">
                <label className="font-semibold w-fit" htmlFor="motorViolation">
                  Motor Vehicle Violation Record:
                </label>
                <div className="flex gap-5">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="motorViolation"
                      id="motV_yes"
                      value={"Motor Violation"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="motV_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="motorViolation"
                      id="motV_no"
                      value={"No Motor Violation"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="motV_no">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between">
              {/* Systolic Blood Pressure */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="systolicBP">
                  Systolic Blood Pressure:
                </label>
                <input
                  type="text"
                  name="systolicBP"
                  id="systolicBP"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.systolicBP || ""}
                  maxLength={3}
                  // className="bg-white py-[10px] w-40"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  // disabled
                />
                {/* <input
                  id="systolicBP"
                  name="systolicBP"
                  type="range"
                  min="50"
                  max="200"
                  value={inputFormData.systolicBP || ""}
                  onChange={(e) => handleOnChangeInput(e)}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                /> */}
              </div>
              {/* Diastolic Blood Pressure */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="diastolicBP">
                  Diastolic Blood Pressure:
                </label>
                <input
                  type="text"
                  name="diastolicBP"
                  id="diastolicBP"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.diastolicBP || ""}
                  maxLength={3}
                  // className="bg-white py-[10px] w-40"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  // disabled
                />
                {/* <input
                  id="diastolicBP"
                  name="diastolicBP"
                  type="range"
                  min="1"
                  max="150"
                  value={inputFormData.diastolicBP || ""}
                  onChange={(e) => handleOnChangeInput(e)}
                  className="w-full h-2 bg-gray-200 rounded-lg text-gray-800 appearance-none cursor-pointer"
                /> */}
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Calculate"
                onClick={(e) => (error ? null : getScore(e))}
                className={`px-5 py-2 ${
                  error
                    ? "bg-gray-300"
                    : "bg-tertiary cursor-pointer  hover:bg-opacity-80 text-white"
                }  rounded-md`}
              />
            )}
          </div>
        </form>
        {/* Error */}
        {error && <p className="text-red-600 absolute">{error}</p>}
      </div>
      {/* Output */}
      <div className="min-w-[730px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg relative">
        <div className="p-3 flex gap-5 items-center justify-center text-center border-b text-xs">
          Range:
          <div className="flex flex-wrap gap-3 font-bold">
            <span className="text-red-500">({">"}25%) Sub Standard</span>
            <span className="text-amber-500">(26% - 50%) Standard</span>
            <span className="text-blue-500">(50% - 75%) Standard Plus</span>
            <span className="text-green-500">(75% - 90%) Preferred</span>
            <span className="text-green-700">(90% +) Super Preferred</span>
          </div>
        </div>
        {/* Percentile Contribution Chart */}
        {scoreData ? (
          <div className="">
            <RadarChart data={scoreData} module={"UW Engine"} />
          </div>
        ) : (
          <div className="flex justify-center items-center h-96">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default UW_Engine;
