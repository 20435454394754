import {
  CurrencyCompactFormatter,
  NumberCompactFormatter,
  NumberFormatter,
  CurrencyMillionFormatter,
  NumberMillionFormatter,
  NumberFormatterK,
  PercentFormatter1,
  PercentFormatter2,
} from "./Formatters";

export const drawingObject = (draw) => {
  return {
    polygon: draw === "polygon",
    // rectangle: draw === "rectangle",
    rectangle: draw === "polygon",
    circle: draw === "circle",
    marker: draw === "marker",
    polyline: draw === "polyline",
    circlemarker: draw === "circlemarker",
  };
};

// Drawing Modes
export const drawingModes = [
  {
    title: "Rectangle",
    type: "rectangle",
    icon: "",
    default: true,
  },
  {
    title: "Circle",
    type: "circle",
    icon: "",
    default: true,
  },
  {
    title: "Marker",
    type: "marker",
    icon: "",
    default: true,
  },
  {
    title: "Free Form",
    type: "polygon",
    icon: "",
    default: true,
  },
  {
    title: "Free Form line",
    type: "polyline",
    icon: "",
    default: true,
  },
  {
    title: "Circle Marker",
    type: "circlemarker",
    icon: "",
    default: true,
  },
];

export const products = [
  "All",
  "IUL",
  "UL",
  "VUL",
  "Term Life",
  "Whole Life",
  "Other",
];

// Map Metric
export const metric = [
  "Market Sales Premium",
  "Client Sales Premium",
  "Premium Share",
];

// function to create options out of arrays for react select
export const createOptionsForGradient = (items) => {
  let array = [];
  let parts = "";
  for (let i = 0; i < items.length; i++) {
    if (items[i].includes("&") === true) {
      parts = items[i].split(" & ").join(" and ");
    } else if (items[i].includes("'") === true) {
      parts = items[i].split("'").join("");
    } else {
      parts = items[i];
    }
    const object = {
      label: items[i] === "Premium Share" ? "Sales Share" : items[i],
      value: parts,
    };
    array.push(object);
  }
  return array;
};

// "Sales Opportunity",
export const calculateMedian = (values) => {
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

export const calculatePercentile = (values, percentile) => {
  values.sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * values.length) - 1;
  return values[index];
};

export const initialOptions = [
  "Market Sales Premium",
  "Client Sales Premium",
  "Premium Share",
  "Market Agents",
  "Client Agents",
  "Agent Share",
  "Market Sold Policies",
  "Client Sold Policies",
  "Policy Share",
  "Average Household Income",
  "Total Sales Opportunity",
  "Incr. Sales Growth %",
];

export const mapTooltipColumns = [
  "Market Sales Premium",
  "Client Sales Premium",
  "Total Sales Opportunity",
  "Incr. Sales Growth %",
];

export const tableAttributeOptions = [
  {
    id: 1,
    label: "Market Sales Premium",
    value: "Market Sales Premium",
    tooltipContent: "3-year Average Market Sales Premium",
  },
  {
    id: 2,
    label: "Client Sales Premium",
    value: "Client Sales Premium",
    tooltipContent: "3-year Average Client Sales Premium",
  },
  {
    id: 3,
    label: "Premium Share",
    value: "Premium Share",
    tooltipContent: "Ratio of Client Sales Premium to Market Sales",
  },
  {
    id: 4,
    label: "Market Agents",
    value: "Market Agents",
    tooltipContent: "Relevant market size of independent agents",
  },
  {
    id: 5,
    label: "Client Agents",
    value: "Client Agents",
    tooltipContent: "Distinct count of Client appointed agents that sold at least one policy between 2021 and 2023",
  },
  {
    id: 6,
    label: "Agent Share",
    value: "Agent Share",
    tooltipContent: "Ratio of Client Agents to Market Agents",
  },
  {
    id: 7,
    label: "Market Sold Policies",
    value: "Market Sold Policies",
    tooltipContent: "2021-2023 Total Market Sold Policies",
  },
  {
    id: 8,
    label: "Client Sold Policies",
    value: "Client Sold Policies",
    tooltipContent: "2021-2023 Total Client Sold Policies",
  },
  {
    id: 9,
    label: "Policy Share",
    value: "Policy Share",
    tooltipContent: "Ratio of Client Sold Policies to Market Sold Policies",
  },
  {
    id: 10,
    label: "Average Household Income",
    value: "Average Household Income",
    tooltipContent: "2021-2023 Average Household Income",
  },
  {
    id: 11,
    label: "Total Sales Opportunity",
    value: "Total Sales Opportunity",
    tooltipContent: "Forward-looking sales potential calculated using a combination of market metrics, client metrics, and wallet share ratios",
  },
  {
    id: 12,
    label: "Incr. Sales Growth %",
    value: "Incr. Sales Growth %",
    tooltipContent: "Ratio of incremental sales growth from Incremental Sales Opportunity to Client Sales Premium",
  },
];

// "Market Sales", "Client Sales Premium", "Premium Share"

export const attributeFormatter = {
  "Market Sales Premium": CurrencyMillionFormatter,
  "Client Sales Premium": CurrencyMillionFormatter,
  "Premium Share": PercentFormatter1,
  "Market Agents": NumberFormatterK,
  "Client Agents": NumberFormatterK,
  "Agent Share": PercentFormatter1,
  "Market Sold Policies": NumberFormatterK,
  "Client Sold Policies": NumberFormatterK,
  "Policy Share": PercentFormatter2,
  "Average Household Income": CurrencyCompactFormatter,
  "Total Sales Opportunity": CurrencyCompactFormatter,
  "Incr. Sales Growth %": PercentFormatter1,
};

export const stateLevelAttributeFormatter = {
  "Market Sales Premium": CurrencyCompactFormatter,
  "Client Sales Premium": CurrencyCompactFormatter,
  "Premium Share": PercentFormatter1,
  "Market Agents": NumberCompactFormatter,
  "Client Agents": NumberCompactFormatter,
  "Agent Share": PercentFormatter1,
  "Market Sold Policies": NumberCompactFormatter,
  "Client Sold Policies": NumberCompactFormatter,
  "Policy Share": PercentFormatter2,
  "Average Household Income": CurrencyCompactFormatter,
  "Total Sales Opportunity": CurrencyCompactFormatter,
  "Incr. Sales Growth %": PercentFormatter1,
};

export const attributeColors = {
  "Market Sales Premium": "#ED7D31",
  "Client Sales Premium": "#002060",
  "Premium Share": "#059212",
};

export const reduceGeoJson = (geoJson, option) => {
  return geoJson.reduce((sum, object) => sum + object.properties[option], 0);
};

export const calculateAvgHHIncome = (geoJson) => {
  const totalIncome = geoJson.reduce(
    (sum, object) => sum + object.properties["Total Income"],
    0
  );
  const totalHousehold = geoJson.reduce(
    (sum, object) => sum + object.properties["Total Household"],
    0
  );
  return totalIncome / totalHousehold;
};

export const calculateSalesGrowth = (geoJson, data) => {
  const IncrSalesOpty = geoJson.reduce(
    (sum, object) => sum + object.properties["Incremental Sales Opportunity"],
    0
  );
  return IncrSalesOpty / data["Client Sales Premium"];
};
