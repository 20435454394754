import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import {
  low_cost_locations,
  regions,
} from "../../../../data/modules/opsTransformationPlanning";
import Select from "react-select";
import { colourStyles } from "../../../utils/Functions";
import { createOptions, selectedOption } from "../../../../data/staticData";

const AutoLowRoleForm = ({
  Automation_Inputs,
  LowCostLocation_Inputs,
  RoleFTE_Inputs,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  handleOnChangeSelectInput,
  handleNumberInputChange,
  targetStateAutomation,
  targetStateLowCost,
  targetStateRoleFTE,
  defaultValues,
  setError,
}) => {
  const [regionFilter, setRegionFilter] = useState("Region 1");
  const [regionIndex, setRegionIndex] = useState(0);
  let showError = false;

  // set Region Index everytime region is changed
  useEffect(() => {
    const parts = regionFilter.split(" ");
    const regionId = parts[1] - 1;
    setRegionIndex(regionId);
  }, [regionFilter]);

  // if (showError) {
  //   return setError({
  //     tab: "Automation, Low Cost Location & Role FTE",
  //     message: "Total percentage cannot be more than 100",
  //   });
  // }

  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Automation, Low Cost Location & Role FTE</h3>
        <div className="flex items-start gap-5">
          {/* Select region filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Region:</label>
            <Select
              name={"region"}
              options={createOptions(regions)}
              className="w-40 text-xs"
              // classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(regionFilter)}
              onChange={(e) => setRegionFilter(e.value)}
            />
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("AutoLowRole")}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      {/* Select Low Cost Location */}
      <div className="flex gap-2 items-center justify-start text-xs">
        <label htmlFor="Lower Cost Location">Lower Cost Location:</label>
        {LowCostLocation_Inputs["Lower Cost Location"].map(
          (location, id) =>
            id === regionIndex && (
              <InputField
                inputType={"select"}
                name={"Lower Cost Location"}
                index={regionIndex}
                currentState={LowCostLocation_Inputs}
                targetState={targetStateLowCost}
                handleOnChangeSelectInput={handleOnChangeSelectInput}
                options={low_cost_locations}
              />
            )
        )}
      </div>
      {/* Input Tables Automation & Low Cost Locations */}
      <div className="flex w-full gap-4 text-sm">
        {/* Filtered inputs by Region */}
        <div className="border rounded-md overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-300">
              <tr className="border-b">
                <th className="py-2 px-4 border-r" colSpan={1} rowSpan={2}>
                  Category
                </th>
                <th className="py-2 px-4 border-r" colSpan={3}>
                  2023
                </th>
                <th className="py-2 px-4 border-r" colSpan={3}>
                  2024
                </th>
                <th className="py-2 px-4" colSpan={3}>
                  2025
                </th>
              </tr>
              <tr className="border-b">
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4 border-r">Role 3</th>
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4 border-r">Role 3</th>
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4">Role 3</th>
              </tr>
            </thead>
            <tbody className="overflow-visible">
              <tr className="border-b">
                <td className="px-4 py-2 w-40 border-r font-bold">
                  Automation (%)
                </td>
                {Object.entries(Automation_Inputs)
                  .slice(2)
                  .map(([key, value], index) => (
                    <td key={index} className="px-4 py-2 w-28 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={key}
                        index={regionIndex}
                        currentState={Automation_Inputs}
                        targetState={targetStateAutomation}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                  ))}
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2 w-40 border-r font-bold">
                  Low Cost (%)
                </td>
                {Object.entries(LowCostLocation_Inputs)
                  .slice(2)
                  .map(([key, value], index) => (
                    <td key={index} className="px-4 py-2 w-28 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={key}
                        index={regionIndex}
                        currentState={LowCostLocation_Inputs}
                        targetState={targetStateLowCost}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                  ))}
              </tr>
              <tr className="border-b">
                <td className="px-4 py-4 w-40 border-r font-bold">Total (%)</td>
                {Object.entries(Automation_Inputs)
                  .slice(2)
                  .map(([key, value], index) => (
                    <td
                      key={index}
                      className={`px-4 py-2 w-28 border-r text-center font-semibold ${
                        value[regionIndex] +
                          LowCostLocation_Inputs[key][regionIndex] >
                        100
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      {value[regionIndex] +
                        LowCostLocation_Inputs[key][regionIndex] >
                      100
                        ? (showError = true)
                        : ""}
                      {value[regionIndex] +
                        LowCostLocation_Inputs[key][regionIndex]}
                      %
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Input Table for Role FTE */}
      <div className="flex w-full gap-4 text-sm">
        {regions.map(
          (region, index) =>
            index === regionIndex && (
              <div key={index} className="border rounded-md">
                <table className="w-full">
                  <thead className="bg-gray-300">
                    <tr className="border-b">
                      <th className="py-2 px-4 border-r">Role</th>
                      {RoleFTE_Inputs.Role.slice(
                        index * 3,
                        (index + 1) * 3
                      ).map((role, id) => (
                        <th
                          key={id}
                          className="py-2 px-4 border-r last-of-type:border-r-0"
                        >
                          {role}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="overflow-visible">
                    <th className="p-4 w-40 border-r">2022 FTE</th>
                    {RoleFTE_Inputs.Role.slice(index * 3, (index + 1) * 3).map(
                      (role, id) => (
                        <td className="px-4 w-28 border-r last-of-type:border-r-0">
                          <InputField
                            inputType={"number"}
                            name={"2022 FTE"}
                            index={index * 3 + id}
                            currentState={RoleFTE_Inputs}
                            targetState={targetStateRoleFTE}
                            handleNumberInputChange={handleNumberInputChange}
                          />
                        </td>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )
        )}
      </div>
      <div className="relative w-full">
        {showError && (
          <p className="absolute right-0 text-red-500 font-bold">
            Total perecentage should not be more than 100
          </p>
        )}
      </div>
    </>
  );
};

export default AutoLowRoleForm;
