import React from "react";

const InputSidebar = ({
  currentInputTab,
  setCurrentInputTab,
  AllInputTabs,
}) => {
  return (
    <div className="px-3 mt-8 select-none">
      {AllInputTabs.map((input, index) => (
        <div
          key={index}
          className={`py-2 px-3 text-xs w-full rounded-md cursor-pointer my-3 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow  ${
            currentInputTab == input
              ? "bg-gradient-to-r text-white"
              : "bg-gray-100 text-gray-500"
          }`}
          onClick={() => setCurrentInputTab(input)}
        >
          {input}
        </div>
      ))}
    </div>
  );
};

export default InputSidebar;
