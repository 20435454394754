import React, { useEffect, useState } from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import Select from "react-select";
import { colourStyles } from "../../../utils/Functions";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { IoCloudDownloadOutline, IoCloudUploadOutline } from "react-icons/io5";
import { LuLoader2 } from "react-icons/lu";
import { NumberFormatter } from "../../../utils/Utils";

const sampleFile =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/capmod/OpsTransformationSampleData.xlsx";

const Baseline = ({
  Baseline_Inputs,
  excelFile,
  handleFileUpload,
  defaultValues,
  targetState,
  handleNumberInputChange,
  uploadBaselineData,
  load,
}) => {
  const [regionFilter, setRegionFilter] = useState("Region 1");
  const [regionIndex, setRegionIndex] = useState(0);
  const [showUpload, setShowUpload] = useState(false);

  // set Region Index everytime region is changed
  useEffect(() => {
    const parts = regionFilter.split(" ");
    const regionId = parts[1] - 1;
    setRegionIndex(regionId);
  }, [regionFilter]);

  useEffect(() => {
    if (load) {
      const timeout = setTimeout(() => {
        setShowUpload(false);
      }, 500);

      return clearTimeout(timeout);
    }
  }, [load]);

  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Baseline</h3>
        <div className="flex items-start gap-5">
          {/* Select region filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Region:</label>
            <Select
              name={"region"}
              options={createOptions(regions)}
              className="w-40 text-xs"
              // classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(regionFilter)}
              onChange={(e) => setRegionFilter(e.value)}
            />
          </div>
          {/* Sample file download */}
          <a
            href={sampleFile}
            download
            className="flex w-fit gap-2 items-center justify-center cursor-pointer rounded-md px-4 py-2 relative shadow bg-secondary bg-opacity-75 hover:bg-opacity-100"
          >
            <IoCloudDownloadOutline className="text-xl" />
            <span>Download sample file</span>
          </a>
          {/* Upload file modal open */}
          <button
            className="flex w-fit gap-2 items-center justify-center cursor-pointer rounded-md px-4 py-2 relative shadow bg-primary text-white bg-opacity-75 hover:bg-opacity-100"
            type="button"
            onClick={() => setShowUpload(true)}
          >
            <IoCloudUploadOutline className="text-xl" />
            <span>Upload excel file here</span>
          </button>
          {/* Default File */}
          <button
            type="button"
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("Upload File")}
          >
            Default File
          </button>
        </div>
      </div>
      <p>
        Note: Not uploading the required file will default to using the data
        from the sample input file.
      </p>

      <div className="border rounded-md overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-300">
            <tr className="border-b">
              <th className="py-2 px-4 border-r" rowSpan={2}>
                LOB
              </th>
              <th className="py-2 px-4 border-r" colSpan={3}>
                Submissions
              </th>
              <th className="py-2 px-4" colSpan={3}>
                Inforce
              </th>
            </tr>
            <tr className="border-b">
              <th className="py-2 px-4 border-r">2021</th>
              <th className="py-2 px-4 border-r">2022</th>
              <th className="py-2 px-4 border-r">2023</th>
              <th className="py-2 px-4 border-r">2021</th>
              <th className="py-2 px-4 border-r">2022</th>
              <th className="py-2 px-4">2023</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(Baseline_Inputs).map(
              ([key, value]) =>
                regionFilter === key && (
                  <>
                    {Object.entries(value).map(([subkey, subValues], id) => (
                      <tr key={id} className="">
                        <td className="px-4 py-2 w-60 border-r">{subkey}</td>
                        {subValues.map((subValue, index) => (
                          <td key={index} className="px-4 py-2 w-60 border-r">
                            <input
                              type="text"
                              name={subkey}
                              id={subkey}
                              className="border-b-2 border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-full text-center"
                              value={NumberFormatter(
                                Baseline_Inputs[key][subkey][index] || 0
                              )}
                              onChange={(e) =>
                                handleNumberInputChange(
                                  e,
                                  index,
                                  targetState,
                                  key
                                )
                              }
                              maxLength={10}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                    {/* <tr className="border-t font-semibold">
                        <td className="px-4 py-2 border-r">Total</td>
                        <td className="px-4 py-2 border-r">
                          {Object.entries(value).map(
                            ([subkey, subValues], id) =>
                              subValues.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue;
                              }, 0)
                          )}
                        </td>
                      </tr> */}
                  </>
                )
            )}
          </tbody>
        </table>
      </div>

      {showUpload && (
        <>
          <div
            className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
            onClick={() => setShowUpload(false)}
          ></div>
          <div className="fixed w-2/3 z-30 flex items-end justify-end top-[50%] flex-col gap-5 left-[50%] translate-x-[-50%] translate-y-[-50%] p-10 bg-white rounded-lg">
            <div className="flex w-full gap-5 items-center justify-center border rounded-lg p-2 relative">
              <label
                htmlFor="file_input"
                className="file_btn w-full h-60 text-center flex flex-col gap-2 items-center justify-center cursor-pointer border-3 border-dashed border-primary bg-primary bg-opacity-20"
              >
                <IoCloudUploadOutline className="text-3xl" />
                <span>Drag & drop your excel file here or click to open</span>
                {excelFile && <span>File Name: {excelFile["name"]}</span>}
              </label>
              <input
                id="file_input"
                name="file"
                type="file"
                accept=".xlsx"
                className={`w-full h-full absolute opacity-0 top-0 left-0`}
                onChange={(e) => handleFileUpload(e)}
              />
            </div>
            {load ? (
              <button
                type="button"
                className="px-4 py-2 flex gap-2 shadow bg-gray-200 rounded-md"
              >
                <LuLoader2 className="text-xl animate-spin" />
                Uploading...
              </button>
            ) : (
              <button
                type="button"
                className="px-4 py-2 shadow bg-primary text-white bg-opacity-75 hover:bg-opacity-100 cursor-pointer rounded-md"
                onClick={() => {
                  uploadBaselineData();
                  setTimeout(() => {
                    setShowUpload(false);
                  }, 200);
                }}
              >
                Upload
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Baseline;
