import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({ data, industry }) => {
  const location = useLocation();
  const split = location.pathname.split("/");
  const [activeTab, setActiveTab] = useState(`/${split[split.length - 1]}`);

  useEffect(() => {
    setActiveTab(`/${split[split.length - 1]}`);
  }, [location]);

  return (
    <>
      {/* overflow-x-auto scrollbar-hide */}
      <div className="flex whitespace-nowrap select-none gap-2 border-b-2 overflow-x-scroll scrollbar-hide border-gray-600 text-gray-500 relative -mt-2">
        {data?.modules?.map((module) => {
          return module?.industries?.map(
            (item, index) =>
              item.slug === industry && (
                <Link
                  key={index}
                  to={`${data.slug + industry + module.slug}`}
                  className={`flex gap-2 items-center py-2 px-3 w-max rounded-t-md border-transparent from-gray-600 via-gray-700 to-gray-800 hover:shadow-sm hover:border-gray-300 ease-in-out ${
                    activeTab === module.slug
                      ? "text-white bg-gradient-to-r"
                      : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
                  } `}
                >
                  <span>{module.icon}</span>
                  <span className="text-xs font-semibold">{module.title}</span>
                </Link>
              )
          );
        })}
        {data?.components?.map((component) => {
          return component?.industries?.map(
            (item, index) =>
              item.slug === industry && (
                <Link
                  key={index}
                  to={`${data.slug + industry + component.slug}`}
                  className={`flex gap-2 items-center py-2 px-3 w-max rounded-t-md border-transparent from-gray-600 via-gray-700 to-gray-800 hover:shadow-sm hover:border-gray-300 ease-in-out ${
                    activeTab === component.slug
                      ? "text-white bg-gradient-to-r"
                      : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
                  } `}
                >
                  <span>{component.icon}</span>
                  <span className="text-xs font-semibold">
                    {component.title}
                  </span>
                </Link>
              )
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
