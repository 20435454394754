import React from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import InputField from "./InputField";

const PIRForm = ({
  PIR_Inputs,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  targetState,
  defaultValues
}) => {
  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">
          Productivity Improvement - Year End Targets
        </h3>
        <div className="flex items-start gap-5">
          <div className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("PIR")}
            >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      <div className="border w-fit rounded-md overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-300">
            <tr className="border-b">
              <th className="py-2 px-4 border-r">Region</th>
              <th className="py-2 px-4 border-r">2023F</th>
              <th className="py-2 px-4 border-r">2024F</th>
              <th className="py-2 px-4 border-r">2025F</th>
            </tr>
          </thead>
          <tbody>
            {regions.map((region, index) => (
              <tr key={index}>
                <td className="px-4 py-4 border-r w-44 text-center">
                  {region}
                </td>
                <td className="px-4 w-44 border-r">
                  <InputField
                    inputType="percentage"
                    name={"2023"}
                    index={index}
                    handlePercentageInputChange={handlePercentageInputChange}
                    handleBackspaceForPercentage={handleBackspaceForPercentage}
                    targetState={targetState}
                    currentState={PIR_Inputs}
                  />
                </td>
                <td className="px-4 w-44 border-r">
                  <InputField
                    inputType="percentage"
                    name={"2024"}
                    index={index}
                    handlePercentageInputChange={handlePercentageInputChange}
                    handleBackspaceForPercentage={handleBackspaceForPercentage}
                    targetState={targetState}
                    currentState={PIR_Inputs}
                  />
                </td>
                <td className="px-4 w-44">
                  <InputField
                    inputType="percentage"
                    name={"2025"}
                    index={index}
                    handlePercentageInputChange={handlePercentageInputChange}
                    handleBackspaceForPercentage={handleBackspaceForPercentage}
                    targetState={targetState}
                    currentState={PIR_Inputs}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PIRForm;
