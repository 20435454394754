import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PL_WorkInventory from "../../components/modules/leadPrioritizer/PL_WorkInventory";
import { UserContext } from "../../context/userContext";
import SelectIndustry from "../../components/modules/SelectIndustry";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { breadCrumbs } from "../../data/data";
import {
  IL_filterInputs,
  IL_formInputs,
} from "../../data/modules/leadPrioritizer";
import PL_ScoreCalculator from "../../components/modules/leadPrioritizer/PL_ScoreCalculator";
import UwPerformanceAnalysis from "../../components/modules/capMod/UwPerformanceAnalysis";
import CompetitiveLandscape from "../../components/modules/capMod/CompetitiveLandscape";
import Images from "../../components/modules/Images";
import PredictorTool from "../../components/modules/lapsePredictor/PredictorTool";
import PredictorRenewalTool from "../../components/modules/lapsePredictor/PredictorRenewalTool";

const PersonalLines = ({ solution }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.modules.map((module) => {
      if (module.components) {
        module.components.map((component) => {
          if (component.slug.substring(1, component.slug.length) === slug) {
            setModules(module);
            setPageData(component);
            return setIsSlugCorrect(true);
          } else {
            return null;
          }
        });
      } else {
        if (module.slug.substring(1, module.slug.length) === slug) {
          setModules(solution);
          setPageData(module);
          return setIsSlugCorrect(true);
        } else {
          return null;
        }
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(
      `${modules?.title === solution?.title ? "" : `${modules?.title}: `} ${
        pageData?.title
      }`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData]);

  return (
    <>
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-2 w-full min-h-[35rem] h-full">
          <div className="flex justify-between">
            <Breadcrumbs crumbs={crumbs} />
            <SelectIndustry
              industries={pageData.industries}
              selected={{
                label: "Personal Lines - P&C",
                value: "personal-lines-pnc",
              }}
            />
          </div>
          <div className="my-5">
            <Tabs data={modules} industry={"/personal-lines-pnc"} />
          </div>
          <div className={`w-full pb-10 h-full min-h-[35rem]`}>
            {/* Lead Prioritizer Modules */}
            {pageData.title === "Work Inventory" && <PL_WorkInventory />}
            {pageData.title === "Score Calculator" && <PL_ScoreCalculator />}
            {pageData.title === "Attribute Analysis" && (
              <PowerBI reportId={pageData.dashboards.personalLines.reportId} />
            )}
            {pageData.title === "Priority Bin Dashboard" && (
              <PowerBI reportId={pageData.dashboards.personalLines.reportId} />
            )}

            {/* Scenario Planner Modules */}
            {pageData.title === "Unit Cost" &&
              pageData.excelModels.industryAgnostic.iframe}
            {pageData.title === "Zero Based Budget" &&
              pageData.excelModels.industryAgnostic.iframe}

            {/* CapMod Modules */}
            {pageData.title === "Operations Transformation Planning" &&
              pageData.excelModels.industryAgnostic.iframe}

            {pageData.title === "Productivity Charts" && (
              <Images data={pageData} />
            )}
            {pageData.title === "Productivity Comparison" && (
              <Images data={pageData} />
            )}
            {pageData.title === "Latent Capacity" && <Images data={pageData} />}
            {pageData.title === "Payband Productivity" && (
              <Images data={pageData} />
            )}
            {pageData.title === "UW Performance Analysis" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "Competitive Landscape" && (
              <CompetitiveLandscape />
            )}
            {pageData.title === "Resource Navigator" && "Coming Soon!"}

            {pageData.title === "NBUW Staffing Model" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "IT Staffing Model" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}

            {/* Lapse Predictor */}
            {pageData.title === "Renewal List" && <PredictorRenewalTool />}
            {pageData.title === "Lapse Prediction Tool" && <PredictorTool />}

            {/* 4Qast Modules */}
            {pageData.title === "Sales 4Qast" && "Coming Soon!"}
            {pageData.title === "4Qast Dashboard" && (
              <PowerBI reportId={pageData.dashboards.personalLines.reportId} />
            )}
            {pageData.title === "4Qast Drill Down" && (
              <PowerBI reportId={pageData.dashboards.personalLines.reportId} />
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default PersonalLines;
