import React, { useEffect, useState } from "react";
import { API_FILE } from "../../../api/api";
import { CSVLink } from "react-csv";
import { CSVData, Lapse_dbColumns } from "../../../data/modules/lapsePredictor";
import { IoCloudUploadOutline } from "react-icons/io5";
import Select from "react-select";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
} from "../../utils/Utils";
import {
  Lapse_requiredFieldNames,
  Lapse_tableColumns,
} from "../../../data/modules/lapsePredictor";
import { LuLoader2 } from "react-icons/lu";
import TablePaginate from "../../utils/TablePaginate";
import { colourStyles } from "../../utils/Functions";
import { RiSearchLine } from "react-icons/ri";

const PredictorRenewalTool = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [csvHeader, setCsvHeader] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [lapseScoreData, setLapseScoreData] = useState(null);
  const [missingCols, setMissingCols] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [ascDesc, setAscDesc] = useState(null);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [showEntries, setShowEntries] = useState(20);
  const [searchedData, setSearchedData] = useState(null);

  const openModal = () => {
    setShowModal(!showModal);
  };

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // handle on upload file
  const handleOnInputChange = (e) => {
    setError(null);
    setMissingCols(null);
    if (e.target.files.length === 0) return;
    // console.log(e.target.files);
    const isCSV = e.target.files[0]["name"].split(".");
    // console.log(isCSV[isCSV.length - 1]);
    if (isCSV[isCSV.length - 1] === "csv") {
      setFile(e.target.files[0]);
    } else {
      setError("Only csv type is accepted as input!");
    }
  };

  // handle submit to get table data
  const getLapseScores = (e) => {
    e.preventDefault();
    setMissingCols(null);
    setLoad(true);
    const formData = new FormData();
    formData.append("file", file);
    API_FILE.post("/lapse-predictor", formData)
      .then(({ data }) => {
        // console.log(data);
        let score = data.data;
        setLapseScoreData(JSON.parse(score));
        setShowModal(false);
        setLoad(false);
      })
      .catch(({ response }) => {
        console.log(response.data.errorResponse);
        setMissingCols(response.data.errorResponse);
        setLoad(false);
      });
  };

  useEffect(() => {
    const headers = [];
    Lapse_requiredFieldNames.forEach((item, index) => {
      headers.push({ label: item, key: item });
    });
    setCsvHeader(headers);
    setCsvData(CSVData);
    // console.log(lapseScoreData);
  }, [lapseScoreData]);

  // sorting table
  useEffect(() => {
    // console.log("sorting", ascDesc, sortColumn);
    if (ascDesc && sortColumn) {
      const data = [...lapseScoreData];

      function compareString(a, b) {
        if (ascDesc === "ASC") {
          // console.log(a[sortColumn]);
          return a[sortColumn]?.localeCompare(b[sortColumn]);
        } else if (ascDesc === "DESC") {
          return b[sortColumn]?.localeCompare(a[sortColumn]);
        } else {
          return a[sortColumn]?.localeCompare(b[sortColumn]);
        }
      }

      function compareNumbers(a, b) {
        if (ascDesc === "ASC") {
          return a[sortColumn] - b[sortColumn];
        } else if (ascDesc === "DESC") {
          return b[sortColumn] - a[sortColumn];
        } else {
          return a[sortColumn] - b[sortColumn];
        }
      }

      function compareMonths(a, b) {
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        if (ascDesc === "ASC") {
          return months.indexOf(a[sortColumn]) - months.indexOf(b[sortColumn]);
        } else if (ascDesc === "DESC") {
          return months.indexOf(b[sortColumn]) - months.indexOf(a[sortColumn]);
        } else {
          return months.indexOf(a[sortColumn]) - months.indexOf(b[sortColumn]);
        }
      }

      if (
        sortColumn === "Product Line" ||
        sortColumn === "Coverage" ||
        sortColumn === "Market Segment" ||
        sortColumn === "SIC Category" ||
        sortColumn === "Territory" ||
        sortColumn === "suggested_action"
      ) {
        data.sort(compareString);
      } else if (sortColumn === "Renewal Month") {
        data.sort(compareMonths);
      } else {
        data.sort(compareNumbers);
      }

      setLapseScoreData(data);
    }
  }, [ascDesc, sortColumn]);

  // Handle Searching in array of object
  const handleSearchFor = (e) => {
    const value = e.target.value.toLowerCase(); // Ensure case-insensitive search
    if (value.length > 0) {
      const results = lapseScoreData.filter((object) => {
        // Iterate through object properties using Object.entries
        for (const [key, propertyValue] of Object.entries(object)) {
          // Convert propertyValue to a string for case-insensitive comparison
          const stringifiedPropertyValue = String(propertyValue).toLowerCase();

          // Check if propertyValue partially matches search value (optional)
          if (stringifiedPropertyValue.includes(value)) {
            return true; // Include object in results if there's a match
          }
        }
        return false; // Exclude object if no match found on any property
      });
      console.log(results);
      setSearchedData(results);
      return null;
    } else {
      setSearchedData(null);
    }
  };

  return (
    <div className="w-full text-sm">
      {/* Table Actions */}
      <div className="flex justify-between my-5 text-[11px]">
        {/* Upload CSV */}
        <div className="flex gap-4 items-center border w-fit p-2 px-4 rounded-lg shadow">
          <p>
            Upload CSV <sup className="text-red-600">*</sup> :{" "}
          </p>
          <button
            className="bg-tertiary text-white px-4 py-1 rounded-md"
            onClick={() => openModal()}
          >
            Choose a file
          </button>
        </div>
        {lapseScoreData && (
          <div className="flex items-center justify-center gap-10">
            {/* Show number of Entries at a time filter */}
            <div className="flex gap-2 items-center h-fit">
              Show <br /> entries:
              <Select
                className="w-24"
                classNamePrefix="select"
                isSearchable={true}
                name="underwriter"
                options={[
                  { value: "20", label: "20" },
                  { value: "40", label: "40" },
                  { value: "60", label: "60" },
                  { value: "80", label: "80" },
                  { value: "100", label: "100" },
                ]}
                onChange={(e) => handleEntriesChange(e)}
                defaultValue={{
                  value: "20",
                  label: "20",
                }}
                styles={colourStyles}
              />
            </div>
            {/* Search Filter */}
            <div className="relative h-fit">
              <RiSearchLine className="absolute top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
              <input
                type="text"
                name="searchFor"
                id="searchFor"
                placeholder="Search Table.."
                className="rounded-lg indent-10 px-2 py-[10px] border focus:outline-none w-56 focus:outline-gray-200"
                onChange={(e) => handleSearchFor(e)}
              />
            </div>
          </div>
        )}
      </div>

      {/* Modal to upload csv file */}
      {showModal && (
        <>
          <div
            className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="fixed z-30 top-[50%] left-[50%] translate-x-[-50%] rounded-lg translate-y-[-50%] bg-white p-4 w-2/3 xl:w-1/2">
            <div className="border-b p-2 pt-0">
              Required Fields in CSV File<sup className="text-red-600">*</sup>
            </div>
            {/* List of Columns */}
            <div className="w-full p-4 border-b">
              <ul className="grid grid-cols-3 pl-4 list-disc place-content-between relative">
                {Lapse_requiredFieldNames.map((item, index) =>
                  missingCols ? (
                    <li
                      key={index}
                      className={`p-1 ${
                        missingCols.includes(item) ? "text-red-500" : ""
                      }`}
                    >
                      {item}
                    </li>
                  ) : (
                    <li key={index} className="p-1">
                      {item}
                    </li>
                  )
                )}
                {missingCols && (
                  <span className="p-1 text-xs text-red-500 absolute bottom-0 right-0">
                    Some fields are missing
                  </span>
                )}
                {error && (
                  <span className="p-1 text-xs text-red-500 absolute bottom-0 right-0">
                    {error}
                  </span>
                )}
              </ul>
            </div>
            <div className="flex gap-2 justify-between items-center w-full mt-4 mb-2">
              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename="lapse-testing-data"
                className="w-fit px-3 py-2 border rounded-md hover:bg-green-500 hover:text-white"
              >
                Download sample input data
              </CSVLink>

              {/* Input file form */}
              <form
                onSubmit={(e) =>
                  file ? getLapseScores(e) : e.preventDefault()
                }
                className="flex w-2/3 justify-between items-center"
              >
                <div className="flex gap-2 items-center">
                  <label
                    htmlFor="file_input"
                    className="file_btn px-3 py-2 flex gap-2 items-center cursor-pointer border rounded-md hover:bg-tertiary hover:text-white"
                  >
                    <IoCloudUploadOutline className="text-xl" />
                    <span>Upload csv here</span>
                  </label>
                  <input
                    hidden
                    id="file_input"
                    name="csv_file"
                    type="file"
                    accept=".csv"
                    className="file_input"
                    onChange={(e) => handleOnInputChange(e)}
                  />
                  {file && file["name"]}
                </div>
                <div>
                  {load ? (
                    <button
                      type="submit"
                      className={`rounded-md px-4 py-2 bg-gray-300 flex gap-2`}
                    >
                      <LuLoader2 className="text-xl animate-spin" />
                      Uploading
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className={`rounded-md px-4 py-2 bg-primary text-white ${
                        file ? "hover:bg-opacity-90" : "cursor-default"
                      }`}
                      onClick={(e) =>
                        file ? getLapseScores(e) : e.preventDefault()
                      }
                    >
                      Upload
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {/* Table with data */}
      {lapseScoreData ? (
        <>
          {/* Lapse Score Table */}
          <div className="shadow overflow-x-auto mt-5">
            <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
              <thead className="capitalize bg-gray-300 text-gray-700">
                <tr className="p-2 transition-all duration-300 ease-in-out text-center">
                  {Lapse_tableColumns.map((column, id) => (
                    <th
                      key={id}
                      className={`p-1 cursor-pointer hover:underline underline-offset-2 select-none w-40 min-w-max ${
                        sortColumn === Lapse_dbColumns[id]
                          ? "text-tertiary"
                          : ""
                      }`}
                      onClick={() => {
                        if (sortColumn === Lapse_dbColumns[id]) {
                          if (ascDesc === "ASC") {
                            setAscDesc("DESC");
                          } else {
                            setAscDesc("ASC");
                          }
                        } else {
                          setSortColumn(Lapse_dbColumns[id]);
                          setAscDesc("ASC");
                        }
                      }}
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {searchedData
                  ? searchedData.map((data, id) => (
                      <tr
                        key={id}
                        className={`border-b hover:bg-gray-200 break-words text-center ${
                          id % 2 === 0 ? "" : "bg-gray-50"
                        }`}
                      >
                        {Lapse_dbColumns.map((col, id) => (
                          <td
                            className={`p-2 ${
                              col === "Lapse Score"
                                ? data["Lapse Score"] >= 0 &&
                                  data["Lapse Score"] <= 500
                                  ? "text-green-500 bg-green-100 font-bold"
                                  : data["Lapse Score"] >= 501 &&
                                    data["Lapse Score"] <= 750
                                  ? "text-amber-500 bg-amber-100 font-bold"
                                  : "text-red-500 bg-red-100 font-bold"
                                : ""
                            } ${
                              col === "Industry" ||
                              col === "Territory" ||
                              col === "Market Segment"
                                ? "text-left"
                                : ""
                            }`}
                            key={id}
                          >
                            {col === "Renewal Premium"
                              ? CurrencyFormatter(data[`${col}`])
                              : col === "Lives" ||
                                col === "Lapse Score" ||
                                col === "A/E Ratio" ||
                                col === "Rate Change"
                              ? NumberFormatter(data[`${col}`])
                              : col === "Loss Ratio"
                              ? PercentFormatter(data[`${col}`])
                              : data[`${col}`]}
                          </td>
                        ))}
                      </tr>
                    ))
                  : lapseScoreData.map((data, id) => (
                      <tr
                        key={id}
                        className={`border-b hover:bg-gray-200 break-words text-center ${
                          id % 2 === 0 ? "" : "bg-gray-50"
                        }`}
                      >
                        {Lapse_dbColumns.map((col, id) => (
                          <td
                            className={`p-2 ${
                              col === "Lapse Score"
                                ? data["Lapse Score"] >= 0 &&
                                  data["Lapse Score"] <= 500
                                  ? "text-green-500 bg-green-100 font-bold"
                                  : data["Lapse Score"] >= 501 &&
                                    data["Lapse Score"] <= 750
                                  ? "text-amber-500 bg-amber-100 font-bold"
                                  : "text-red-500 bg-red-100 font-bold"
                                : ""
                            } ${
                              col === "Industry" ||
                              col === "Territory" ||
                              col === "Market Segment"
                                ? "text-left"
                                : ""
                            }`}
                            key={id}
                          >
                            {col === "Renewal Premium"
                              ? CurrencyFormatter(data[`${col}`])
                              : col === "Lives" ||
                                col === "Lapse Score" ||
                                col === "A/E Ratio" ||
                                col === "Rate Change"
                              ? NumberFormatter(data[`${col}`])
                              : col === "Loss Ratio"
                              ? PercentFormatter(data[`${col}`])
                              : data[`${col}`]}
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          {/* Table Pagination */}
          <TablePaginate
            setPage={setPage}
            showEntries={showEntries}
            page={page}
            table={searchedData ? searchedData : lapseScoreData}
          />
        </>
      ) : (
        <div className="shadow overflow-x-auto mt-5 w-full relative">
          <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
            <thead className="capitalize bg-gray-300 text-gray-700 w-full">
              <tr className="p-2 transition-all duration-300 ease-in-out text-center w-full">
                {Lapse_tableColumns.map((column, id) => (
                  <th
                    key={id}
                    className="p-1 transition-all duration-300 ease-in-out"
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
                <td className="p-2 py-4"></td>
              </tr>
            </tbody>
          </table>
          <span className="absolute bottom-2 text-xs text-gray-500 left-[42%]">
            Upload a CSV file to see data here
          </span>
        </div>
      )}
    </div>
  );
};

export default PredictorRenewalTool;
