import React, { useEffect, useRef, useState } from "react";
import * as L from "leaflet";
import { MapContainer, GeoJSON, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { SketchPicker } from "react-color";
import Select from "react-select";
import { colourStyles } from "../../utils/Functions";
import * as turf from "@turf/turf";
import geoData from "../../../data/json/demo-geojson-v3.json";
import {
  calculatePercentile,
  drawingObject,
  metric,
  attributeFormatter,
  tableAttributeOptions,
  initialOptions,
  stateLevelAttributeFormatter,
  createOptionsForGradient,
  mapTooltipColumns,
  reduceGeoJson,
  calculateAvgHHIncome,
  calculateSalesGrowth,
} from "../../utils/LeafletMapUtils";

import chroma from "chroma-js";
import { FaXmark } from "react-icons/fa6";
import { TbEdit } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { TfiPaintBucket } from "react-icons/tfi";
import { FaServer } from "react-icons/fa";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const initialLayerInfo = {
  selectedLayer: null,
  referenceName: "",
  shapeType: "",
  color: "#C20805",
  layerId: null,
  selectedRegionGeoJson: [],
  isEditing: false,
};

const TerritoryDesigner = () => {
  let tooltipTimeout;

  const mapContainerRef = useRef(null);
  const featureRef = useRef(null);
  const editControlRef = useRef(null);
  const mapRef = useRef();

  const [selectedStateLayer, setSelectedStateLayer] = useState(null);

  const [mode, setMode] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [staticOverall, setStaticOverall] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [filteredGeoJson, setFilteredGeoJson] = useState([]);
  const [totalFilteredGeoJson, setTotalFilteredGeoJson] = useState(null);
  const [restFilteredGeoJson, setRestFilteredGeoJson] = useState([]);
  const [drawingMode, setDrawingMode] = useState("");
  const [filterMapGradient, setFilterMapGradient] = useState({
    // Metric: "Sales Opportunity",
    Metric: "Market Sales Premium",
  });
  // Export data states
  const csvTableRef = useRef(null);
  const csvTableOverallRef = useRef(null);
  const [csvTableHeader, setCsvTableHeader] = useState([]);
  const [csvTableData, setCsvTableData] = useState([]);
  const [csvTableOverallHeader, setCsvTableOverallHeader] = useState([]);
  const [csvTableOverallData, setCsvTableOverallData] = useState([]);

  const [showTableData, setShowTableData] = useState(false);
  const [toggleMap, setToggleMap] = useState(true);
  const [layerInfo, setLayerInfo] = useState(initialLayerInfo);
  const [removedRegion, setRemovedRegion] = useState([]);
  const [optionalAttributes, setOptionalAttributes] = useState(initialOptions);
  const [showError, setShowError] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const clickToAddRef = useRef(null);
  const currStateRef = useRef();
  const addStateDuringEditingRef = useRef(null);

  const tempLayerRef = useRef([]);

  // get static overall numbers
  useEffect(() => {
    let temp = {};
    if (optionalAttributes.length > 0) {
      optionalAttributes.forEach((option) => {
        if (option === "Premium Share") {
          temp[option] =
            temp["Client Sales Premium"] / temp["Market Sales Premium"];
        } else if (option === "Agent Share") {
          temp[option] = temp["Client Agents"] / temp["Market Agents"];
        } else if (option === "Policy Share") {
          temp[option] =
            temp["Client Sold Policies"] / temp["Market Sold Policies"];
        } else if (option === "Average Household Income") {
          temp[option] = calculateAvgHHIncome(geoData.features);
        } else if (option === "Incr. Sales Growth %") {
          temp[option] = calculateSalesGrowth(geoData.features, temp);
        } else {
          temp[option] = reduceGeoJson(geoData.features, option);
        }
      });
    }
    setStaticOverall(temp);
    if (filteredGeoJson.length > 0) {
      const tempGeoData = [...filteredGeoJson];
      calculateFilteredGeoData(tempGeoData, "", temp);
    }
    // mapOptionalTooltip = [...optionalAttributes];
  }, [optionalAttributes]);

  const calculateFilteredGeoData = async (
    filterGeoData,
    mode = "",
    tempStatic = null
  ) => {
    if (mode != "editing") {
      setShowTableData(false);
      setToggleMap(false);
    }

    // Calculate Territory Level Data
    filterGeoData.forEach((feature, index) => {
      if (optionalAttributes.length > 0) {
        optionalAttributes.forEach((option) => {
          if (option === "Premium Share") {
            feature[option] =
              feature["Client Sales Premium"] / feature["Market Sales Premium"];
          } else if (option === "Agent Share") {
            feature[option] =
              feature["Client Agents"] / feature["Market Agents"];
          } else if (option === "Policy Share") {
            feature[option] =
              feature["Client Sold Policies"] / feature["Market Sold Policies"];
          } else if (option === "Average Household Income") {
            feature[option] = calculateAvgHHIncome(feature.geoJson);
          } else if (option === "Incr. Sales Growth %") {
            feature[option] = calculateSalesGrowth(feature.geoJson, feature);
          } else {
            feature[option] = reduceGeoJson(feature.geoJson, option);
          }
        });
      }
    });

    if (mode === "editing") {
      return filterGeoData;
    }

    const totalData = await calculateTotalFilterGeoData(filterGeoData);
    const restData = await calculateRestFilterGeoData(
      filterGeoData,
      totalData,
      tempStatic
    );

    setFilteredGeoJson(filterGeoData);
    setTotalFilteredGeoJson(totalData);
    setRestFilteredGeoJson(restData);
    setShowTableData(true);

    setTimeout(() => {
      setToggleMap(true);
    }, 100);
  };

  // Calculate Total Filtered GeoData
  const calculateTotalFilterGeoData = async (filterGeoData) => {
    console.log(filterGeoData);
    const total = {};
    if (optionalAttributes.length > 0) {
      const data = [];
      filterGeoData.forEach((feature) =>
        feature.geoJson.forEach((object) => {
          data.push(object);
        })
      );
      optionalAttributes.forEach((option) => {
        if (option === "Premium Share") {
          total[option] =
            total["Client Sales Premium"] / total["Market Sales Premium"];
        } else if (option === "Agent Share") {
          total[option] = total["Client Agents"] / total["Market Agents"];
        } else if (option === "Policy Share") {
          total[option] =
            total["Client Sold Policies"] / total["Market Sold Policies"];
        } else if (option === "Average Household Income") {
          total[option] = calculateAvgHHIncome(data);
        } else if (option === "Incr. Sales Growth %") {
          total[option] = calculateSalesGrowth(data, total);
        } else {
          total[option] = filterGeoData.reduce(
            (sum, object) => sum + object[option],
            0
          );
        }
      });
    }
    return total;
  };

  const calculateRestFilterGeoData = async (
    filterGeoData,
    total,
  ) => {
    const rest = {};
    if (optionalAttributes.length > 0) {
      const markedStates = [];
      filterGeoData.forEach((feature) => {
        feature.geoJson.forEach((object) => {
          markedStates.push(object.properties.State);
        });
      });

      const remainingStates = geoData.features.filter(
        (feature) => !markedStates.includes(feature.properties.State)
      );
      optionalAttributes.forEach((option) => {
        if (option === "Premium Share") {
          rest[option] =
            rest["Client Sales Premium"] / rest["Market Sales Premium"];
        } else if (option === "Agent Share") {
          rest[option] = rest["Client Agents"] / rest["Market Agents"];
        } else if (option === "Policy Share") {
          rest[option] =
            rest["Client Sold Policies"] / rest["Market Sold Policies"];
        } else if (option === "Average Household Income") {
          rest[option] = calculateAvgHHIncome(remainingStates);
        } else if (option === "Incr. Sales Growth %") {
          rest[option] = calculateSalesGrowth(remainingStates, rest);
        } else {
          rest[option] = staticOverall[option] - total[option];
        }
      });
    }

    return rest;
  };

  // Conditions on each feature
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: (e) => {
        const layer = e.target;
        const stateData = feature.properties;
        if (!clickToAddRef.current) {
          if (currStateRef.current === stateData["State"]) {
            currStateRef.current = null;
            setSelectedState(null);
            setSelectedStateLayer(null);
          } else {
            setSelectedState({
              name: stateData["State"],
              // Premium
              "Market Sales Premium": stateData["Market Sales Premium"],
              "Client Sales Premium": stateData["Client Sales Premium"],
              "Premium Share": stateData["Premium Share"],
              // Agents
              "Market Agents": stateData["Market Agents"],
              "Client Agents": stateData["Client Agents"],
              "Agent Share": stateData["Agent Share"],
              // Policies
              "Market Sold Policies": stateData["Market Sold Policies"],
              "Client Sold Policies": stateData["Client Sold Policies"],
              "Policy Share": stateData["Policy Share"],
              // Household Data and Sales Opty
              "Average Household Income": stateData["Average Household Income"],
              "Total Sales Opportunity": stateData["Total Sales Opportunity"],
              "Incr. Sales Growth %": stateData["Incr. Sales Growth %"],
            });
            setSelectedStateLayer(layer);
            currStateRef.current = stateData["State"];
          }
        } else {
          // Add Territory when clicking on state while drawing shape
          setLayerInfo((prev) => {
            if (prev.isEditing) {
              // add state during editing
              let isDuplicateState = false;
              prev.selectedRegionGeoJson.forEach((data) => {
                if (data.properties.State === stateData["State"]) {
                  isDuplicateState = true;
                }
              });

              if (
                !isDuplicateState &&
                addStateDuringEditingRef.current?.length > 0
              ) {
                addStateDuringEditingRef.current.forEach((data) => {
                  if (data.properties.State === stateData["State"]) {
                    isDuplicateState = true;
                  }
                });
              }

              if (!isDuplicateState) {
                addStateDuringEditingRef.current = [
                  ...addStateDuringEditingRef.current,
                  feature,
                ];
              }
              return { ...prev };
            } else {
              if (
                prev.selectedRegionGeoJson.some(
                  (data) => data.properties.State === feature.properties.State
                )
              ) {
                return {
                  ...prev,
                };
              } else {
                return {
                  ...prev,
                  selectedRegionGeoJson: [
                    ...prev.selectedRegionGeoJson,
                    feature,
                  ],
                };
              }
            }
          });
          layer.setStyle({
            weight: 0.5,
            color: "black",
            fillOpacity: 1,
          });
        }
      },
      mouseover: (e) => {
        if (feature.properties) {
          const tooltipContent = `
          <div classname="flex flex-col gap-2">
            <div classname="flex font-bold">
              State: ${feature.properties["State"]}
            </div>
            ${mapTooltipColumns
              .map((option) => {
                return `
                    <div classname="flex">
                      ${getLabel(option)}: ${stateLevelAttributeFormatter[
                  option
                ](feature.properties[option])}
                    </div>
                `;
              })
              .join("")}
          </div>
        `;
          layer.bindTooltip(tooltipContent);
        }
        tooltipTimeout = setTimeout(() => {
          const layer = e.target;
          layer.openTooltip(e.latlng);
        }, 700);
      },
      mouseout: (e) => {
        clearTimeout(tooltipTimeout);
        const layer = e.target;
        // layer.closeTooltip();
        if (!selectedStateLayer || selectedStateLayer === layer) {
          layer.closeTooltip();
          layer.unbindTooltip();
        }
      },
    });
  };

  // On click Outside the map unselect the selected state
  useEffect(() => {
    if (mapContainerRef.current) {
      const map = mapContainerRef.current;

      const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        const clickedLayer = Object.values(map._layers).find(
          (layer) =>
            layer.feature &&
            layer.getBounds &&
            layer.getBounds().contains([lat, lng])
        );
        if (!clickedLayer) {
          setSelectedStateLayer(null);
          setSelectedState(null);
          currStateRef.current = null;
        }
      };

      map.on("click", handleMapClick);

      return () => {
        map.off("click", handleMapClick);
      };
    }
  }, [selectedState]);

  // On Clicking on state change the style of that state.
  useEffect(() => {
    if (selectedStateLayer) {
      selectedStateLayer.setStyle({
        fillOpacity: 1,
        opacity: 1,
      });
    }
  }, [selectedStateLayer]);

  // first render style
  const style = (feature) => {
    return {
      fillColor: mapPolygonColorToDensity(
        feature.properties[filterMapGradient["Metric"]]
      ),
      weight: 0.5,
      opacity: 1,
      color: "gray",
      fillOpacity: selectedStateLayer ? 0.3 : 1,
    };
  };

  // For individual Territory Style
  const filteredStyle = (color) => {
    return {
      fillColor: color,
      weight: 0.5,
      opacity: 1,
      color: "gray",
      fillOpacity: selectedStateLayer ? 0.3 : 1,
    };
  };

  // Gradient for first render
  const mapPolygonColorToDensity = (density) => {
    let values = [];

    values = geoData.features.map(
      (feature) => feature.properties[filterMapGradient["Metric"]]
    );

    // const percentiles = [10, 30, 50, 70, 90];

    const highestValue = Math.max(...values);
    const medianValue = calculatePercentile(values, 90);
    const medianValue2 = calculatePercentile(values, 70);
    const medianValue3 = calculatePercentile(values, 50);
    const medianValue4 = calculatePercentile(values, 30);
    const medianValue5 = calculatePercentile(values, 10);
    const lowestValue = Math.min(...values);

    const colorScale = chroma
      .scale(["#3b8a14", "#7ebc48", "#a1d062", "#D8DA92", "#dddf90", "#e7e198"])
      .domain([
        highestValue,
        medianValue,
        medianValue2,
        medianValue3,
        medianValue4,
        medianValue5,
        lowestValue,
      ]);
    const color = colorScale(density).rgba();
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  };

  // Check for duplicate
  const checkforDuplicates = async (newFeatures, mode) => {
    let filterData = [...filteredGeoJson];
    let removeIds = [];
    newFeatures.forEach((newFeature) => {
      filterData.forEach((feature) => {
        if (mode === "editing" && feature["id"] === layerInfo.layerId) return;
        const tempGeoJson = [];
        feature.geoJson.forEach((data) => {
          if (data.properties.State !== newFeature.properties.State) {
            tempGeoJson.push(data);
          }
        });

        if (tempGeoJson.length > 0) {
          feature["geoJson"] = tempGeoJson;
        }

        if (
          feature["geoJson"].length === 1 &&
          feature["geoJson"][0]["properties"]["State"] ===
            newFeature.properties.State
        ) {
          removeIds.push(feature["id"]);
        }
      });
    });

    if (removeIds.length > 0)
      filterData = filterData.filter((data) => {
        if (removeIds.includes(data["id"])) {
          data.layer.remove();
        } else {
          return data;
        }
      });

    if (mode === "editing") {
      return await calculateFilteredGeoData(filterData, "editing");
    }

    return filterData;
  };

  // While plotting first shape
  const handleDrawStart = (e) => {
    if (tempLayerRef.current.length > 0) {
      tempLayerRef.current.forEach((layer) => {
        if (mapContainerRef.current) {
          mapContainerRef.current.removeLayer(layer);
        }
      });
      tempLayerRef.current = []; // Clear the temp array
    }

    // setLayerInfo(current => ({ ...initialLayerInfo }));
    // setLayerInfo({ ...initialLayerInfo })
    setLayerInfo(initialLayerInfo);
  };

  // first polygon when created
  const handleCreated = (e) => {
    const { layerType, layer } = e;
    let featuresWithin = [];
    // For ploygon
    if (layerType === "polygon") {
      const drawnGeoJSON = layer.toGeoJSON();
      const coordinates = layer
        .getLatLngs()[0]
        .map((latlng) => [latlng.lat, latlng.lng]);
      console.log(coordinates);
      featuresWithin = geoData.features.filter((feature) => {
        return turf.intersect(drawnGeoJSON, feature) !== null;
      });
    }

    // For Rectangle
    if (layerType === "rectangle") {
      const bounds = layer.getBounds();
      const bboxpolygon = turf.bboxPolygon([
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ]);

      // filter the geojson data
      featuresWithin = geoData.features.filter((feature) => {
        return turf.booleanIntersects(bboxpolygon, feature);
      });
    }

    setLayerInfo({
      ...layerInfo,
      selectedRegionGeoJson: featuresWithin,
      selectedLayer: layer,
      shapeType: layerType,
    });
    tempLayerRef.current.push(layer);
    clickToAddRef.current = true;
  };

  // Remove Territory on click on cross mark
  const handleRemoveTerritory = (territory, mode = "") => {
    const state = territory.properties.State;
    if (layerInfo.isEditing) {
      setRemovedRegion((prev) => [...prev, state]);

      if (mode === "edit" && addStateDuringEditingRef.current.length > 0) {
        addStateDuringEditingRef.current =
          addStateDuringEditingRef.current.filter(
            (feature) => feature.properties.State !== state
          );
      }
    }

    setLayerInfo((prev) => ({
      ...prev,
      selectedRegionGeoJson: prev.selectedRegionGeoJson.filter(
        (feature) => feature.properties.State !== state
      ),
    }));
  };

  // Save territory
  const handleCreateTerritory = async () => {
    if (!layerInfo.referenceName) return;
    tempLayerRef.current = [];

    if (layerInfo.selectedLayer?.setStyle) {
      layerInfo.selectedLayer?.setStyle({
        color: "transparent",
        opacity: 0,
        weight: 0,
        dashArray: "",
      });
    } else if (layerInfo.selectedLayer?.setRadius) {
      // for circles
      layerInfo.selectedLayer?.setStyle({ color: "transparent" });
    }
    layerInfo.selectedLayer
      ?.bindTooltip(layerInfo.referenceName, {
        permanent: true,
        direction: "center",
        className: "",
      })
      .openTooltip();

    const features = [...layerInfo.selectedRegionGeoJson];

    const newFeatures = {
      id: layerInfo.selectedLayer._leaflet_id,
      color: layerInfo.color,
      geoJson: features,
      name: layerInfo.referenceName,
      layer: layerInfo.selectedLayer,
      shapeType: layerInfo.shapeType,
    };

    const filteredData = await checkforDuplicates(features, "drawing");
    const finalData = [...filteredData, newFeatures];
    await calculateFilteredGeoData(finalData);

    setLayerInfo(initialLayerInfo);
    setShowColorPicker(false);
    setDrawingMode("");
    clickToAddRef.current = false;
  };

  const enableEditing = (id) => {
    filteredGeoJson.forEach((feature, index) => {
      if (feature["id"] === id) {
        feature.layer.editing.enable();
        setLayerInfo({
          ...layerInfo,
          layerId: id,
          isEditing: true,
          selectedLayer: feature.layer,
          color: feature.color,
          referenceName: feature.name,
          selectedRegionGeoJson: feature.geoJson,
          shapeType: feature.shapeType,
        });
        clickToAddRef.current = true;
        addStateDuringEditingRef.current = [];
      }
    });
  };

  const saveEditing = async () => {
    setToggleMap(false);

    layerInfo.selectedLayer.editing.disable();
    let intersectingStates = [];

    if (layerInfo.shapeType === "polygon") {
      let drawnGeoJSON = layerInfo.selectedLayer.toGeoJSON();
      intersectingStates = geoData.features.filter((feature) => {
        return turf.intersect(drawnGeoJSON, feature) !== null;
      });
    }

    if (layerInfo.shapeType === "rectangle") {
      const bounds = layerInfo.selectedLayer.getBounds();
      const bboxpolygon = turf.bboxPolygon([
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ]);

      // filter the geojson data
      intersectingStates = geoData.features.filter((feature) => {
        return turf.booleanIntersects(bboxpolygon, feature);
      });
    }

    // Removing the states/region on cross mark
    if (removedRegion.length > 0) {
      intersectingStates = intersectingStates.filter(
        (feature) => !removedRegion.includes(feature.properties.State)
      );
      setRemovedRegion([]);
    }

    if (
      addStateDuringEditingRef.current &&
      addStateDuringEditingRef.current.length > 0
    ) {
      intersectingStates = [
        ...intersectingStates,
        ...addStateDuringEditingRef.current,
      ];
    }

    layerInfo.selectedLayer
      ?.bindTooltip(layerInfo.referenceName, {
        permanent: true,
        direction: "center",
        className: "",
      })
      .openTooltip();

    const filteredData = await checkforDuplicates(
      intersectingStates,
      "editing"
    );

    // Recalculate territory level data
    filteredData.forEach((feature) => {
      if (feature.id === layerInfo.layerId) {
        feature["geoJson"] = intersectingStates;
        feature["color"] = layerInfo.color;
        feature["name"] = layerInfo.referenceName;

        if (optionalAttributes.length > 0) {
          optionalAttributes.forEach((option) => {
            if (option === "Premium Share") {
              feature[option] =
                feature["Client Sales Premium"] /
                feature["Market Sales Premium"];
            } else if (option === "Agent Share") {
              feature[option] =
                feature["Client Agents"] / feature["Market Agents"];
            } else if (option === "Policy Share") {
              feature[option] =
                feature["Client Sold Policies"] /
                feature["Market Sold Policies"];
            } else if (option === "Average Household Income") {
              feature[option] = calculateAvgHHIncome(intersectingStates);
            } else if (option === "Incr. Sales Growth %") {
              feature[option] = calculateSalesGrowth(
                intersectingStates,
                feature
              );
            } else {
              feature[option] = reduceGeoJson(intersectingStates, option);
            }
          });
        }
      }
    });

    const totalData = await calculateTotalFilterGeoData(filteredData);
    const restData = await calculateRestFilterGeoData(filteredData, totalData);

    setFilteredGeoJson(filteredData);
    setTotalFilteredGeoJson(totalData);
    setRestFilteredGeoJson(restData);

    clickToAddRef.current = false;
    setTimeout(() => {
      setLayerInfo(initialLayerInfo);
      addStateDuringEditingRef.current = [];
      setToggleMap(true);
    }, 100);
  };

  const ondeleteShape = async (id) => {
    setToggleMap(false);
    setShowTableData(false);
    const filterData = filteredGeoJson.filter((feature, index) => {
      if (feature["id"] === id) {
        feature.layer.remove();
      } else {
        return feature;
      }
    });

    setFilteredGeoJson(filterData);
    const totalData = await calculateTotalFilterGeoData(filterData);
    const restData = await calculateRestFilterGeoData(filterData, totalData);

    setTotalFilteredGeoJson(totalData);
    setRestFilteredGeoJson(restData);

    setShowTableData(true);
    setTimeout(() => {
      setToggleMap(true);
    }, 100);
  };

  // Filter change the gradient
  const onChangeSelect = (column, name) => {
    if (name === "metric") {
      setFilterMapGradient({
        Metric: column.value,
      });
      setSelectedState(null);
    }
  };

  // enable Add Territory
  const startAddingTerritory = () => {
    setDrawingMode("polygon");
  };

  // get Optional Attribute label value
  const getLabel = (column) => {
    return tableAttributeOptions.find((object) => object.value === column)
      .label;
  };

  // Get ToolTip Content
  const getTooltipContent = (data, column) => {
    return data.find((object) => object.value === column).tooltipContent;
  };

  // ******* CSV Calculation  ******** //
  // Create Table Header
  const createTableHeader = () => {
    const headers = [];
    if (optionalAttributes.length > 0) {
      optionalAttributes.forEach((col) => {
        headers.push({ label: getLabel(col), key: col });
      });
    }

    return headers;
  };

  // Get Total CSV Data
  const getTotalCsvData = async () => {
    const data = filteredGeoJson.map((feature, index) => {
      const temp = {};
      temp["Territory"] = feature["name"];
      if (optionalAttributes.length > 0) {
        optionalAttributes.forEach((option) => {
          if (option === "Premium Share") {
            temp[option] =
              temp["Client Sales Premium"] / temp["Market Sales Premium"];
          } else if (option === "Agent Share") {
            temp[option] = temp["Client Agents"] / temp["Market Agents"];
          } else if (option === "Policy Share") {
            temp[option] =
              temp["Client Sold Policies"] / temp["Market Sold Policies"];
          } else if (option === "Average Household Income") {
            temp[option] = calculateAvgHHIncome(feature.geoJson);
          } else if (option === "Incr. Sales Growth %") {
            temp[option] = calculateSalesGrowth(feature.geoJson, temp);
          } else {
            temp[option] = reduceGeoJson(feature.geoJson, option);
          }
        });
      }

      return temp;
    });

    // Calculation for total region/territories.
    const total = await calculateTotalFilterGeoData(filteredGeoJson);
    total["Territory"] = "Sub total";

    console.log(total);
    // Calculation for remaining states.
    const rest = await calculateRestFilterGeoData(filteredGeoJson, total);
    rest["Territory"] = "Unassigned Territories (Rest Of U.S.A)";
    const totalData = [...data, total, rest];
    return totalData;
  };

  // export Territory Data (Table)
  const exportTerritoryData = async () => {
    let headers = [{ label: "Region", key: "Territory" }];
    const rest_headers = createTableHeader();
    headers = [...headers, ...rest_headers];

    const data = await getTotalCsvData();
    setCsvTableHeader(headers);
    setCsvTableData(data);

    setTimeout(() => {
      csvTableRef.current.link.click();
    }, 500);
  };

  // States-wise Calculation (export State data)
  const calculateRemainingForCsv = () => {
    const markedStates = [];
    filteredGeoJson.forEach((feature) => {
      feature.geoJson.forEach((object) => {
        markedStates.push(object.properties.State);
      });
    });

    const remainingStates = geoData.features.filter(
      (feature) => !markedStates.includes(feature.properties.State)
    );

    const remainingData = [];
    remainingStates.forEach((feature) => {
      const temp = {};
      temp["Territory"] = "Unassigned Territories (Rest Of U.S.A)";
      temp["State"] = feature["properties"]["State"];

      if (optionalAttributes.length > 0) {
        optionalAttributes.forEach((option) => {
          temp[option] = feature["properties"][option];
        });
      }
      remainingData.push(temp);
    });

    return remainingData;
  };

  // export State data (State Wise Calculation)
  const exportStateData = () => {
    let headers = [
      { label: "Region", key: "Territory" },
      { label: "State", key: "State" },
    ];
    const rest_headers = createTableHeader();
    headers = [...headers, ...rest_headers];

    const data = [];
    filteredGeoJson.forEach((feature, index) => {
      feature.geoJson.forEach((object, index) => {
        const temp = {};
        temp["Territory"] = feature["name"];
        temp["State"] = object["properties"]["State"];

        if (optionalAttributes.length > 0) {
          optionalAttributes.forEach((option) => {
            temp[option] = object["properties"][option];
          });
        }

        data.push(temp);
      });
    });

    setCsvTableOverallHeader(headers);

    const remainingData = calculateRemainingForCsv();

    setCsvTableOverallData([...data, ...remainingData]);
    setTimeout(() => {
      csvTableOverallRef.current.link.click();
    }, 500);
  };

  // ******* CSV Calculation  ******** //

  // Export Map PDF & PNG
  const exportMap = async (mode) => {
    try {
      const mapContent = mapRef.current;
      mapContent.classList.add("my-map-container");
      const scale = 2; // increase scale for better quality
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: mapRef.current.offsetWidth + "px",
        height: mapRef.current.offsetHeight + "px",
      };

      // Save as PDF
      if (mode === "pdf") {
        const blob = await domtoimage.toBlob(mapRef.current, {
          width: mapRef.current.offsetWidth * scale,
          height: mapRef.current.offsetHeight * scale,
          style,
        });
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        img.onload = () => {
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [img.width, img.height],
          });
          pdf.addImage(img, "PNG", 0, 0, img.width, img.height);
          pdf.save(`Region Map ${Date.now()}.pdf`);
          URL.revokeObjectURL(img.src);
        };
      }

      // Save as Image
      if (mode === "png") {
        const dataUrl = await domtoimage.toPng(mapRef.current, {
          width: mapRef.current.offsetWidth * scale,
          height: mapRef.current.offsetHeight * scale,
          style,
        });
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Region Map ${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error exporting map:", error);
    } finally {
      const mapContent = mapRef.current;
      mapContent.classList.remove("my-map-container");
    }
  };

  // console.log(filteredGeoJson);

  // Clear Everything
  const handleClearAll = () => {
    if (filteredGeoJson.length > 0) {
      setToggleMap(false);
      filteredGeoJson.forEach((feature) => {
        feature.layer.remove();
      });

      setFilteredGeoJson([]);
      setTotalFilteredGeoJson(null);
      setRestFilteredGeoJson(null);
      setMode("");
      setTimeout(() => {
        setToggleMap(true);
      }, 100);
    }
  };

  // Create Scenario
  const createScenario = (currMode) => {
    if (currMode === "Scenario 1") {
      const filterData = [...filteredGeoJson];
      if (filterData.length > 0) {
        handleClearAll();
      }
      setMode("Scenario 1");

      const territories = {
        California: {
          coordinates: [
            [39.12324210126719, -124.78229164124905],
            [39.66660515155835, -121.17678470488893],
            [35.20254025023743, -117.39539938138931],
          ],
          color: "#bc1d16",
        },
        West: {
          coordinates: [
            [28.95239834932688, -119.15418325278448],
            [34.40872248111766, -111.15171663793643],
            [46.52258755463394, -121.61648067273774],
            [47.30307454296101, -108.60148002441342],
            [33.240525289348604, -106.57887857230895],
            [27.792435037329902, -112.29492615434329],
          ],
          color: "#885830",
        },
        Midwest: {
          coordinates: [
            [47.58838293122479, -99.8075606674375],
            [41.58751037762676, -99.54374308672824],
            [41.32402646890273, -86.00110727698538],
            [49.622099098141, -85.2975937284273],
          ],
          color: "#f7e744",
        },
        "South Central": {
          coordinates: [
            [31.083047265629688, -101.91810131311175],
            [39.46334002121671, -94.17945227897295],
            [31.007746194904694, -92.24479002043824],
          ],
          color: "#8a22f8",
        },
        Florida: {
          coordinates: [
            [28.38173504322311, -83.62674905060187],
            [29.38217507514529, -79.40566775925343],
            [26.115985925333536, -80.98857324350911],
          ],
          color: "#5090de",
        },
        Southeast: {
          coordinates: [
            [32.576443318946346, -89.69455340691525],
            [38.43810069865369, -81.2523908242184],
            [34.91476428293397, -78.43833662998608],
          ],
          color: "#f1a83b",
        },
        "Mid-Atlantic": {
          coordinates: [
            [41.168730605075694, -83.50888550954255],
            [41.06941275352491, -74.49511816864226],
            [38.227824557573584, -75.30855570916252],
            [38.914972067185566, -76.97940038698798],
            [40.40345765550521, -78.4523818792814],
          ],
          color: "#87d13c",
        },
        Northeast: {
          coordinates: [
            [45.4416335169918, -78.41302023803468],
            [40.76057275088258, -73.18063822063402],
            [41.48882904652525, -66.10153313826845],
            [47.183739409675916, -71.9494895106574],
          ],
          color: "#61dbbe",
        },
      };

      const scenarioGeoJson = [];

      for (const territory in territories) {
        const tempRegion = {};
        tempRegion["name"] = territory;
        tempRegion["shapeType"] = "polygon";
        tempRegion["color"] = territories[territory]["color"];

        if (featureRef.current) {
          const layer = L.polygon(territories[territory]["coordinates"]).addTo(
            featureRef.current
          );
          const drawnGeoJSON = layer.toGeoJSON();
          const featuresWithin = geoData.features.filter((feature) => {
            return turf.intersect(drawnGeoJSON, feature) !== null;
          });

          layer.setStyle({
            color: "transparent",
            opacity: 0,
            weight: 0,
            dashArray: "",
          });

          layer
            .bindTooltip(tempRegion["name"], {
              permanent: true,
              direction: "center",
              className: "",
            })
            .openTooltip();

          tempRegion["geoJson"] = featuresWithin;
          tempRegion["layer"] = layer;
          tempRegion["id"] = layer._leaflet_id;
          scenarioGeoJson.push(tempRegion);
        }
      }

      calculateFilteredGeoData(scenarioGeoJson);
      return;
    }

    if (currMode === "Scenario 2") {
      const filterData = [...filteredGeoJson];
      if (filterData.length > 0) {
        handleClearAll();
      }
      setMode("Scenario 2");

      const territories = {
        "West Coast": {
          coordinates: [
            [27.52775820686191, -110.79995986365738],
            [28.459033019728068, -122.40793341486557],
            [46.86019101567027, -131.64154873969025],
            [47.39834920035926, -120.20945357562158],
            [38.548165423046584, -120.82502793060989],
          ],
          color: "#bc1d16",
        },
        Mountain: {
          coordinates: [
            [33.578014746144014, -112.73462212219208],
            [40.38002840251183, -115.72455470356388],
            [47.15984001304432, -112.4708045414828],
            [47.15984001304432, -99.8075606674375],
            [31.50362930577303, -96.99350647320524],
            [30.600093873550072, -91.45333727831041],
            [28.14950321154457, -98.66435115103064],
          ],
          color: "#885830",
        },
        South: {
          coordinates: [
            [25.894809056040586, -80.81269485636959],
            [32.78358036364333, -89.78249260048501],
            [33.22582245317025, -83.71468824417163],
          ],
          color: "#5090de",
        },
        Midwest: {
          coordinates: [
            [47.279229002570844, -94.88296582753101],
            [35.67514743608467, -92.24479002043824],
            [35.746512259918504, -84.24232340559018],
            [40.17887331434698, -86.35286405126439],
            [48.748945343432936, -83.80262743774139],
          ],
          color: "#f7e744",
        },
        "Mid-Atlantic": {
          coordinates: [
            [40.860356187851735, -83.01117469561356],
            [41.12571122031631, -77.29512711357923],
            [38.76779013449804, -75.4484040486143],
            [33.66583960488906, -80.46093808209055],
          ],
          color: "#f1a83b",
        },
        Northeast: {
          coordinates: [
            [44.74049125832266, -79.05391098497441],
            [48.42336881631053, -68.76502533731264],
            [39.05502501956236, -68.85296453088237],
            [39.86927468403545, -74.65695130648648],
            [42.05908220825523, -74.12931614506792],
          ],
          color: "#8a22f8",
        },
      };

      const scenarioGeoJson = [];

      for (const territory in territories) {
        const tempRegion = {};

        tempRegion["name"] = territory;

        tempRegion["shapeType"] = "polygon";

        tempRegion["color"] = territories[territory]["color"];

        if (featureRef.current) {
          const layer = L.polygon(territories[territory]["coordinates"]).addTo(
            featureRef.current
          );
          const drawnGeoJSON = layer.toGeoJSON();
          const featuresWithin = geoData.features.filter((feature) => {
            return turf.intersect(drawnGeoJSON, feature) !== null;
          });

          layer.setStyle({
            color: "transparent",
            opacity: 0,
            weight: 0,
            dashArray: "",
          });

          layer
            .bindTooltip(tempRegion["name"], {
              permanent: true,
              direction: "center",
              className: "",
            })
            .openTooltip();

          tempRegion["geoJson"] = featuresWithin;
          tempRegion["layer"] = layer;
          tempRegion["id"] = layer._leaflet_id;
          scenarioGeoJson.push(tempRegion);
        }
      }

      calculateFilteredGeoData(scenarioGeoJson);
      return;
    }
  };

  return (
    <>
      {/* Filters */}
      <div className="flex items-center justify-between text-xs mb-2"></div>
      {/* Maps Layer */}
      <div className="flex gap-2 items-start w-full h-full">
        {/* Map */}
        {/* <div className="flex"> */}
        <div className="relative w-3/5 border h-full" ref={mapRef}>
          <MapContainer
            ref={mapContainerRef}
            center={[37.0902, -95.7129]}
            zoom={4}
            scrollWheelZoom={false}
            style={{
              height: 500,
              width: "100%",
              zIndex: 0,
              backgroundColor: "transparent",
            }}
          >
            {geoData && (
              <>
                <GeoJSON
                  data={geoData}
                  style={style}
                  onEachFeature={onEachFeature}
                />
                <FeatureGroup ref={featureRef}>
                  <EditControl
                    ref={editControlRef}
                    position="topright"
                    onCreated={handleCreated}
                    onDrawStart={handleDrawStart}
                    draw={drawingObject(drawingMode)}
                    edit={{
                      edit: false,
                      remove: false,
                    }}
                  />
                </FeatureGroup>
                {filteredGeoJson.map(
                  (feature) =>
                    toggleMap && (
                      <GeoJSON
                        key={feature["id"]}
                        data={feature["geoJson"]}
                        style={filteredStyle(feature["color"])}
                        onEachFeature={onEachFeature}
                      />
                    )
                )}
              </>
            )}
          </MapContainer>
          <p className="italic text-xs text-gray-800 mb-2 ml-2">
            Data excludes US territories (e.g., Puerto Rico, Guam, etc.)
          </p>
          {/* Tooltip substitute */}
          {/* <div className="absolute z-[2] bottom-2 right-2 border rounded-md p-2">
            Hello world
          </div> */}
        </div>
        {/* Map Controls */}
        <div className="relative w-2/5 flex flex-col gap-5 p-2 border text-xs h-full">
          <div className="flex justify-between items-center">
            {/* Title */}
            <p className="text-xl font-bold">
              {selectedState ? selectedState["name"] : "US Total"}
            </p>
            {/* Filter Gradient */}
            <div className="flex gap-2 items-center">
              <label htmlFor="metric" className="font-bold">
                Map Metric:
              </label>
              <Select
                styles={colourStyles}
                name="metric"
                id="metric"
                options={createOptionsForGradient(metric)}
                className="w-44"
                onChange={(e) => onChangeSelect(e, "metric")}
                defaultValue={createOptionsForGradient(metric)[0]}
              />
            </div>
          </div>
          {/* Business Cards */}
          <div className="border-t border-b py-2 w-full">
            {/* Summary Metrics: */}
            <p className="font-extrabold text-sm mb-2">Summary Metrics:</p>
            <div className="flex flex-wrap h-full w-full justify-between items-center cursor-default">
              {optionalAttributes.map((column, index) => (
                <div
                  key={index}
                  data-tooltip-id={column + index}
                  className={`p-3 text-center gap-2 flex flex-col w-1/3`}
                >
                  <p className="text-xs">{getLabel(column)}</p>
                  {staticOverall && (
                    <p className="font-bold text-sm">
                      {selectedState
                        ? stateLevelAttributeFormatter[column](
                            selectedState[column]
                          )
                        : attributeFormatter[column](staticOverall[column])}
                    </p>
                  )}
                  <Tooltip
                    id={column + index}
                    place="top"
                    content={getTooltipContent(tableAttributeOptions, column)}
                    variant="dark"
                    style={{
                      width: "15rem",
                      fontSize: 11,
                      textAlign: "left",
                    }}
                  />
                </div>
              ))}
            </div>
            {/* Sales Opportunity Metrics: */}
          </div>
          {/* Scenarios */}
          <div
            className={`flex gap-3 ${
              layerInfo.selectedRegionGeoJson?.length === 0
                ? "justify-between"
                : "justify-end"
            }`}
          >
            {layerInfo.selectedRegionGeoJson?.length === 0 && (
              <button
                type="button"
                onClick={() => startAddingTerritory()}
                className="border-green-500 border-2 px-4 py-2 w-36 text-green-600 h-fit cursor-pointer hover:shadow hover:text-white hover:bg-green-500 rounded-md"
                data-tooltip-id="add_new_region"
              >
                Add New Region
                <Tooltip
                  id={"add_new_region"}
                  place="top"
                  html="Use the Polygon <span class='text-base'>⬟</span> or Rectangle <span class='text-lg'>■</span> buttons to draw a territory. Any state that is touched by the shape will be included in the territory"
                  variant="dark"
                  style={{
                    width: "15rem",
                    fontSize: 11,
                    textAlign: "left",
                  }}
                />
              </button>
            )}
            <div className="flex gap-3 justify-between">
              <button
                onClick={() => createScenario("Scenario 1")}
                className={`cursor-pointer px-4 py-2 border-2 border-tertiary ${
                  mode === "Scenario 1"
                    ? "bg-tertiary text-white"
                    : "text-tertiary hover:bg-tertiary hover:text-white"
                } duration-300 hover:opacity-80 ease-in-out rounded-md text-xs `}
              >
                View 8 Region Scenario
              </button>
              <button
                onClick={() => createScenario("Scenario 2")}
                className={`cursor-pointer px-4 py-2 border-2 border-tertiary ${
                  mode === "Scenario 2"
                    ? "bg-tertiary text-white"
                    : "text-tertiary hover:bg-tertiary hover:text-white"
                } duration-300 hover:opacity-80 ease-in-out rounded-md text-xs`}
              >
                View 6 Region Scenario
              </button>
            </div>
          </div>
          {/* Inputs */}
          {layerInfo.selectedRegionGeoJson?.length > 0 ? (
            <div className="border relative p-2 flex flex-col gap-4 text-xs">
              <div className="flex flex-col gap-x-4 relative">
                <div className="flex relative">
                  <div className="flex">
                    <input
                      type="text"
                      name="territoryName"
                      className="border p-2 focus:outline-none rounded-l-md w-60"
                      placeholder="Territory Name"
                      onChange={(e) => {
                        setLayerInfo({
                          ...layerInfo,
                          referenceName: e.target.value,
                        });
                      }}
                      value={layerInfo.referenceName || ""}
                    />
                    <button
                      type="button"
                      style={{
                        backgroundColor: layerInfo.color,
                      }}
                      className="flex items-center justify-center w-10 cursor-pointer hover:opacity-80 rounded-r-md"
                      onClick={() => setShowColorPicker(!showColorPicker)}
                      data-tooltip-id="territory_map_color"
                    >
                      <TfiPaintBucket className="text-xl w-fit text-white" />
                      <Tooltip
                        id={"territory_map_color"}
                        place="top"
                        content="Territory Map Color"
                        variant="dark"
                        style={{
                          width: "fit-content",
                          fontSize: 11,
                          textAlign: "left",
                        }}
                      />
                    </button>
                    {showColorPicker && (
                      <div className="absolute bottom-12 z-[1]">
                        <SketchPicker
                          color={layerInfo.color}
                          onChangeComplete={(color) =>
                            setLayerInfo({ ...layerInfo, color: color.hex })
                          }
                        />
                      </div>
                    )}
                    <button
                      type="button"
                      className="border-2 border-green-500 text-green-600 rounded-md ml-2 select-none px-4 py-2 hover:bg-green-500 hover:text-white"
                      onClick={() =>
                        layerInfo.isEditing
                          ? saveEditing()
                          : handleCreateTerritory()
                      }
                    >
                      {layerInfo.isEditing ? "Save Region" : "Add Region"}
                    </button>
                  </div>
                </div>
                {showError && (
                  <p className="mt-2 italic text-xs text-red-500">
                    This region's name or color already exists
                  </p>
                )}
              </div>
              <div className="text-[11px] italic ">
                States to be included in the selected territory. Click ‘X’ on
                any state to remove it from the territory
              </div>
              <div className="flex gap-3 flex-wrap items-center">
                {layerInfo.selectedRegionGeoJson.length > 0 &&
                  layerInfo.selectedRegionGeoJson?.map((territory, index) => (
                    <div
                      key={index}
                      className="w-fit px-2 py-1 border relative select-none"
                    >
                      {territory.properties["State"]}
                      <FaXmark
                        onClick={() => handleRemoveTerritory(territory)}
                        className="text-xs text-black absolute -top-2 -right-2 bg-white border rounded-full p-1 h-5 w-5 text-center hover:bg-red-500 hover:text-white cursor-pointer"
                      />
                    </div>
                  ))}
                {addStateDuringEditingRef.current &&
                  addStateDuringEditingRef.current.length > 0 &&
                  addStateDuringEditingRef.current.map((territory, index) => (
                    <div
                      key={index}
                      className="w-fit px-2 py-1 border relative select-none"
                    >
                      {territory.properties["State"]}
                      <FaXmark
                        onClick={() => handleRemoveTerritory(territory, "edit")}
                        className="text-xs text-black absolute -top-2 -right-2 bg-white border rounded-full p-1 h-5 w-5 text-center hover:bg-red-500 hover:text-white cursor-pointer"
                      />
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-end w-full items-center text-xs">
              {filteredGeoJson.length > 0 && (
                <div className="relative ml-4 flex gap-4">
                  <button
                    type="button"
                    onClick={() => handleClearAll()}
                    className="w-36 border-red-600 border-2 text-red-600 hover:text-white hover:bg-red-600 duration-300 ease-in-out flex gap-2 items-center justify-center rounded-md px-4 py-2 hover:opacity-80"
                  >
                    <MdDeleteOutline className="text-lg" />
                    Clear All
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowExport(!showExport)}
                    className="w-36 flex gap-2 items-center justify-center justify-centerr relative border-2 h-fit px-4 py-2 rounded-md text-xs cursor-pointer border-primary hover:bg-primary hover:text-white duration-200 ease-in-out"
                  >
                    <FaServer className="text-lg" />
                    Export
                  </button>

                  {showExport && (
                    <div className="flex flex-col border text-center rounded-md w-36 absolute z-[1] right-0 top-10 mt-1 shadow-md bg-white justify-start items-start">
                      {/* Export Table */}
                      <button
                        className="px-3 py-2 border-b w-full hover:bg-gray-300 duration-300 ease-in-out"
                        onClick={() => exportMap("pdf")}
                        type="button"
                      >
                        Map PDF
                      </button>
                      <button
                        className="px-3 py-2 border-b w-full hover:bg-gray-300 duration-300 ease-in-out"
                        onClick={() => exportMap("png")}
                        type="button"
                      >
                        Map PNG
                      </button>
                      <button
                        className="px-3 py-2 border-b w-full hover:bg-gray-300 duration-300 ease-in-out"
                        onClick={() => exportTerritoryData()}
                        type="button"
                      >
                        Region Data
                      </button>
                      <CSVLink
                        data={csvTableData}
                        headers={csvTableHeader}
                        filename={`Region Level Data ${Date.now()}.csv`}
                        className="hidden"
                        ref={csvTableRef}
                      />
                      {/* Export Data */}
                      <button
                        className="px-3 py-2 border-b w-full hover:bg-gray-300 duration-300 ease-in-out"
                        onClick={() => exportStateData()}
                      >
                        State Data
                      </button>
                      <CSVLink
                        data={csvTableOverallData}
                        headers={csvTableOverallHeader}
                        filename={`State Level Data ${Date.now()}.csv`}
                        className="hidden"
                        ref={csvTableOverallRef}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Output Table */}
      {filteredGeoJson.length > 0 && showTableData && (
        <div className="p-2 border my-5 w-fit">
          <section className="w-fit overflow-x-auto">
            <table className="text-xs text-center">
              <thead>
                <tr className="p-2 bg-gray-200">
                  <th className="p-2 border w-48" colSpan={2}>
                    Region
                  </th>
                  {optionalAttributes.map((col, index) => (
                    <th
                      className="p-3 border w-28"
                      key={index + 10}
                      data-tooltip-id={col}
                    >
                      {getLabel(col)}
                      <Tooltip
                        id={col}
                        place="top"
                        content={getTooltipContent(tableAttributeOptions, col)}
                        variant="dark"
                        style={{ width: "15rem", fontWeight: 400 }}
                      />
                    </th>
                  ))}
                  <th colSpan={2} className="p-2 border">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredGeoJson.map((territory, id) => (
                  <tr
                    key={id}
                    className={`${id % 2 === 0 ? "" : "bg-gray-50"}`}
                  >
                    <td
                      className="border border-black text-center w-5"
                      style={{ backgroundColor: territory["color"] }}
                    ></td>
                    <td className="border p-2 font-bold text-left w-32">
                      {territory["name"]}
                    </td>
                    {optionalAttributes.map((col, index) => (
                      <td key={index + 20} className="border p-2 w-28">
                        {attributeFormatter[col](territory[col])}
                      </td>
                    ))}

                    <td className="border p-2">
                      <button
                        onClick={() => enableEditing(territory["id"])}
                        disabled={
                          layerInfo.isEditing
                            ? layerInfo.layerId === territory["id"]
                              ? false
                              : true
                            : false
                        }
                        className="disabled:cursor-not-allowed hover:text-blue-400"
                      >
                        <TbEdit className="text-xl" />
                      </button>
                    </td>
                    <td className="border p-2">
                      <button
                        className="hover:text-red-600"
                        onClick={() => ondeleteShape(territory["id"])}
                      >
                        <MdDeleteOutline className="text-xl" />
                      </button>
                    </td>
                  </tr>
                ))}
                {/* Sub Total */}
                {filteredGeoJson.length > 1 &&
                  totalFilteredGeoJson &&
                  showTableData && (
                    <tr className="font-bold">
                      <td className="border p-2" colSpan={2}>
                        Sub Total
                      </td>
                      {optionalAttributes.map((column, index) => (
                        <td key={index} className="border p-2">
                          {attributeFormatter[column](
                            totalFilteredGeoJson[column]
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                {/* Rest */}
                <tr className="p-2 h-5"></tr>
                <tr className="bg-blue-100 font-bold">
                  <td className="border p-2" colSpan={2}>
                    Rest of US
                  </td>
                  {optionalAttributes.map((col, index) => (
                    <td key={index} className="border p-2">
                      {attributeFormatter[col](
                        Math.abs(restFilteredGeoJson[col])
                      )}
                    </td>
                  ))}
                </tr>
                {/* Total Of USA */}
                <tr className="bg-yellow-200 font-bold">
                  <td className="border p-2" colSpan={2}>
                    US Total
                  </td>
                  {optionalAttributes.map((col, index) => (
                    <td key={index} className="border p-2">
                      {attributeFormatter[col](staticOverall[col])}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      )}
    </>
  );
};

export default TerritoryDesigner;
