import React, { useEffect, useState } from "react";
import InputSidebar from "./inputs/InputSidebar";
import OutputSidebar from "./outputs/OutputSidebar";
import InputForms from "./inputs/InputForms";
import ToggleSidebarButton from "./ToggleSidebarButton";
import OutputScreen from "./outputs/OutputScreen";
import {
  AllInputTabs,
  Automation_Cost_input_data,
  Automation_input_data,
  baseline_input_data,
  Compensation_input_data,
  EffortIndex_input_data,
  LowCostLocation_Cost_input_data,
  LowCostLocation_input_data,
  one_time_cost_non_tech,
  one_time_cost_tech,
  PIR_input_data,
  region_severance,
  Role_FTE_input_data,
  staffing_overlap,
  VGR_input_data,
  weighted_avg_cost_capital,
} from "../../../data/modules/opsTransformationPlanning";
import API, { API_FILE } from "../../../api/api";

const OpsTransformationPlanning = () => {
  const [sidebar, setSidebar] = useState("input");
  const [currentInputTab, setCurrentInputTab] = useState(AllInputTabs[0]);
  const [currentOutputTab, setCurrentOutputTab] = useState("Projected Volumes");
  const [subActiveTab, setSubActiveTab] = useState("Submissions");
  const [outputData, setOutputData] = useState(null);
  const [showOutput, setShowOutput] = useState(false);
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [enableOutput, setEnableOutput] = useState(false);
  // Baseline
  const [Baseline_Inputs, setBaseline_Inputs] = useState(baseline_input_data);
  // const [baseKey, setBaseKey] = useState(null);
  // Volume Growth Rates States
  const [VGR_Inputs, setVGR_Inputs] = useState(VGR_input_data);
  // Productivity Improvement Rate
  const [PIR_Inputs, setPIR_Inputs] = useState(PIR_input_data);
  // Compensation Input
  const [Compensation_Inputs, setCompensation_Inputs] = useState(
    Compensation_input_data
  );
  // Effore Index States
  const [EffortIndex_Inputs, setEffortIndex_Inputs] = useState(
    EffortIndex_input_data
  );
  //Automation, Low Costs and Role FTE States
  const [Automation_Inputs, setAutomation_Inputs] = useState(
    Automation_input_data
  );
  const [LowCostLocation_Inputs, setLowCostLocation_Inputs] = useState(
    LowCostLocation_input_data
  );
  const [RoleFTE_Inputs, setRoleFTE_Inputs] = useState(Role_FTE_input_data);
  // Cost Inputs States
  const [Automation_Cost_Inputs, setAutomation_Cost_Inputs] = useState(
    Automation_Cost_input_data
  );
  const [LowCostLocation_Cost_Inputs, setLowCostLocation_Cost_Inputs] =
    useState(LowCostLocation_Cost_input_data);
  const [RegionSeverence_Inputs, setRegionSeverence_Inputs] =
    useState(region_severance);
  const [OneTimeCost_NonTech_Inputs, setOneTimeCost_NonTech_Inputs] = useState(
    one_time_cost_non_tech
  );
  const [OneTimeCost_Tech_Inputs, setOneTimeCost_Tech_Inputs] =
    useState(one_time_cost_tech);
  const [StaffingOverlapInput, setStaffingOverlapInput] =
    useState(staffing_overlap);
  const [WeightedAvgCostInput, setWeightedAvgCostInput] = useState(
    weighted_avg_cost_capital
  );
  // excel sheet upload
  const [file, setFile] = useState(null);

  // handle backspace for percentages
  const handleBackspaceForPercentage = (e, index, targetState) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      if (e.target.value === "%") {
        e.target.value = "0%";
        const removePercent = e?.target?.value?.split("%")?.join("");
        const value = parseFloat(removePercent ? removePercent : 0);
        changeState(e, value, index, targetState);
        return;
      }
      if (
        e.target.value.length > 0 &&
        e.target.value[e.target.value.length - 2] !== "%"
      ) {
        if (e.target.value.slice(0, -2) === "") {
          e.target.value = "0%";
          const removePercent = e?.target?.value?.split("%")?.join("");
          const value = parseFloat(removePercent ? removePercent : 0);
          changeState(e, value, index, targetState);

          return;
        }
        e.target.value = e.target.value.slice(0, -2) + "%";
        const removePercent = e?.target?.value?.split("%")?.join("");
        const value = parseFloat(removePercent ? removePercent : 0);
        changeState(e, value, index, targetState);
      }
    }
  };

  // handle inputs with percentage
  const handlePercentageInputChange = (e, index, targetState) => {
    setShowOutput(false);
    const removePercent = e?.target?.value?.split("%")?.join("");
    const value = parseFloat(removePercent ? removePercent : 0);
    changeState(e, value, index, targetState);
  };

  // Currency change inputs
  const handleCurrencyChange = (e, index, targetState) => {
    setShowOutput(false);
    const removeDollar = e?.target?.value?.split("$");
    const removeComma = removeDollar[1]
      ? removeDollar[1]?.split(",")?.join("")
      : 0;
    const value = parseInt(removeComma);
    changeState(e, value, index, targetState);
  };

  // handle number inputs
  const handleNumberInputChange = (e, index, targetState, key = null) => {
    setShowOutput(false);
    let value = e.target.value.replace(/[^\d.]/g, "");
    value = value.replace(/^0+\B(?=(\d{3})+(?!\d))/g, ",");
    // value = value.replace(/^0+|[^\d.](?=(\d{3})+(?!\d))/g, ",");
    if (key) {
      changeState(e, value, index, targetState, key);
    } else {
      changeState(e, value, index, targetState);
    }
  };

  // select inputs change
  const handleOnChangeSelectInput = (e, name, index, targetState) => {
    if (targetState === "EffortIndex") {
      let currentValues = [...EffortIndex_Inputs[name]];
      currentValues[index] = e.value;
      setEffortIndex_Inputs({
        ...EffortIndex_Inputs,
        [name]: currentValues,
      });
    }
    if (targetState === "LowCostLocationIndex") {
      let currentValues = [...LowCostLocation_Inputs[name]];
      currentValues[index] = e.value;
      setLowCostLocation_Inputs({
        ...LowCostLocation_Inputs,
        [name]: currentValues,
      });
    }
  };

  // Change input data object after changes in each form
  const changeState = (e, value, index, targetState, key = null) => {
    setShowOutput(false);

    if (targetState === "Baseline") {
      // console.log(Baseline_Inputs[key][e.target.name][index]);
      let currentValues = { ...Baseline_Inputs[key] };
      // console.log("current", currentValues);
      currentValues[e.target.name][index] = parseFloat(value);
      setBaseline_Inputs({
        ...Baseline_Inputs,
        [key]: currentValues,
      });
      return;
    }

    if (targetState === "VGR") {
      let currentValues = [...VGR_Inputs[e.target.name]];
      currentValues[index] = value;
      setVGR_Inputs({ ...VGR_Inputs, [e.target.name]: currentValues });
      return;
    }

    if (targetState === "PIR") {
      let currentValues = [...PIR_Inputs[e.target.name]];
      currentValues[index] = value;
      setPIR_Inputs({ ...PIR_Inputs, [e.target.name]: currentValues });
      return;
    }

    if (targetState === "Compensation") {
      let currentValues = [...Compensation_Inputs[e.target.name]];
      currentValues[index] = value;
      setCompensation_Inputs({
        ...Compensation_Inputs,
        [e.target.name]: currentValues,
      });
      return;
    }

    if (targetState === "EffortIndex") {
      let currentValues = [...EffortIndex_Inputs[e.target.name]];
      currentValues[index] = value;
      setEffortIndex_Inputs({
        ...EffortIndex_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "AutomationIndex") {
      let currentValues = [...Automation_Inputs[e.target.name]];
      currentValues[index] = value;
      setAutomation_Inputs({
        ...Automation_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "LowCostLocationIndex") {
      let currentValues = [...LowCostLocation_Inputs[e.target.name]];
      currentValues[index] = value;
      setLowCostLocation_Inputs({
        ...LowCostLocation_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "RoleFTEIndex") {
      let currentValues = [...RoleFTE_Inputs[e.target.name]];
      currentValues[index] = value;
      setRoleFTE_Inputs({
        ...RoleFTE_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "Automation_CostInputs_Index") {
      let currentValues = [...Automation_Cost_Inputs[e.target.name]];
      currentValues[index] = value;
      setAutomation_Cost_Inputs({
        ...Automation_Cost_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "LowCostLocation_CostInputs_Index") {
      let currentValues = [...LowCostLocation_Cost_Inputs[e.target.name]];
      currentValues[index] = value;
      setLowCostLocation_Cost_Inputs({
        ...LowCostLocation_Cost_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "RegionSeverence_Index") {
      let currentValues = [...RegionSeverence_Inputs[e.target.name]];
      currentValues[index] = value;
      setRegionSeverence_Inputs({
        ...RegionSeverence_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "OneTimeCost_NonTech_Index") {
      let currentValues = [...OneTimeCost_NonTech_Inputs[e.target.name]];
      if (
        e.target.name === "facility_buildup" ||
        e.target.name === "sq. feet per FTE"
      ) {
        currentValues[index] = value;
      } else {
        currentValues[index] = value / 100;
      }
      setOneTimeCost_NonTech_Inputs({
        ...OneTimeCost_NonTech_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "OneTimeCost_Tech_Index") {
      let currentValues = [...OneTimeCost_Tech_Inputs[e.target.name]];
      currentValues[index] = value;
      setOneTimeCost_Tech_Inputs({
        ...OneTimeCost_Tech_Inputs,
        [e.target.name]: currentValues,
      });
    }

    if (targetState === "StaffingOverlap_Index") {
      setStaffingOverlapInput(value);
    }

    if (targetState === "WeightedAvgCost_Index") {
      setWeightedAvgCostInput(value / 100);
    }
  };

  // handle file upload
  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files.length === 0) return;
    // console.log(e.target.files);
    const isCSV = e.target.files[0]["name"].split(".");
    // console.log(isCSV[isCSV.length - 1]);
    if (isCSV[isCSV.length - 1] === "xlsx") {
      setFile(e.target.files[0]);
    } else {
      setError("Only csv type is accepted as input!");
    }
  };

  // fixData for percentages where
  const fixData = (object) => {
    let new_object = {};
    for (const key in object) {
      new_object[key] = object[key][0];
    }
    return new_object;
  };

  // handle submit form
  const submitForm = (e = null) => {
    setLoad(true);
    setShowOutput(false);
    e?.preventDefault();
    setEnableOutput(false);

    const data = {
      Baseline_Inputs: { ...Baseline_Inputs },
      VGR_Inputs: { ...VGR_Inputs },
      PIR_Inputs: { ...PIR_Inputs },
      Compensation_Inputs: { ...Compensation_Inputs },
      EffortIndex_Inputs: { ...EffortIndex_Inputs },
      Automation_Inputs: { ...Automation_Inputs },
      LowCostLocation_Inputs: { ...LowCostLocation_Inputs },
      RoleFTE_Inputs: { ...RoleFTE_Inputs },
      Automation_Cost_Inputs: { ...Automation_Cost_Inputs },
      LowCostLocation_Cost_Inputs: { ...LowCostLocation_Cost_Inputs },
      region_severance: { ...RegionSeverence_Inputs },
      one_time_cost_non_tech: fixData(OneTimeCost_NonTech_Inputs),
      one_time_cost_tech: fixData(OneTimeCost_Tech_Inputs),
      staffing_overlap: StaffingOverlapInput,
      weighted_avg_cost_capital: WeightedAvgCostInput,
    };

    // API request
    API.post("/ops-transformation-model", { data })
      .then(({ data }) => {
        console.log(data);
        setOutputData(data);
        setLoad(false);
        setShowOutput(true);
        setEnableOutput(true);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  // handle reset values to default
  const defaultValues = (name) => {
    if (name === "Baseline") {
      setFile(null);
      setBaseline_Inputs(baseline_input_data);
    }
    if (name === "VGR") {
      setVGR_Inputs(VGR_input_data);
    }
    if (name === "PIR") {
      setPIR_Inputs(PIR_input_data);
    }
    if (name === "Effort Index") {
      setEffortIndex_Inputs(EffortIndex_input_data);
    }
    if (name === "Compensation Input") {
      setCompensation_Inputs(Compensation_input_data);
    }
    if (name === "AutoLowRole") {
      setAutomation_Inputs(Automation_input_data);
      setLowCostLocation_Inputs(LowCostLocation_input_data);
      setRoleFTE_Inputs(Role_FTE_input_data);
    }
    if (name === "Cost Input") {
      setAutomation_Cost_Inputs(Automation_Cost_input_data);
      setLowCostLocation_Cost_Inputs(LowCostLocation_Cost_input_data);
      setRegionSeverence_Inputs(region_severance);
      setOneTimeCost_NonTech_Inputs(one_time_cost_non_tech);
      setOneTimeCost_Tech_Inputs(one_time_cost_tech);
      setStaffingOverlapInput(staffing_overlap);
      setWeightedAvgCostInput(weighted_avg_cost_capital);
    }

    submitForm();
  };

  // handle upload File
  const uploadBaselineData = () => {
    if (file) {
      setLoad(true);
      const formData = new FormData();
      formData.append("file", file);
      API_FILE.post("/ops-transformation-model/update-inputs", formData)
        .then(({ data }) => {
          console.table(data);
          setBaseline_Inputs(data?.response);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        });
    } else {
      return;
    }
  };

  useEffect(() => {
    const data = {
      Baseline_Inputs: Baseline_Inputs,
      VGR_Inputs: VGR_input_data,
      PIR_Inputs: PIR_input_data,
      Compensation_Inputs: Compensation_input_data,
      EffortIndex_Inputs: EffortIndex_input_data,
      Automation_Inputs: Automation_input_data,
      LowCostLocation_Inputs: LowCostLocation_input_data,
      RoleFTE_Inputs: Role_FTE_input_data,
      Automation_Cost_Inputs: Automation_Cost_input_data,
      LowCostLocation_Cost_Inputs: LowCostLocation_Cost_input_data,
      region_severance: region_severance,
      one_time_cost_non_tech: fixData(one_time_cost_non_tech),
      one_time_cost_tech: fixData(one_time_cost_tech),
      staffing_overlap: staffing_overlap,
      weighted_avg_cost_capital: weighted_avg_cost_capital,
    };

    API.post("/ops-transformation-model", {
      data,
    })
      .then(({ data }) => {
        console.log(data);
        setOutputData(data);
        setEnableOutput(true);
        setShowOutput(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="flex gap-5 w-full h-full justify-between text-sm text-gray-700 relative">
        {/* Left SideBar containing output and output Tabs */}
        <div className="w:1/5 xl:w-1/6 pr-2 pl-0 border-r relative">
          {/* Toggle Input & Output Tabs */}
          <ToggleSidebarButton
            sidebar={sidebar}
            setSidebar={setSidebar}
            enableOutput={enableOutput}
          />
          <div className="sticky top-24">
            {sidebar === "input" ? (
              <InputSidebar
                currentInputTab={currentInputTab}
                setCurrentInputTab={setCurrentInputTab}
                AllInputTabs={AllInputTabs}
              />
            ) : (
              <OutputSidebar
                currentOutputTab={currentOutputTab}
                setCurrentOutputTab={setCurrentOutputTab}
                subActiveTab={subActiveTab}
                setSubActiveTab={setSubActiveTab}
              />
            )}
          </div>
        </div>

        {/* Right Entire Input and Output Screen */}
        <div className="w-9/12 xl:w-5/6 h-full">
          {sidebar === "input" ? (
            <InputForms
              currentInputTab={currentInputTab}
              showOutput={showOutput}
              load={load}
              excelFile={file}
              Baseline_Inputs={Baseline_Inputs}
              VGR_Inputs={VGR_Inputs}
              PIR_Inputs={PIR_Inputs}
              Compensation_Inputs={Compensation_Inputs}
              EffortIndex_Inputs={EffortIndex_Inputs}
              Automation_Inputs={Automation_Inputs}
              LowCostLocation_Inputs={LowCostLocation_Inputs}
              RoleFTE_Inputs={RoleFTE_Inputs}
              Automation_Cost_Inputs={Automation_Cost_Inputs}
              LowCostLocation_Cost_Inputs={LowCostLocation_Cost_Inputs}
              RegionSeverence_Inputs={RegionSeverence_Inputs}
              OneTimeCost_NonTech_Inputs={OneTimeCost_NonTech_Inputs}
              OneTimeCost_Tech_Inputs={OneTimeCost_Tech_Inputs}
              StaffingOverlapInput={StaffingOverlapInput}
              WeightedAvgCostInput={WeightedAvgCostInput}
              handleFileUpload={handleFileUpload}
              handleBackspaceForPercentage={handleBackspaceForPercentage}
              handlePercentageInputChange={handlePercentageInputChange}
              handleCurrencyChange={handleCurrencyChange}
              handleNumberInputChange={handleNumberInputChange}
              handleOnChangeSelectInput={handleOnChangeSelectInput}
              submitForm={submitForm}
              defaultValues={defaultValues}
              error={error}
              setError={setError}
              setSidebar={setSidebar}
              uploadBaselineData={uploadBaselineData}
            />
          ) : (
            <>
              {outputData && (
                <OutputScreen
                  currentOutputTab={currentOutputTab}
                  subActiveTab={subActiveTab}
                  outputData={outputData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpsTransformationPlanning;
