import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { NumberFormatter } from "../../../utils/Utils";

const ProjectedVolumes = ({ data }) => {
  const getSeriesData = (name) => {
    let series_data = [];
    for (let index = 0; index < 3; index++) {
      if (index === 0) {
        // series_data.push([3, Math.round(data["actual"][2]).toFixed(2)]);
        series_data.push([4, Math.round(data[name][index]).toFixed(2)]);
      } else {
        series_data.push([index + 4, Math.round(data[name][index]).toFixed(2)]);
      }
    }
    return series_data;
  };

  const seriesData = [
    {
      name: "Actual",
      data: data["actual"].map((val, index) => [
        index + 1,
        Math.round(val).toFixed(0),
      ]),
    },
    {
      name: "Actual",
      data: [
        [3, Math.round(data["actual"][2].toFixed(0))],
        [4, Math.round(data["business"][0]).toFixed(0)],
      ],
    },
    {
      name: "Actual",
      data: [
        [3, Math.round(data["actual"][2].toFixed(0))],
        [4, Math.round(data["stochastic"][0]).toFixed(0)],
      ],
    },
    {
      name: "Business",
      data: getSeriesData("business"),
    },
    {
      name: "Stochastic",
      data: getSeriesData("stochastic"),
    },
  ];

  const options = {
    chart: {
      type: "line",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: "category",
      // Overwrite Category is used to show the all X-axis categories that are required.
      overwriteCategories: ["2020", "2021", "2022", "2023F", "2024F", "2025F"],
      categories: ["2020", "2021", "2022", "2023F", "2024F", "2025F"],
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        show: false,
        text: "", // Y-axis label
      },
    },
    colors: ["#1A4873", "#1A4873", "#1A4873", "#E5943E", "#1A7326"],
    // colors: ["#E5943E", "#E5943E", "#E5943E", "#1A4873", "#1A7326"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return NumberFormatter(val);
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      position: "top",
      customLegendItems: ["", "", "Actual", "Business", "Stochastic"],
      markers: {
        width: [0, 0, 12, 12, 12],
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
  };

  return (
    <div className="p-4 w-full relative">
      <ReactApexChart
        options={options}
        series={seriesData}
        type="line"
        // width="100%"
        height="550"
      />
      {/* <div className="absolute flex gap-10 top-0 right-[405px] justify-between items-center bg-white w-20 h-20">
      </div> */}
    </div>
  );
};

export default ProjectedVolumes;
