import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

// after auth
import Header from "./components/account/Header";
import ScrollToTop from "./components/utils/ScrollToTop";
import HomePage from "./pages/HomePage";
import Account from "./pages/account/Account";
// Misc
import NotFound from "./pages/NotFound";
import { UserContext } from "./context/userContext";
import jwtDecode from "jwt-decode";
import API from "./api/api";
import AuthPath from "./components/powerBIAuth/AuthPath";
import AuthTemplate from "./pages/auth/AuthTemplate";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PowerBiPermissionScopes } from "./components/powerBIAuth/authConfig";
////////////////////////////////////////////////////////////////////// Industries //////////////////////////////////////////////////////////////////////
import IndividualLife from "./pages/industries/IndividualLife";
import EmployeeBenefits from "./pages/industries/EmployeeBenefits";
import WealthManagement from "./pages/industries/WealthManagement";
import PropertyCasualty from "./pages/industries/PropertyCasualty";
import Annuity from "./pages/industries/Annuity";
import Brokers from "./pages/industries/Brokers";
import RetailBrokers from "./pages/industries/RetailBrokers";
import WholesaleBrokers from "./pages/industries/WholesaleBrokers";
import CommercialLines from "./pages/industries/CommercialLines";
import PersonalLines from "./pages/industries/PersonalLines";
import SupplementalHealth from "./pages/industries/SupplementalHealth";
import EcommerceConsumerGoods from "./pages/industries/EcommerceConsumerGoods";
import Worksite from "./pages/industries/Worksite";
import Reinsurance from "./pages/industries/Reinsurance";

////////////////////////////////////////////////////////////////////// Data //////////////////////////////////////////////////////////////////////
import {
  capMod,
  cartalyze,
  commissionIntelligence,
  commissionOptimizerRetail,
  commissionOptimizerWholesale,
  forecast,
  lapsePredictor,
  leadPrioritizer,
  renewalsOpt,
  renewalsOptCL,
  renewalsOptEB,
  returnify,
  salesProphet,
  scenarioPlanner,
  uwEngine,
} from "./data/data";
import IndustryPage from "./pages/IndustryPage";
import SolutionPage from "./pages/SolutionPage";

axios.defaults.withCredentials = true;

const spinnakerIcon =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-icon-white.webp";

function App() {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    setLoggedInUserData,
    setPbiAccessToken,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("demo_user_info")) {
      // If JWT token is present User is looged in.
      const accessToken = JSON.parse(
        localStorage.getItem("demo_user_info")
      ).accessToken;
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        localStorage.clear();
        setIsLoading(false);
        return;
      }
      setLoggedInUserData({
        company: decodedToken.company,
        fname: decodedToken.fname,
        lname: decodedToken.lname,
        username: decodedToken.username,
        email: decodedToken.email ?? null,
        role: decodedToken.role,
        phone: decodedToken.phone ?? null,
        picture: decodedToken.picture ?? null,
      });
      setIsUserLoggedIn(true);
      setIsLoading(false);
    } else {
      // If JWT Token is not present, the check for session logged in.
      const sessionToken = { session_token: "demo_user_info" };
      API.post("/auth-user-session", sessionToken)
        .then((response) => {
          if (response.status === 200) {
            setIsUserLoggedIn(true);
            setLoggedInUserData({
              company: response.data.userInfo.company,
              username: response.data.userInfo.username,
              role: response.data.userInfo.role,
              fname: response.data.userInfo.fname,
              lname: response.data.userInfo.lname,
              id: response.data.userInfo.id,
              email: response.data.userInfo.email ?? null,
              phone: response.data.userInfo.phone ?? null,
              picture: response.data.userInfo.picture ?? null,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
          // console.log("User not logged in");
          setIsLoading(false);
          // Clear session storage which contains all msal tokens
          setIsUserLoggedIn(false);
          setLoggedInUserData(null);
          localStorage.clear();
          setPbiAccessToken(null);
          sessionStorage.clear();
        });
    }
    // eslint-disable-next-line
  }, []);

  const getAccessToken = async () => {
    const accessTokenRequest = {
      scopes: PowerBiPermissionScopes,
      account: account,
    };
    // before acquiring the token(or making any call to Msal API), first initialize and await the Msal Instance.
    await instance.initialize();

    // if user is logged-in into the dashboard atleast once in the current session then only acquire the token silently in the background.
    if (account) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((res) => {
          // console.log("Access Token Client (Refresh Token)\n", res);
          setPbiAccessToken(res.accessToken);
          localStorage.setItem("token_info", JSON.stringify(res));
        })
        .catch((err) => {
          // console.log("Refresh Token Error\n", err);
        });
    }
  };

  // Power BI token expired check
  const isTokenExpired = async () => {
    const tokenInfo = await JSON.parse(localStorage.getItem("token_info"));
    // console.log(tokenInfo);
    const expiryTime = new Date(tokenInfo.expiresOn);
    const currentTime = new Date();
    if (currentTime < expiryTime) {
      setPbiAccessToken(tokenInfo.accessToken);
      // console.log(tokenInfo);
    } else {
      getAccessToken();
    }
  };

  // Power BI refresh
  useEffect(() => {
    if (localStorage.getItem("token_info")) {
      isTokenExpired();
    } else {
      getAccessToken();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // for every 58 minutes
    const interval = setInterval(() => {
      if (isAuthenticated) {
        getAccessToken();
      }
    }, 3500 * 1000);
    return clearInterval(interval);
  });

  return (
    <>
      {!isLoading ? (
        <>
          <BrowserRouter>
            {isUserLoggedIn ? (
              <>
                {/* Pages only accessible for logged in users */}
                {/* Constant header */}
                <Header />
                <ScrollToTop />
                <main className="relative flex flex-col gap-2 transition-all duration-300 ease-in-out ml-12 scrollbar-hide bg-white">
                  {/* Routes */}
                  <Routes>
                    {/* Default */}
                    <Route
                      exact
                      path="/"
                      element={<Navigate to="/home" replace />}
                    ></Route>
                    {/* Home */}
                    <Route exact path="/home" element={<HomePage />} />
                    {/* Industries */}
                    <Route exact path="/">
                      <Route
                        exact
                        path=":slug"
                        element={<IndustryPage />}
                      ></Route>
                    </Route>
                    {/* Power BI auth path */}
                    <Route
                      exact
                      path="/microsoft/oauth2/v2.0/authorize"
                      element={<AuthPath />}
                    />
                    {/* User account routing */}
                    <Route exact path="/account/:slug" element={<Account />} />

                    {/*================================= Modules Routing Here  =================================*/}
                    {/* Sales Prophet™ routing */}
                    <Route exact path="/sales-prophet/">
                      {/* <Route exact path="" element={<SalesProphet />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={salesProphet} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={salesProphet} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={salesProphet} />}
                      />
                      {/* Annuity */}
                      <Route
                        exact
                        path="annuity/:slug"
                        element={<Annuity solution={salesProphet} />}
                      />
                      {/* Wealth Management */}
                      <Route
                        exact
                        path="wealth-management/:slug"
                        element={<WealthManagement solution={salesProphet} />}
                      />
                      {/* Property and Casualty */}
                      <Route
                        exact
                        path="property-and-casualty/:slug"
                        element={<PropertyCasualty solution={salesProphet} />}
                      />
                      {/* Worksite */}
                      <Route
                        exact
                        path="worksite/:slug"
                        element={<Worksite solution={salesProphet} />}
                      />
                      {/* Brokers */}
                      <Route
                        exact
                        path="brokers/:slug"
                        element={<Brokers solution={salesProphet} />}
                      />
                      {/* Reinsurance */}
                      <Route
                        exact
                        path="reinsurance/:slug"
                        element={<Reinsurance solution={salesProphet} />}
                      />
                    </Route>
                    {/* Lead Prioritizer™ Engine routing */}
                    <Route exact path="/lead-prioritizer/">
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={leadPrioritizer} />}
                      />
                      {/* <Route path="" element={<LeadPrioritizer />}></Route> */}
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={leadPrioritizer} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={
                          <EmployeeBenefits solution={leadPrioritizer} />
                        }
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={leadPrioritizer} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={leadPrioritizer} />}
                      />
                    </Route>
                    {/* 4Qast™ routing */}
                    <Route exact path="/4qast/">
                      {/* <Route exact path="" element={<ForeQast />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={forecast} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={forecast} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={forecast} />}
                      />
                      {/* Annuity */}
                      <Route
                        exact
                        path="annuity/:slug"
                        element={<Annuity solution={forecast} />}
                      />
                      {/* Wealth Management */}
                      <Route
                        exact
                        path="wealth-management/:slug"
                        element={<WealthManagement solution={forecast} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={forecast} />}
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={forecast} />}
                      />
                      {/* Brokers */}
                      <Route
                        exact
                        path="brokers/:slug"
                        element={<Brokers solution={forecast} />}
                      />
                      {/* Ecommerce and Consumer Goods */}
                      <Route
                        exact
                        path="ecommerce-and-consumer-goods/:slug"
                        element={<EcommerceConsumerGoods solution={forecast} />}
                      />
                      {/* Supplemental Health */}
                      <Route
                        exact
                        path="supplemental-health/:slug"
                        element={<SupplementalHealth solution={forecast} />}
                      />
                    </Route>
                    {/* CapMod */}
                    <Route exact path="/capmod/">
                      {/* <Route exact path="" element={<CapMod />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={capMod} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={capMod} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={capMod} />}
                      />
                      {/* Annuity */}
                      <Route
                        exact
                        path="annuity/:slug"
                        element={<Annuity solution={capMod} />}
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={capMod} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={capMod} />}
                      />
                      {/* Brokers */}
                      <Route
                        exact
                        path="brokers/:slug"
                        element={<Brokers solution={capMod} />}
                      />
                    </Route>
                    {/* Returnify */}
                    <Route exact path="/returnify/">
                      {/* <Route exact path="" element={<Returnify />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={returnify} />}
                      />
                      {/* Ecommerce and Consumer Goods */}
                      <Route
                        exact
                        path="ecommerce-and-consumer-goods/:slug"
                        element={
                          <EcommerceConsumerGoods solution={returnify} />
                        }
                      />
                    </Route>
                    {/* Cartalyze */}
                    <Route exact path="/cartalyze/">
                      {/* <Route exact path="" element={<Cartalyze />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={cartalyze} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={cartalyze} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={cartalyze} />}
                      />
                      {/* Ecommerce and Consumer Goods */}
                      <Route
                        exact
                        path="ecommerce-and-consumer-goods/:slug"
                        element={
                          <EcommerceConsumerGoods solution={cartalyze} />
                        }
                      />
                    </Route>
                    {/* Commission Intelligence routing */}
                    <Route exact path="/commission-intelligence/">
                      {/* <Route
                        exact
                        path=""
                        element={<CommissionIntelligence />}
                      /> */}
                      <Route
                        exact
                        path=""
                        element={
                          <SolutionPage solution={commissionIntelligence} />
                        }
                      />
                      {/* Retail Brokers */}
                      <Route
                        exact
                        path="retail-brokers/:slug"
                        element={
                          <RetailBrokers solution={commissionOptimizerRetail} />
                        }
                      />
                      {/* Wholesale Brokers */}
                      <Route
                        exact
                        path="wholesale-brokers/:slug"
                        element={
                          <WholesaleBrokers
                            solution={commissionOptimizerWholesale}
                          />
                        }
                      />
                    </Route>
                    {/* Renewals Optimizer */}
                    <Route exact path="/renewals-optimizer/">
                      {/* <Route exact path="" element={<RenewalsOptimizer />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={renewalsOpt} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={renewalsOpt} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={renewalsOptEB} />}
                      />
                      {/* Annuity */}
                      <Route
                        exact
                        path="annuity/:slug"
                        element={<Annuity solution={renewalsOpt} />}
                      />
                      {/* Wealth Management */}
                      <Route
                        exact
                        path="wealth-management/:slug"
                        element={<WealthManagement solution={renewalsOpt} />}
                      />
                      {/* Property and Casualty */}
                      <Route
                        exact
                        path="property-and-casualty/:slug"
                        element={<PropertyCasualty solution={renewalsOpt} />}
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={renewalsOpt} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={renewalsOptCL} />}
                      />
                      {/* Brokers */}
                      <Route
                        exact
                        path="brokers/:slug"
                        element={<Brokers solution={renewalsOpt} />}
                      />
                      {/* Retail Brokers */}
                      <Route
                        exact
                        path="retail-brokers/:slug"
                        element={<RetailBrokers solution={renewalsOpt} />}
                      />
                      {/* Wholesale Brokers */}
                      <Route
                        exact
                        path="wholesale-brokers/:slug"
                        element={<WholesaleBrokers solution={renewalsOpt} />}
                      />
                      {/* Supplemental Health */}
                      <Route
                        exact
                        path="supplemental-health/:slug"
                        element={<SupplementalHealth solution={renewalsOpt} />}
                      />
                      {/* Ecommerce and Consumer Goods */}
                      <Route
                        exact
                        path="ecommerce-and-consumer-goods/:slug"
                        element={
                          <EcommerceConsumerGoods solution={renewalsOpt} />
                        }
                      />
                    </Route>
                    {/* Scenario Planner */}
                    <Route exact path="/scenario-planner/">
                      {/* <Route exact path="" element={<ScenarioPlanner />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={scenarioPlanner} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={scenarioPlanner} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={
                          <EmployeeBenefits solution={scenarioPlanner} />
                        }
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={scenarioPlanner} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={scenarioPlanner} />}
                      />
                      {/* Ecommerce and Consumer Goods */}
                      <Route
                        exact
                        path="ecommerce-and-consumer-goods/:slug"
                        element={
                          <EcommerceConsumerGoods solution={scenarioPlanner} />
                        }
                      />
                    </Route>
                    {/* UwEngine */}
                    <Route exact path="/uw-engine/">
                      {/* <Route exact path="" element={<UwEngine />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={uwEngine} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={uwEngine} />}
                      />
                    </Route>
                    {/* Lapse Predictor */}
                    <Route exact path="/lapse-predictor/">
                      {/* <Route exact path="" element={<LapsePredictor />} /> */}
                      <Route
                        exact
                        path=""
                        element={<SolutionPage solution={lapsePredictor} />}
                      />
                      {/* Individual Life */}
                      <Route
                        exact
                        path="individual-life/:slug"
                        element={<IndividualLife solution={lapsePredictor} />}
                      />
                      {/* Employee Benefits */}
                      <Route
                        exact
                        path="employee-benefits/:slug"
                        element={<EmployeeBenefits solution={lapsePredictor} />}
                      />
                      {/* Personal Lines */}
                      <Route
                        exact
                        path="personal-lines-pnc/:slug"
                        element={<PersonalLines solution={lapsePredictor} />}
                      />
                      {/* Commercial Lines */}
                      <Route
                        exact
                        path="commercial-lines-pnc/:slug"
                        element={<CommercialLines solution={lapsePredictor} />}
                      />
                    </Route>
                    {/* Not Found page (All unknown routes) */}
                    <Route exact path="*" element={<NotFound />} />
                  </Routes>
                </main>
                {/* Show on small devices */}
                {/* <section className="fixed top-0 lg:hidden h-full w-full flex flex-col gap-5 items-center justify-center p-10 text-center text-[10px]">
                  <img
                    src={spinnakerLogo}
                    alt="spinnaker logo"
                    className="w-40"
                  />
                  Looks like you are using or small screen. <br /> Please switch
                  to a laptop or a wider screen to use the portal. <br /> If you
                  are using an ipad or tablet switch to landscape mode.
                </section> */}
              </>
            ) : (
              <>
                {/* Pages Accessible only for not logged in users */}
                <Routes>
                  <Route exact path="/" element={<Navigate to="/login" />} />
                  <Route exact path="/login" element={<AuthTemplate />} />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<AuthTemplate />}
                  />
                  <Route
                    exact
                    path="/request-an-account"
                    element={<AuthTemplate />}
                  />
                  <Route exact path="*" element={<NotFound />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </>
      ) : (
        // Add a Loading Screen
        <>
          <div className="w-screen h-screen flex justify-center items-center">
            <span className="relative flex h-10 w-10">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-70"></span>
              <img src={spinnakerIcon} className="" alt="" />
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default App;
