import React, { useState, useEffect } from "react";
import API from "../../../api/api";
import { HiOutlinePlus } from "react-icons/hi";
import {
  CurrencyFormatter,
  DateFormatter,
  NumberFormatter,
  allowScroll,
  changeDate,
  preventScroll,
} from "../../utils/Utils";
import Select from "react-select";
import {
  FaFolderOpen,
  FaFolderClosed,
  FaFolderPlus,
  FaXmark,
  FaBookOpen,
} from "react-icons/fa6";
import RadarChart from "../../utils/RadarChart";
import {
  PL_products,
  PL_brokers,
  status,
  assessment,
  filterInputs,
  PL_formInputs,
  PL_columns,
  PL_leadAction,
  avoidPriority,
} from "../../../data/modules/leadPrioritizer";
import {
  ageBin,
  bmiBin,
  createOptions,
  getIndustryBySicCode,
  getMonth,
  removeComma,
  states,
} from "../../../data/staticData";
import { RiSearchLine } from "react-icons/ri";
import { colourStyles } from "../../utils/Functions";
import { LuLoader2 } from "react-icons/lu";
import SicCodeBox from "../../utils/SicCodeBox";
import TablePaginate from "../../utils/TablePaginate";

const PL_WorkInventory = () => {
  const [inputFilterData, setInputFilterData] = useState(filterInputs);
  const [inputFormData, setInputFormData] = useState(PL_formInputs);
  const [underwriters, setUnderwriters] = useState(null);
  const [workInventory, setWorkInventory] = useState(null);
  const [caseCompleted, setCaseCompleted] = useState("All");
  const [priority, setPriority] = useState(false);
  const [page, setPage] = useState(1);
  const [showEntries, setShowEntries] = useState(20);
  const [showCaseForm, setShowCaseForm] = useState(false);
  const [editCase, setEditCase] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [caseScores, setCaseScores] = useState(null);
  const [caseTable, setCaseTable] = useState(null);
  const [chart, openChart] = useState(false);
  const [load, setLoad] = useState(null);
  const [tableLoad, setTableLoad] = useState(true);
  const [formNotifications, setFormNotifications] = useState(false);
  const [showSic, setShowSic] = useState(false);
  const [sic, setSic] = useState(null);

  // function to fetch all underwriters
  const getAllUnderwriters = () => {
    API.get("/LP/PL/work-inventory/underwriters")
      .then(({ data }) => {
        const underwriterOptions = createOptions(data.underwriters);
        setUnderwriters(underwriterOptions);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // fetch work inventory table from database
  const getWorkInventory = () => {
    API.post("/LP/PL/work-inventory", inputFilterData)
      .then(async (res) => {
        setCaseCompleted(inputFilterData.status);
        // fresh or updated work inventory
        await setWorkInventory(res.data.tableData);
        if (inputFilterData.priority === "High Priority") {
          setPriority(true);
          // stop loader
          setLoad(null);
          setTableLoad(false);
        } else {
          setPriority(false);
          // stop loader
          setLoad(null);
          setTableLoad(false);
        }
      })
      .catch((err) => {
        // stop loader
        setLoad(null);
        setTableLoad(false);
        // console.log(err);
      });
  };

  // When page loads execute the following functions
  useEffect(() => {
    getAllUnderwriters();
  }, []);

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // Handle underwriter change
  const handleUnderwriterChange = (e) => {
    // console.log(e);
    setInputFilterData({
      ...inputFilterData,
      underwriter: e.value,
    });
  };

  // Handle status change
  const handleOnClickForStatus = (status) => {
    setLoad(status);
    setInputFilterData({
      ...inputFilterData,
      status: status,
    });
  };

  // Change Priority
  const handlePriorityChange = (priority) => {
    setLoad(priority);
    setInputFilterData({
      ...inputFilterData,
      priority: priority,
    });
  };

  // Search Handle
  const handleSearchFor = (e) => {
    setInputFilterData({
      ...inputFilterData,
      searchFor: e.target.value,
    });
  };

  // Whenever inputFilterData changes
  useEffect(() => {
    setTableLoad(true);
    getWorkInventory();
    setPage(1);
  }, [inputFilterData]);

  // select case operations
  useEffect(() => {
    if (selectedCase) {
      setCaseScores([
        {
          title: "Percentile Contribution Chart",
          score: selectedCase["Lead Score"],
          action: selectedCase["Lead Action"],
        },
        {
          Age: selectedCase["Lives Pct"],
          Product: selectedCase["Product Pct"],
          State: selectedCase["State Pct"],
          Broker: selectedCase["Broker Pct"],
          Month: selectedCase["Month Pct"],
        },
      ]);
      setCaseTable({
        "Case ID": selectedCase["Case ID"],
        "Effective Date": selectedCase["Effective Date"],
        "Client Name": selectedCase["Client Name"]
          ? selectedCase["Client Name"]
          : "---",
        Age: selectedCase["Age"],
        Gender: selectedCase["Gender"],
        "Annual Premium": selectedCase["Annual Premium"],
        Product: selectedCase["Product"],
        State: selectedCase["State"],
        "Zip Code": selectedCase["Zip Code"],
        SIC: selectedCase["SIC"],
        Industry: selectedCase["Industry"],
        Broker: selectedCase["Broker"],
        Status: selectedCase["Status"],
        Assessment: selectedCase["Assessment"]
          ? selectedCase["Assessment"]
          : "---",
      });
    }
  }, [selectedCase]);

  // Open add case and edit case form
  const openForm = (item) => {
    // console.log(item);
    setShowCaseForm(true);

    if (item) {
      let date = new Date(item["Effective Date"]);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      date = `${year}-${(month + 1).toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

      setInputFormData({
        ...PL_formInputs,
        id: item["ID"],
        age: item["Age"],
        gender: item["Gender"],
        state: item["State"],
        product: item["Product"],
        broker: item["Broker"],
        assessment: item["Assessment"],
        premium: item["Annual Premium"],
        status: item["Status"],
        underwriter: item["Underwriter"],
        effectiveDate: date,
        zipCode: item["Zip Code"],
        sic: item["SIC"],
        industry: item["Industry"],
      });
    }
    preventScroll();
  };

  // Close above opened form
  const closeForm = () => {
    setShowCaseForm(false);
    setEditCase(false);
    setInputFormData(PL_formInputs);
    allowScroll();
    setSelectedCase(null);
  };

  // Open chart modal
  const openChartModal = (item) => {
    // console.log(item);
    setSelectedCase(item);
    openChart(true);
    preventScroll();
  };

  // Close above chart
  const closeChart = () => {
    openChart(false);
    setSelectedCase(null);
    allowScroll();
    setEditCase(false);
  };

  // handle input changes for adding case or editing case
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "premium") {
      const value = e.target.value.replace(/[^\d.]/g, "");
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else if (e.target?.name === "age" || e.target?.name === "zipCode") {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target?.name === "sic") {
      let industry = getIndustryBySicCode(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target?.value),
        industry: industry,
      });
      setSic({ sic: e.target?.value, industry: industry });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // Add / edit case form
  const addCaseForm = async (e) => {
    e.preventDefault();
    setLoad("Add Case");
    inputFormData.ageBin = ageBin(inputFormData.age);
    // If existing case is being edited
    if (editCase) {
      // inputFormData.id = selectedCase["ID"];
      await API.put("/LP/PL/add-case", inputFormData)
        .then(({ data }) => {
          // console.log(data.response);
          getWorkInventory();
          setFormNotifications(true);
          setTimeout(() => {
            setFormNotifications(false);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // If new case is added
    else {
      // to check if all inputs are filled
      const inputs = {
        age: inputFormData.age,
        state: inputFormData.state,
        product: inputFormData.product,
        broker: inputFormData.broker,
        underwriter: inputFormData.underwriter,
        sic: inputFormData.sic,
        industry: inputFormData.industry,
      };

      let inputEmpty = false;
      // Check if all fields are filled then set required true
      await Object.values(inputs).forEach((value) => {
        if (!value) {
          inputEmpty = true;
          setFormNotifications(true);
          return;
        }
      });
      // ends function if inputs are empty
      if (inputEmpty) {
        setLoad(false);
        return;
      } else {
        setFormNotifications(false);
      }

      inputFormData.status = "Open";
      inputFormData.month = getMonth(inputFormData.effectiveDate);
      // Get scores and percentiles from existing inputs
      await API.post("/LP/PL/score-calculator", inputFormData)
        .then(({ data }) => {
          // console.log(data.scores);
          let scores = data.scores;
          let totalScore = 0;
          // calculate total score
          Object.values(scores).forEach((value) => {
            totalScore += value["Score"];
          });
          inputFormData.leadScore = totalScore - 1559;
          inputFormData.leadAction = PL_leadAction(totalScore - 1559);
          inputFormData.percentiles = data.scores;
        })
        .catch((error) => console.log(error));

      // Change values
      inputFormData.submitDate = DateFormatter(new Date());
      inputFormData.effectiveDate = changeDate(inputFormData.effectiveDate);
      inputFormData.premium = removeComma(inputFormData.premium);
      // Api call to add case
      await API.post("/LP/PL/add-case", inputFormData)
        .then(({ data }) => {
          // console.log(data);
          closeForm();
          openChartModal(data.response);
          // To get the latest data in the section
          inputFilterData.orderBy = "ID";
          inputFilterData.orderStyle = "DESC";
          getWorkInventory();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoad(null);
  };

  // Edit case function
  const editCaseFunction = (item) => {
    setEditCase(true);
    // setSelectedCase(item);
    openForm(item);
  };

  // Reset Add Case Form
  const resetForm = () => {
    setLoad();
    setInputFormData(PL_formInputs);
    setEditCase(false);
  };

  // sort table
  const sortByColumn = (column) => {
    setInputFilterData({
      ...inputFilterData,
      orderBy: column,
      orderStyle:
        inputFilterData.orderBy === column &&
        inputFilterData.orderStyle === "ASC"
          ? "DESC"
          : "ASC",
    });
  };

  // update sic
  useEffect(() => {
    if (sic) {
      setInputFormData({
        ...inputFormData,
        sic: sic.sic,
        industry: sic.industry,
      });
      setShowSic(false);
    }
  }, [sic]);

  return (
    <div className="relative">
      {/* Table starts here */}
      {workInventory === null ? (
        <div className="p-5 gap-3 flex items-center justify-center w-full">
          Loading Work Inventory
          <span className="animate-spin text-2xl">
            <LuLoader2 />
          </span>
        </div>
      ) : (
        <>
          {/* Table filters */}
          <div className="flex justify-between my-5 text-[11px]">
            {/* Filter Selects */}
            <div className="flex justify-end items-center gap-5 h-fit">
              {/* Show number of Entries at a time filter */}
              <div className="flex gap-2 items-center h-fit">
                Show entries:
                <Select
                  className="w-full"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="underwriter"
                  options={[
                    { value: "20", label: "20" },
                    { value: "40", label: "40" },
                    { value: "60", label: "60" },
                    { value: "80", label: "80" },
                    { value: "100", label: "100" },
                  ]}
                  onChange={(e) => handleEntriesChange(e)}
                  defaultValue={{
                    value: "20",
                    label: "20",
                  }}
                  styles={colourStyles}
                />
              </div>
              {/* Select Underwriter filter */}
              {underwriters !== null && (
                <div className="flex gap-2 h-fit items-center w-64">
                  Select Underwriter:
                  <Select
                    className="w-full"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="underwriter"
                    options={[
                      { value: "All Underwriters", label: "All Underwriters" },
                      ...underwriters,
                    ]}
                    onChange={(e) => handleUnderwriterChange(e)}
                    defaultValue={{
                      value: "All Underwriters",
                      label: "All Underwriters",
                    }}
                    styles={colourStyles}
                  />
                </div>
              )}
              {/* Search Filter */}
              <div className="relative h-fit">
                <RiSearchLine className="absolute top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
                <input
                  type="text"
                  name="searchFor"
                  id="searchFor"
                  placeholder="Search Table.."
                  className="rounded-lg indent-10 px-2 py-3 border focus:outline-2 w-56 focus:outline-gray-200"
                  onChange={(e) => handleSearchFor(e)}
                />
              </div>
            </div>
            {/* Action Buttons */}
            <div className="flex justify-end items-center gap-3 h-fit">
              {/* Add a case */}
              <div
                onClick={() => openForm()}
                className="bg-white border text-gray-600 hover:text-primary p-2 px-3 hover:border-primary rounded-lg cursor-pointer flex items-center gap-1"
              >
                <HiOutlinePlus className="text-lg" />
                <span>Case</span>
              </div>
              {/* Show Completed / Open / All Status */}
              {load === "All" || load === "Complete" || load === "Open" ? (
                <div className="flex gap-2 items-center text-gray-600 cursor-not-allowed border bg-white p-2 px-4 rounded-lg ">
                  <span className="animate-spin text-xl">
                    <LuLoader2 />
                  </span>
                  <span>Processing..</span>
                </div>
              ) : (
                <div
                  className="flex gap-2 items-center text-gray-600 cursor-pointer border hover:border-tertiary hover:text-tertiary bg-white p-2 px-4 rounded-lg "
                  onClick={() => {
                    if (caseCompleted === "Open") {
                      handleOnClickForStatus("Complete");
                    } else if (caseCompleted === "Complete") {
                      handleOnClickForStatus("All");
                    } else if (caseCompleted === "All") {
                      handleOnClickForStatus("Open");
                    }
                  }}
                >
                  {caseCompleted === "Open" && (
                    <>
                      <FaFolderOpen className="text-xl" />
                      <span>Open Cases</span>
                    </>
                  )}
                  {caseCompleted === "Complete" && (
                    <>
                      <FaFolderClosed className="text-xl" />
                      <span>Completed Cases</span>
                    </>
                  )}
                  {caseCompleted === "All" && (
                    <>
                      <FaFolderPlus className="text-xl" />
                      <span>All Cases</span>
                    </>
                  )}
                </div>
              )}
              {/* Prioritize Leads */}
              {load === "High Priority" || load === "Normal" ? (
                <div className="flex gap-2 items-center text-gray-600 cursor-not-allowed border bg-white p-2 px-4 rounded-lg ">
                  <span className="animate-spin text-lg">
                    <LuLoader2 />
                  </span>
                  <span>Processing..</span>
                </div>
              ) : (
                <div
                  className={`p-4 py-[10px] ${
                    priority
                      ? "bg-amber-500 border-amber-500 hover:text-amber-500"
                      : "bg-green-500 border-green-500 hover:text-green-500"
                  } rounded-lg text-white cursor-pointer hover:bg-white border font-semibold`}
                  onClick={() => {
                    if (priority) {
                      handlePriorityChange("Normal");
                    } else {
                      handlePriorityChange("High Priority");
                    }
                  }}
                >
                  {priority ? "Reset Priority" : "Prioritize Cases"}
                </div>
              )}
            </div>
          </div>

          {/* Work Inventory Table */}
          <div className="shadow border-l border-r overflow-x-auto">
            <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
              <thead className="capitalize bg-gray-300 text-gray-700">
                <tr className="p-2 transition-all duration-300 ease-in-out text-center">
                  {PL_columns.map((column, index) => (
                    <th
                      key={index}
                      scope=""
                      className="p-1 transition-all duration-300 ease-in-out"
                    >
                      <div
                        className={`flex p-2 gap-1 justify-center relative cursor-pointer hover:underline underline-offset-2 ${
                          inputFilterData.orderBy === column && "text-tertiary"
                        }`}
                        onClick={() => sortByColumn(column)}
                      >
                        {column}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {workInventory
                  ?.slice(showEntries * (page - 1), page * showEntries)
                  .map((item, index) => (
                    <tr
                      key={index}
                      className={`border-b hover:bg-gray-200 break-words text-center ${
                        DateFormatter(new Date()) ===
                        DateFormatter(new Date(item["Submit Date"]))
                          ? "first:bg-orange-200"
                          : index % 2 === 0
                          ? "bg-gray-50"
                          : "bg-white"
                      }`}
                    >
                      <td
                        className="p-2 font-semibold text-gray-700 cursor-pointer hover:underline underline-offset-2 hover:text-blue-500"
                        onClick={() => editCaseFunction(item)}
                      >
                        {item["Case ID"]}
                      </td>
                      <td className="p-2">
                        {DateFormatter(new Date(item["Submit Date"]))}
                      </td>
                      {PL_columns.slice(2, PL_columns.length).map(
                        (column, id) => (
                          // On click open chart showing percentile
                          <td
                            key={id}
                            className={`p-2 ${
                              (column === "Lead Action" ||
                                column === "Lead Score") &&
                              `font-semibold cursor-pointer hover:underline underline-offset-2  ${
                                item["Lead Action"] === "High Priority" &&
                                "text-green-700 bg-green-200"
                              } ${
                                item["Lead Action"] === "Normal Priority" &&
                                "text-blue-400"
                              } ${
                                item["Lead Action"] === "Low Priority" &&
                                "text-amber-500 bg-amber-100"
                              } ${
                                item["Lead Action"] === "DTQ" &&
                                "text-red-500 bg-red-300"
                              } `
                            } ${
                              column === "Broker" || column === "Industry"
                                ? "text-left"
                                : ""
                            }`}
                            onClick={() =>
                              (column === "Lead Action" ||
                                column === "Lead Score") &&
                              openChartModal(item)
                            }
                          >
                            {column === "Annual Premium"
                              ? CurrencyFormatter(item[column])
                              : column === "Lead Action"
                              ? avoidPriority(item[column])
                              : item[column]}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
              </tbody>
              {tableLoad && (
                <tbody className="absolute top-16 bg-black bg-opacity-30 text-white text-lg flex gap-2 items-center justify-center z-10 h-full w-full">
                  <td className="animate-spin text-xl">
                    <LuLoader2 />
                  </td>
                  <td>Loading..</td>
                </tbody>
              )}
            </table>
          </div>

          {/* Show when no rows available */}
          {workInventory.length === 0 && (
            <div className="w-full flex justify-center p-2 text-center border shadow text-xs text-gray-600">
              No matching records found
            </div>
          )}

          {/* Table Pagination */}
          <TablePaginate
            setPage={setPage}
            showEntries={showEntries}
            page={page}
            table={workInventory}
          />

          {/* Add a case or edit a case modal */}
          {showCaseForm && (
            <>
              <div
                className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
                onClick={() => closeForm()}
              ></div>
              <div className="fixed z-30 flex items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <form
                  className="bg-white p-10 rounded-lg relative"
                  onSubmit={(e) => addCaseForm(e)}
                >
                  {/* Close modal Button */}
                  <div
                    className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                    onClick={() => closeForm()}
                  >
                    <FaXmark />
                  </div>
                  {/* Inputs */}
                  {/* Personal Information */}
                  <div className="flex flex-col gap-3 text-xs ">
                    <h2 className="uppercase font-semibold text-gray-500">
                      Personal Information:
                    </h2>
                    <div className="flex justify-between gap-10">
                      {/* Age */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="age">
                          Age:
                        </label>
                        <input
                          type="text"
                          name="age"
                          id="age"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.age || ""}
                          maxLength={2}
                          disabled={editCase ? true : false}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      {/* Gender */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="gender">
                          Gender:
                        </label>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-1 items-center">
                            <input
                              type="radio"
                              name="gender"
                              id="male"
                              value={"Male"}
                              checked={inputFormData.gender === "Male"}
                              disabled={editCase ? true : false}
                              onClick={(e) =>
                                editCase === false && handleOnChangeInput(e)
                              }
                            />
                            <label htmlFor="male">Male</label>
                          </div>
                          <div className="flex gap-1 items-center">
                            <input
                              type="radio"
                              name="gender"
                              id="female"
                              value={"Female"}
                              checked={inputFormData.gender === "Female"}
                              disabled={editCase ? true : false}
                              onClick={(e) =>
                                editCase === false && handleOnChangeInput(e)
                              }
                            />
                            <label htmlFor="female">Female</label>
                          </div>
                          <div className="flex gap-1 items-center">
                            <input
                              type="radio"
                              name="gender"
                              id="other"
                              value={"Others"}
                              checked={inputFormData.gender === "Others"}
                              disabled={editCase ? true : false}
                              onClick={(e) =>
                                editCase === false && handleOnChangeInput(e)
                              }
                            />
                            <label htmlFor="other">Other</label>
                          </div>
                        </div>
                      </div>
                      {/* State */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="state">
                          State:
                        </label>
                        <Select
                          styles={colourStyles}
                          isSearchable={true}
                          name="state"
                          options={createOptions(states)}
                          classNamePrefix="inventory"
                          className="w-48"
                          isDisabled={editCase ? true : false}
                          onChange={(e) =>
                            editCase === false &&
                            handleOnChangeInput(e, "state")
                          }
                          value={
                            inputFormData.state
                              ? createOptions([inputFormData.state])
                              : null
                          }
                          defaultValue={
                            inputFormData.state
                              ? createOptions([inputFormData.state])
                              : null
                          }
                        ></Select>
                      </div>
                      {/* Zip Code */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="zipCode">
                          Zip Code:
                        </label>
                        <input
                          type="text"
                          name="zipCode"
                          id="zipCode"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.zipCode || ""}
                          maxLength={6}
                          disabled={editCase ? true : false}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between gap-10">
                      {/* Effective Date */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="effectiveDate"
                        >
                          Effective Date:
                        </label>
                        <input
                          type="date"
                          name="effectiveDate"
                          id="effectiveDate"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.effectiveDate || ""}
                          disabled={editCase ? true : false}
                          // pattern="\d{1,2}/\d{1,2}/\d{4}"
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      <div className="flex justify-between gap-6">
                        {/* SIC Code */}
                        <div className="flex flex-col gap-1">
                          <label className="font-semibold" htmlFor="sic">
                            SIC Code:
                          </label>
                          <input
                            type="text"
                            name="sic"
                            id="sic"
                            onChange={(e) =>
                              editCase === false && handleOnChangeInput(e)
                            }
                            onFocus={() => setShowSic(true)}
                            value={inputFormData.sic || ""}
                            disabled={editCase ? true : false}
                            maxLength={5}
                            className="border rounded-md focus:outline-none px-2 py-[10px] w-36"
                          />
                        </div>
                        {/* Open SIC Code Book */}
                        <div className="flex flex-col gap-1 justify-end">
                          <div
                            onClick={() =>
                              editCase === false && setShowSic(!showSic)
                            }
                            className={`p-2 text-xl border rounded-md ${
                              editCase === false
                                ? "hover:bg-gray-500 hover:text-white cursor-pointer"
                                : ""
                            } ${showSic && "bg-gray-500 text-white"}`}
                          >
                            <FaBookOpen />
                          </div>
                          {/* Open Book */}
                          <SicCodeBox
                            show={showSic}
                            setSic={setSic}
                            sic={sic}
                          />
                        </div>
                        {/* Industry */}
                        <div className="flex flex-col gap-1">
                          <label className="font-semibold" htmlFor="industry">
                            Industry:
                          </label>
                          <input
                            type="text"
                            name="industry"
                            id="industry"
                            onChange={(e) =>
                              editCase === false && handleOnChangeInput(e)
                            }
                            value={inputFormData.industry || ""}
                            className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-10 justify-between w-full">
                    {/* Application Infomation */}
                    <div className="flex flex-col gap-3 my-7 text-xs ">
                      <h2 className="uppercase font-semibold text-gray-500">
                        Application Infomation:
                      </h2>
                      <div className="flex justify-between gap-10 w-full">
                        {/* Product */}
                        <div className="flex flex-col gap-1">
                          <label className="font-semibold" htmlFor="product">
                            Product:
                          </label>
                          <Select
                            styles={colourStyles}
                            isSearchable={true}
                            name="product"
                            options={createOptions(PL_products)}
                            classNamePrefix="inventory"
                            className="w-48"
                            isDisabled={editCase ? true : false}
                            onChange={(e) =>
                              editCase === false &&
                              handleOnChangeInput(e, "product")
                            }
                            value={
                              inputFormData.product
                                ? createOptions([inputFormData.product])
                                : null
                            }
                            defaultValue={
                              inputFormData.product
                                ? createOptions([inputFormData.product])
                                : null
                            }
                          ></Select>
                        </div>
                        {/* Broker */}
                        <div className="flex flex-col gap-1">
                          <label className="font-semibold" htmlFor="broker">
                            Broker:
                          </label>
                          <Select
                            styles={colourStyles}
                            isSearchable={true}
                            name="broker"
                            options={createOptions(PL_brokers)}
                            classNamePrefix="inventory"
                            className="w-48"
                            isDisabled={editCase ? true : false}
                            onChange={(e) =>
                              editCase === false &&
                              handleOnChangeInput(e, "broker")
                            }
                            value={
                              inputFormData.broker
                                ? createOptions([inputFormData.broker])
                                : null
                            }
                            defaultValue={
                              inputFormData.broker
                                ? createOptions([inputFormData.broker])
                                : null
                            }
                          ></Select>
                        </div>
                      </div>
                      <div className="flex gap-20 w-full">
                        {/* Annual Premium */}
                        <div className="flex flex-col gap-1 relative">
                          <label className="font-semibold" htmlFor="premium">
                            Annual Premium ($):
                          </label>
                          <div className="flex">
                            <span className="rounded-l-md p-[10px] bg-gray-200">
                              $
                            </span>
                            <input
                              type="text"
                              name="premium"
                              id="premium"
                              value={inputFormData.premium || ""}
                              disabled={editCase ? true : false}
                              onChange={(e) =>
                                editCase === false && handleOnChangeInput(e)
                              }
                              className="border rounded-r-md focus:outline-none px-2 py-[10px] w-[165px]"
                            />
                          </div>
                        </div>
                        {/* Assign Underwriter */}
                        <div className="flex flex-col gap-1">
                          <label
                            className="font-semibold"
                            htmlFor="underwriter"
                          >
                            Assign Underwriter:
                          </label>
                          <Select
                            styles={colourStyles}
                            isSearchable={true}
                            name="underwriter"
                            options={underwriters}
                            classNamePrefix="inventory"
                            className="w-48"
                            onChange={(e) =>
                              handleOnChangeInput(e, "underwriter")
                            }
                            value={
                              inputFormData.underwriter
                                ? createOptions([inputFormData.underwriter])
                                : null
                            }
                            defaultValue={
                              inputFormData.underwriter
                                ? createOptions([inputFormData.underwriter])
                                : null
                            }
                          ></Select>
                        </div>
                      </div>
                    </div>
                    {/* Underwriter Inputs */}
                    {editCase && (
                      <div className="flex flex-col gap-3 my-7 text-xs">
                        <h2 className="uppercase font-semibold text-gray-500">
                          Underwriter Input:
                        </h2>
                        <div className="flex flex-col gap-3">
                          {/* Status */}
                          <div className="flex flex-col gap-1">
                            <label className="font-semibold" htmlFor="status">
                              Status:
                            </label>
                            <Select
                              styles={colourStyles}
                              isSearchable={true}
                              name="status"
                              options={createOptions(status)}
                              classNamePrefix="inventory"
                              className="w-48"
                              onChange={(e) => handleOnChangeInput(e, "status")}
                              value={
                                inputFormData.status
                                  ? createOptions([inputFormData.status])
                                  : null
                              }
                              defaultValue={
                                inputFormData.status
                                  ? createOptions([inputFormData.status])
                                  : null
                              }
                            ></Select>
                          </div>
                          {/* Assessment */}
                          <div className="flex flex-col gap-1">
                            <label
                              className="font-semibold"
                              htmlFor="assessment"
                            >
                              Assessment:
                            </label>
                            <Select
                              styles={colourStyles}
                              isSearchable={true}
                              name="assessment"
                              options={createOptions(assessment)}
                              classNamePrefix="inventory"
                              className="w-48"
                              onChange={(e) =>
                                handleOnChangeInput(e, "assessment")
                              }
                              value={
                                inputFormData.assessment
                                  ? createOptions([inputFormData.assessment])
                                  : null
                              }
                              defaultValue={
                                inputFormData.assessment
                                  ? createOptions([inputFormData.assessment])
                                  : null
                              }
                            ></Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Action Buttons */}
                  <div
                    className={`w-full flex gap-2 mt-3 text-sm items-center ${
                      editCase && formNotifications
                        ? "justify-between"
                        : editCase
                        ? "justify-end"
                        : "justify-between"
                    }`}
                  >
                    {editCase === false ? (
                      <span
                        className={`${formNotifications ? "text-red-500" : ""}`}
                      >
                        <sup className="text-red-500">*</sup>All Inputs fields
                        are required
                      </span>
                    ) : (
                      formNotifications && (
                        <span className="text-green-500">
                          Case updated successfully with Case ID{" "}
                          {inputFormData.id}
                        </span>
                      )
                    )}
                    <div className="flex gap-2 justify-end items-end">
                      {editCase === false && (
                        <input
                          type="reset"
                          value="Reset Form"
                          onClick={() => resetForm()}
                          className="px-4 py-2 bg-gray-200 rounded-md cursor-pointer"
                        />
                      )}
                      {load === "Add Case" ? (
                        <div className="flex items-center gap-2 px-4 py-2 bg-gray-300 rounded-md">
                          <span className="animate-spin">
                            <LuLoader2 />
                          </span>
                          <span>Processing...</span>
                        </div>
                      ) : (
                        <input
                          type="submit"
                          value={editCase ? "Update Case" : "Add Case"}
                          onClick={(e) => addCaseForm(e)}
                          className="px-4 py-2 bg-tertiary hover:bg-opacity-80 rounded-md text-white cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Modal which opens a chart */}
          {chart && (
            <>
              <div
                className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
                onClick={() => closeChart()}
              ></div>
              {/* {console.log(caseScores)} */}
              <div className="fixed z-30 flex items-center w-[80%] justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <div className="bg-white px-16 py-10 rounded-lg relative w-full h-fit flex gap-10 items-center justify-center">
                  <div
                    className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                    onClick={() => closeChart()}
                  >
                    <FaXmark />
                  </div>
                  {caseTable && (
                    <div className="w-2/6 shadow rounded-lg h-full">
                      <div className="shadow rounded-lg overflow-x-auto">
                        <table className="w-full text-left text-gray-500">
                          <thead className="text-xs text-gray-100 capitalize bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800">
                            <tr className="p-2">
                              <th scope="col" className="p-3">
                                Attribute
                              </th>
                              <th scope="col" className="p-3 border-l">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {Object.entries(caseTable).map(
                              ([key, value], index) => (
                                <tr
                                  key={index}
                                  className={`border-b hover:bg-gray-100 break-words text-xs`}
                                >
                                  <td className="p-2">{key}</td>
                                  <td className="p-2 border-l">
                                    {key === "Submit Date"
                                      ? DateFormatter(new Date(value))
                                      : key === "Annual Premium"
                                      ? CurrencyFormatter(value)
                                      : value}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="w-4/6 border rounded-lg shadow h-full">
                    <RadarChart data={caseScores} module={"Lead Prioritizer"} />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PL_WorkInventory;
