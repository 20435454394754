// form inputs
export const IL_PR_formInputs = {
  age: "",
  ageBin: "",
  gender: "",
  maritalStatus: "",
  dependents: "",
  region: "",
  netWorth: "",
  occupation: "",
};

// occupation dropdown
export const IL_PR_occupation = [
  "Agriculture",
  "Communications & Utilities",
  "Construction",
  "Education & Social Services",
  "Entertainment & Recreation",
  "Financial Services",
  "Health Services",
  "Legal Services",
  "Manufacturing",
  "Membership Organizations",
  "Mining",
  "Personal & Business Services",
  "Professional Services",
  "Public Administration",
  "Retail",
  "Transportation",
  "Wholesale Trade",
];

// region dropdown
export const IL_PR_region = [
  "East",
  "West",
  "Central",
  "South",
  "North",
  "Southwest",
  "Northwest",
];

// new worth dropdown
export const IL_PR_worth = [
  { value: "Mass Market", label: "Mass Market (<$100K)" },
  { value: "Affluent", label: "Affluent ($100K-$1M)" },
  { value: "High Net Worth", label: "High Net Worth ($1M+)" },
];

// dependents dropdown
export const IL_PR_dependents = ["None", "1", "2+"];

// function to assign age bin
export const IL_PR_ageBin = (age) => {
  let ageBin = "";
  if (age < 25) {
    ageBin = "<25";
  } else if (age >= 25 && age <= 34) {
    ageBin = "25-34";
  } else if (age >= 35 && age <= 44) {
    ageBin = "35-44";
  } else if (age >= 45 && age <= 54) {
    ageBin = "45-54";
  } else if (age >= 55 && age <= 69) {
    ageBin = "55-69";
  } else {
    ageBin = "70+";
  }
  return ageBin;
};
