import React from "react";
import {
  CurrencyFormatter,
  CurrencyFormatterK,
  NumberFormatterRounded,
  PercentFormatter,
} from "../../../utils/Utils";
import { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { useEffect } from "react";

// const boldIndex = [2, 3, 4, 5, 22]
// const pad_1Index = [8, 12, ]
const years = [2022, 2023, 2024, 2025];

const CBASummary = ({ info, data, IRR, NPV, payBackPeriod }) => {
  const [openRows, setOpenRows] = useState({}); // Use an object for multiple open/close

  const handleOpenCloseRow = (row, isOpen) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [row]: isOpen, // Toggle open/close state for the specific row
    }));
  };

  const renderRowContent = (rowData, rowIndex, isOpen) => (
    <>
      <td colSpan={1} className="w-5 pl-3">
        {(rowIndex == 8 ||
          rowIndex == 13 ||
          rowIndex == 18 ||
          rowIndex == 23 ||
          rowIndex == 27 ||
          rowIndex == 31) && (
          <span
            className="text-lg cursor-pointer"
            onClick={() => handleOpenCloseRow(rowIndex, isOpen)}
          >
            {isOpen ? <CiSquarePlus /> : <CiSquareMinus />}
          </span>
        )}
      </td>
      <td className="py-2 px-4 border-r w-60">{rowData}</td>
      {years.map((year, idx) => (
        <td
          key={idx + 10}
          className="py-2 px-4 w-32 text-center border-r last-of-type:border-r-0"
        >
          {rowIndex < 3
            ? NumberFormatterRounded(data[`${year}`][rowIndex])
            : CurrencyFormatterK(data[`${year}`][rowIndex])}
        </td>
      ))}
    </>
  );

  return (
    <>
      <div className="flex flex-col justify-center w-full my-5">
        <h3 className="font-bold pb-5">
          High Level CBA Summary of {info} Volume Scenario
        </h3>
        <div className="w-full border rounded-md overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-300">
              <tr>
                <th className="py-2 border-r" colSpan={2} rowSpan={2}>
                  Aggregate
                </th>
                <th className="py-2 border-r">Baseline</th>
                <th className="py-2" colSpan={3}>
                  CBA Financials
                </th>
              </tr>
              <tr className="border-t">
                {years.map((year, index) => (
                  <th
                    key={index}
                    className="py-2 border-r last-of-type:border-r"
                  >
                    {year}
                    {index != 0 && "F"}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[
                ...[0, 1, 2, 3, 4, 5, 6, 7, 8],
                ...(openRows[8] ? [9, 10, 11] : []),
                ...[12, 13],
                ...(openRows[13] ? [14, 15, 16, 17] : []),
                18,
                ...(openRows[18] ? [19, 20, 21] : []),
                ...[22, 23],
                ...(openRows[23] ? [24, 25] : []),
                ...[26, 27],
                ...(openRows[27] ? [28, 29, 30] : []),
                31,
                ...(openRows[31] ? [32] : []),
                ...[33, 34],
              ].map((rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`${rowIndex % 2 === 0 ? "" : "bg-gray-100"}`}
                >
                  {renderRowContent(
                    data["Aggregate"][rowIndex],
                    rowIndex,
                    openRows[rowIndex] ? false : true
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Final Results */}
      <div className="my-8">
        <div className="flex justify-end gap-10 my-4 items-center">
          <p className="text-sm">Internal Rate of Return (IRR)</p>
          <p className="text-lg border-2 border-green-500 font-bold p-2 w-32 text-center">
            {PercentFormatter(IRR)}
          </p>
        </div>
        <div className="flex justify-end gap-10 my-4 items-center">
          <p className="text-sm">Net Present Value (NPV)</p>
          <p className="text-lg border-2 border-green-500 font-bold p-2 w-32 text-center">
            {CurrencyFormatterK(NPV)}
          </p>
        </div>
        <div className="flex justify-end gap-10 my-4 items-center">
          <p className="text-sm">Payback Period (years)</p>
          <p className="text-lg border-2 border-green-500 font-bold p-2 w-32 text-center">
            {NumberFormatterRounded(payBackPeriod)}
          </p>
        </div>
      </div>
    </>
  );
};

export default CBASummary;
