import React from "react";
import CL from "./competitive-landscape.png";
import Images from "../Images";

const CompetitiveLandscape = () => {

  const toggleFullScreenOn = (index) => {
    document.getElementById(index).requestFullscreen();
  };

  const toggleFullScreenOff = () => {
    document.exitFullscreen();
  };

  return (
    <div onClick={() => toggleFullScreenOn(1)} className="cursor-zoom-in">
      <img
        id={1}
        src={CL}
        alt={"competitive landscape"}
        className="cursor-zoom-out"
        style={{ cursor: "zoom-out" }}
        onClick={() => toggleFullScreenOff()}
      />
    </div>
  );
};

export default CompetitiveLandscape;
