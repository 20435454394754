import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import HomeContent from "../components/home/HomeContent";
import { industries, solutions } from "../data/data";

const HomePage = () => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Welcome to the Spinnaker Portal!");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex gap-10 p-5 pt-0 relative h-full">
      <section className="w-full h-full text-xs xl:text-sm">
        {/* Tabs */}
        <div className="flex my-2 flex-col gap-5 h-full transition-all duration-300 ease-linear select-none">
          {/* Solutions */}
          <div className="mt-2">
            <h2 className="flex items-center gap-2 text-gray-700 w-fill p-5 pt-0 text-base xl:text-lg font-bold">
              Spinnaker Solutions:
            </h2>
            <HomeContent data={solutions} />
          </div>
          {/* Industries */}
          <div className="mt-2">
            <h2 className="flex items-center  gap-2 text-gray-700 w-fill p-5 md:pt-0 text-base xl:text-lg font-bold">
              Industry Focused Solutions:
            </h2>
            <HomeContent data={industries} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
