// Common Data

// Filtering Inputs
export const filterInputs = {
  underwriter: "All Underwriters",
  status: "All",
  priority: "",
  searchFor: "",
  orderBy: "Submit Date",
  orderStyle: "ASC",
};

// Status
export const status = ["New", "Open", "Complete"];

// Assessment
export const assessment = [
  "Declined",
  "More Info Required",
  "High Risk",
  "Medium Risk",
  "Low Risk",
];

// Remove priority word from Lead Action
export const avoidPriority = (leadAction) => {
  const parts = leadAction.split(" ");
  return parts[0];
};

//============================================================= Individual Life =============================================================
// Add case Form Inputs
export const IL_formInputs = {
  age: "",
  ageBin: "",
  income: "",
  incomeBin: "",
  state: "",
  gender: "",
  alcohol: "",
  tobacco: "",
  height: "",
  weight: "",
  bmi: "",
  bmiBin: "",
  appType: "",
  faceAmount: "",
  faceAmountBin: "",
  product: "",
  broker: "",
  assessment: "",
  status: "",
  underwriter: "",
  percentiles: [],
  leadAction: "",
  leadScore: "",
  submitDate: "",
  id: "",
};

// Column Names
export const IL_columns = [
  "Case ID",
  "Submit Date",
  "Client Name",
  "Age",
  "Gender",
  "BMI",
  "State",
  "Income",
  "Alcohol",
  "Tobacco",
  "App Type",
  "Product",
  "Face Amount",
  "Broker",
  "Status",
  "Assessment",
  "Lead Score",
  "Lead Action",
];

export const IL_SC_formInputs = {
  age: "",
  income: "",
  state: "",
  gender: "",
  alcohol: "",
  tobacco: "",
  height: "",
  weight: "",
  appType: "",
  faceAmount: "",
  product: "",
  broker: "",
  bmiBin: "",
  faceAmountBin: "",
  incomeBin: "",
  ageBin: "",
};

export const IL_productTypes = [
  "IUL",
  "Guaranteed UL",
  "Term 10",
  "Term 15",
  "Term 20",
  "Term 30",
  "VUL",
  "Whole Life",
];

// broker names
export const IL_brokers = [
  "Aon",
  "Ash",
  "AXA Network",
  "Bramco",
  "Brown",
  "Capitas",
  "CPS",
  "Crump",
  "Doyle",
  "Executive Valmark",
  "Gallagher",
  "Highland Capital",
  "IDA",
  "JLT",
  "Lifemark",
  "M Financial",
  "Marsh",
  "Merrill",
  "Millennium",
  "Morgan ",
  "Nexus",
  "NFP",
  "Willis",
  "Other",
];

// function change yes to tobacco and no to non-tobacco and vice versa
export const changeTobacco = (str) => {
  let value = "";
  if (str === "Yes") {
    value = "Tobacco";
  } else if (str === "No") {
    value = "Non-Tobacco";
  } else if (str === "Tobacco") {
    value = "Yes";
  } else if (str === "Non-Tobacco") {
    value = "No";
  }
  return value;
};

// function change yes to alcohol and no to non-alcohol and vice versa
export const changeAlcohol = (str) => {
  let value = "";
  if (str === "Yes") {
    value = "Alcohol";
  } else if (str === "No") {
    value = "Non-Alcohol";
  } else if (str === "Alcohol") {
    value = "Yes";
  } else if (str === "Non-Alcohol") {
    value = "No";
  }
  return value;
};

// function to assign income bin
export const IL_incomeBin = (income) => {
  let new_income = income.toString().includes(",")
    ? income.split(",").join("")
    : income;
  let incomeBin = "";
  if (new_income <= 30000) {
    incomeBin = "<$30k";
  } else if (new_income > 30000 && new_income <= 50000) {
    incomeBin = "$30-50k";
  } else if (new_income > 50000 && new_income <= 75000) {
    incomeBin = "$50-75k";
  } else if (new_income > 75000 && new_income <= 100000) {
    incomeBin = "$75-100k";
  } else if (new_income > 100000 && new_income <= 150000) {
    incomeBin = "$100-150k";
  } else if (new_income > 150000 && new_income <= 200000) {
    incomeBin = "$150-200k";
  } else if (new_income > 200000 && new_income <= 300000) {
    incomeBin = "$200-300k";
  } else if (new_income > 300000 && new_income <= 500000) {
    incomeBin = "$300-500k";
  } else {
    incomeBin = "$500k+";
  }
  return incomeBin;
};

// function to assign face amount bin
export const IL_faceAmountBin = (face_amount) => {
  let new_amount = face_amount.toString().includes(",")
    ? face_amount.split(",").join("")
    : face_amount;
  let faceAmountBin = "";
  if (new_amount <= 250000) {
    faceAmountBin = "<$250k";
  } else if (new_amount > 250000 && new_amount <= 500000) {
    faceAmountBin = "$250k-$500k";
  } else if (new_amount > 500000 && new_amount <= 1000000) {
    faceAmountBin = "$500k-$1M";
  } else if (new_amount > 1000000 && new_amount <= 3000000) {
    faceAmountBin = "$1-3M";
  } else if (new_amount > 3000000 && new_amount <= 5000000) {
    faceAmountBin = "$3M-$5M";
  } else if (new_amount > 5000000 && new_amount <= 10000000) {
    faceAmountBin = "$5M-$10M";
  } else {
    faceAmountBin = "$10M+";
  }
  return faceAmountBin;
};

// function to assign lead action
export const IL_leadAction = (score) => {
  let leadAction = "";
  if (score >= 425) {
    leadAction = "High Priority";
  } else if (score >= 300) {
    leadAction = "Normal Priority";
  } else if (score >= 226) {
    leadAction = "Low Priority";
  } else {
    leadAction = "DTQ";
  }
  return leadAction;
};

//============================================================= Employee Benefits =============================================================
// Add case Form Inputs
export const EB_formInputs = {
  id: "",
  submitDate: "",
  lives: "",
  companySize: "",
  state: "",
  product: "",
  broker: "",
  sic: "",
  industry: "",
  assessment: "",
  effectiveDate: "",
  premium: "",
  status: "",
  underwriter: "",
  percentiles: [],
  leadAction: "",
  leadScore: "",
  month: "",
};

// Column Names
export const EB_columns = [
  "Case ID",
  "Submit Date",
  "Client Name",
  "Company Size",
  "SIC",
  "Industry",
  "Product",
  "Zip Code",
  "State",
  "Annual Premium",
  "Effective Date",
  "Broker",
  "Status",
  "Assessment",
  "Lead Score",
  "Lead Action",
];

// form inputs
export const EB_SC_formInputs = {
  companySize: "",
  lives: "",
  product: "",
  state: "",
  month: "",
  broker: "",
  sic: "",
  industry: "",
};

// product types
export const EB_products = [
  "Dental",
  "Life",
  "LTD",
  "STD",
  "Vision",
  "Worksite",
];

// brokers
export const EB_brokers = [
  "Alliant",
  "Aon",
  "BeneUSA",
  "Brown & Brown",
  "Cambridge Consulting",
  "Cornerstone Group",
  "Direct",
  "Emerson Reid",
  "Expresslink",
  "FNA",
  "Gallagher",
  "Hays",
  "Hub International",
  "Hylant",
  "Innovative Broker Solutions",
  "Johnson insurance",
  "Kelly & Associates",
  "Lockton",
  "Marsh",
  "Mercer",
  "NFP",
  "OneDigital",
  "Paychex",
  "Professional Group Plans",
  "R & R",
  "Stealth Partners",
  "The Cason Group",
  "USI",
  "Vista National",
  "Willis",
];

// function to assign company size bin
export const EB_companySizeBin = (number) => {
  let new_number = number.toString().includes(",")
    ? number.split(",").join("")
    : number;
  let lives = "";
  if (new_number === null || new_number === "") {
    lives = "";
  } else if (new_number < 25) {
    lives = "<25";
  } else if (new_number >= 25 && new_number <= 99) {
    lives = "25-99";
  } else if (new_number >= 100 && new_number <= 499) {
    lives = "100-499";
  } else if (new_number >= 500 && new_number <= 999) {
    lives = "500-999";
  } else if (new_number >= 1000 && new_number <= 1999) {
    lives = "1000-1999";
  } else {
    lives = ">2000";
  }
  return lives;
};

// function to assign lead action
export const EB_leadAction = (score) => {
  let leadAction = "";
  if (score >= 800) {
    leadAction = "High Priority";
  } else if (score >= 500 && score < 800) {
    leadAction = "Normal Priority";
  } else if (score >= 200) {
    leadAction = "Low Priority";
  } else {
    leadAction = "DTQ";
  }
  return leadAction;
};

// legends
export const EB_legends = {
  "High Priority": "More then 800",
  "Normal Priotiry": "500 - 800",
  "Low Priority": "200 - 500",
  DTQ: "Less then 200",
};

//============================================================= Commercial Lines - P&C =============================================================
// Add case Form Inputs
export const CL_formInputs = {
  companySize: "",
  state: "",
  product: "",
  broker: "",
  sic: "",
  industry: "",
  assessment: "",
  effectiveDate: "",
  premium: "",
  status: "",
  underwriter: "",
  territory: "CENTRAL",
  marketSegment: "",
};

// Column Names
export const CL_columns = [
  "Case ID",
  "Submit Date",
  "Client Name",
  "Company Size",
  "Product",
  "SIC",
  "Zip Code",
  "Effective Date",
  "State",
  "Annual Premium",
  "Broker",
  "Status",
  "Assessment",
  "Lead Score",
  "Lead Action",
];

// form inputs
export const CL_SC_formInputs = {
  companySize: "",
  lives: "",
  product: "",
  state: "",
  month: "",
  sic: "",
  industry: "",
  territory: "",
  marketSegment: "",
};

// products
export const CL_products = [
  "Professional Liability",
  "Fleet",
  "SME Package",
  "Worker's Comp",
  "Property",
  "Others",
];

// brokers
export const CL_brokers = [
  "AJ Gallagher",
  "Alliant",
  "AON",
  "Assured Partners",
  "Bolton",
  "Brown & Brown",
  "Charles L Crane",
  "Epic",
  "First Insurance",
  "Heffernan",
  "Holmes Murphy",
  "Hub",
  "Hylant",
  "IOA",
  "IMA",
  "Lockton",
  "Marsh",
  "McGriff",
  "NFP",
  "Parker Smith & Feek",
  "USI",
  "Willis",
  "All Other Brokers",
];

// market segments
export const CL_marketSegments = ["Small/Middle Markets", "Major Accounts"];

// territory
export const CL_territories = ["CENTRAL", "EAST", "WEST"];

// function to assign company size bin
export const CL_companySizeBin = (number) => EB_companySizeBin(number);

// function to assign lead action
export const CL_leadAction = (score) => EB_leadAction(score);

//============================================================= Personal Lines - P&C =============================================================
// Add case Form Inputs
export const PL_formInputs = {
  age: "",
  ageBin: "",
  gender: "",
  state: "",
  premium: "",
  sic: "",
  industry: "",
  product: "",
  broker: "",
  assessment: "",
  status: "",
  underwriter: "",
};

// Column Names
export const PL_columns = [
  "Case ID",
  "Submit Date",
  "Client Name",
  "Age",
  "Gender",
  "Product",
  "State",
  "Zip Code",
  // "SIC",
  // "Industry",
  "Annual Premium",
  "Broker",
  "Status",
  "Assessment",
  "Lead Score",
  "Lead Action",
];

// form inputs
export const PL_SC_formInputs = {
  age: "",
  product: "",
  state: "",
  month: "",
  broker: "",
  sic: "",
  industry: "",
};

// products
export const PL_products = [
  "Auto",
  "Home",
  "Renters",
  "Specialty Personal",
  "Valuables",
];

// brokers
export const PL_brokers = [
  "AJ Gallagher",
  "Alliant",
  "AON",
  "Assured Partners",
  "Bolton",
  "Brown & Brown",
  "Charles L Crane",
  "Epic",
  "First Insurance",
  "Heffernan",
  "Holmes Murphy",
  "Hub",
  "Hylant",
  "IOA",
  "IMA",
  "Lockton",
  "Marsh",
  "McGriff",
  "NFP",
  "Parker Smith & Feek",
  "USI",
  "Willis",
  "All Other Brokers",
];

// function to assign lead action
export const PL_leadAction = (score) => IL_leadAction(score);
