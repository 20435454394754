import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../../../../api/api";
import {
  CurrencyFormatter,
  DateFormatter,
  PercentFormatter,
  allowScroll,
  preventScroll,
} from "../../../utils/Utils";
import { LuLoader2 } from "react-icons/lu";
import { FaFilePdf, FaXmark } from "react-icons/fa6";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiSearchLine } from "react-icons/ri";
import TablePaginate from "../../../utils/TablePaginate";
import {
  CI_columns,
  CI_filterInputs,
} from "../../../../data/modules/commissionIntelligence";
import { colourStyles } from "../../../utils/Functions";
import { createOptions } from "../../../../data/staticData";

const ContractInventory = ({ load, inputFormData }) => {
  const [inputFilterData, setInputFilterData] = useState(CI_filterInputs);
  const [contractTable, setContractTable] = useState(null);
  const [tableLoad, setTableLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [fileData, setFileData] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPct, setUploadPct] = useState({ text: "", number: 0 });
  const [edit, setEdit] = useState(false);
  const [carriers, setCarriers] = useState(null);
  const [showEntries, setShowEntries] = useState(20);

  // function to fetch all carriers
  const getAllCarriers = () => {
    API.post("/commission-intelligence/get-carriers", inputFilterData)
      .then(({ data }) => {
        // console.log(data.carriers);
        const carrierOptions = createOptions(data.carriers);
        // setCarriers(data.carriers);
        setCarriers(carrierOptions);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // fetch work inventory table from database
  const getContractTable = () => {
    API.post("/commission-intelligence/get-contracts", inputFilterData)
      .then(async (res) => {
        // fresh or updated work inventory
        await setContractTable(res.data.tableData);
        setTableLoad(false);
      })
      .catch((err) => {
        // stop loader
        setTableLoad(false);
        // console.log(err);
      });
  };

  // Whenever inputFilterData changes
  useEffect(() => {
    setTableLoad(true);
    getContractTable();
    setPage(1);
  }, [inputFilterData]);

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // Handle carrier change
  const handleCarrierChange = (e) => {
    // console.log(e);
    setInputFilterData({
      ...inputFilterData,
      carrier: e.value,
    });
  };

  // Search Handle
  const handleSearchFor = (e) => {
    setInputFilterData({
      ...inputFilterData,
      searchFor: e.target.value,
    });
  };

  // sort table
  const sortByColumn = (column) => {
    setInputFilterData({
      ...inputFilterData,
      orderBy: column,
      orderStyle:
        inputFilterData.orderBy === column &&
        inputFilterData.orderStyle === "ASC"
          ? "DESC"
          : "ASC",
    });
  };

  // handle file info
  const handleFileInfo = (e) => {
    let fileName = e.target.value.split("\\");
    setFileData(fileName[fileName.length - 1]);
  };

  // close upload modal
  const closeUploadModal = () => {
    setOpenUpload(false);
    allowScroll();
    setFileData(null);
    setUploading(false);
  };

  // Load this first
  useEffect(() => {
    getAllCarriers();
  }, []);

  // handle upload file
  const handleFileUpload = (e) => {
    e.preventDefault();

    if (fileData) {
      setUploading(true);

      let max = 20;
      let time = 50;
      uploadPct.text = "Uploading the contract";

      const loading = () => {
        setUploadPct({
          ...uploadPct,
          number: (uploadPct.number += 1),
        });
        if (uploadPct.number === max) {
          clearInterval(animate);
        }
      };

      const animate = setInterval(() => loading(), time);

      setTimeout(() => {
        max = 43;
        time = 100;
        uploadPct.text = "Searching for contract";
        const loading = () => {
          setUploadPct({
            ...uploadPct,
            number: (uploadPct.number += 1),
          });
          if (uploadPct.number === max) {
            clearInterval(animate);
          }
          const animate = setInterval(() => loading(), time);
        };
      }, 1000);

      // PATCH API call to update the date
      setTimeout(() => {
        max = 85;
        time = 140;
        uploadPct.text = "Extracting the data";

        API.patch("/commission-intelligence/commission-tool", {
          carrier: "Liberty",
        })
          .then((res) => {
            inputFilterData.carrier = "Liberty";
          })
          .catch((err) => {
            console.log(err);
          });

        const loading = () => {
          setUploadPct({
            ...uploadPct,
            number: (uploadPct.number += 1),
          });
          if (uploadPct.number === max) {
            clearInterval(animate);
          }
        };

        const animate = setInterval(() => loading(), time);
      }, 4000);

      setTimeout(() => {
        max = 100;
        time = 130;
        uploadPct.text = "Updating the database";

        const loading = () => {
          if (uploadPct.number < 100) {
            setUploadPct({
              ...uploadPct,
              number: (uploadPct.number += 1),
            });
          } else {
            clearInterval(animate);
          }
          inputFilterData.isFileUploaded = true;
          inputFormData.isFileUploaded = true;
          getAllCarriers();
          getContractTable();
          if (uploadPct.number >= max) {
            closeUploadModal();
            clearInterval(animate);
          }
        };
        const animate = setInterval(() => loading(), time);
      }, 10000);
    }
  };

  return contractTable === null ? (
    <div className="p-10 mt-10 gap-2 py-3 flex items-center justify-center w-full">
      Loading Contracts
      <span className="animate-spin text-2xl">
        <LuLoader2 />
      </span>
    </div>
  ) : (
    <section>
      {/* Title */}
      <h2 className="text-base font-semibold">Table with existing contracts</h2>
      {/* Table filters */}
      <div className="flex justify-between my-5 text-[11px]">
        {/* Filter Selects */}
        <div className="flex justify-between w-full gap-10 items-center h-fit">
          <div className="flex gap-10">
            {/* Show number of Entries at a time filter */}
            <div className="flex gap-2 items-center h-fit">
              Show entries:
              <Select
                className="w-20"
                classNamePrefix="select"
                isSearchable={true}
                name="underwriter"
                options={[
                  { value: "20", label: "20" },
                  { value: "40", label: "40" },
                  { value: "60", label: "60" },
                  { value: "80", label: "80" },
                  { value: "100", label: "100" },
                ]}
                onChange={(e) => handleEntriesChange(e)}
                defaultValue={{
                  value: "20",
                  label: "20",
                }}
                styles={colourStyles}
              />
            </div>
            {/* Select Carriers filter */}
            {carriers !== null && (
              <div className="flex gap-2 h-fit items-center w-64">
                Select Carrier:
                <Select
                  className="w-40"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="carriers"
                  options={[
                    { value: "All Carriers", label: "All Carriers" },
                    ...carriers,
                  ]}
                  onChange={(e) => handleCarrierChange(e)}
                  defaultValue={
                    inputFormData.isFileUploaded
                      ? {
                          value: "Liberty",
                          label: "Liberty",
                        }
                      : {
                          value: "All Carriers",
                          label: "All Carriers",
                        }
                  }
                  styles={colourStyles}
                />
              </div>
            )}
          </div>
          <div className="flex gap-10 relative">
            {/* Upload Contract */}
            <button
              onClick={() => {
                setOpenUpload(true);
                preventScroll();
              }}
              className="flex gap-2 items-center text-sm px-5 rounded-lg text-white bg-tertiary hover:bg-opacity-80"
            >
              <AiOutlineCloudUpload className="text-xl" />
              Upload Contract
            </button>
            {/* Search Filter */}
            <div className="relative h-fit">
              <RiSearchLine className="absolute z-5 top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
              <input
                type="text"
                name="searchFor"
                id="searchFor"
                placeholder="Search Table.."
                className="rounded-lg indent-10 px-2 py-3 border focus:outline-2 w-56 focus:outline-gray-200"
                onChange={(e) => handleSearchFor(e)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Contracts Table */}
      <div className="shadow border-l border-r overflow-x-auto mt-6">
        <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
          <thead className="capitalize bg-gray-300 text-gray-700">
            <tr className="p-2 transition-all duration-300 ease-in-out text-center">
              {CI_columns.map((column, index) => (
                <th
                  key={index}
                  scope=""
                  className="p-1 transition-all duration-300 ease-in-out"
                >
                  <div
                    className={`flex p-2 gap-1 justify-center relative cursor-pointer hover:underline underline-offset-2 ${
                      inputFilterData.orderBy === column && "text-tertiary"
                    }`}
                    onClick={() => sortByColumn(column)}
                  >
                    {column}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {contractTable
              ?.slice(showEntries * (page - 1), page * showEntries)
              .map((item, index) => (
                <tr
                  key={index}
                  className={`border-b hover:bg-gray-200 break-words text-center ${
                    index % 2 === 0 ? "" : "bg-gray-50"
                  }`}
                >
                  {CI_columns.map((column, id) => (
                    // On click open chart showing percentile
                    <td
                      key={id}
                      className={`p-2 ${
                        item[column] === "Liberty"
                          ? "text-sky-700 font-semibold"
                          : ""
                      }`}
                    >
                      {column === "Elegible Written Premium (Min)" ||
                      column === "Elegible Written Premium (Max)"
                        ? CurrencyFormatter(item[column])
                        : column === "Ingestion Date"
                        ? DateFormatter(new Date(item["Ingestion Date"]))
                        : column === "Limited Loss Ratio (Min)" ||
                          column === "Limited Loss Ratio (Max)" ||
                          column === "Contingent Commission (%)"
                        ? PercentFormatter(item[column])
                        : item[column]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          {tableLoad && (
            <tbody className="absolute topx-9 py-36 bg-black bg-opacity-30 text-white text-lg flex gap-2 items-center justify-center z-10 h-full w-full">
              <tr className="w-full">
                <td className="animate-spin text-xl">
                  <LuLoader2 />
                </td>
                <td>Loading..</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {/* Show when no rows available */}
      {contractTable.length === 0 && (
        <div className="w-full flex justify-center p-2 text-center border shadow text-xs text-gray-600">
          No matching records found
        </div>
      )}
      {/* Table Pagination */}
      <TablePaginate
        setPage={setPage}
        showEntries={showEntries}
        page={page}
        table={contractTable}
      />

      {/* upload pdf file modal */}
      {openUpload && (
        <>
          <div
            className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
            onClick={() => load === false && closeUploadModal()}
          ></div>
          {/* {console.log(caseScores)} */}
          <div className="fixed z-30 flex items-center w-1/2 justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div className="bg-white px-16 py-10 rounded-lg relative flex w-full gap-10 items-center justify-center">
              <div
                className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                onClick={() => uploading === false && closeUploadModal()}
              >
                <FaXmark />
              </div>
              <form action="" className="h-fit relative w-full">
                <div
                  className={`h-[300px] relative w-full border-[3.5px] border-dashed select-none ${
                    uploading
                      ? ""
                      : "cursor-pointer hover:border-primary bg-gray-100 hover:bg-gray-200"
                  }  rounded-md flex items-center justify-center `}
                >
                  {fileData ? (
                    <>
                      {uploading && (
                        <video
                          src="https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/commission-intelligence/uploader.mp4"
                          className="outline-none w-[200px]"
                          playsInline
                          autoPlay
                          muted
                        ></video>
                      )}
                      <div
                        className={`absolute flex items-center justify-center gap-2 ${
                          uploading ? "bottom-5" : ""
                        }`}
                      >
                        <FaFilePdf className="text-2xl" />
                        {fileData}
                      </div>
                    </>
                  ) : (
                    <p className="text-gray-400 absolute">
                      Drag and drop pdf contract file here..
                    </p>
                  )}
                  {!uploading && (
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="w-full h-full opacity-0 cursor-pointer"
                      accept="application/pdf"
                      onChange={(e) => handleFileInfo(e)}
                    />
                  )}
                </div>
                {/* Actions */}
                <div className="mt-3">
                  {uploading ? (
                    <>
                      <div className="w-full h-4 bg-gray-400 rounded-full">
                        <div
                          className="h-4 rounded-full transition-all ease-in-out bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800"
                          style={{ width: uploadPct?.number + "%" }}
                        ></div>
                      </div>
                      <p className="text-sm mt-2 text-center w-full">
                        {uploadPct?.text}.. {uploadPct?.number}% complete
                      </p>
                    </>
                  ) : (
                    <input
                      type="submit"
                      value="Upload Contract"
                      className="float-right p-2 cursor-pointer bg-primary hover:bg-opacity-80 rounded-lg text-white"
                      onClick={(e) => handleFileUpload(e)}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ContractInventory;
