import React, { useState } from "react";
import API from "../../../api/api";
import Select from "react-select";
import { createOptions } from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
import { LuLoader2 } from "react-icons/lu";
import {
  IL_PR_ageBin,
  IL_PR_dependents,
  IL_PR_formInputs,
  IL_PR_occupation,
  IL_PR_region,
  IL_PR_worth,
} from "../../../data/modules/cartalyze";

const PREngine = () => {
  const [inputFormData, setInputFormData] = useState(IL_PR_formInputs);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [products, setProducts] = useState(null);
  const [prodList, setProdList] = useState([]);
  const [selectedProd, setSelectedProd] = useState("");
  const [load, setLoad] = useState(false);
  const [carriers, setCarriers] = useState(null);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "age") {
      let value = IL_PR_ageBin(e.target.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
        ageBin: value,
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // function to get score
  const getRecommendation = async (e) => {
    e.preventDefault();
    setLoad(true);
    setCarriers(null);
    setSelectedProd("");
    // If existing case is being edited

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      console.log(inputFormData);
      return;
    } else {
      setInputsFilled(false);
    }

    // API post request
    API.post("/Cartalyze/IL/pr-engine", inputFormData)
      .then(({ data }) => {
        let sorted = Object.entries(data.likelihood).sort(
          (a, b) => b[1] - a[1]
        );
        sorted = Object.fromEntries(sorted);
        setProducts(sorted);
        setProdList([]);
        Object.keys(sorted).every((key, i) => {
          setProdList((prev) => [...prev, key]);
          // console.log(i);
          if (i > 1) {
            // console.log("returned");
            return false;
          } else {
            return true;
          }
        });
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  // function to get product on change
  const onProductSelect = (e) => {
    setSelectedProd(e.value);
    API.get("/Cartalyze/IL/pr-engine", {
      params: {
        ...inputFormData,
        product: e.value,
      },
    })
      .then(({ data }) => {
        let sorted = Object.entries(data.carriers).sort((a, b) => b[1] - a[1]);
        sorted = Object.fromEntries(sorted);
        setCarriers(sorted);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // reset form
  const resetForm = () => {
    setInputFormData(IL_PR_formInputs);
    setProducts(null);
    setProdList([]);
    setSelectedProd("");
    setCarriers(null);
    setInputsFilled(false);
  };

  return (
    <div className="flex h-max gap-10">
      <div className="min-w-[480px] p-7 bg-white rounded-lg shadow border h-full text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getRecommendation(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-10 justify-between">
              {/* Age */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="age">
                  Age:
                </label>
                <input
                  type="text"
                  name="age"
                  id="age"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.age || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                />
              </div>
            </div>
            <div className="flex gap-44">
              {/* Gender */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="gender">
                  Gender:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value={"Male"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value={"Female"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="other"
                      value={"Others"}
                      checked={inputFormData.gender === "Others"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              </div>
              {/* Marital Status */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="maritalStatus">
                  Marital Status:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="maritalStatus"
                      id="maritalStatus_single"
                      value={"Single"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="maritalStatus_single">Single</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="maritalStatus"
                      id="maritalStatus_married"
                      value={"Married"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="maritalStatus_married">Married</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Dependents */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="dependents">
                  Dependents:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="dependents"
                  options={createOptions(IL_PR_dependents)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "dependents")}
                  value={
                    inputFormData.dependents
                      ? createOptions([inputFormData.dependents])
                      : null
                  }
                ></Select>
              </div>
              {/* Region */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="region">
                  Region:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="region"
                  options={createOptions(IL_PR_region)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "region")}
                  value={
                    inputFormData.region
                      ? createOptions([inputFormData.region])
                      : null
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Net Worth */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="netWorth">
                  Net Worth:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="netWorth"
                  options={IL_PR_worth}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "netWorth")}
                  value={
                    inputFormData.netWorth
                      ? IL_PR_worth.map((item) => {
                          if (item.value === inputFormData.netWorth) {
                            return item;
                          }
                        })
                      : null
                  }
                ></Select>
              </div>
              {/* Occupation */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="occupation">
                  Occupation:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="occupation"
                  options={createOptions(IL_PR_occupation)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "occupation")}
                  value={
                    inputFormData.occupation
                      ? createOptions([inputFormData.occupation])
                      : null
                  }
                ></Select>
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Get Recommendation"
                onClick={(e) => getRecommendation(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>
      <div className="min-w-[700px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg flex flex-col p-5">
        {products ? (
          <div>
            {/* Select Product */}
            <div className="flex items-center justify-end gap-1 text-xs">
              <label className="text-sm font-semibold" htmlFor="occupation">
                Select Product:
              </label>
              <Select
                styles={colourStyles}
                isSearchable={true}
                name="occupation"
                options={createOptions(prodList)}
                classNamePrefix="inventory"
                value={
                  selectedProd !== "" && {
                    label: selectedProd,
                    value: selectedProd,
                  }
                }
                className="w-48"
                onChange={(e) => onProductSelect(e)}
              ></Select>
            </div>
            <div className="grid grid-cols-2 gap-5">
              {/* Top Products */}
              <div className="p-5 text-base">
                <h2 className="font-semibold mt-2">Top Recommended Products</h2>
                <div className="overflow-x-auto w-fit mt-10 shadow rounded-lg">
                  <table className="text-sm">
                    <thead>
                      <tr>
                        <th className="p-4 border border-t-0 border-l-0 bg-gray-700 text-gray-200">
                          #
                        </th>
                        <th className="p-4 border border-t-0 bg-gray-700 text-gray-200">
                          Product
                        </th>
                        <th className="p-4 border border-t-0 border-r-0 bg-gray-700 text-gray-200">
                          Purchase Likelihood
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(products)
                        .splice(0, 3)
                        .map(([key, value], id) => (
                          <tr key={id}>
                            <td className="p-4 border border-b-0 border-l-0">
                              {id + 1}
                            </td>
                            <td
                              className="p-4 border border-b-0 cursor-pointer hover:underline underline-offset-2 hover:text-blue-500"
                              onClick={() => onProductSelect({ value: key })}
                            >
                              {key}
                            </td>
                            <td className="p-4 border border-b-0 border-r-0">
                              {value + "%"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Carriers for selected products */}
              {carriers !== null && (
                <div className="p-5 text-base flex flex-col">
                  <h2 className="font-semibold mt-2">
                    Carriers most likely to quote
                  </h2>
                  <div className="overflow-x-auto w-fit mt-10 shadow rounded-lg">
                    <table className="text-sm">
                      <thead>
                        <tr>
                          <th className="p-4 border border-t-0 border-l-0 bg-gray-700 text-gray-200">
                            #
                          </th>
                          <th className="p-4 border border-t-0 bg-gray-700 text-gray-200">
                            Carriers
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(carriers)
                          .splice(0, 5)
                          .map(([key], id) => (
                            <tr key={id}>
                              <td className="p-4 border border-b-0 border-l-0">
                                {id + 1}
                              </td>
                              <td className="p-4 px-10 border border-b-0">
                                {key}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-96">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default PREngine;
