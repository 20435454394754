import React from "react";

//========================================= Number Operations =========================================

// Number formatter
export const NumberFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US").format(number);
  return formattedNumber;
};

export const NumberFormatterRounded = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US").format(
    Math.round(number)
  );
  return formattedNumber;
};

// Number formatter for currency
export const NumCurrFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(number);
  return formattedNumber;
};

// Number formatter for currency
export const NumFormatterM = (number) => {
  const formattedNumber = (number / 1000000).toFixed(1);
  return formattedNumber + "M";
};

export const NumFormatterK = (number) => {
  const formattedNumber = (number / 1000).toFixed(0);
  return formattedNumber + "K";
};

export const CurrencyFormatterK = (number) => {
  let formattedNumber = (number / 1000).toFixed(0);
  formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(formattedNumber);
  return formattedNumber + "K";
};

// Currency $ formatter
export const CurrencyFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
  return formattedNumber;
};

// Percent Formatter %
export const PercentFormatter = (number) => {
  const formattedNumber = (parseFloat(number) * 100).toFixed(2) + "%";
  return formattedNumber;
};

// Format number to plain int
export const makeInt = (number) => {
  try {
    const parts = number?.split(",").join("");
    return parseFloat(parts);
  } catch (error) {
    return number;
  }
};

//========================================= Date Operations =========================================

// Date Fromatter
export const DateFormatter = (date) => {
  const formattedDate = new Intl.DateTimeFormat("en-US").format(date);
  return formattedDate;
};

// function to change date pattern to mm/dd/yyyy
export const changeDate = (date) => {
  const parts = date.split("-");
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
};

//========================================= Miscellaneous Operations =========================================

// Functions created to allow and disallow scroll to work when working on an existing layer over body
const body = document.body;

// prevents scroll in a body
export const preventScroll = () => {
  body.classList.add("overflow-hidden");
  body.classList.remove("overflow-auto");
};

// allows scroll in body
export const allowScroll = () => {
  body.classList.add("overflow-auto");
  body.classList.remove("overflow-hidden");
};

// Get client device width with cursor x and y coordinates
export const getCursorCoordinates = (event) => {
  let x = event.clientX;
  let y = event.clientY;
  let width = Math.max(window.screen.width, window.innerWidth);
  let height = Math.max(window.screen.height, window.innerHeight);
  return { width: width, height: height, x: x, y: y };
};

// function to get element's offset rations
export const getOffset = (el) => {
  const rect = el.target.getBoundingClientRect();
  // console.log(rect.width);
  return {
    left: (rect.left + window.scrollX).toFixed(2),
    top: (rect.top + window.scrollY).toFixed(2),
    width: rect.width,
    height: rect.height,
  };
};

// ================ Get Options for Select field =================== //
export const createSelectOptions = (data, target = "") => {
  const options = [];
  if (target === "sales_rep") {
    data.forEach((item, index) => {
      options.push({ value: item, label: `Sales Rep ${index + 1}` });
    });
  } else {
    data.forEach((item, index) => {
      options.push({ value: item, label: item });
    });
  }

  return options;
};

