import React from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";

const years = [2023, 2024, 2025];
const scenarios = ["Business", "Stochastic"];
const role_cols = ["Role 1", "Role 2", "Role 3", "Total"];

const ProjectedStaffing = ({ data, formatter, info }) => {
  return (
    <>
      {/* Title and filter */}
      <div className="flex items-center justify-between w-full sticky top-16 border-b py-5 bg-white">
        <h3 className="font-bold">Projected Staffing: {info}</h3>
      </div>

      {/* Current Staffing By Role */}
      <div className="flex flex-col justify-center w-full my-5">
        <h3 className="font-bold pb-5">Current Staffing by Role</h3>
        <div className="w-fit border rounded-md overflow-auto">
          <table className="w-full max-w-full">
            <thead className="bg-gray-300">
              <tr>
                <th
                  className="px-4 py-1 text-center border-r border-b"
                  rowSpan={2}
                >
                  {info}
                </th>
                <th className="px-2 py-1 border-b" colSpan={4}>
                  2022
                </th>
              </tr>
              <tr>
                {role_cols.map((role, index) => (
                  <th
                    className="px-4 py-1 border-b border-r last-of-type:border-r-0"
                    key={index}
                  >
                    {role}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {regions.map((region, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                >
                  <td className="py-2 px-4 w-32 border-r">{region}</td>
                  <td className="py-2 px-4 w-32 border-r">
                    {formatter(data["Role 1_2022"][index])}
                  </td>
                  <td className="py-2 px-4 w-32 border-r">
                    {formatter(data["Role 2_2022"][index])}
                  </td>
                  <td className="py-2 px-4 w-32 border-r">
                    {formatter(data["Role 3_2022"][index])}
                  </td>
                  <td className="py-2 px-4 w-32">
                    {formatter(data["Total_2022"][index])}
                  </td>
                </tr>
              ))}
              <tr className="font-bold">
                <td className="py-2 px-4 border-r">Total</td>
                <td className="py-2 px-4 border-r">
                  {formatter(
                    Object.values(data["Role 1_2022"]).reduce(
                      (partialSum, currentValue) => partialSum + currentValue,
                      0
                    )
                  )}
                </td>
                <td className="py-2 px-4 border-r">
                  {formatter(
                    Object.values(data["Role 2_2022"]).reduce(
                      (partialSum, currentValue) => partialSum + currentValue,
                      0
                    )
                  )}
                </td>
                <td className="py-2 px-4 border-r">
                  {formatter(
                    Object.values(data["Role 3_2022"]).reduce(
                      (partialSum, currentValue) => partialSum + currentValue,
                      0
                    )
                  )}
                </td>
                <td className="py-2 px-4">
                  {formatter(
                    Object.values(data["Total_2022"]).reduce(
                      (partialSum, currentValue) => partialSum + currentValue,
                      0
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Scenarios Mapped */}
      {scenarios.map((scenario, index) => (
        <div className="flex flex-col justify-center w-full my-5">
          <h3 className="font-bold pb-5">
            {index === 0
              ? "Staffing by Business Volume"
              : "Staffing by Stochastic"}
          </h3>
          <div className="w-fit border rounded-md overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr>
                  <th className="p-2 border-r" rowSpan={2}>
                    {info}
                  </th>
                  {years.map((year, index) => (
                    <th className="p-2 border-r" colSpan={4} key={year}>
                      {year}F
                    </th>
                  ))}
                </tr>
                <tr className="border-t">
                  {years.map((year, ind) => (
                    <>
                      {role_cols.map((role, index) => (
                        <th className="p-2 border-r" key={index}>
                          {role}
                        </th>
                      ))}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody className="text-center">
                {regions.map((region, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                  >
                    <td className="p-2 border-r w-40">{region}</td>
                    {years.map((year, ind) => (
                      <>
                        {role_cols.map((col, idx) => {
                          //   console.log(data[`${scenario}_${col}_${year}F`]);
                          return (
                            <td className="p-2 border-r last-of-type:border-r-0 w-28">
                              {formatter(
                                data[`${scenario}_${col}_${year}F`][index]
                              )}
                            </td>
                          );
                        })}
                      </>
                    ))}
                  </tr>
                ))}
                <tr className="font-bold">
                  <td className="p-2 border-r w-40">Total</td>
                  {years.map((year, ind) => (
                    <>
                      {role_cols.map((col, idx) => {
                        //   console.log(data[`${scenario}_${col}_${year}F`]);
                        return (
                          <td className="p-2 border-r last-of-type:border-r-0 w-28">
                            {formatter(
                              Object.values(
                                data[`${scenario}_${col}_${year}F`]
                              ).reduce(
                                (partialSum, currentValue) =>
                                  partialSum + currentValue,
                                0
                              )
                            )}
                          </td>
                        );
                      })}
                    </>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectedStaffing;
