import React, { useEffect, useState } from "react";
import API from "../../../../api/api";
import { LuLoader2 } from "react-icons/lu";
import Select from "react-select";
import {
  CI_productsForForm,
  CI_productsFirmLevelList,
  CI_formInputs,
  CI_FirmList,
} from "../../../../data/modules/commissionIntelligence";
import { createOptions } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";
import {
  CurrencyFormatter,
  NumCurrFormatter,
  makeInt,
} from "../../../utils/Utils";
import { FaList } from "react-icons/fa6";

const CI_InputForm = ({
  inputFormData,
  setInputFormData,
  load,
  setLoad,
  setGraph,
  setComparisonChart,
  setErrorMessage,
  topAgencies,
  setTopAgencies,
}) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [error, setError] = useState(false);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [isEwpGenerated, setIsEwpGenerated] = useState(false);

  // Input handling on change
  const handleOnChangeInput = (e, name = null) => {
    setShowProductModal(false);

    if (name === "products") {
      setIsEwpGenerated(false);
      setTopAgencies([]);
      setComparisonChart(null);
      inputFormData.agencies = [];
      let tempProducts = [];
      let tempPremiums = [];
      for (let index = 0; index < e?.length; index++) {
        if (e[index]["value"] === "Select All") {
          // tempProducts = CI_productsFirmLevelList.slice(1, CI_productsFirmLevelList.length - 1)
          tempProducts = ["All Products"];
          tempPremiums = Object.values(CI_productsForForm);
          break;
        } else {
          tempProducts.push(e[index]["value"]);
          tempPremiums.push(CI_productsForForm[e[index]["value"]]);
        }
      }
      // console.log(e, tempProducts);
      setInputFormData((prev) => ({
        ...prev,
        premiums: [...tempPremiums],
        products: [...tempProducts],
      }));
    }

    if (name === "agencies") {
      setComparisonChart(null);
      let tempFirms = [];
      // console.log(e);

      for (let index = 0; index < e?.length; index++) {
        if (e[index]["value"] === "All Agencies") {
          tempFirms = [e[index]["value"]];
          break;
        } else {
          tempFirms.push(e[index]["value"]);
        }
      }
      // console.log(tempFirms);
      setInputFormData((prev) => ({
        ...prev,
        agencies: [...tempFirms],
      }));
    }

    if (e?.target?.name === "lossRatio") {
      let inputValue = e.target.value;
      // Remove any non-numeric characters except the last '%'
      let numericValue = inputValue.replace(/[^0-9.]/g, "");
      // Remove leading digits greater than 5
      numericValue = numericValue.replace(/^([6-9])|([0-9]\d{2,})/, "");
      // Count the number of decimal points
      let decimalCount = numericValue.split(".")?.length - 1;
      // Allow only one decimal point
      if (decimalCount > 1) {
        numericValue = numericValue.slice(0, numericValue.lastIndexOf("."));
      }
      // Limit the number of digits after the decimal point to two
      let parts = numericValue.split(".");
      if (parts[1] && parts[1]?.length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }
      // Join the parts and add '%' symbol at the end of the numeric value if it's not empty
      let finalValue = parts.join(".") !== "" ? parts.join(".") : "";

      setInputFormData({
        ...inputFormData,
        [e.target.name]: finalValue,
      });
    }
  };

  // generate ewp and top 5 agencies
  const generateTopFirmsAndEWP = async (e = null) => {
    e?.preventDefault();
    // setComparisonChart(null);
    await API.post("/commission-intelligence/get-ewp", inputFormData)
      .then(({ data }) => {
        // console.log(data);
        if (data.output) {
          const response = Object.fromEntries(
            Object.entries(data.output).sort((a, b) => b[1] - a[1])
          );
          setGraph([response, "Agencies"]);
          // function to return object keys in an array
          let temp_array = [];
          const collectKeys = (obj) => {
            Object.entries(obj).forEach(([key, value]) => {
              temp_array.push(key);
              if (typeof value === "object" && value !== null) {
                collectKeys(value); // Recursively collect keys from nested objects
              }
            });
          };

          collectKeys(response);
          if (temp_array?.length > 0) {
            temp_array.unshift("All Agencies");
          }
          setTopAgencies(temp_array);
        }

        setInputFormData((prev) => ({
          ...prev,
          writtenPremium: data.ewp,
        }));
        setIsEwpGenerated(true);
      })
      .catch((err) => {
        setError("Couldn't generate EWP");
      });
  };

  // Run when product or agencies are selected
  useEffect(() => {
    if (inputFormData.products?.length > 0) {
      generateTopFirmsAndEWP();
    }
    if (inputFormData.products?.length == 0) {
      setGraph(null);
      setComparisonChart(null);
    }
  }, [inputFormData.products, inputFormData.agencies]);

  // API response / form submit
  const generateCommission = async (e = null) => {
    e?.preventDefault();
    setLoad(true);
    setShowProductModal(false);

    // ends function if inputs are empty
    if (!inputFormData.lossRatio || !inputFormData.writtenPremium) {
      setInputsFilled(true);
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    // if loss ratio is greater then 55%
    if (inputFormData.lossRatio >= 55) {
      setError(true);
    } else {
      setError(false);
    }

    // api call to fetch all data
    await API.post("/commission-intelligence/generate-commission", {
      ...inputFormData,
      // converting into Int by removing ","
      writtenPremium: makeInt(inputFormData.writtenPremium),
    })
      .then(({ data }) => {
        const response = Object.fromEntries(
          Object.entries(data.topCarriers).sort((a, b) => b[1] - a[1])
        );
        setGraph([response, "Carriers"]);

        const carrierNames = data?.comparison?.carrierNames.map(
          (carrier, index) => ({
            carrierName: carrier,
            mixAmount: data?.comparison?.current?.mixAmount[index],
          })
        );

        carrierNames.sort((a, b) => b.mixAmount - a.mixAmount);

        const sortedCarrierNames = carrierNames.map(
          (carrier) => carrier.carrierName
        );

        // sort comparison data by inforce / mix amount percentage used in graph
        const sorting = (carriers, data) => {
          // console.log(data);
          const mapped = carriers.map((carrier, index) => ({
            carrierName: carrier,
            mixPercentage: data.mixPercentage[index],
            commissionPercentage: data.commissionPercentage[index],
            mixAmount: data.mixAmount[index],
            minEWP: data.minEWP[index],
            minLLR: data.minLLR[index],
            commissionRate: data.commissionRate[index],
            commission: data.commission[index],
          }));
          // console.log(mapped);
          let result_object = {
            carrierName: [],
            mixPercentage: [],
            commissionPercentage: [],
            mixAmount: [],
            minEWP: [],
            minLLR: [],
            commissionRate: [],
            commission: [],
          };
          sortedCarrierNames.map((carrier, index) => {
            // console.log(index, temp_object);
            mapped.map((item, id) => {
              if (item.carrierName === carrier) {
                result_object.carrierName.push(carrier);
                result_object.mixPercentage.push(item.mixPercentage);
                result_object.commissionPercentage.push(
                  item.commissionPercentage
                );
                result_object.mixAmount.push(item.mixAmount);
                result_object.minEWP.push(item.minEWP);
                result_object.minLLR.push(item.minLLR);
                result_object.commissionRate.push(item.commissionRate);
                result_object.commission.push(item.commission);
              }
            });
          });
          return result_object;
        };
        const currentView = sorting(
          data?.comparison?.carrierNames,
          data?.comparison?.current
        );
        const optimizedView = sorting(
          data?.comparison?.carrierNames,
          data?.comparison?.optimized
        );
        setComparisonChart({
          current: {
            ...data.comparison.current,
            ...currentView,
          },
          optimized: {
            ...data.comparison.optimized,
            ...optimizedView,
          },
          carrierNames: sortedCarrierNames,
        });

        setErrorMessage(null);
        setError(false);
      })
      .catch((err) => {
        const error = "Input outside the data scope! Please change values";
        setErrorMessage(error);
        // console.log(err);
      });
    setLoad(false);
  };

  useEffect(() => {
    if (inputFormData.isFileUploaded || inputFormData.premiumMovement) {
      generateCommission();
    }
  }, [inputFormData.isFileUploaded, inputFormData.premiumMovement]);

  // console.log(inputFormData);
  return (
    <div className="p-7 bg-white rounded-lg shadow border w-[26rem] text-xs text-gray-600">
      <h2 className="text-base font-bold mb-5">
        Commercial Lines Illustration{" "}
        {/* <span className={inputsFilled ? "text-red-500" : ""}>
          (Insert All Fields)
        </span> */}
      </h2>
      <form
        className=""
        onSubmit={(e) =>
          isEwpGenerated ? generateCommission(e) : generateTopFirmsAndEWP(e)
        }
      >
        {/* Inputs */}
        <div className="flex flex-col gap-5 text-xs">
          {/* Product */}
          <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="products">
              Products:
            </label>
            <div className="flex">
              <Select
                styles={colourStyles}
                isSearchable={true}
                name="products"
                options={createOptions(CI_productsFirmLevelList.slice(0, -1))}
                className="w-full max-w-[19rem]"
                onChange={(e) => handleOnChangeInput(e, "products")}
                onFocus={() => setShowProductModal(true)}
                onBlur={() => setShowProductModal(false)}
                defaultValue={createOptions(inputFormData.products)}
                isMulti
                // closeMenuOnSelect={false}
              ></Select>
              <FaList
                className={`hover:bg-gray-100 ml-5 rounded-lg p-[5px] h-full w-10 cursor-pointer ${
                  showProductModal ? "bg-gray-200" : ""
                }`}
                onClick={() => setShowProductModal(!showProductModal)}
              />
            </div>
          </div>
          {isEwpGenerated && (
            <>
              {/* Agencies */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="product">
                  Agencies:
                </label>
                <div className="flex">
                  <Select
                    styles={colourStyles}
                    isSearchable={true}
                    name="agencies"
                    options={createOptions(topAgencies)}
                    className="w-full max-w-[19rem]"
                    onChange={(e) => handleOnChangeInput(e, "agencies")}
                    defaultValue={createOptions(inputFormData.agencies)}
                    isMulti
                    // closeMenuOnSelect={false}
                  ></Select>
                </div>
              </div>
              {/* Eligible Written Premium */}
              <div className="flex flex-col gap-1 relative">
                <label className="font-semibold" htmlFor="writtenPremium">
                  Eligible Written Premium (USD):
                </label>
                <div className="flex items-center">
                  <div className="border rounded-md focus:outline-none px-2 py-[10px] w-full">
                    {CurrencyFormatter(inputFormData.writtenPremium)}
                  </div>
                  {/* <MdOutlineModeEdit
                        className={`hover:bg-gray-100 ml-5 rounded-lg p-[5px] h-full w-10 cursor-pointer ${
                          edit ? "bg-gray-300" : ""
                        }`}
                        onClick={() => setEdit(!edit)}
                      /> */}
                </div>
              </div>
              {/* Loss Ratio */}
              <div className="flex flex-col gap-1 relative">
                <label className="font-semibold" htmlFor="lossRatio">
                  Loss Ratio (%) <sup className="text-red-500 text-sm">*</sup>:
                </label>
                <div className="flex">
                  <span className="rounded-l-md p-[10px] bg-gray-200">%</span>
                  <input
                    type="text"
                    name="lossRatio"
                    id="lossRatio"
                    value={inputFormData.lossRatio || ""}
                    onChange={(e) => handleOnChangeInput(e)}
                    maxLength={5}
                    className="border rounded-r-md focus:outline-none px-2 py-[10px] w-full"
                  />
                </div>
                {error && (
                  <span className="absolute text-red-500 right-0 -bottom-5 text-[10px]">
                    Max loss ratio should be under 55%
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        {/* Product List */}
        {showProductModal && (
          <div className="absolute bg-white z-[1] top-20 left-[450px] rounded-lg shadow-lg border overflow-auto">
            <table className="">
              <thead>
                <tr className="w-full">
                  <th className="p-2 border border-l-0 border-t-0">Product</th>
                  <th className="p-2 border border-r-0 border-t-0">Premium</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(CI_productsForForm).map(([key, value], id) => (
                  <tr key={id}>
                    <td className="p-2 border border-l-0 border-b-0">{key}</td>
                    <td className="p-2 border border-r-0 border-b-0 text-center">
                      {NumCurrFormatter(value)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Actions */}
        <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
          {/* {isEwpGenerated && (
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
          )} */}
          {/* Get Score */}
          {load ? (
            <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
              <span className="animate-spin">
                <LuLoader2 />
              </span>
              <span>Processing...</span>
            </div>
          ) : (
            isEwpGenerated && (
              <input
                type="submit"
                value={"Distrubute Commission"}
                onClick={(e) => generateCommission(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )
          )}
        </div>
        {isEwpGenerated && (
          <p>
            <sup className="text-red-500 text-sm">*</sup> Adjusted/Limited loss
            ratio in line with stipulated contracts
          </p>
        )}
      </form>
    </div>
  );
};

export default CI_InputForm;
