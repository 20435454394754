import React, { useState } from "react";
import InputField from "./InputField";
import {
  low_cost_locations,
  regions,
} from "../../../../data/modules/opsTransformationPlanning";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const lastRegion = 8;
const inputOptions = ["Regions", "Low Cost Locations"];

const CompensationForm = ({
  Compensation_Inputs,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  handleCurrencyChange,
  targetState,
  defaultValues,
}) => {
  const [filter, setFilter] = useState("Regions");

  return (
    <>
      <div className="flex items-center justify-between w-full sticky top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Compensation Input</h3>
        <div className="flex items-start gap-5">
          {/* Select Input filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Region:</label>
            <Select
              name={"region"}
              options={createOptions(inputOptions)}
              className="w-44 text-xs"
              classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(filter)}
              onChange={(e) => setFilter(e.value)}
            />
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("Compensation Input")}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      <div className="border lg:w-full xl:w-5/6 rounded-md overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-300">
            <tr className="border-b">
              <th className="py-2 px-4 border-r">Region</th>
              <th className="py-2 px-4 border-r">COLA %</th>
              <th className="py-2 px-4 border-r">Role 1</th>
              <th className="py-2 px-4 border-r">Role 2</th>
              <th className="py-2 px-4">Role 3</th>
            </tr>
          </thead>
          <tbody>
            {filter === "Regions" &&
              regions.map((region, index) => (
                <tr key={index}>
                  <td className="px-4 py-4 border-r w-44 text-center">
                    {region}
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="percentage"
                      name={"COLA %"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="currency"
                      name={"Role 1"}
                      index={index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="currency"
                      name={"Role 2"}
                      index={index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44">
                    <InputField
                      inputType="currency"
                      name={"Role 3"}
                      index={index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                </tr>
              ))}

            {filter === "Low Cost Locations" &&
              low_cost_locations.map((location, index) => (
                <tr key={index}>
                  <td className="px-4 py-4 border-r w-44 text-center">
                    {location}
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="percentage"
                      name={"COLA %"}
                      index={lastRegion + index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="currency"
                      name={"Role 1"}
                      index={lastRegion + index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType="currency"
                      name={"Role 2"}
                      index={lastRegion + index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44">
                    <InputField
                      inputType="currency"
                      name={"Role 3"}
                      index={lastRegion + index}
                      handleCurrencyChange={handleCurrencyChange}
                      targetState={targetState}
                      currentState={Compensation_Inputs}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompensationForm;
