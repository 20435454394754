import React, { useState } from "react";
import API from "../../../api/api";
import Select from "react-select";

import { LuLoader2 } from "react-icons/lu";
import {
  LPT_action,
  LPT_coverage,
  LPT_coverage_filter,
  LPT_initialInputData,
  LPT_market_segment,
  LPT_product_line,
  LPT_renew_month,
  LPT_sales_rep,
  LPT_sic_category,
  LPT_territory,
} from "../../../data/modules/lapsePredictor";
import { createOptions } from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
import RadarChartLapse from "./RadarChartLapse";
import { createSelectOptions } from "../../utils/Utils";

const PredictorTool = () => {
  const [inputFormData, setInputFormData] = useState(LPT_initialInputData);
  const [scoreData, setScoreData] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);
  const [product, setProduct] = useState(null);

  // handle input changes
  const handleOnChangeInput = (e) => {
    if (e.target.name === "Lives" || e.target.name === "Renewal Premium") {
      // const value = e.target.value.split(",").join("");
      const value = e.target.value.replace(/[^\d.]/g, "");
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else {
      setInputFormData({ ...inputFormData, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeSelectInput = (e, target) => {
    // console.log(target);
    if (target === "Product Line") {
      setProduct(e.value);
      inputFormData.Coverage = "";
    }
    setInputFormData({ ...inputFormData, [target]: e.value });
  };

  // console.log(inputFormData);

  // function to get score
  const getScore = async (e) => {
    setScoreData(null);
    e.preventDefault();
    setLoad(true);

    // Required columns
    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    let payload = {
      ...inputFormData,
      Lives: inputFormData["Lives"].split(",").join(""),
      "Renewal Premium": inputFormData["Renewal Premium"].split(",").join(""),
    };

    console.log(payload);

    API.post("/lapse-predictor-single-case", payload)
      .then(({ data }) => {
        let scores = data.pct_scores;
        let totalScore = 0;
        let keys = {};

        Object.entries(scores).forEach(([key, value]) => {
          totalScore += value["Score"];
          // console.log(key, value["Score"]);
          keys[key] = value;
        });

        let scoreData = [
          {
            score: data.data["Lapse Score"],
            action: LPT_action(data.data["Lapse Score"]),
            suggested_action: data.data["suggested_action"],
          },
          {
            "A/E Ratio": keys["A/E Ratio Bin"],
            Coverage: keys["Coverage"],
            Lives: keys["Lives Bin"],
            "Loss Ratio": keys["Loss Ratio Bin"],
            "Market Segment": keys["Market Segment"],
            "Product Line": keys["Product Line"],
            "Rate Change": keys["Rate Change Bin"],
            "Renewal Month": keys["Renewal Month"],
            "Renewal Premium": keys["Renewal Premium Bin"],
            "Renewal Rate": keys["Renewal Rate Bin"],
            Territory: keys["SIC Category Bin"],
            Sales: keys["Sales Rep"],
            Territory: keys["Territory"],
          },
        ];

        console.log("Data", data);

        setLoad(false);
        setScoreData(scoreData);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  // Reset Form
  const resetForm = () => {
    setProduct(false);
    setInputFormData(LPT_initialInputData);
    setScoreData(null);
  };

  return (
    <div className="flex flex-wrap h-max gap-10">
      <div className="min-w-[450px] max-w-[500px] p-7 bg-white rounded-lg shadow border text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        {/* Form */}
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex flex-col gap-3 justify-between">
              <div className="flex justify-between gap-x-4">
                {/* Product Line */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="productLine">
                    Product Line:
                  </label>
                  <Select
                    name="Product Line"
                    options={createOptions(LPT_product_line)}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="productLine"
                    onChange={(e) =>
                      handleOnChangeSelectInput(e, "Product Line")
                    }
                    value={
                      inputFormData["Product Line"]
                        ? createOptions([inputFormData["Product Line"]])
                        : null
                    }
                  />
                </div>
                {/* Coverage */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="coverage">
                    Coverage:
                  </label>
                  <Select
                    name="Coverage"
                    options={LPT_coverage_filter[product]}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="coverage"
                    onChange={(e) => handleOnChangeSelectInput(e, "Coverage")}
                    value={
                      inputFormData["Coverage"]
                        ? LPT_coverage_filter[product][
                            inputFormData["Coverage"]
                          ]
                        : null
                    }
                    isDisabled={product ? false : true}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-x-4">
                {/* Territory */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="territory">
                    Territory:
                  </label>
                  <Select
                    name="Territory"
                    options={createOptions(LPT_territory)}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="territory"
                    onChange={(e) => handleOnChangeSelectInput(e, "Territory")}
                    value={
                      inputFormData["Territory"]
                        ? createOptions([inputFormData["Territory"]])
                        : null
                    }
                  />
                </div>
                {/* Renewal Month */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="renewMonth">
                    Renewal Month:
                  </label>
                  <Select
                    name="Renewal Month"
                    options={createOptions(LPT_renew_month)}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="renewMonth"
                    onChange={(e) =>
                      handleOnChangeSelectInput(e, "Renewal Month")
                    }
                    value={
                      inputFormData["Renewal Month"]
                        ? createOptions(LPT_renew_month)
                        : null
                    }
                  />
                </div>
              </div>

              <div className="flex justify-between gap-x-4">
                {/* Market Segment */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="marketSegment">
                    Market Segment:
                  </label>
                  <Select
                    name="Market Segment"
                    options={createOptions(LPT_market_segment)}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="marketSegment"
                    onChange={(e) =>
                      handleOnChangeSelectInput(e, "Market Segment")
                    }
                    value={
                      inputFormData["Market Segment"]
                        ? createOptions([inputFormData["Market Segment"]])
                        : null
                    }
                  />
                </div>
                {/* Sales Rep */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="salesRep">
                    Sales Rep:
                  </label>
                  <Select
                    name="Sales Rep"
                    options={createSelectOptions(LPT_sales_rep, "sales_rep")}
                    className="w-48"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    id="salesRep"
                    onChange={(e) => handleOnChangeSelectInput(e, "Sales Rep")}
                    value={
                      inputFormData["Sales Rep"]
                        ? createSelectOptions(
                            [inputFormData["Sales Rep"]],
                            "sales_rep"
                          )
                        : null
                    }
                  />
                </div>
              </div>

              <div className="flex justify-between gap-x-4">
                {/* Total Eligible Lives */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="lives">
                    Total Eligible Lives:
                  </label>
                  <input
                    id="lives"
                    name="Lives"
                    type="text"
                    value={inputFormData["Lives"] || ""}
                    onChange={(e) => handleOnChangeInput(e)}
                    className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  />
                </div>
                {/* Renewal Premium */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold" htmlFor="renewalPremium">
                    Renewal Premium:
                  </label>
                  <div className="flex">
                    <span className="rounded-l-md p-[10px] bg-gray-200">$</span>
                    <input
                      type="text"
                      name="Renewal Premium"
                      id="renewalPremium"
                      value={inputFormData["Renewal Premium"] || ""}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="border rounded-r-md focus:outline-none px-2 py-[10px] w-[167px]"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-x-4">
                {/* Loss Ratio */}
                <div className="flex flex-col gap-1">
                  <label
                    className="font-semibold flex gap-2 items-center"
                    htmlFor="lossRatio"
                  >
                    Loss Ratio:
                    <span>{inputFormData["Loss Ratio"]}</span>
                  </label>

                  <div className="flex gap-x-4 mt-4 w-full">
                    <input
                      id="lossRatio"
                      name="Loss Ratio"
                      type="range"
                      min="0"
                      max="3"
                      step={"0.1"}
                      value={inputFormData["Loss Ratio"] || "0"}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="w-48 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                  </div>
                </div>
                {/* Renewal Rate */}
                <div className="flex flex-col gap-1">
                  <label
                    className="font-semibold flex gap-2 items-center"
                    htmlFor="lossRatio"
                  >
                    Renewal Rate:
                    <span>{inputFormData["Renewal Rate"]}</span>
                  </label>

                  <div className="flex gap-x-4 mt-4 w-full">
                    <input
                      id="renewRate"
                      name="Renewal Rate"
                      type="range"
                      min="0"
                      max="3"
                      step={"0.1"}
                      value={inputFormData["Renewal Rate"] || "0"}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="w-48 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-x-4 mt-2">
                {/* Rate Change */}
                <div className="flex flex-col gap-1">
                  <label
                    className="font-semibold flex gap-2"
                    htmlFor="rateChange"
                  >
                    Rate Change:
                    <span>{inputFormData["Rate Change"]}</span>
                  </label>

                  <div className="flex gap-x-4 mt-4 w-full">
                    <input
                      id="rateChange"
                      name="Rate Change"
                      type="range"
                      min="0"
                      max="3"
                      step={"0.1"}
                      value={inputFormData["Rate Change"] || "0"}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="w-48 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                  </div>
                </div>
                {/* A/E Ratio */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold flex gap-2" htmlFor="aeRatio">
                    A/E Ratio:
                    <span>{inputFormData["A/E Ratio"]}</span>
                  </label>
                  <div className="flex gap-x-4 mt-4 w-full">
                    <input
                      id="aeRatio"
                      name="A/E Ratio"
                      type="range"
                      min="0"
                      max="3"
                      step={"0.1"}
                      value={inputFormData["A/E Ratio"] || "0"}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="w-48 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-x-4 mt-2">
                {/* Industry */}
                <div className="flex flex-col gap-1 w-full">
                  <label className="font-semibold" htmlFor="industry">
                    Industry:
                  </label>
                  <Select
                    name="SIC Category"
                    options={createOptions(LPT_sic_category)}
                    className="w-48"
                    id="industry"
                    classNamePrefix="predictor"
                    styles={colourStyles}
                    onChange={(e) =>
                      handleOnChangeSelectInput(e, "SIC Category")
                    }
                    value={
                      inputFormData["SIC Category"]
                        ? createOptions([inputFormData["SIC Category"]])
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Calculate"
                onClick={(e) => getScore(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>

      {/* Percentile Contribution Chart */}
      <div className="min-w-[700px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg">
        <div className="p-3 flex gap-10 items-center justify-center text-center border-b text-xs">
          Range:
          <div className="flex gap-10 font-bold">
            <span className="text-green-500">(0 - 499) Low</span>
            <span className="text-amber-500">(500 - 749) Normal</span>
            <span className="text-red-500">(750+) High</span>
          </div>
        </div>
        {scoreData ? (
          <div className="">
            <RadarChartLapse data={scoreData} module={"Lapse Predictor"} />
          </div>
        ) : (
          <div className="flex justify-center items-center h-full">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default PredictorTool;
