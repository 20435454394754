import React, { useState } from "react";
import VGRFormInput from "./VGRForm";
import PIRForm from "./PIRForm";
import CompensationForm from "./CompensationForm";
import EffortIndexForm from "./EffortIndexForm";
import { LuLoader2 } from "react-icons/lu";
import ALCLForm from "./AutoLowRoleForm";
import CostForm from "./CostForm";
import Baseline from "./Baseline";

const InputForms = ({
  currentInputTab,
  showOutput,
  load,
  excelFile,
  Baseline_Inputs,
  VGR_Inputs,
  PIR_Inputs,
  Compensation_Inputs,
  EffortIndex_Inputs,
  Automation_Inputs,
  LowCostLocation_Inputs,
  RoleFTE_Inputs,
  Automation_Cost_Inputs,
  LowCostLocation_Cost_Inputs,
  RegionSeverence_Inputs,
  OneTimeCost_NonTech_Inputs,
  OneTimeCost_Tech_Inputs,
  StaffingOverlapInput,
  WeightedAvgCostInput,
  handleFileUpload,
  handleBackspaceForPercentage,
  handlePercentageInputChange,
  handleCurrencyChange,
  handleNumberInputChange,
  handleOnChangeSelectInput,
  submitForm,
  defaultValues,
  error,
  setError,
  setSidebar,
  uploadBaselineData,
  key,
}) => {
  return (
    <div className="p-5 pt-0 border shadow rounded-lg w-full h-full">
      <form className="flex flex-col gap-5 h-full">
        {currentInputTab === "Baseline" && (
          <Baseline
            handleFileUpload={handleFileUpload}
            excelFile={excelFile}
            defaultValues={defaultValues}
            Baseline_Inputs={Baseline_Inputs}
            handleNumberInputChange={handleNumberInputChange}
            uploadBaselineData={uploadBaselineData}
            load={load}
            targetState={"Baseline"}
            key={key}
          />
        )}
        {currentInputTab === "Volume Growth Rate" && (
          <VGRFormInput
            VGR_Inputs={VGR_Inputs}
            handlePercentageInputChange={handlePercentageInputChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            targetState={"VGR"}
            defaultValues={defaultValues}
          />
        )}
        {currentInputTab === "Productivity Improvement Rate" && (
          <PIRForm
            PIR_Inputs={PIR_Inputs}
            handlePercentageInputChange={handlePercentageInputChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            targetState={"PIR"}
            defaultValues={defaultValues}
          />
        )}
        {currentInputTab === "Compensation Input" && (
          <CompensationForm
            Compensation_Inputs={Compensation_Inputs}
            handlePercentageInputChange={handlePercentageInputChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            handleCurrencyChange={handleCurrencyChange}
            targetState={"Compensation"}
            defaultValues={defaultValues}
          />
        )}
        {currentInputTab === "Effort Index" && (
          <EffortIndexForm
            EffortIndex_Inputs={EffortIndex_Inputs}
            handlePercentageInputChange={handlePercentageInputChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            handleOnChangeSelectInput={handleOnChangeSelectInput}
            targetState={"EffortIndex"}
            defaultValues={defaultValues}
            setError={setError}
          />
        )}
        {currentInputTab === "Automation, Low Cost Location & Role FTE" && (
          <ALCLForm
            Automation_Inputs={Automation_Inputs}
            LowCostLocation_Inputs={LowCostLocation_Inputs}
            RoleFTE_Inputs={RoleFTE_Inputs}
            handlePercentageInputChange={handlePercentageInputChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            handleOnChangeSelectInput={handleOnChangeSelectInput}
            handleNumberInputChange={handleNumberInputChange}
            targetStateAutomation={"AutomationIndex"}
            targetStateLowCost={"LowCostLocationIndex"}
            targetStateRoleFTE={"RoleFTEIndex"}
            defaultValues={defaultValues}
            setError={setError}
          />
        )}
        {currentInputTab === "Cost Input" && (
          <CostForm
            Automation_Cost_Inputs={Automation_Cost_Inputs}
            LowCostLocation_Cost_Inputs={LowCostLocation_Cost_Inputs}
            RegionSeverence_Inputs={RegionSeverence_Inputs}
            OneTimeCost_NonTech_Inputs={OneTimeCost_NonTech_Inputs}
            OneTimeCost_Tech_Inputs={OneTimeCost_Tech_Inputs}
            StaffingOverlap_Input={StaffingOverlapInput}
            WeightedAvgCost_Input={WeightedAvgCostInput}
            handleNumberInputChange={handleNumberInputChange}
            handleCurrencyChange={handleCurrencyChange}
            handleBackspaceForPercentage={handleBackspaceForPercentage}
            handlePercentageInputChange={handlePercentageInputChange}
            targetStateAutomation={"Automation_CostInputs_Index"}
            targetStateLowCostLocation={"LowCostLocation_CostInputs_Index"}
            targetStateRegionSeverence={"RegionSeverence_Index"}
            targetStateOneTimeCost_NonTech={"OneTimeCost_NonTech_Index"}
            targetStateOneTimeCost_Tech={"OneTimeCost_Tech_Index"}
            targetStateStaffingOverlap={"StaffingOverlap_Index"}
            targetStateWeightedAvgCost={"WeightedAvgCost_Index"}
            defaultValues={defaultValues}
          />
        )}
        {/* Actions */}
        <div className="flex justify-end gap-5 select-none relative">
          <span className="absolute right-0 -top-5 font-bold text-red-500">
            {error.tab === currentInputTab && error.message}
          </span>
          <div className="flex justify-end gap-5 my-5 select-none">
            {load ? (
              <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                <LuLoader2 className="text-xl animate-spin" /> Processing
              </span>
            ) : (
              <input
                type="submit"
                onClick={(e) =>
                  error.message ? e.preventDefault() : submitForm(e)
                }
                value={"Submit"}
                className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white bg-primary ${
                  error.message ? "bg-opacity-20" : "cursor-pointer"
                }`}
              />
            )}
            <div
              onClick={() => (showOutput ? setSidebar("output") : null)}
              className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                showOutput ? "bg-orange-500 cursor-pointer" : "bg-orange-200"
              }`}
            >
              Show Output
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InputForms;
