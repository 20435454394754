import React, { useState } from "react";
import API from "../../../api/api";
import Select from "react-select";
import {
  ageBin,
  bmiBin,
  createOptions,
  getMonth,
  states,
} from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
import RadarChart from "../../utils/RadarChart";
import {
  Ecom_AgeBin,
  Ecom_LoyaltyStatus,
  Ecom_PaymentMethods,
  Ecom_PostalCodes,
  Ecom_ProductBrands,
  Ecom_ProductGroups,
  Ecom_ReturnPropensity,
  Ecom_SC_formInputs,
} from "../../../data/modules/returnify";
import { LuLoader2 } from "react-icons/lu";

const RP_ScoreCalculator = () => {
  const [inputFormData, setInputFormData] = useState(Ecom_SC_formInputs);
  const [scoreData, setScoreData] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "faceAmount" || e.target?.name === "income") {
      const value = e.target.value.replace(/[^\d.]/g, "");
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else if (
      e.target?.name === "height" ||
      e.target?.name === "weight" ||
      e.target?.name === "age"
    ) {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target?.name === "month") {
      let value = getMonth(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value,
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // function to get score
  const getScore = async (e) => {
    e.preventDefault();
    setLoad(true);
    // If existing case is being edited
    inputFormData.ageBin = Ecom_AgeBin(inputFormData.age);

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    await API.post("/RP/Ecom/score-calculator", inputFormData)
      .then(({ data }) => {
        let scores = data.scores;
        let totalScore = 0;
        let keys = {};

        Object.entries(scores).forEach(([key, value]) => {
          totalScore += value["Score"];
          // console.log(key, value["Score"]);
          keys[key] = value["Percentile Rank"];
        });

        let scoreData = [
          {
            score: totalScore,
            action: Ecom_ReturnPropensity(totalScore),
          },
          {
            Age: keys["age"],
            Gender: keys["gender"],
            Shipping: keys["shipping"],
            "Postal Code": keys["postal"],
            Loyalty: keys["loyaltyStatus"],
            "Product Group": keys["productGroup"],
            "Product Brand": keys["productBrand"],
            "Purchase Month": keys["month"],
            "Payment Method": keys["paymentMethod"],
          },
        ];
        setLoad(false);
        setScoreData(scoreData);
      })
      .catch((error) => {
        setLoad(false);
        // console.log(error);
      });
  };

  // reset form
  const resetForm = () => {
    setInputFormData(Ecom_SC_formInputs);
  };

  return (
    <div className="flex h-max gap-16">
      <div className="p-7 col-span-2 bg-white rounded-lg shadow border w-fit text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-10 justify-between">
              {/* Age */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="age">
                  Age:
                </label>
                <input
                  type="text"
                  name="age"
                  id="age"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.age || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
              {/* Gender */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="gender">
                  Gender:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value={"Male"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value={"Female"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="other"
                      value={"Others"}
                      checked={inputFormData.gender === "Others"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              </div>
              {/* Express Shipping */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="shipping">
                  Express Shipping:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="shipping"
                      id="shipping_yes"
                      value={"Yes"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="shipping_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="shipping"
                      id="shipping_no"
                      value={"No"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="shipping_no">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Postal Code */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="postal">
                  Postal Code:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="postal"
                  options={Ecom_PostalCodes}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "postal")}
                  value={
                    inputFormData.postal
                      ? createOptions([inputFormData.postal])
                      : null
                  }
                ></Select>
              </div>
              {/* Loyalty Status */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="loyaltyStatus">
                  Loyalty Status:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="loyaltyStatus"
                  options={Ecom_LoyaltyStatus}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "loyaltyStatus")}
                  value={
                    inputFormData.loyaltyStatus
                      ? createOptions([inputFormData.loyaltyStatus])
                      : null
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Product Group */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="productGroup">
                  Product Group:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="productGroup"
                  options={Ecom_ProductGroups}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "productGroup")}
                  value={
                    inputFormData.productGroup
                      ? createOptions([inputFormData.productGroup])
                      : null
                  }
                ></Select>
              </div>
              {/* Product Brand */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="productBrand">
                  Product Brand:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="productBrand"
                  options={Ecom_ProductBrands}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "productBrand")}
                  value={
                    inputFormData.productBrand
                      ? createOptions([inputFormData.productBrand])
                      : null
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Month */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="month">
                  Purchase Month:
                </label>
                <input
                  type="month"
                  name="month"
                  id="month"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  onChange={(e) => handleOnChangeInput(e, "month")}
                />
              </div>
              {/* Payment Method */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="paymentMethod">
                  Payment Method:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="paymentMethod"
                  options={Ecom_PaymentMethods}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "paymentMethod")}
                  value={
                    inputFormData.paymentMethod
                      ? createOptions([inputFormData.paymentMethod])
                      : null
                  }
                ></Select>
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Calculate"
                onClick={(e) => getScore(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>

      {/* Output */}
      <div className="min-w-[730px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg relative">
        <div className="p-3 flex gap-5 items-center justify-center text-center border-b text-xs">
          Range:
          <div className="flex flex-wrap gap-3 font-bold">
            <span className="text-amber-500">({">"}2600) Low</span>
            <span className="text-blue-500">(2600-3200) Moderate</span>
            <span className="text-green-600">(3200-3800) High</span>
            <span className="text-green-800">(3800+) Very High</span>
          </div>
        </div>
        {scoreData ? (
          <div className="">
            <RadarChart data={scoreData} module={"Returnify"} />
          </div>
        ) : (
          <div className="flex justify-center items-center h-96">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default RP_ScoreCalculator;
