import React, { useState } from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const years = [2023, 2024, 2025];
const scenarios = ["Business", "Stochastic"];
const role_cols = ["Role 1", "Role 2", "Role 3", "Total"];
const inputOptions = ["Role 1", "Role 2", "Role 3", "Total"];
const types = ["FTE", "Cost"];

const ProjectedStaffingFTECost = ({ data, formatter, info }) => {
  const [filter, setFilter] = useState("Role 1");
  console.log(data);
  return (
    <>
      {/* Title and filter */}
      <div className="flex items-center justify-between w-full sticky top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Projected Staffing: FTE v/s Cost</h3>
        <div className="flex items-start gap-5">
          {/* Select Input filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Role:</label>
            <Select
              name={"region"}
              options={createOptions(inputOptions)}
              className="w-44 text-xs"
              classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(filter)}
              onChange={(e) => setFilter(e.value)}
            />
          </div>
        </div>
      </div>

      {/* Current Staffing By Role */}
      <div className="flex flex-col justify-center w-full my-5">
        <h3 className="font-bold pb-5">Current Staffing by {filter}</h3>
        <div className="w-fit border rounded-md overflow-auto">
          <table className="w-full max-w-full">
            <thead className="bg-gray-300">
              <tr>
                <th
                  className="px-4 py-1 text-center border-r border-b"
                  rowSpan={2}
                >
                  FTE v/s Cost
                </th>
                <th className="px-2 py-1 border-b" colSpan={4}>
                  2022
                </th>
              </tr>
              <tr>
                {role_cols.map(
                  (role, index) =>
                    role === filter &&
                    types.map((type, id) => (
                      <th
                        className="px-4 py-1 border-b border-r last-of-type:border-r-0"
                        key={index}
                      >
                        {type}
                      </th>
                    ))
                )}
              </tr>
            </thead>
            <tbody className="text-center">
              {regions.map((region, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                >
                  <td className="py-2 px-4 w-40 border-r">{region}</td>
                  {types.map((type, id) => (
                    <td className="py-2 px-4 w-40 border-r" key={id}>
                      {formatter[type](data[type][`${filter}_2022`][index])}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="font-bold">
                <td className="py-2 px-4 border-r">Total</td>
                {types.map((type, id) => (
                  <td className="py-2 px-4 border-r" key={id}>
                    {formatter[type](
                      Object.values(data[type][`${filter}_2022`]).reduce(
                        (partialSum, currentValue) => partialSum + currentValue,
                        0
                      )
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Table */}
      {scenarios.map((scenario, index) => (
        <div className="flex flex-col justify-center w-full my-5">
          <h3 className="font-bold pb-5">
            {index === 0
              ? "Staffing by Business Volume "
              : "Staffing by Stochastic "}
            and {filter}
          </h3>
          <div className="w-fit border rounded-md overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr>
                  <th className="p-2 border-r" rowSpan={2}>
                    FTE v/s Cost
                  </th>
                  {years.map((year, index) => (
                    <th className="p-2 border-r" colSpan={2} key={year}>
                      {year}F
                    </th>
                  ))}
                </tr>
                <tr className="border-t">
                  {years.map((year, ind) => (
                    <>
                      {role_cols.map(
                        (role, index) =>
                          role == filter &&
                          types.map((type, id) => (
                            <th className="p-2 border-r" key={id}>
                              {type}
                            </th>
                          ))
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody className="text-center">
                {regions.map((region, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                  >
                    <td className="p-2 border-r w-40">{region}</td>
                    {years.map((year, ind) => (
                      <>
                        {role_cols.map(
                          (col, idx) =>
                            col == filter &&
                            types.map((type, id) => (
                              <td
                                className="p-2 border-r last-of-type:border-r-0 w-40"
                                key={id}
                              >
                                {formatter[type](
                                  data[type][`${scenario}_${col}_${year}F`][
                                    index
                                  ]
                                )}
                              </td>
                            ))
                        )}
                      </>
                    ))}
                  </tr>
                ))}
                <tr className="font-bold">
                  <td className="p-2 border-r w-40">Total</td>
                  {years.map((year, ind) => (
                    <>
                      {role_cols.map(
                        (col, idx) =>
                          col === filter &&
                          types.map((type, id) => (
                            <td
                              className="p-2 border-r last-of-type:border-r-0 w-28"
                              key={id}
                            >
                              {formatter[type](
                                Object.values(
                                  data[type][`${scenario}_${col}_${year}F`]
                                ).reduce(
                                  (partialSum, currentValue) =>
                                    partialSum + currentValue,
                                  0
                                )
                              )}
                            </td>
                          ))
                      )}
                    </>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectedStaffingFTECost;
