//============================================================= Ecommerce & Consumer Goods =============================================================

// form inputs
export const Ecom_SC_formInputs = {
  age: "",
  ageBin: "",
  gender: "",
  shipping: "",
  postal: "",
  loyaltyStatus: "",
  productGroup: "",
  productBrand: "",
  month: "",
  paymentMethod: "",
};

// postal codes
export const Ecom_PostalCodes = [
  { label: "51445", value: "51445" },
  { label: "51454", value: "51454" },
  { label: "51458", value: "51458" },
  { label: "51465", value: "51465" },
  { label: "51468", value: "51468" },
  { label: "51470", value: "51470" },
  { label: "51471", value: "51471" },
  { label: "51477", value: "51477" },
  { label: "51458", value: "51458" },
  { label: "51461", value: "51461" },
];

// loyalty status
export const Ecom_LoyaltyStatus = [
  { label: "Without Loyalty", value: "Without Loyalty" },
  { label: "Orange", value: "Orange" },
  { label: "Silver", value: "Silver" },
  { label: "Gold", value: "Gold" },
  { label: "Platinum", value: "Platinum" },
];

// product groups
export const Ecom_ProductGroups = [
  { label: "Fibrefill jackets", value: "Fibrefill jackets" },
  { label: "Long trousers", value: "Long trousers" },
  { label: "Tees", value: "Tees" },
  { label: "Daypacks", value: "Daypacks" },
  { label: "Mid high shoes", value: "Mid high shoes" },
  { label: "Socks", value: "Socks" },
  { label: "Thermal mats", value: "Thermal mats" },
  { label: "Shorts", value: "Shorts" },
  { label: "Pullovers", value: "Pullovers" },
  { label: "Shirts", value: "Shirts" },
];

// product brands
export const Ecom_ProductBrands = [
  { label: "Nike", value: "Nike" },
  { label: "Adidas", value: "Adidas" },
  { label: "Meindl", value: "Meindl" },
  { label: "The North Face", value: "The North Face" },
  { label: "Jack Wolfskin", value: "Jack Wolfskin" },
  { label: "Patagonia", value: "Patagonia" },
  { label: "Arcteryx", value: "Arcteryx" },
  { label: "Deuter", value: "Deuter" },
  { label: "Ortlieb", value: "Ortlieb" },
  { label: "Mammut", value: "Mammut" },
];

// payment methods
export const Ecom_PaymentMethods = [
  {
    label: "Immediately after invoicing",
    value: "Immediately after invoicing",
  },
  { label: "Credit card payment", value: "Credit card payment" },
  { label: "Wallet payment", value: "wallet payment" },
  { label: "Cash on Delivery", value: "Cash on Delivery" },
  { label: "Online Bank Transfer", value: "Online Bank Transfer" },
  { label: "Paypal", value: "Paypal" },
];

// function to assign age bin
export const Ecom_AgeBin = (age) => {
  let ageBin = "";
  if (age <= 20) {
    ageBin = "0-20";
  } else if (age >= 21 && age <= 20) {
    ageBin = "20-29";
  } else if (age >= 31 && age <= 40) {
    ageBin = "31-40";
  } else if (age >= 41 && age <= 50) {
    ageBin = "41-50";
  } else if (age >= 51 && age <= 60) {
    ageBin = "51-60";
  } else {
    ageBin = "60+";
  }
  return ageBin;
};

// function to assign return propensity
export const Ecom_ReturnPropensity = (score) => {
  let propensity = "";
  if (score < 2600) {
    propensity = "Low";
  } else if (score >= 2600 && score < 3200) {
    propensity = "Moderate";
  } else if (score >= 3200 && score <= 3800) {
    propensity = "High";
  } else if (score > 3800) {
    propensity = "Very High";
  }

  return propensity;
};
