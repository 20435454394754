import React, { useState } from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import {
  CurrencyFormatter,
  CurrencyFormatterK,
  NumberFormatterRounded,
} from "../../../utils/Utils";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const table_headers = [
  "Submissions",
  "Submissions Cost",
  "Inforce",
  "Inforce Cost",
  "Total Cost",
  "Avg. Submissions",
  "Avg. Inforce",
];

const table_columns = [
  "Submissions",
  "Submission Cost",
  "Inforce",
  "Inforce Cost",
  "Total Cost",
];

const scenarios = ["Business", "Stochastic"];
const years = [2023, 2024, 2025];
const caseTypes = ["Submissions", "Inforce"];
const inputOptions = ["Business", "Stochastic"];

const UnitCost = ({ data }) => {
  const [filter, setFilter] = useState("Business");
  // console.log(data);
  return (
    <>
      {/* Title and filter */}
      <div className="flex items-center justify-between w-full sticky z-10 top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Projected Staffing: {filter}</h3>
        {/* Select Input filter */}
        <div className="flex gap-2 items-center justify-center text-xs">
          <label htmlFor="region">Filter by Case:</label>
          <Select
            name={"region"}
            options={createOptions(inputOptions)}
            className="w-44 text-xs"
            classNamePrefix={"inventory"}
            styles={colourStyles}
            defaultValue={selectedOption(filter)}
            onChange={(e) => setFilter(e.value)}
          />
        </div>
      </div>

      {/* Scenarios table */}
      {scenarios.map(
        (scenario, index) =>
          scenario === filter && (
            <div
              className="flex flex-col justify-center w-full my-5"
              key={index}
            >
              <h3 className="font-bold pb-5">{scenario} Volumes: Unit Cost</h3>
              <div className="w-fit border rounded-md overflow-auto">
                <table className="w-fit max-w-full">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className="py-2 border-r" rowSpan={2}>
                        Region
                      </th>
                      {years.map((year, index) => (
                        <th className="py-2" colSpan={2} key={index}>
                          {year}F
                        </th>
                      ))}
                    </tr>
                    <tr className="border-t">
                      {years.map((year, idx) => (
                        <>
                          {caseTypes.map((caseType, index) => (
                            <th
                              className="py-2 border-r last-of-type:border-r-0"
                              key={index}
                            >
                              {caseType}
                            </th>
                          ))}
                        </>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {regions.map((region, index) => (
                      <tr
                        className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                        key={index}
                      >
                        <td className="py-2 px-4 border-r">{region}</td>
                        {years.map((year, ind) => (
                          <>
                            {caseTypes.map((caseType, idx) => (
                              <td
                                className="py-2 px-4 w-32 border-r last-of-type:border-r-0"
                                key={idx}
                              >
                                {NumberFormatterRounded(
                                  data[
                                    `${scenario} Unit Cost ${year}F ${caseType}`
                                  ][index]
                                )}
                              </td>
                            ))}
                          </>
                        ))}
                      </tr>
                    ))}
                    <tr className="font-bold">
                      <td className="p-2 border-r">Total</td>
                      {years.map((year, ind) => (
                        <>
                          {caseTypes.map((caseType, idx) => (
                            <td
                              className="p-2 border-r last-of-type:border-r-0"
                              key={idx}
                            >
                              {NumberFormatterRounded(
                                Object.values(
                                  data[`${scenario} Vol ${year}F ${caseType}`]
                                ).reduce((sum, value) => sum + value, 0) /
                                  Object.values(
                                    data[`${scenario} ${year}F WV ${caseType}`]
                                  ).reduce((sum, value) => sum + value, 0)
                              )}
                            </td>
                          ))}
                        </>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
      )}

      {/* Baslines 2022 */}
      <div className="flex flex-col justify-center w-full my-5">
        <h3 className="font-bold pb-5">Baseline: 2022 Volumes Unit Costs</h3>
        <div className="w-fit border rounded-md overflow-auto">
          <table className="w-fit max-w-full overflow-auto">
            <thead className="bg-gray-300">
              <tr>
                <th className="p-2 text-center border-r border-b w-36">
                  Regions
                </th>
                {table_headers.map((header, index) => (
                  <th
                    className="p-2 text-center border-r border-b w-36"
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {regions.map((region, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                >
                  <td className="py-2 px-4 border-r">{region}</td>
                  {table_columns.map((col, id) => (
                    <td className="py-2 px-4 border-r" key={id}>
                      {(col === "Submissions" || col === "Inforce")
                        ? NumberFormatterRounded(data[`2022 ${col}`][index])
                        : CurrencyFormatterK(data[`2022 ${col}`][index])}
                    </td>
                  ))}
                  <td className="py-2 px-4 border-r">
                    {CurrencyFormatter(data["2022 Submissions_1"][index])}
                  </td>
                  <td className="py-2 px-4">
                    {CurrencyFormatter(data["2022 Inforce_1"][index])}
                  </td>
                </tr>
              ))}
              <tr className="font-bold">
                <td className="py-2 px-4 border-r">Total</td>
                {table_columns.map((col, id) => (
                  <td className="py-2 px-4 border-r" key={id}>
                    {col === "Submissions" || col === "Inforce"
                      ? NumberFormatterRounded(
                          Object.values(data[`2022 ${col}`]).reduce(
                            (sum, value) => sum + value,
                            0
                          )
                        )
                      : CurrencyFormatterK(
                          Object.values(data[`2022 ${col}`]).reduce(
                            (sum, value) => sum + value,
                            0
                          )
                        )}
                  </td>
                ))}
                <td className="py-2 px-4 border-r">
                  {CurrencyFormatter(
                    Object.values(data["2022 Submission Cost"]).reduce(
                      (sum, value) => sum + value,
                      0
                    ) /
                      Object.values(data["2022 Submissions"]).reduce(
                        (sum, value) => sum + value,
                        0
                      )
                  )}
                </td>
                <td className="py-2 px-4">
                  {CurrencyFormatter(
                    Object.values(data["2022 Inforce Cost"]).reduce(
                      (sum, value) => sum + value,
                      0
                    ) /
                      Object.values(data["2022 Inforce"]).reduce(
                        (sum, value) => sum + value,
                        0
                      )
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UnitCost;
