import React, { useState, useEffect } from "react";
import InputField from "./InputField";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const inputOptions = ["Submissions", "Inforce", "Cancellation Task"];

const EffortIndexForm = ({
  EffortIndex_Inputs,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  handleOnChangeSelectInput,
  targetState,
  defaultValues,
  setError,
}) => {
  const [filter, setFilter] = useState("Submissions");
  const [totalSubmissions, setTotalSubmissions] = useState(null);
  const [totalInforce, setTotalInforce] = useState(null);
  const [totalCancellation, setTotalCancellation] = useState(null);

  // Get total of all the values
  useEffect(() => {
    const totalSub = EffortIndex_Inputs["Effort Index"]
      .slice(0, 13)
      .reduce((acc, current) => acc + current, 0);
    setTotalSubmissions(totalSub);

    const totalInf = EffortIndex_Inputs["Effort Index"]
      .slice(13, 22)
      .reduce((acc, current) => acc + current, 0);
    setTotalInforce(totalInf);

    const totalCanc = EffortIndex_Inputs["Effort Index"]
      .slice(22)
      .reduce((acc, current) => acc + current, 0);
    setTotalCancellation(totalCanc);
  }, [EffortIndex_Inputs]);

  useEffect(() => {
    if (
      totalSubmissions > 100 ||
      totalCancellation > 100 ||
      totalInforce > 100
    ) {
      setError({
        tab: "Effort Index",
        message: "Total percentage cannot be greater than 100",
      });
    } else {
      setError({});
    }
  }, [totalCancellation, totalSubmissions, totalInforce]);

  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Role Assignment & Effort Index</h3>
        <div className="flex items-start gap-5">
          {/* Select Input filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Region:</label>
            <Select
              name={"region"}
              options={createOptions(inputOptions)}
              className="w-44 text-xs"
              classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(filter)}
              onChange={(e) => setFilter(e.value)}
            />
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("Effort Index")}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      <div className="w-2/3 ">
        {/* Submissions */}
        {filter === "Submissions" && (
          <div className="border rounded-md">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr className="border-b">
                  <th className="py-2 px-4 border-r h-14">
                    Submissions / New Business Process
                  </th>
                  <th className="py-2 px-4 border-r">Effort Index</th>
                  <th className="py-2 px-4">Role</th>
                </tr>
              </thead>
              <tbody>
                {EffortIndex_Inputs.Tasks.slice(0, 13).map((task, index) => (
                  <tr key={index} className="">
                    <td className="px-4 py-2 border-r">{task}</td>
                    <td className="px-4 w-32 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={"Effort Index"}
                        index={index}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                    <td className="px-4 py-2 w-32">
                      <InputField
                        inputType={"select"}
                        name={"Role"}
                        index={index}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handleOnChangeSelectInput={handleOnChangeSelectInput}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="font-bold border-t">
                  <td className="px-4 py-4 border-r">Total</td>
                  <td
                    className={`px-4 py-4 border-r text-center ${
                      totalSubmissions > 100 ? "text-red-500" : ""
                    }`}
                  >
                    {totalSubmissions}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {/* Inforce */}
        {filter === "Inforce" && (
          <div className="border rounded-md">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr className="border-b">
                  <th className="py-2 px-4 border-r h-14">
                    Inforce / Renewals Process
                  </th>
                  <th className="py-2 px-4 border-r">Role</th>
                  <th className="py-2 px-4">Effort Index</th>
                </tr>
              </thead>
              <tbody>
                {EffortIndex_Inputs.Tasks.slice(13, 22).map((task, index) => (
                  <tr key={index} className="">
                    <td className="px-4 py-2 border-r">{task}</td>
                    <td className="px-4 w-32 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={"Effort Index"}
                        index={index + 13}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                    <td className="px-4 py-2 w-32">
                      <InputField
                        inputType={"select"}
                        name={"Role"}
                        index={index + 13}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handleOnChangeSelectInput={handleOnChangeSelectInput}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="font-bold border-t">
                  <td className="px-4 py-4 border-r">Total</td>
                  <td
                    className={`px-4 py-4 border-r text-center ${
                      totalInforce > 100 ? "text-red-500" : ""
                    }`}
                  >
                    {totalInforce}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {/* Cancellation Task */}
        {filter === "Cancellation Task" && (
          <div className="border rounded-md overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr className="border-b">
                  <th className="py-2 px-4 border-r h-14">Cancellation Task</th>
                  <th className="py-2 px-4 border-r">Role</th>
                  <th className="py-2 px-4">Effort Index</th>
                </tr>
              </thead>
              <tbody>
                {EffortIndex_Inputs.Tasks.slice(22).map((task, index) => (
                  <tr key={index} className="">
                    <td className="px-4 py-2 border-r">{task}</td>
                    <td className="px-4 w-32 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={"Effort Index"}
                        index={index + 22}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                    <td className="px-4 py-2 w-32">
                      <InputField
                        inputType={"select"}
                        name={"Role"}
                        index={index + 22}
                        currentState={EffortIndex_Inputs}
                        targetState={targetState}
                        handleOnChangeSelectInput={handleOnChangeSelectInput}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="font-bold border-t">
                  <td className="px-4 py-4 border-r">Total</td>
                  <td
                    className={`px-4 py-4 border-r text-center ${
                      totalCancellation > 100 ? "text-red-500" : ""
                    }`}
                  >
                    {totalCancellation}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default EffortIndexForm;
