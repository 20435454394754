import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { solutions } from "../../data/data";

const IndustryContent = ({ data }) => {
  const [arraySolutions, setArraySolutions] = useState([]);
  const [activeSolution, setActiveSolution] = useState(null);
  const [modules, setModules] = useState(null);

  // fetch all the titles of solutions for relevant industry
  const getTitlesOfSolutions = async () => {
    setArraySolutions([]);
    let temp = [];
    solutions.map((solution) => {
      solution.modules.map((module) => {
        module?.components
          ? module?.components.map((component) =>
              component.industries.map((industry) => {
                if (industry.slug === data.slug) {
                  // Check if object with same title already exists
                  const existing = temp.find(
                    (obj) => obj.title === solution.title
                  );
                  if (!existing) {
                    temp.push({
                      title: solution.title,
                      icon: solution.icon,
                      slug: solution.slug,
                    });
                  }
                }
                return null;
              })
            )
          : module?.industries.map((industry, i) => {
              if (industry.slug === data.slug) {
                const existing = temp.find(
                  (obj) => obj.title === solution.title
                );
                if (!existing) {
                  temp.push({
                    title: solution.title,
                    icon: solution.icon,
                    slug: solution.slug,
                  });
                }
              }
              return null;
            });
        return null;
      });
      return null;
    });
    setArraySolutions(temp);
    setActiveSolution(temp[0]);
    return null;
  };

  // load titles here
  useEffect(() => {
    if (!arraySolutions.length > 0) {
      getTitlesOfSolutions();
    }
    // eslint-disable-next-line
  }, []);

  // get Modules for select solution
  const getModules = async () => {
    setModules(null);
    let temp = [];
    solutions.map((solution) => {
      solution.modules.map((module) => {
        module.components
          ? module?.components.map((component) =>
              component.industries.map((industry) => {
                if (industry.slug === data.slug) {
                  if (solution.title === activeSolution.title) {
                    const existingModule = temp.find(
                      (obj) => obj.module.title === module.title
                    );
                    if (existingModule) {
                      existingModule.components =
                        existingModule.components || []; // Initialize if not present
                      existingModule.components.push(component);
                    } else {
                      temp.push({ module: module, components: [component] });
                    }
                  }
                }
              })
            )
          : module?.industries.map((industry, i) => {
              if (industry.slug === data.slug) {
                if (solution.title === activeSolution.title) {
                  temp.push({ module: module, components: [] }); // Empty components for modules without them
                }
              }
            });
      });
    });

    // Sort temp by component length (ascending)
    temp.sort((a, b) => a.components?.length - b.components?.length);

    // console.log(temp);
    setModules(temp);
    return null;
  };

  useEffect(() => {
    if (activeSolution) {
      getModules();
    }
    // eslint-disable-next-line
  }, [activeSolution]);

  const handleSelectedSolution = (solution) => {
    setActiveSolution(solution);
  };

  return (
    <div className="relative text-xs lg:text-sm 2xl:text-base">
      {/* <span className="absolute opacity-5 right-5 top-5 text-9xl">
        {activeSolution?.icon}
      </span> */}
      <div className="flex justify-between gap-5 h-full">
        {/* List of all Solutions for Navigation */}
        <div className="w-1/6 flex flex-col gap-2 h-max">
          {arraySolutions.map((solution, id) => (
            <div
              className={`flex items-center gap-3 border shadow text-gray-700 p-5 from-gray-600 via-gray-700 to-gray-800 hover:bg-gray-200 rounded-lg w-fill relative cursor-pointer ${
                activeSolution?.title === solution.title
                  ? "bg-gradient-to-r text-white"
                  : ""
              }`}
              key={id}
              onClick={() => handleSelectedSolution(solution)}
            >
              <span className="text-xl">{solution.icon}</span>
              <h1 className="font-bold text-sm">{solution.title}</h1>
            </div>
          ))}
        </div>
        {/* Content for specific solution */}
        <div className="w-5/6 flex flex-col gap-5 p-5 pr-0 pb-0 relative border-l">
          {/* <span className="text-9xl absolute opacity-10">{activeSolution.icon}</span> */}
          {activeSolution && (
            <>
              <h1 className="flex gap-2 items-center text-lg font-bold">
                {activeSolution?.title}
              </h1>
            </>
          )}
          {/* Modules List */}
          {modules && (
            <div className="flex flex-wrap gap-5 h-max">
              {/* Only Modules listed */}
              {modules.map(
                (module, id) =>
                  module &&
                  module?.components.length === 0 && (
                    <Link
                      to={
                        activeSolution?.slug + data?.slug + module?.module?.slug
                      }
                      className="p-5 border rounded-lg md:min-w-[14rem] flex flex-col gap-2 justify-center items-center shadow from-gray-600 via-gray-700 to-gray-800 hover:bg-gradient-to-r hover:text-white hover:shadow-lg min-w-[195px]"
                      key={id}
                    >
                      <span className="text-xl">{module?.module?.icon}</span>
                      <span>{module?.module?.title}</span>
                    </Link>
                  )
              )}
              {/* Modules with components */}
              {modules.map(
                (module, id) =>
                  module?.components.length > 0 &&
                  // <div
                  //   key={id}
                  //   className="flex flex-col gap-2 p-5 border rounded-lg justify-center items-center shadow h-max"
                  // >
                  // <div className="flex gap-2 mb-2 text-left w-full">
                  //   <span className="text-xl">{module?.module?.icon}</span>
                  //   <span>{module?.module?.title}</span>
                  // </div>
                  // <div
                  //   className="flex flex-wrap gap-5 justify-center items-center"
                  //   key={id}
                  // >
                  module.components?.map((component, idx) => (
                    <Link
                      to={activeSolution?.slug + data?.slug + component?.slug}
                      className="p-5 border rounded-lg md:min-w-[14rem] flex flex-col gap-2 justify-center items-center from-gray-600 via-gray-700 to-gray-800 hover:bg-gradient-to-r hover:text-white shadow min-w-[195px]"
                      key={idx}
                    >
                      <span className="text-xl">{component?.icon}</span>
                      <span>{component?.title}</span>
                    </Link>
                  ))
                // </div>
                // </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndustryContent;
