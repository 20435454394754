import React, { useState } from "react";
import API from "../../../api/api";
import Select from "react-select";
import {
  ageBin,
  bmiBin,
  createOptions,
  getMonth,
  states,
} from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
import {
  IL_productTypes,
  IL_brokers,
  IL_SC_formInputs,
  IL_faceAmountBin,
  IL_incomeBin,
  IL_leadAction,
} from "../../../data/modules/leadPrioritizer";
import RadarChart from "../../utils/RadarChart";
import { LuLoader2 } from "react-icons/lu";

const IL_ScoreCalculator = () => {
  const [inputFormData, setInputFormData] = useState(IL_SC_formInputs);
  const [scoreData, setScoreData] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "faceAmount" || e.target?.name === "income") {
      const value = e.target.value.replace(/[^\d.]/g, "");
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else if (
      e.target?.name === "height" ||
      e.target?.name === "weight" ||
      e.target?.name === "age"
    ) {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target?.name === "month") {
      let value = getMonth(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value,
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // function to get score
  const getScore = async (e) => {
    e.preventDefault();
    setLoad(true);
    // If existing case is being edited
    inputFormData.bmiBin = bmiBin(inputFormData.height, inputFormData.weight);
    inputFormData.incomeBin = IL_incomeBin(inputFormData.income);
    inputFormData.faceAmountBin = IL_faceAmountBin(inputFormData.faceAmount);
    inputFormData.ageBin = ageBin(inputFormData.age);

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    API.post("/LP/IL/score-calculator", inputFormData)
      .then(({ data }) => {
        let scores = data.scores;
        // console.log(data.scores);
        let totalScore = 0;
        let keys = {};

        Object.entries(scores).forEach(([key, value]) => {
          totalScore += value["Score"];
          // console.log(key, value["Score"]);
          keys[key] = value["Percentile Rank"];
        });

        let scoreData = [
          {
            score: totalScore - 600,
            action: IL_leadAction(totalScore - 600),
          },
          {
            Age: keys["ageBin"],
            BMI: keys["bmiBin"],
            Gender: keys["gender"],
            Alcohol: keys["alcohol"],
            Tobacco: keys["tobacco"],
            "App Type": keys["appType"],
            State: keys["state"],
            "Product Type": keys["product"],
            Broker: keys["broker"],
            "Annual Income": keys["incomeBin"],
            "Face Amount": keys["faceAmountBin"],
          },
        ];
        setLoad(false);
        setScoreData(scoreData);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  // reset form
  const resetForm = () => {
    setInputFormData(IL_SC_formInputs);
    setScoreData(null);
  };

  return (
    <div className="flex h-max gap-10">
      <div className="w-fit p-7 bg-white rounded-lg shadow border h-full text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-10 justify-between">
              {/* Age */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="age">
                  Age:
                </label>
                <input
                  type="text"
                  name="age"
                  id="age"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.age || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
              {/* Height */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="height">
                  Height (inches):
                </label>
                <input
                  type="text"
                  name="height"
                  id="height"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.height || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
              {/* Weight */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="weight">
                  Weight (lbs):
                </label>
                <input
                  type="text"
                  name="weight"
                  id="weight"
                  value={inputFormData.weight || ""}
                  maxLength={3}
                  onChange={(e) => handleOnChangeInput(e)}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-32"
                />
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Gender */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="gender">
                  Gender:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value={"Male"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value={"Female"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="gender"
                      id="other"
                      value={"Others"}
                      checked={inputFormData.gender === "Others"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              </div>
              {/* Alcohol Consumption */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="alcohol">
                  Alcohol:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="alcohol"
                      id="al_yes"
                      value={"Alcohol"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="al_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="alcohol"
                      id="al_no"
                      value={"Non-Alcohol"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="al_no">No</label>
                  </div>
                </div>
              </div>
              {/* Tobacco Consumption */}
              <div className="flex flex-col gap-1 ml-2">
                <label className="font-semibold" htmlFor="tobacco">
                  Tobacco:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="tobacco"
                      id="tb_yes"
                      value={"Tobacco"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="tb_yes">Yes</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="tobacco"
                      id="tb_no"
                      value={"Non-Tobacco"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="tb_no">No</label>
                  </div>
                </div>
              </div>
              {/* Application Type */}
              <div className="flex flex-col gap-1 w-32">
                <label className="font-semibold w-fit" htmlFor="appType">
                  Application Type:
                </label>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="appType"
                      id="formal"
                      value={"Formal"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="formal">Formal</label>
                  </div>
                  <div className="flex gap-1 items-center">
                    <input
                      type="radio"
                      name="appType"
                      id="informal"
                      value={"Informal"}
                      onClick={(e) => handleOnChangeInput(e)}
                    />
                    <label htmlFor="informal">Informal</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* State */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="state">
                  State:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="state"
                  options={createOptions(states)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "state")}
                  value={
                    inputFormData.state
                      ? createOptions([inputFormData.state])
                      : null
                  }
                ></Select>
              </div>
              {/* Product Type */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="product">
                  Product Type:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="product"
                  options={createOptions(IL_productTypes)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "product")}
                  value={
                    inputFormData.product
                      ? createOptions([inputFormData.product])
                      : null
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Broker */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="broker">
                  Broker:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="broker"
                  options={createOptions(IL_brokers)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "broker")}
                  value={
                    inputFormData.broker
                      ? createOptions([inputFormData.broker])
                      : null
                  }
                ></Select>
              </div>
              {/* Month */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="month">
                  Received Month:
                </label>
                <input
                  type="month"
                  name="month"
                  id="month"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  onChange={(e) => handleOnChangeInput(e, "month")}
                />
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Annual Income */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="income">
                  Annual Income ($):
                </label>
                <div className="flex">
                  <span className="rounded-l-md p-[10px] bg-gray-200">$</span>
                  <input
                    type="text"
                    name="income"
                    id="income"
                    onChange={(e) => handleOnChangeInput(e)}
                    value={inputFormData.income || ""}
                    className="border rounded-r-md focus:outline-none px-2 py-[10px] w-[167px]"
                  />
                </div>
              </div>
              {/* Face Amount */}
              <div className="flex flex-col gap-1 relative">
                <label className="font-semibold" htmlFor="faceAmount">
                  Face Amount ($):
                </label>
                <div className="flex">
                  <span className="rounded-l-md p-[10px] bg-gray-200">$</span>
                  <input
                    type="text"
                    name="faceAmount"
                    id="faceAmount"
                    value={inputFormData.faceAmount || ""}
                    onChange={(e) => handleOnChangeInput(e)}
                    className="border rounded-r-md focus:outline-none px-2 py-[10px] w-[167px]"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Get Score"
                onClick={(e) => getScore(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>

      {/* Output */}
      <div className="min-w-[700px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg">
        <div className="p-3 flex gap-10 items-center justify-center text-center border-b text-xs">
          Range:
          <div className="flex gap-5 font-bold">
            <span className="text-red-500">({"<"}226) DTQ</span>
            <span className="text-amber-500">(227 - 300) Low Priority</span>
            <span className="text-blue-500">(301 - 424) Normal Priority</span>
            <span className="text-green-500">(425+) High Priority</span>
          </div>
        </div>
        {/* Percentile Contribution Chart */}
        {scoreData ? (
          <div className="">
            <RadarChart data={scoreData} module={"Lead Prioritizer"} />
          </div>
        ) : (
          <div className="flex justify-center items-center h-96">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default IL_ScoreCalculator;
