import React from "react";

const EconomicAnalysis = () => {
  return (
    <div className="p-3">
      <div className="flex justify-between items-end">
        <p>
          Potential to grow contingent commission by $20M - $40M+ and enterprise
          value by $350M - $700M+
        </p>
        <p className="px-5 py-3 bg-primary text-white">ILLUSTRATIVE</p>
      </div>
      <div className="pt-10 w-full">
        <table className="w-full text-sm">
          <thead>
            <tr>
              <td colSpan="10" className="p-2">
                All figures in $M
              </td>
            </tr>
            <tr>
              <th
                colSpan="2"
                className="p-3 text-left bg-cyan-800 text-white rounded-tl-lg w-4/6"
              >
                Lever
              </th>
              <th
                colSpan="4"
                className="p-3 bg-cyan-800 text-white border border-gray-100 rounded-tr-lg"
              >
                {" "}
                Potential Increment (Range)
              </th>
            </tr>
            <tr>
              <td
                colSpan="2"
                className="bg-gray-300 p-2 border border-gray-100"
              ></td>
              <th
                colSpan="2"
                className="p-2 bg-cyan-800 text-white border border-gray-100"
              >
                Contingent Commission
              </th>
              <th
                colSpan="2"
                className="p-2 bg-cyan-800 text-white border border-gray-100"
              >
                Enterprise Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-2 border">1.</td>
              <td className="p-2 border">
                Premium distribution within existing contingent
                commission contracts
              </td>
              <td className="p-2 border text-center">$2</td>
              <td className="p-2 border text-center">$5</td>
              <td className="p-2 border text-center">$32</td>
              <td className="p-2 border text-center">$80</td>
            </tr>
            <tr>
              <td className="p-2 border">2.</td>
              <td className="p-2 border">
                Move additional premium into contingent commission contracts
              </td>
              <td className="p-2 border text-center">$5</td>
              <td className="p-2 border text-center">$10</td>
              <td className="p-2 border text-center">$80</td>
              <td className="p-2 border text-center">$160</td>
            </tr>
            <tr>
              <td className="p-2 border">3.</td>
              <td className="p-2 border">
                Scale up through acquisitions. Presume 30-50% growth over next
                2-3 years while preserving current product premium and loss mix
              </td>
              <td className="p-2 border text-center">$16</td>
              <td className="p-2 border text-center">$30</td>
              <td className="p-2 border text-center">$256</td>
              <td className="p-2 border text-center">$480</td>
            </tr>
            <tr className="font-semibold bg-cyan-600">
              <td colSpan="2" className="p-2 border border-gray-100">
                Total Potential Incremental
              </td>
              <td className="p-2 border border-gray-100 text-center">$23</td>
              <td className="p-2 border border-gray-100 text-center">$45</td>
              <td className="p-2 border border-gray-100 text-center">$368</td>
              <td className="p-2 border border-gray-100 text-center">$720</td>
            </tr>
            <tr className="bg-gray-300 h-10">
              <td colSpan="2"></td>
              <td colSpan="1"></td>
              <td colSpan="1"></td>
              <td colSpan="1"></td>
              <td colSpan="1"></td>
            </tr>
            <tr>
              <td colSpan="2" className="p-2 border ">
                Current Contingent Commission
              </td>
              <td className="p-2 border text-center">$25</td>
              <td className="p-2 border text-center">$25</td>
              <td className="p-2 border text-center">$400</td>
              <td className="p-2 border text-center">$400</td>
            </tr>
            <tr className="font-semibold">
              <td
                colSpan="2"
                className="p-2 bg-cyan-600 border border-gray-100 rounded-bl-lg"
              >
                Total Potential Contingent Commission
              </td>
              <td className="p-2 border border-gray-100 text-center bg-cyan-600">
                $47
              </td>
              <td className="p-2 border border-gray-100 text-center bg-cyan-600">
                $69
              </td>
              <td className="p-2 border border-gray-100 text-center bg-cyan-600">
                $752
              </td>
              <td className="p-2 text-center bg-cyan-600 rounded-br-lg border border-gray-100">
                $1,104
              </td>
            </tr>
            <tr>
              <td colSpan="10" className="p-2 italic font-light">
                Note: Assumes enterprise value at 16X contingent commission
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EconomicAnalysis;
