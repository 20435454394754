import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { NumFormatterM } from "../../../utils/Utils";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

const CI_Output = ({
  comparisonChart,
  inputFormData,
  setInputFormData,
  compareOptions,
  enterpriseValue,
  setEnterpriseValue,
}) => {
  const [changePremium, setChangePremium] = useState(
    inputFormData.premiumMovement
  );

  // Enter full screen mode
  const fullScreenModeOn = () => {
    document.getElementById("dashboard").requestFullscreen();
  };

  // console.log(comparisonChart);

  return (
    <div className="text-xs w-full">
      <div className="my-5 relative">
        <hr />
      </div>

      {/* Enter fullscreen */}
      {/* <div className={`flex justify-end items-center w-full`}>
        <div
          className="px-3 py-2 -mt-3 mb-2 text-xs w-fit cursor-pointer rounded-md bg-tertiary text-white hover:opacity-80"
          onClick={() => fullScreenModeOn()}
        >
          Full Screen
        </div>
      </div> */}

      <div className="bg-white" id="dashboard">
        {/* Cards */}
        <div className="mb-5 relative flex justify-between gap-2">
          <div className="xl:w-2/6">
            <h2 className="mb-5 text-base font-semibold">
              Premium Mix & Commission Mix
            </h2>
            <div className="p-5 shadow rounded-md flex flex-col gap-5">
              {/* Products List */}
              <div className="flex gap-5 items-center pb-5 border-b">
                <p className="font-bold">Products:</p>
                <div className="flex gap-2">
                  {inputFormData.products.length > 0 &&
                    inputFormData.products.length < 6 &&
                    inputFormData.products.map((product, i) => (
                      <p className="">
                        {product}
                        {inputFormData.products?.length > 0 &&
                        inputFormData.products?.length - 1 > i
                          ? ","
                          : ""}
                      </p>
                    ))}
                </div>
              </div>
              {/* Agency List */}
              <div className="rounded-md flex gap-5 items-center">
                <p className="font-bold">Agencies:</p>
                <div className="flex gap-2">
                  {inputFormData.agencies.length > 0 &&
                    inputFormData.agencies.map((agencies, i) => (
                      <span className="">
                        {agencies}
                        {inputFormData.agencies?.length > 0 &&
                        inputFormData.agencies?.length - 1 > i
                          ? ","
                          : ""}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 xl:w-[48%]">
            <div className="flex gap-2">
              {/* Commission Improvement ($) */}
              <div className="py-5 shadow rounded-md flex flex-col gap-3 w-2/6 items-center justify-center text-center">
                <p className="text-lg font-bold">
                  $
                  {NumFormatterM(
                    comparisonChart?.optimized?.estimatedCommission -
                      comparisonChart?.current?.estimatedCommission
                  )}
                </p>
                <p className="">Commission Improvement ($)</p>
              </div>
              {/* Commission Improvement (%) */}
              <div className="py-5 shadow rounded-md flex flex-col gap-3 w-2/6 items-center justify-center text-center">
                <p className="text-lg font-bold">
                  {(
                    ((comparisonChart?.optimized?.estimatedCommission -
                      comparisonChart?.current?.estimatedCommission) /
                      comparisonChart?.current?.estimatedCommission) *
                    100
                  ).toFixed(0)}
                  %
                </p>
                <p className="">Commission Improvement (%)</p>{" "}
              </div>
              {/* Enterprise Value Improvement ($) */}
              <div className="py-5 shadow rounded-md flex flex-col gap-3 w-2/6 items-center justify-center text-center">
                <p className="text-lg font-bold">
                  $
                  {NumFormatterM(
                    (comparisonChart?.optimized?.estimatedCommission -
                      comparisonChart?.current?.estimatedCommission) *
                      (enterpriseValue === null || enterpriseValue === ""
                        ? 16
                        : enterpriseValue)
                  )}
                </p>
                <p className="">Enterprise Value Improvement ($)</p>
              </div>
            </div>
            {/* Slicers */}
            <div className="flex gap-2 justify-end text-center">
              {/* Premium movement to best carrier */}
              <div className="p-5 shadow rounded-md flex flex-col gap-3 w-4/6 items-center">
                <p className="">Premium movement to best carrier:</p>
                <div className="flex gap-2 items-center 2xl:text-base">
                  <span className="font-bold text-center w-12 text-base">
                    {changePremium + "%"}
                  </span>
                  <button
                    type="button"
                    className="border rounded-full w-fit p-2 hover:bg-primary hover:text-white"
                    onClick={() =>
                      changePremium < 2
                        ? null
                        : setChangePremium(parseInt(changePremium) - 1)
                    }
                  >
                    <FaCaretDown />
                  </button>
                  <input
                    id="premiumMovement"
                    name="premiumMovement"
                    type="range"
                    min={1}
                    max={100}
                    step={1}
                    value={changePremium || ""}
                    onChange={(e) => setChangePremium(e.target.value)}
                    className="border-primary w-28 rounded-full appearance-none cursor-pointer border-2 p-1 h-fit"
                  />
                  <button
                    type="button"
                    className="border rounded-full w-fit p-2 hover:bg-primary hover:text-white"
                    onClick={() =>
                      changePremium > 99
                        ? null
                        : setChangePremium(parseInt(changePremium) + 1)
                    }
                  >
                    <FaCaretUp />
                  </button>
                  <button
                    type="button"
                    className="bg-primary text-xs text-white w-28 p-2 rounded-md hover:shadow"
                    onClick={() =>
                      setInputFormData((prev) => ({
                        ...prev,
                        premiumMovement: changePremium,
                      }))
                    }
                  >
                    Move Premium
                  </button>
                </div>
              </div>
              {/* EBITDA Multiple */}
              <div className="p-5 shadow rounded-md flex flex-col gap-3 w-2/6 items-center">
                <p className="">EBITDA Multiple:</p>
                <div className="flex h-fit items-center justify-center gap-3 2xl:text-base">
                  <button
                    type="button"
                    className="border rounded-full p-2 hover:bg-primary hover:text-white"
                    onClick={() =>
                      setEnterpriseValue(
                        parseInt(enterpriseValue) === 0 ||
                          !parseInt(enterpriseValue)
                          ? 1
                          : parseInt(enterpriseValue) - 1
                      )
                    }
                  >
                    <FaCaretDown />
                  </button>
                  <input
                    type="text"
                    className="border-2 rounded-md w-20 h-10 p-2 2xl:text-base text-center"
                    value={enterpriseValue ? enterpriseValue : null}
                    onChange={(e) =>
                      setEnterpriseValue(
                        parseInt(e.target.value) === 0 ||
                          !parseInt(e.target.value)
                          ? 1
                          : parseInt(e.target.value)
                      )
                    }
                  />
                  <button
                    type="button"
                    className="border rounded-full p-2 hover:bg-primary hover:text-white"
                    onClick={() =>
                      setEnterpriseValue(
                        parseInt(enterpriseValue) === 0 ||
                          !parseInt(enterpriseValue)
                          ? 1
                          : parseInt(enterpriseValue) + 1
                      )
                    }
                  >
                    <FaCaretUp />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Views */}
        <div className={`flex w-full h-full justify-center gap-2`}>
          {/* Titles Table */}
          <div className="flex flex-col text-[9px] xl:text-[10px] -ml-5 2xl:text-xs w-[18%] 2xl:w-[15%] gap-2 justify-end items-center text-right">
            <table className="w-full font-semibold">
              <tbody className="w-full">
                <tr>
                  <td className="w-full py-2">Inforce Premium ($):</td>
                </tr>
                <tr>
                  <td className="w-full py-2">Min EWP:</td>
                </tr>
                <tr>
                  <td className="w-full py-2">Adjusted Loss Ratio:</td>
                </tr>
                <tr>
                  <td className="w-full py-2">
                    Contingent Commission Rate (%):
                  </td>
                </tr>
                <tr>
                  <td className="w-full py-2">Contingent Commission ($):</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Current View */}
          <div className="flex flex-col justify-between w-1/2 text-center pr-5 border-r-2">
            <h3 className="font-bold text-lg text-left uppercase mb-3">
              Current View
            </h3>
            {/* Chart */}
            <div className="-ml-8 w-[90%] xl:w-[85%]">
              <ReactApexChart
                height={300}
                series={
                  compareOptions(comparisonChart.current, "current").series
                }
                options={
                  compareOptions(comparisonChart.current, "current").options
                }
                type="bar"
              />
            </div>
            {/* Table / Grid */}
            <table className="w-full -mt-7">
              <tbody className="w-full">
                <tr>
                  {comparisonChart.carrierNames
                    ?.slice(0, 4)
                    .map((carrier, index) => (
                      <td key={index} className="p-2 w-28 font-bold border">
                        {carrier}
                      </td>
                    ))}
                  <td className="font-semibold border">Overall</td>
                </tr>
                <tr>
                  {comparisonChart?.current?.mixAmount
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {NumFormatterM(item)}
                      </td>
                    ))}
                  <td className="p-2 w-28 font-semibold border">
                    {NumFormatterM(inputFormData.writtenPremium)}
                  </td>
                </tr>
                <tr>
                  {comparisonChart?.current?.minEWP
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {NumFormatterM(item)}
                      </td>
                    ))}
                </tr>
                <tr>
                  {comparisonChart?.current?.minLLR
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {item}
                      </td>
                    ))}
                </tr>
                <tr>
                  {comparisonChart?.current?.commissionRate
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {item}%
                      </td>
                    ))}
                  <td className="p-2 w-28 font-bold border">
                    {comparisonChart?.current?.overAllCommissionRate}%
                  </td>
                </tr>
                <tr>
                  {comparisonChart?.current?.commission
                    ?.slice(0, 4)
                    .map((item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {NumFormatterM(item)}
                      </td>
                    ))}
                  <td className="p-2 w-28 border-[#68ba7f] border-2 font-bold">
                    $
                    {NumFormatterM(
                      comparisonChart?.current?.estimatedCommission
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Optimized View */}
          <div className="flex flex-col justify-between pl-5 w-3/5 text-center">
            <h3 className="font-bold text-lg text-left uppercase mb-3">
              Optimized View
            </h3>
            {/* Chart */}
            <div
              className={`-ml-8 ${
                inputFormData.isFileUploaded
                  ? "w-[93%] xl:w-[89%]"
                  : "w-[89%] xl:w-[85%]"
              }`}
            >
              <ReactApexChart
                height={300}
                series={
                  compareOptions(comparisonChart.optimized, "optimized").series
                }
                options={
                  compareOptions(comparisonChart.optimized, "optimized").options
                }
                type="bar"
              />
            </div>
            {/* Table / Grid */}
            <table className="w-full -mt-7">
              <tbody className="w-full">
                <tr>
                  {comparisonChart.carrierNames.map((carrier, index) => (
                    <td key={index} className="p-2 w-28 border font-bold">
                      {carrier}
                    </td>
                  ))}
                  <td className="font-semibold border">Overall</td>
                </tr>
                <tr>
                  {comparisonChart?.optimized?.mixAmount?.map((item, index) => (
                    <td key={index} className="p-2 w-28 border ">
                      {NumFormatterM(item)}
                    </td>
                  ))}
                  <td className="p-2 w-28 border font-semibold">
                    {NumFormatterM(inputFormData.writtenPremium)}
                  </td>
                </tr>
                <tr>
                  {comparisonChart?.optimized?.minEWP?.map((item, index) => (
                    <td key={index} className="p-2 w-28 border">
                      {NumFormatterM(item)}
                    </td>
                  ))}
                </tr>
                <tr>
                  {comparisonChart?.optimized?.minLLR?.map((item, index) => (
                    <td key={index} className="p-2 w-28 border">
                      {item}
                    </td>
                  ))}
                </tr>
                <tr>
                  {comparisonChart?.optimized?.commissionRate?.map(
                    (item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {item}%
                      </td>
                    )
                  )}
                  <td className="p-2 w-28 border font-bold">
                    {comparisonChart?.optimized?.overAllCommissionRate}%
                  </td>
                </tr>
                <tr>
                  {comparisonChart?.optimized?.commission?.map(
                    (item, index) => (
                      <td key={index} className="p-2 w-28 border">
                        {NumFormatterM(item)}
                      </td>
                    )
                  )}
                  <td className="p-2 w-28 border-[#00965f] font-bold border-2">
                    $
                    {NumFormatterM(
                      comparisonChart?.optimized?.estimatedCommission
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CI_Output;
