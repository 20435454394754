import React, { useState } from "react";
import InputField from "./InputField";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const inputOptions = [
  "Automation Scenario: Costs ($K)",
  "Low Cost Location Scenario: Costs",
  "Severance and Staff Overlap Input",
];

const CostForm = ({
  Automation_Cost_Inputs,
  LowCostLocation_Cost_Inputs,
  RegionSeverence_Inputs,
  OneTimeCost_NonTech_Inputs,
  OneTimeCost_Tech_Inputs,
  StaffingOverlap_Input,
  WeightedAvgCost_Input,
  handleNumberInputChange,
  handleCurrencyChange,
  handleBackspaceForPercentage,
  handlePercentageInputChange,
  targetStateAutomation,
  targetStateLowCostLocation,
  targetStateRegionSeverence,
  targetStateOneTimeCost_NonTech,
  targetStateOneTimeCost_Tech,
  targetStateStaffingOverlap,
  targetStateWeightedAvgCost,
  defaultValues,
}) => {
  const [filter, setFilter] = useState("Automation Scenario: Costs ($K)");

  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Cost Inputs</h3>
        <div className="flex items-start gap-5">
          {/* Select Input filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="region">Filter by Type:</label>
            <Select
              name={"region"}
              options={createOptions(inputOptions)}
              className="w-64 text-xs"
              classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(filter)}
              onChange={(e) => setFilter(e.value)}
            />
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("Cost Input")}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      {/* Automation Scenario: Costs ($K) */}
      {filter === "Automation Scenario: Costs ($K)" && (
        <div className="flex gap-5 justify-between lg:w-full text-sm border rounded-md overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-300">
              <tr className="border-b">
                <th className="py-2 px-4 border-r" colSpan={1} rowSpan={2}>
                  Cost Category
                </th>
                <th className="py-2 px-4 border-r" colSpan={3}>
                  2023
                </th>
                <th className="py-2 px-4 border-r" colSpan={3}>
                  2024
                </th>
                <th className="py-2 px-4" colSpan={3}>
                  2025
                </th>
              </tr>
              <tr className="border-b">
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4 border-r">Role 3</th>
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4 border-r">Role 3</th>
                <th className="py-2 px-4 border-r">Role 1</th>
                <th className="py-2 px-4 border-r">Role 2</th>
                <th className="py-2 px-4">Role 3</th>
              </tr>
            </thead>
            <tbody>
              {Automation_Cost_Inputs["Cost Category"].map(
                (cost_category, index) => (
                  <tr key={index}>
                    {console.log(cost_category)}
                    <td className="px-4 py-2 w-40 border-r">{cost_category}</td>
                    {Object.entries(Automation_Cost_Inputs)
                      .slice(1)
                      .map(([key, value], id) => (
                        <td key={id} className="px-4 py-2 w-32 border-r">
                          <InputField
                            inputType={"currency"}
                            name={key}
                            index={index}
                            currentState={Automation_Cost_Inputs}
                            targetState={targetStateAutomation}
                            handleCurrencyChange={handleCurrencyChange}
                          />
                        </td>
                      ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Low Cost Location Scenario: Costs */}
      {filter === "Low Cost Location Scenario: Costs" && (
        <div className="flex flex-col gap-10">
          {/* Ongoing Costs */}
          <div className="flex gap-5 justify-between lg:w-full text-sm border rounded-md overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-300">
                <tr className="border-b">
                  <th className="py-2 px-4" colSpan={5}>
                    Ongoing Costs
                  </th>
                </tr>
                <tr className="border-b">
                  <th className="py-2 px-4 border-r" colSpan={1} rowSpan={3}>
                    Region
                  </th>
                  <th className="py-2 px-4 border-r" colSpan={3}>
                    Non-Tech
                  </th>
                  <th className="py-2 px-4 border-r">Tech</th>
                </tr>
                <tr className="border-b">
                  <th className="py-2 px-4 border-r">Annual Rent</th>
                  <th className="py-2 px-4 border-r">YoY Rent Increase</th>
                  <th className="py-2 px-4 border-r">
                    Administrative & Managerial Cost
                  </th>
                  <th className="py-2 px-4 border-r" rowSpan={2}>
                    License cost per FTE
                  </th>
                </tr>
                <tr className="border-b">
                  <th className="py-2 px-4 border-r">(per Sq. Ft)</th>
                  <th className="py-2 px-4 border-r">(%)</th>
                  <th className="py-2 px-4 border-r">(% of Total FTE Cost)</th>
                </tr>
              </thead>
              <tbody>
                {LowCostLocation_Cost_Inputs["Region"].map((region, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 w-44 border-r">{region}</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"Annual Rent"}
                        index={index}
                        currentState={LowCostLocation_Cost_Inputs}
                        targetState={targetStateLowCostLocation}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={"YoY Rent increase"}
                        index={index}
                        currentState={LowCostLocation_Cost_Inputs}
                        targetState={targetStateLowCostLocation}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage"}
                        name={"Administrative & Managerial Cost"}
                        index={index}
                        currentState={LowCostLocation_Cost_Inputs}
                        targetState={targetStateLowCostLocation}
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                      />
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"License cost per FTE"}
                        index={index}
                        currentState={LowCostLocation_Cost_Inputs}
                        targetState={targetStateLowCostLocation}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* One Time Costs*/}
          <div className="flex gap-10">
            {/* Non Tech */}
            <div className="flex h-fit gap-5 justify-between lg:w-full text-sm border rounded-md overflow-auto">
              <table className="w-full">
                <thead className="bg-gray-300">
                  <tr className="border-b">
                    <th className="py-2 px-4" colSpan={5}>
                      One Time Cost: Non Tech
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">
                      Facility Buildup Cost (Per Sq. Ft)
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"facility_buildup"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">
                      Sq. Feet per FTE
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"number"}
                        name={"sq. feet per FTE"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleNumberInputChange={handleNumberInputChange}
                      />
                    </td>
                  </tr>
                  <tr className="border-t border-b">
                    <th className="px-4 py-2 w-44 border-r" colSpan={2}>
                      Training Cost as % of Annual Comp: High Cost Location
                    </th>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 1</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Training_Role 1"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 2</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Training_Role 2"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 3</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Training_Role 3"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr className="border-t border-b">
                    <th className="px-4 py-2 w-44 border-r" colSpan={2}>
                      Hiring Cost as % of Annual Comp: Low Cost Location
                    </th>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 1</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Hiring_Role 1"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 2</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Hiring_Role 2"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">Role 3</td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"Hiring_Role 3"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                  <tr className="border-t">
                    <td className="px-4 py-2 w-44 border-r">
                      Relocation bonus as % of Annual Comp
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"percentage_100"}
                        name={"relocation_bonus"}
                        index={0}
                        currentState={OneTimeCost_NonTech_Inputs}
                        targetState={targetStateOneTimeCost_NonTech}
                        handleBackspaceForPercentage={
                          handleBackspaceForPercentage
                        }
                        handlePercentageInputChange={
                          handlePercentageInputChange
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Tech */}
            <div className="flex h-fit gap-5 justify-between lg:w-full text-sm border rounded-md overflow-auto">
              <table className="w-full">
                <thead className="bg-gray-300">
                  <tr className="border-b">
                    <th className="py-2 px-4" colSpan={5}>
                      One Time Cost: Tech
                    </th>
                  </tr>
                  <tr className="border-b">
                    <th className="py-2 px-4" colSpan={5}>
                      Hardware
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">
                      Laptop/System cost per FTE
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"laptop/system"}
                        index={0}
                        currentState={OneTimeCost_Tech_Inputs}
                        targetState={targetStateOneTimeCost_Tech}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">
                      Security (Biometric, etc.)
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"security"}
                        index={0}
                        currentState={OneTimeCost_Tech_Inputs}
                        targetState={targetStateOneTimeCost_Tech}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 w-44 border-r">
                      Infrastructure Setup (Wifi, Printers, etc)
                    </td>
                    <td className="px-4 py-2 w-44 border-r">
                      <InputField
                        inputType={"currency"}
                        name={"infrastructure"}
                        index={0}
                        currentState={OneTimeCost_Tech_Inputs}
                        targetState={targetStateOneTimeCost_Tech}
                        handleCurrencyChange={handleCurrencyChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {/* Severance and Staff Overlap Input */}
      {filter === "Severance and Staff Overlap Input" && (
        <div className="flex flex-col gap-5 justify-between lg:w-full text-sm border rounded-md overflow-auto">
          <div className="flex justify-between p-5">
            {/* Staffing Overlap (Months) */}
            <div className="flex items-center gap-3 justify-between">
              <label htmlFor="staffing_overlap" className="w-fit">
                Staffing Overlap (Months)
              </label>
              <input
                type="text"
                name={"staffing_overlap"}
                id={"staffing_overlap"}
                className="rounded-md border border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-24 text-center"
                value={StaffingOverlap_Input || ""}
                onChange={(e) =>
                  handleNumberInputChange(e, 0, targetStateStaffingOverlap)
                }
                maxLength={10}
              />
            </div>
            {/* Weighted Average Cost of Capital */}
            <div className="flex items-center gap-3 justify-between">
              <label htmlFor="weighted_avg_cost_capital" className="w-fit">
                Weighted Average Cost of Capital
              </label>
              <input
                type="text"
                name={"weighted_avg_cost_capital"}
                id={"weighted_avg_cost_capital"}
                className="rounded-md border border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-24 text-center"
                value={WeightedAvgCost_Input * 100 + "%" || ""}
                onChange={(e) =>
                  handlePercentageInputChange(e, 0, targetStateWeightedAvgCost)
                }
                onKeyDown={(e) =>
                  handleBackspaceForPercentage(e, 0, targetStateWeightedAvgCost)
                }
                maxLength={10}
              />
            </div>
          </div>
          {/* Severance */}
          <table className="w-1/3">
            <thead className="bg-gray-300">
              <tr className="border-b border-t">
                <th className="py-2 px-4 border-r">Regions</th>
                <th className="py-2 px-4 border-r">Severance (Months)</th>
              </tr>
            </thead>
            <tbody>
              {RegionSeverence_Inputs.Region.map((region, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 w-40 border-r text-center">
                    {region}
                  </td>
                  <td className="px-4 py-2 w-40 border-r">
                    <InputField
                      inputType={"currency"}
                      name={"Severance"}
                      index={index}
                      currentState={RegionSeverence_Inputs}
                      targetState={targetStateRegionSeverence}
                      handleCurrencyChange={handleCurrencyChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CostForm;
