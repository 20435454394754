import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  CI_formInputs,
  CI_formInputsForEWP,
} from "../../../../data/modules/commissionIntelligence";
import ContractInventory from "./ContractInventory";
import CI_InputForm from "./CI_InputForm";
import CI_Output from "./CI_Output";
import { CurrencyFormatter } from "../../../utils/Utils";

const CommissionTool = () => {
  const [inputFormData, setInputFormData] = useState(CI_formInputs);
  const [graph, setGraph] = useState(null);
  const [comparisonChart, setComparisonChart] = useState(null);
  const [load, setLoad] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [topAgencies, setTopAgencies] = useState([]);
  const [enterpriseValue, setEnterpriseValue] = useState(16);

  // Graph options
  let options;
  let compareOptions;

  if (graph) {
    // basic graph options for
    options = {
      series: [
        {
          data: Object.values(graph[0]),
        },
      ],
      options: {
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 5,
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: Object.keys(graph[0]).map((key) =>
          key === "Other Agencies" ? "gray" : "#014e94"
        ),

        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return graph[1] === "Agencies"
              ? CurrencyFormatter(value)
              : value + "%";
          },
          offsetY: -30,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
            fontFamily: "Noto Sans, sans-serif",
            fontWeight: 500,
          },
        },
        xaxis: {
          categories: Object.keys(graph[0]),
          position: "bottom",
          style: {
            fontFamily: "Noto Sans, sans-serif",
            fontWeight: 700,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text:
            graph[1] === "Agencies"
              ? "Top Agencies by Premium"
              : "Top Carriers by Contingent Commission (%)",
          floating: false,
          align: "center",
          style: {
            color: "#444",
            fontFamily: "Noto Sans, sans-serif",
            fontWeight: 700,
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
      },
    };
  }

  if (comparisonChart) {
    // comparison charts
    compareOptions = (data, type) => {
      // function to generate data for chart series
      const generateData = (array, series) => {
        let arrayOfObject = [];
        for (
          let i = 0;
          i <
          array.carrierName.length -
            (type === "current" && inputFormData.isFileUploaded ? 1 : 0);
          i++
        ) {
          if (array.carrierName[i] === "Liberty") {
            let object = {
              x: array.carrierName[i],
              y:
                series === "inforce"
                  ? Math.round(array.mixPercentage[i]).toFixed(0)
                  : Math.round(array.commissionPercentage[i]).toFixed(0),
              // fillColor: series === "inforce" ? "#9d9d9c" : "#2382d8",
              fillColor:
                series === "inforce"
                  ? "#9d9d9c"
                  : type === "current"
                  ? "#68ba7f"
                  : "#00965f",
            };
            arrayOfObject.push(object);
          } else {
            let object = {
              x: array.carrierName[i],
              y:
                series === "inforce"
                  ? Math.round(array.mixPercentage[i]).toFixed(0)
                  : Math.round(array.commissionPercentage[i]).toFixed(0),
              fillColor:
                series === "inforce"
                  ? "#9d9d9c"
                  : type === "current"
                  ? "#68ba7f"
                  : "#00965f",
            };
            arrayOfObject.push(object);
          }
        }
        // console.log("array", arrayOfObject);
        return arrayOfObject;
      };

      return {
        series: [
          {
            name: "Inforce Premium Mix (%)",
            data: generateData(data, "inforce"),
          },
          {
            name: "Contingent Commission Mix (%)",
            data: generateData(data, "contingent"),
          },
        ],
        options: {
          plotOptions: {
            bar: {
              distributed: false,
              borderRadius: 5,
              borderRadiusApplication: "end",
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#9d9d9c", type === "current" ? "#68ba7f" : "#00965f"],
          dataLabels: {
            enabled: true,
            formatter: function (value) {
              return value + "%";
            },
            offsetY: -30,
            style: {
              fontSize: "11px",
              colors: ["#304758"],
              fontFamily: "Noto Sans, sans-serif",
              fontWeight: 500,
            },
          },
          stroke: {
            show: true,
            width: 5,
            colors: ["transparent"],
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
            min: 0,
            max: 110,
            axisBorder: {
              show: false,
            },
          },
          title: {
            show: false,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            itemMargin: {
              horizontal: 24,
              vertical: 0,
            },
            show: true,
            onItemClick: {
              toggleDataSeries: false,
            },
            onItemHover: {
              highlightDataSeries: false,
            },
          },
          grid: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
          },
        },
      };
    };
  }

  return (
    <div className="w-full relative select-none">
      {/* Slider Tabs */}
      <div className="flex justify-end">
        <div className="flex w-fit -mt-2 p-1 border shadow text-xs font-semibold rounded-lg bg-gray-100">
          <button
            className={`p-2 rounded-lg from-gray-600 via-gray-700 to-gray-800 ${
              toggle ? "" : "bg-gradient-to-r text-white"
            }`}
            onClick={() => setToggle(false)}
          >
            Distribute Commission
          </button>
          <button
            className={`p-2 rounded-lg from-gray-600 via-gray-700 to-gray-800 ${
              toggle ? "bg-gradient-to-r text-white" : ""
            }`}
            onClick={() => setToggle(true)}
          >
            Contract Database
          </button>
        </div>
      </div>

      {toggle ? (
        // Contract Table
        <ContractInventory load={load} inputFormData={inputFormData} />
      ) : (
        // Commission Intelligence Calculator
        <section>
          <div className="p-5 flex justify-between h-full gap-16 relative">
            {/* Input Form */}
            <CI_InputForm
              inputFormData={inputFormData}
              setInputFormData={setInputFormData}
              load={load}
              setLoad={setLoad}
              options={options}
              graph={graph}
              setGraph={setGraph}
              comparisonChart={comparisonChart}
              setComparisonChart={setComparisonChart}
              setErrorMessage={setErrorMessage}
              setTopAgencies={setTopAgencies}
              topAgencies={topAgencies}
            />
            {graph ? (
              <div className="w-3/5 border bg-white rounded-lg shadow p-4 md:p-6">
                <ReactApexChart
                  series={options?.series}
                  options={options?.options}
                  type="bar"
                />
              </div>
            ) : (
              <div className="m-auto">{errorMessage && errorMessage}</div>
            )}
          </div>

          {/* Comparison Charts and Tables */}
          {comparisonChart && (
            <CI_Output
              comparisonChart={comparisonChart}
              setComparisonChart={setComparisonChart}
              inputFormData={inputFormData}
              graph={graph}
              options={options}
              compareOptions={compareOptions}
              enterpriseValue={enterpriseValue}
              setEnterpriseValue={setEnterpriseValue}
              setInputFormData={setInputFormData}
            />
          )}
        </section>
      )}
    </div>
  );
};

export default CommissionTool;
