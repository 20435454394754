import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";
const clientLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";
const loginImage =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/signin-image.webp";
const resetPassImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/reset_pass-image.webp";
const bbbImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/accredited-business.webp";
const sdoImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/SDO-logo.webp";

const AuthTemplate = () => {
  const [page, setPage] = useState(null);
  const { pathname } = useLocation();
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (pathname === "/login") {
      setPage("Login");
    } else if (pathname === "/forgot-password") {
      setPage("ForgotPassword");
    }
  });

  return (
    <>
      <section
        className="sm:grid place-items-center h-screen relative"
        onClick={() => setFocus(false)}
      >
        <div className="flex flex-col sm:flex-row items-center justify-center relative h-full md:h-4/5 lg:h-5/6 xl:4/5 w-full md:w-10/12 md:rounded-xl md:shadow-2xl md:border-t md:border-r sm:overflow-hidden">
          {/* Banners for Desktop*/}
          <div className="sm:bg-primary relative hidden md:flex items-center justify-center w-1/2 md:w-3/5 h-fit sm:h-full">
            <img
              src={
                page === "Login"
                  ? loginImage
                  : page === "ForgotPassword"
                  ? resetPassImg
                  : undefined
              }
              alt="image"
              className="w-[75%]"
            />
          </div>
          <div className="relative md:hidden flex items-center justify-center w-3/4 md:w-3/5 h-fit sm:h-full">
            <img
              src={spinnakerLogo}
              alt="image"
              className="w-2/3 sm:absolute z-[2]"
            />
          </div>

          {/* Form Container */}
          <div className="h-[400px] sm:h-full relative w-full sm:w-1/2 xl:w-2/5 sm:shadow-2xl bg-white flex items-center">
            {page !== "Login" && (
              <div className="fixed sm:absolute top-10 right-5 text-sm">
                <Link
                  to="/login"
                  className="p-2 text-white rounded-md bg-primary hover:bg-white hover:text-primary border-2 border-primary"
                >
                  Back to login
                </Link>
              </div>
            )}
            {/* Forms */}
            <div className="m-3 sm:m-0 sm:px-5 xl:px-20 w-full scale-75 xl:scale-100">
              <div className="flex flex-col gap-3 justify-center">
                <img
                  src={clientLogo}
                  alt="logo"
                  className="hidden sm:block w-40"
                />
                <h1 className="text-2xl font-extrabold">
                  {page === "Login" && "User Login"}
                  {page === "ForgotPassword" && "Reset your password"}
                </h1>
              </div>
              {page === "Login" && <Login focus={focus} setFocus={setFocus} />}
              {page === "ForgotPassword" && <ForgotPassword />}
              {page === "Login" && (
                <>
                  <div className="flex gap-3 justify-center pt-5 items-center text-xs ">
                    <Link to="" className="hover:underline">
                      Terms of use
                    </Link>
                    <RxDividerVertical />
                    <Link to="" className="hover:underline">
                      Privacy policy
                    </Link>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center mt-5 gap-5 w-3/4 md:w-full">
                      <a
                        href="https://www.bbb.org/us/ma/boston/profile/business-coach/spinnaker-llc-0021-91049"
                        target="_blank"
                        className="w-2/6"
                      >
                        <img src={bbbImg} alt="BBB Business Review" />
                      </a>
                      <a
                        href="https://www.diversitycertification.mass.gov/BusinessDirectory/BusinessDirectorySearch.aspx"
                        target="_blank"
                        className="w-1/6"
                      >
                        <img
                          src={sdoImg}
                          alt="Supplier Delivery Office Certified"
                        />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Show on small devices */}
      {/* <section className="lg:hidden h-screen w-screen max-h-screen max-w-full flex flex-col gap-5 items-center justify-center p-10 text-center text-[10px]">
        <img src={spinnakerLogo} alt="spinnaker logo" className="w-40" />
        Looks like you are using or small screen. <br /> Please switch to a
        laptop or a wider screen to use the portal. <br /> If you are using an
        ipad or tablet switch to landscape mode.
      </section> */}
    </>
  );
};

export default AuthTemplate;
