// list of products in contingent commission
export const CI_productsList = [
  "Property",
  "Workers' Compensation",
  "Commercial Auto",
  "Professional Liability",
  "Cyber Liability",
  "Umbrella Insurance",
];

// object with keys as products and value as ewp
export const CI_products = {
  Property: 25471698,
  "Workers Compensation": 14150943,
  "Commercial Auto": 9905660,
  "Professional Liability": 7075472,
  "Cyber Liability": 1415094,
  "Umbrella Insurance": 1981132,
};

// list of products in contingent commission
export const CI_productsFirmLevelList = [
  "Select All",
  "Property",
  "Workers' Compensation",
  "Commercial Auto",
  "Professional Liability",
  "Cyber Liability",
  "Umbrella Insurance",
  // "All",
];

export const CI_productsForForm = {
  Property: 407_646_544,
  "Workers Compensation": 207_142_027,
  "Commercial Auto": 157_997_239,
  "Professional Liability": 98_372_451,
  "Cyber Liability": 28_680_324,
  "Umbrella Insurance": 33_219_117,
};

export const CI_productsFirmLevel = {};

// table columns
export const CI_columns = [
  "Contract ID",
  "Carrier Name",
  "Ingestion Date",
  "Limited Loss Ratio (Min)",
  "Limited Loss Ratio (Max)",
  "Elegible Written Premium (Min)",
  "Elegible Written Premium (Max)",
  "Contingent Commission (%)",
];

export const CI_columnValues = [
  "Contract_ID",
  "Contract",
  "Ingestion Date",
  "LLR_Lower",
  "LLR_Upper",
  "Lower_EWP",
  "Upper_EWP",
  "Value",
];

export const CI_FirmList = [
  "Firm A",
  "Firm B",
  "Firm C",
  "Firm D",
  "Firm E",
  "Firm F",
  "Firm G",
  "Firm H",
];

export const CI_formInputs = {
  isFileUploaded: false,
  writtenPremium: 0,
  lossRatio: 0,
  products: [],
  premiums: [],
  agencies: [],
  enterpriseEBITA: 16,
  premiumMovement: 80,
};

// Table inputs
export const CI_filterInputs = {
  carrier: "All Carriers",
  orderBy: "Carrier Name",
  orderStyle: "ASC",
  searchFor: "",
  isFileUploaded: false,
};
