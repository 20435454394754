import React from "react";
import Select from "react-select";
import { CurrencyFormatter } from "../../../utils/Utils";
import { ROLES } from "../../../../data/modules/opsTransformationPlanning";
import { colourStyles } from "../../../utils/Functions";
import { createOptions, selectedOption } from "../../../../data/staticData";

const InputField = ({
  inputType,
  name,
  index,
  targetState,
  currentState,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  handleCurrencyChange,
  handleOnChangeSelectInput,
  handleNumberInputChange,
  options = ROLES,
}) => {
  if (inputType === "percentage") {
    return (
      <input
        type="text"
        name={name}
        id={name}
        className="border-b-2 border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-full text-center"
        value={currentState[name][index] + "%" || ""}
        onChange={(e) => handlePercentageInputChange(e, index, targetState)}
        onKeyDown={(e) => handleBackspaceForPercentage(e, index, targetState)}
        maxLength={6}
      />
    );
  }

  if (inputType === "percentage_100") {
    return (
      <input
        type="text"
        name={name}
        id={name}
        className="border-b-2 border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-full text-center"
        value={currentState[name][index] * 100 + "%" || ""}
        onChange={(e) => handlePercentageInputChange(e, index, targetState)}
        onKeyDown={(e) => handleBackspaceForPercentage(e, index, targetState)}
        maxLength={6}
      />
    );
  }

  if (inputType === "number") {
    return (
      <input
        type="text"
        name={name}
        id={name}
        className="border-b-2 border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-full text-center"
        value={currentState[name][index] || ""}
        onChange={(e) => handleNumberInputChange(e, index, targetState)}
        maxLength={10}
      />
    );
  }

  if (inputType === "currency") {
    return (
      <input
        type="text"
        name={name}
        id={name}
        className="border-b-2 border-gray-400 focus:border-orange-400 focus:outline-none p-2 w-full text-center"
        value={CurrencyFormatter(currentState[name][index]) || ""}
        onChange={(e) => handleCurrencyChange(e, index, targetState)}
      />
    );
  }

  if (inputType === "select") {
    return (
      <Select
        name={name}
        options={createOptions(options)}
        className="min-w-[10rem] text-xs"
        classNamePrefix={"inventory"}
        styles={colourStyles}
        id={name}
        defaultValue={selectedOption(currentState[name][index])}
        onChange={(e) => handleOnChangeSelectInput(e, name, index, targetState)}
      />
    );
  }
};

export default InputField;
