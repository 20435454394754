// ***************************************************************  All Power BI Dashboards  ***************************************************************

import { commercialLines, employeeBenefits } from "./data";

export const dashboards = {
  // Sales Prophet
  salesProphet: {
    // Market Overview
    marketOverview: {
      individualLife: {
        reportId: "b90b56e6-7323-45dd-aa46-f22ff29e3212",
      },
      employeeBenefits: {
        reportId: "25cf6e7e-1f45-4101-a47f-0882b6c9b262",
      },
      annuity: {
        reportId: "8bf37833-1e7d-4e66-be7a-6a9a36ee4c1c",
      },
      wealthManagement: {
        reportId: "4203a609-2128-402a-8f94-eb5b1296f0ad",
      },
      worksite: {
        reportId: "1081f7cc-174e-4e51-8440-93e5ed4ebf7e",
      },
    },
    // Wallet Share Assessment
    walletShareAssessment: {
      individualLife: {
        reportId: "7459c2c3-8c99-4884-84d6-db76e6f551c7",
      },
    },
    // Sales Opportunity
    salesOpportunity: {
      individualLife: {
        reportId: "f1138b6c-e592-48ba-88e2-129b1f8f62ba",
      },
    },
    // Agent Performance
    agentPerformance: {
      individualLife: {
        reportId: "f72e68dd-24dd-4c19-b005-ca45f611bc80",
      },
    },
    // Intelligent Goal Setter
    intelligentGoalSetter: {
      individualLife: {
        reportId: "fe0a85b2-3194-42b9-b59f-5cacf8eb786e",
      },
      employeeBenefits: {
        reportId: "e1a68e06-705f-4b03-8a3d-be6e9cbb8b8b",
      },
      propertyCasualty: {
        reportId: "d6d216d3-bfb2-4334-905f-e3431e555be4",
      },
    },
    // Territory Design
    territoryDesign: {
      individualLife: {
        reportId: "ef4917ad-12cd-42cf-bc1f-a81920c6ba2d",
      },
    },
    // Broker score card
    brokerScoreCard: {
      employeeBenefits: {
        reportId: "9980a588-1d8a-4f95-93d5-9e29227edb65",
      },
    },
  },
  // Lead Prioritizer
  leadPrioritizer: {
    // Priority Bin Dashboard
    priorityBinDashboard: {
      individualLife: {
        reportId: "8da128d2-7355-4de6-82e6-f607c6b13afe",
      },
      commercialLines: {
        reportId: "42ef4c86-e7b1-4b29-b881-b23aab9ecbf0",
      },
      personalLines: {
        reportId: "de965bfd-b8fb-4d09-bfbd-eff87a2057dc",
      },
    },
    // Attribute Analysis
    attributeAnalysis: {
      individualLife: {
        reportId: "a611e3b8-7cc4-46fb-b302-49d610378e3f",
      },
      employeeBenefits: {
        reportId: "4688ad6f-57a1-4092-9bb6-42ef77f437c7",
      },
      commercialLines: {
        reportId: "89defba0-26c4-4949-a882-84a13dcc7c23",
      },
      // Report pending
      personalLines: {
        reportId: "14b490cc-ecc5-4256-91c4-4eb4489abf84",
      },
    },
    // Score Distribution
    scoreDistribution: {
      individualLife: {
        reportId: "c2f6131a-6466-4197-a97b-49df67b4f7fb",
      },
      employeeBenefits: {
        reportId: "031b3da4-e3d6-4b72-ac2d-7082eae896fe",
      },
    },
  },
  // 4Qast
  forecast: {
    // 4Qast Dashboard
    sales4QastDashboard: {
      individualLife: {
        reportId: "6ffde7f4-c981-4811-abff-580928bc8cf9",
      },
      supplementalHealth: {
        reportId: "6ffde7f4-c981-4811-abff-580928bc8cf9",
      },
      ecommerceConsumerGoods: {
        reportId: "86453d80-0135-44ef-a4f4-458bc231a76c",
      },
      commercialLines: {
        reportId: "b85ada73-f4fe-4ae7-bbc0-e86c10df7db0",
      },
      personalLines: {
        reportId: "b85ada73-f4fe-4ae7-bbc0-e86c10df7db0",
      },
    },
    // 4Qast Dashboard
    sales4QastDrilldown: {
      individualLife: {
        reportId: "db8c1026-1e03-4a37-9a83-9682f2e857bf",
      },
      supplementalHealth: {
        reportId: "db8c1026-1e03-4a37-9a83-9682f2e857bf",
      },
      ecommerceConsumerGoods: {
        reportId: "5e36abb7-3a28-4a7f-baf3-eb24c39651a8",
      },
      commercialLines: {
        reportId: "c3e85374-9693-4ab2-9b77-67c0ff9dcc6d",
      },
      personalLines: {
        reportId: "c3e85374-9693-4ab2-9b77-67c0ff9dcc6d",
      },
    },
    // Attendance 4Qast Dashboard
    attendance4QastDashboard: {
      // Report Pending
      industryAgnostic: {
        reportId: "",
      },
    },
  },
  // Renewals Optimizer
  renewalsOptimizer: {
    // Renewals Estimator
    renewalsEstimator: {
      employeeBenefits: {
        reportId: "3b363d82-d3c0-4094-9dc8-99cb9a7881e7",
      },
      commercialLines: {
        reportId: "2a06d3da-b74b-4a35-9030-fb6d3abb6f98",
      },
    },
    // Target List
    targetList: {
      employeeBenefits: {
        reportId: "b31c2834-394b-4b6e-859e-6a557e772624",
      },
      commercialLines: {
        reportId: "4eeb367d-4e52-40a2-b77e-d827ffb7d408",
      },
    },
    // Renewals Dashboard
    renewalsDashboard: {
      employeeBenefits: {
        reportId: "6cb52c38-24a7-49fb-a47a-001dc63fb7bc",
      },
      commercialLines: {
        reportId: "20b1617b-6dda-45bc-a13b-55af5a1532b1",
      },
    },
    // Pricing & Economics
    pricingEconomics: {
      employeeBenefits: {
        reportId: "",
      },
      commercialLines: {
        reportId: "b509e136-dce2-4102-a9fc-742ce86615e6",
      },
    },
  },
  // returnify
  returnify: {
    // Returns Propensity Dashboard
    returnsPropensityDashbaord: {
      ecommerceConsumerGoods: {
        reportId: "841e3512-a509-444a-ae92-c47ebc9c534d",
      },
    },
  },
  // cartalyze
  cartalyze: {
    crossSellEngine: {
      employeeBenefits: {
        reportId: "3d2b425e-0002-43dc-8e86-0c57e7cffcd2",
      },
    },
    crossSellDashboard: {
      employeeBenefits: {
        reportId: "988d722c-2817-4d4a-a6e2-8bef0ad02f1f",
      },
    },
    pricingOptimizer: {
      ecommerceConsumerGoods: {
        reportId: "7aa67443-6994-4e06-b353-c68fa133fa02",
      },
    },
  },
  // capmod
  capmod: {
    uwPerformanceAnalysis: {
      industryAgnostic: {
        reportId: "ded554dd-8e8f-405c-9335-6bec8e69bc4e",
      },
    },
    nbuwStaffingModel: {
      industryAgnostic: {
        reportId: "6e7b35b7-7d4a-45ff-9965-2d4689fe019f",
        // reportId: "49673274-cff2-4002-985c-203c6bcd2adb",
      },
    },
    itStaffingModel: {
      industryAgnostic: {
        reportId: "e9cd23b5-56a4-4a04-a1df-971bcbdd4c0d",
      },
    },
  },
};

// ***************************************************************  All Excel Models  ***************************************************************

export const excelModels = {
  // CapMod
  capMod: {
    // Operations Transformation Planning
    OperationsTransformationPlanning: {
      industryAgnostic: {
        iframe: (
          <iframe
            title="Transformation Planning"
            width="100%"
            height="800"
            frameBorder="0"
            scrolling="no"
            src="https://spinnakeradmin-my.sharepoint.com/personal/aakash_mishra_spinnakeranalytics_com/_layouts/15/Doc.aspx?sourcedoc={3bc42e6a-35a0-4273-b965-39b13ce91a35}&amp;action=embedview&amp;wdAllowInteractivity=False&amp;AllowTyping=True&amp;ActiveCell='Cover'!A1&amp;wdHideGridlines=True&amp;wdHideHeaders=True&amp;wdDownloadButton=True&amp;wdInConfigurator=True&amp;wdInConfigurator=True&amp;ed1JS=false"
          ></iframe>
        ),
      },
      individualLife: {
        iframe: "",
      },
      employeeBenefits: {
        iframe: "",
      },
      commercialLines: {
        iframe: "",
      },
      personalLines: {
        iframe: "",
      },
    },
  },
  // Sceanrio Planner
  sceanrioPlanner: {
    // Unit Cost
    unitCost: {
      industryAgnostic: {
        iframe: (
          <iframe
            title="Unit Cost"
            width="100%"
            height="900"
            frameBorder="0"
            scrolling="no"
            src="https://spinnakeradmin-my.sharepoint.com/personal/suneet_mehta_spinnakeranalytics_com/_layouts/15/Doc.aspx?sourcedoc={3948c8fa-119a-41c1-8c1e-3b1bfe10370d}&amp;action=embedview&amp;wdAllowInteractivity=False&amp;ActiveCell='UC%20Forecast%20'!B3&amp;wdHideGridlines=True&amp;wdHideHeaders=True&amp;wdDownloadButton=True&amp;wdInConfigurator=True&amp;wdInConfigurator=True&amp;edesNext=true&amp;resen=false"
          ></iframe>
        ),
      },
    },
    // Zero Based Budger
    zeroBasedBudger: {
      industryAgnostic: {
        iframe: (
          <iframe
            title="Zero Based Budget"
            width="100%"
            height="800"
            frameBorder="0"
            scrolling="no"
            src="https://spinnakeradmin-my.sharepoint.com/personal/suneet_mehta_spinnakeranalytics_com/_layouts/15/Doc.aspx?sourcedoc={76d08c6e-18c7-4242-ba73-c5b48f4c343c}&amp;action=embedview&amp;wdAllowInteractivity=False&amp;ActiveCell='Homepage'!A1&amp;wdHideGridlines=True&amp;wdHideHeaders=True&amp;wdDownloadButton=True&amp;wdInConfigurator=True&amp;wdInConfigurator=True&amp;edesNext=false&amp;resen=false"
          ></iframe>
        ),
      },
    },
  },
};
