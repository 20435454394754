// ********************** Numbers ****************************** //

// Number formmater in compact notation
export const NumberCompactFormatter = (number, digits = 0) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: digits,
    notation: "compact",
    maximumFractionDigits: digits,
  }).format(number ? number : 0);
  return formattedNumber;
};

// Normal Number formatter
export const NumberFormatter = (number, digits = 0) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(number ? number : 0);
  return formattedNumber;
};

// Number Formatter in Thousands
export const NumberFormatterK = (number, digits = 0) => {
  const formattedNumber = (number / 1000).toFixed(digits);
  if (isNaN(formattedNumber)) {
    return "$0K";
  }
  return NumberFormatter(formattedNumber) + "K";
};

// Number Formatter in Millions Only
export const NumberMillionFormatter = (number) => {
  const numberInMillion = (number / 1000000).toFixed(0);
  const formattedNumber = NumberFormatter(numberInMillion);
  return formattedNumber + "M";
};

// ****************** Currency *******************//

// Cuurency formmater in compact notation
export const CurrencyCompactFormatter = (number, digits = 0) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    notation: "compact",
    maximumFractionDigits: digits,
  }).format(number ? number : 0);
  return formattedNumber;
};

// Currency formatter in Million Only
export const CurrencyMillionFormatter = (number) => {
  const numberInMillion = (number / 1000000).toFixed(0);
  const formattedNumber = NumberFormatter(numberInMillion);
  return "$" + formattedNumber + "M";
};

// ********************** Percentage **************************** //

// Percent Formatter % for single digit
export const PercentFormatter1 = (number) => {
  const formattedNumber = (number * 100).toFixed(1) + "%";
  if (formattedNumber === "NaN%") {
    return "0%";
  }
  return formattedNumber;
};

// Percent Formatter % for double digit
export const PercentFormatter2 = (number) => {
  const formattedNumber = (number * 100).toFixed(2) + "%";
  if (formattedNumber === "NaN%") {
    return "0%";
  }
  return formattedNumber;
};
