import React from "react";
import { sicCodes } from "../../data/staticData";

const SicCodeBox = ({ show, sic, setSic }) => {
  return (
    <div
      className={`w-max absolute z-[4]  text-xs shadow-lg rounded-lg overflow-auto text-gray-500 transition-all duration-300 ease-in-out left-[30rem] top-0 ${
        show ? "scale-100" : "scale-0"
      } `}
    >
      <div className="grid grid-cols-2 w-fit border border-t-0 rounded-lg">
        <div className="flex gap-20 text-gray-700 capitalize bg-gray-300 w-full rounded-tl-lg p-1 font-semibold">
          <div className="p-2">Range</div>
          <div className="p-2">Industry</div>
        </div>
        <div className="flex gap-20 text-gray-700 capitalize bg-gray-300 w-full rounded-tr-lg p-1 font-semibold">
          <div className="p-2">Range</div>
          <div className="p-2">Industry</div>
        </div>
        <div className="w-full border-r">
          {Object.entries(sicCodes)
            .splice(0, 9)
            .map(([key, value], index) => (
              <div
                onClick={() => setSic({ sic: value[0], industry: key })}
                className={`flex border-b last:border-b-0 from-gray-600 via-gray-700 to-gray-800 ${
                  sic?.industry === key && "bg-gradient-to-r  text-white"
                } hover:bg-gray-100 cursor-pointer bg-white`}
                key={index}
              >
                <div className="p-2 w-28">
                  {value.length > 1
                    ? value[0] + " - " + value[1]
                    : value[0] + " and above"}
                </div>
                <div className="p-2 w-max">{key}</div>
              </div>
            ))}
        </div>
        <div className="w-fit">
          {Object.entries(sicCodes)
            .splice(9, 20)
            .map(([key, value], index) => (
              <div
                onClick={() => setSic({ sic: value[0], industry: key })}
                className={`flex border-b last:border-b-0 from-gray-600 via-gray-700 to-gray-800 ${
                  sic?.industry === key && "bg-gradient-to-r text-white"
                } hover:bg-gray-100 cursor-pointer bg-white`}
                key={index}
              >
                <div className="p-2 w-28">
                  {value.length > 1
                    ? value[0] + " - " + value[1]
                    : value[0] + " and above"}
                </div>
                <div className="p-2 w-max">{key}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SicCodeBox;
