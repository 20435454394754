import React, { useState } from "react";
import { AllOutputTabs } from "../../../../data/modules/opsTransformationPlanning";

const OutputSidebar = ({
  currentOutputTab,
  setCurrentOutputTab,
  subActiveTab,
  setSubActiveTab,
}) => {
  return (
    <div className="px-3 mt-8 select-none">
      {AllOutputTabs.map((output, index) => (
        <>
          <div
            key={index}
            className={`py-2 px-3 text-xs w-full rounded-md cursor-pointer my-3 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow ${
              currentOutputTab === output.tab
                ? "bg-gradient-to-r text-white"
                : "bg-gray-100 text-gray-500"
            }`}
            onClick={() => {
              setCurrentOutputTab(output.tab);
              if (output?.subtabs?.length >= 1) {
                setSubActiveTab(output?.subtabs[0]);
              }
            }}
          >
            {output.tab}
          </div>
          {output?.subtabs?.map((subtab, id) => (
            <div
              key={id}
              className={`py-2 ml-4 px-3 text-xs rounded-md cursor-pointer my-3 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow ${
                subActiveTab === subtab
                  ? "bg-gradient-to-r text-white"
                  : "bg-gray-50 text-gray-500"
              } ${currentOutputTab === output.tab ? "" : "hidden"}`}
              onClick={() => setSubActiveTab(subtab)}
            >
              {subtab}
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

export default OutputSidebar;
