import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import SelectIndustry from "../../components/modules/SelectIndustry";
import { breadCrumbs, individualLife } from "../../data/data";
import IL_WorkInventory from "../../components/modules/leadPrioritizer/IL_WorkInventory";
import Images from "../../components/modules/Images";
import IL_ScoreCalculator from "../../components/modules/leadPrioritizer/IL_ScoreCalculator";
import UW_Engine from "../../components/modules/uwEngine/UW_Engine";
import { selectedIndustry } from "../../data/staticData";
import CompetitiveLandscape from "../../components/modules/capMod/CompetitiveLandscape";
import PREngine from "../../components/modules/cartalyze/PREngine";
import PredictorTool from "../../components/modules/lapsePredictor/PredictorTool";
import PredictorRenewalTool from "../../components/modules/lapsePredictor/PredictorRenewalTool";
import OpsTransformationPlanning from "../../components/modules/OpsTransformationPlanning/OpsTransformationPlanning";
import TerritoryDesigner from "../../components/modules/territoryDesignTool/TerritoryDesigner";

const IndividualLife = ({ solution }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.modules.map((module) => {
      if (module.components) {
        module.components.map((component) => {
          if (component.slug.substring(1, component.slug.length) === slug) {
            setModules(module);
            setPageData(component);
            return setIsSlugCorrect(true);
          } else {
            return null;
          }
        });
      } else {
        if (module.slug.substring(1, module.slug.length) === slug) {
          setModules(solution);
          setPageData(module);
          return setIsSlugCorrect(true);
        } else {
          return null;
        }
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(
      `${modules?.title === solution?.title ? "" : `${modules?.title}: `} ${
        pageData?.title
      }`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData]);

  return (
    <>
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-2 w-full min-h-[35rem]">
          <div className="flex flex-col md:flex-row gap-5 justify-between">
            <Breadcrumbs crumbs={crumbs} />
            <SelectIndustry
              industries={pageData.industries}
              selected={selectedIndustry(individualLife)}
            />
          </div>
          <div className="my-5">
            <Tabs data={modules} industry={individualLife.slug} />
          </div>
          <div className={`w-full pb-10 h-full min-h-[35rem]`}>
            {/* Sales Prophet Modules */}
            {pageData.title === "Market Overview" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Wallet Share Assessment" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Sales Opportunity" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Agent Performance" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Intelligent Goal Setter" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Sales Funnel" && <Images data={pageData} />}
            {pageData.title === "Territory Design" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Territory Design Tool" && (
              <TerritoryDesigner />
            )}

            {/* Lead Prioritizer Modules */}
            {pageData.title === "Work Inventory" && <IL_WorkInventory />}
            {pageData.title === "Score Calculator" && <IL_ScoreCalculator />}
            {pageData.title === "Attribute Analysis" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Priority Bin Dashboard" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Score Distribution" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}

            {/* UW Engine Modules */}
            {pageData.title === "Engine" && <UW_Engine />}
            {pageData.title === "Underwriting Engine Framework" && (
              <Images data={pageData} />
            )}
            {pageData.title === "Performance Metrics" && (
              <Images data={pageData} />
            )}

            {/* Cartalyze Modules */}
            {pageData.title === "Product Recommendation" && <PREngine />}
            {pageData.title === "Customer Profiler" && (
              <Images data={pageData} />
            )}

            {/* Scenario Planner Modules */}
            {pageData.title === "Unit Cost" &&
              pageData.excelModels.industryAgnostic.iframe}
            {pageData.title === "Zero Based Budget" &&
              pageData.excelModels.industryAgnostic.iframe}

            {/* 4Qast Modules */}
            {pageData.title === "Sales 4Qast" && "Coming Soon!"}
            {pageData.title === "4Qast Dashboard" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "4Qast Drill Down" && (
              <PowerBI reportId={pageData.dashboards.individualLife.reportId} />
            )}
            {pageData.title === "Attendance 4Qast" && "Coming Soon!"}
            {pageData.title === "Attendance 4Qast Dashboard" && "Coming Soon!"}

            {/* CapMod Modules */}
            {pageData.title === "Operations Transformation Planning" && (
              <OpsTransformationPlanning />
            )}
            {/* {pageData.title === "Operations Transformation Planning" &&
              pageData.excelModels.industryAgnostic.iframe} */}
            {pageData.title === "Productivity Charts" && (
              <Images data={pageData} />
            )}
            {pageData.title === "Productivity Comparison" && (
              <Images data={pageData} />
            )}
            {pageData.title === "Latent Capacity" && <Images data={pageData} />}
            {pageData.title === "Payband Productivity" && (
              <Images data={pageData} />
            )}
            {pageData.title === "UW Performance Analysis" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "NBUW Staffing Model" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "IT Staffing Model" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "Competitive Landscape" && (
              <CompetitiveLandscape />
            )}
            {pageData.title === "Resource Navigator" && "Coming Soon!"}

            {/* Lapse Predictor */}
            {pageData.title === "Renewal List" && <PredictorRenewalTool />}
            {pageData.title === "Lapse Prediction Tool" && <PredictorTool />}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default IndividualLife;
