import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { colourStyles } from "../utils/Functions";
import { createIndustryOptions } from "../../data/staticData";

const SelectIndustry = ({ industries, selected }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChangeSelect = (e) => {
    // console.log(e);
    let path = pathname.split("/");
    // Find and change second last value which is industry level
    path[path.length - 2] = e.value;
    const newPath = path.join("/");
    navigate(newPath);
  };

  return (
    <div className="text-[10px] md:text-xs flex gap-2 items-center">
      <label htmlFor="industry">Select Industry:</label>
      <Select
        styles={colourStyles}
        name="industry"
        options={createIndustryOptions(industries)}
        className="w-60"
        onChange={(e) => onChangeSelect(e)}
        defaultValue={selected}
        classNamePrefix={"industry"}
      />
    </div>
  );
};

export default SelectIndustry;
