import React, { useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { FaServer } from "react-icons/fa6";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

const RadarChartLapse = ({ data, module }) => {
  const chartRef = useRef();
  const hideRef = useRef();
  const [showExport, setShowExport] = useState(false);

  let options = {};
  let action = "";
  if (data) {
    action = data[0]["action"];
    let color = "";
    if (action === "Low") {
      color = "#22C55E";
    } else if (action === "Normal") {
      color = "#F59E0b";
    } else if (action === "High") {
      color = "#EF4444";
    } else {
      color = "#ccc";
    }

    options = {
      series: [
        {
          name: "Percentile",
          data: Object.values(data[1]).map((item) => Math.ceil(item * 100)),
        },
      ],

      options: {
        chart: {
          fontFamily: "Helvetica, Arial, sans-serif",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radar: {
            size: 165,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        title: {
          text: "Percentile Contribution Chart",
        },
        colors: [color],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: color,
          strokeWidth: 2,
        },
        tooltip: {
          enabled: false,
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          // categories: [],
          categories: Object.keys(data[1]),
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
          min: 0,
          max: 100,
        },
      },
    };
  }
  // console.log("Chart Data", data);

  // Export Chart PDF & PNG
  const exportChart = async (mode) => {
    const hide = hideRef.current;
    try {
      hide.classList.add("hide-export");
      const scale = 2; // increase scale for better quality
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: chartRef.current.offsetWidth + "px",
        height: chartRef.current.offsetHeight + "px",
      };

      // Save as PDF
      if (mode === "pdf") {
        const blob = await domtoimage.toBlob(chartRef.current, {
          width: chartRef.current.offsetWidth * scale,
          height: chartRef.current.offsetHeight * scale,
          style,
        });
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        img.onload = () => {
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [img.width, img.height],
          });
          pdf.addImage(img, "PNG", 0, 0, img.width, img.height);
          pdf.save(`Percentile Contribution Chart ${Date.now()}.pdf`);
          URL.revokeObjectURL(img.src);
        };
      }

      // Save as Image
      if (mode === "png") {
        const dataUrl = await domtoimage.toPng(chartRef.current, {
          width: chartRef.current.offsetWidth * scale,
          height: chartRef.current.offsetHeight * scale,
          style,
        });
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Percentile Contribution Chart ${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error exporting chart:", error);
    } finally {
      hide.classList.remove("hide-export");
      setShowExport(false);
    }
  };

  return (
    data && (
      <div className="" ref={chartRef}>
        {/* Box with Lapse Predictor data */}
        {module === "Lapse Predictor" && (
          <div
            className={`font-semibold flex gap-2 w-full items-center py-3 px-5 border-b-2 ${
              action === "Low" && "text-green-600"
            } ${action === "Normal" && "text-amber-500"} ${
              action === "High" && "text-red-500"
            } `}
          >
            <div className="p-2 px-5 border shadow-lg rounded-lg w-2/6 flex flex-col">
              <span className="text-gray-500 font-normal text-xs">
                Lapse Score:
              </span>{" "}
              {`${data[0]["score"]} (${action})`}
            </div>
            <div className="p-2 px-5 border shadow-lg rounded-lg w-2/6 flex flex-col">
              <span className="text-gray-500 font-normal text-xs">
                Suggested Action:
              </span>{" "}
              {data[0]["suggested_action"]}
            </div>
            <div className="p-2 px-5 rounded-lg text-xs text-gray-500 w-3/6">
              <span className="text-red-500 font-normal">*</span> Lapse score is
              based on the probability of a policy getting lapsed.
            </div>
          </div>
        )}

        <div className="p-5 rounded-lg mt-5 relative">
          <div ref={hideRef}>
            <button
              type="button"
              onClick={() => setShowExport(!showExport)}
              className="w-24 absolute z-[1] right-5 text-primary flex gap-2 items-center justify-center border-2 h-fit px-4 py-2 rounded-md text-xs cursor-pointer border-primary hover:bg-primary hover:text-white duration-200 ease-in-out"
            >
              <FaServer className="text-base" />
              Export
            </button>
            {showExport && (
              <div className="flex flex-col text-center border rounded-md w-24 absolute z-[1] overflow-auto right-5 text-xs top-14 mt-1 shadow-md bg-white justify-start items-start">
                {/* Export Table */}
                <button
                  className="px-3 py-2 border-b w-full hover:bg-tertiary hover:text-white duration-300 ease-in-out"
                  onClick={() => exportChart("png")}
                  type="button"
                >
                  PNG
                </button>
                <button
                  className="px-3 py-2 w-full hover:bg-tertiary hover:text-white duration-300 ease-in-out"
                  onClick={() => exportChart("pdf")}
                  type="button"
                >
                  PDF
                </button>
              </div>
            )}
          </div>
          <ReactApexChart
            series={options.series}
            options={options.options}
            type="radar"
            height="400"
            width="100%"
          />
        </div>
      </div>
    )
  );
};

export default RadarChartLapse;
