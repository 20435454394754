import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { breadCrumbs, industries } from "../data/data";
import { UserContext } from "../context/userContext";
import Breadcrumbs from "../components/account/Breadcrumbs";
import NotFound from "./NotFound";
import IndustryContent from "../components/home/IndustryContent";
import {
  onClickCloseEvent,
  onClickOpenEvent,
} from "../components/utils/Functions";

const IndustryPage = () => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [storedData, setStoredData] = useState(null);
  const [element, setElement] = useState(null);

  useEffect(() => {
    industries.map((industry) => {
      if (industry.slug.substring(1, industry.slug.length) === slug) {
        setPageData(industry);
        return setIsSlugCorrect(true);
      } else {
        return null;
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(`Industry: ${pageData?.title}`);
    setCrumbs([...breadCrumbs({ title: pageData?.title })]);
  }, [pageData]);

  return (
    <>
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-2 w-full min-h-[35rem]">
          <div className="flex justify-between">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          <div className="my-5"></div>
          <div className={`w-full pb-10 h-full min-h-[35rem]`}>
            {pageData?.solutions ? (
              <IndustryContent
                data={pageData}
                storedData={storedData}
                element={element}
                setStoredData={setStoredData}
                setElement={setElement}
                onClickOpenEvent={onClickOpenEvent}
                onClickCloseEvent={onClickCloseEvent}
              />
            ) : (
              <div className="py-10 xl:px-5 flex flex-col gap-5 h-full">
                No solutions yet..
              </div>
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default IndustryPage;
