import React from "react";
import { Link } from "react-router-dom";

const HomeContent = ({ data }) => {
  return (
    <div className="p-2">
      {/* Category Items */}
      <div className="grid grid-cols-2 md:flex md:flex-wrap gap-5 items-start">
        {/* Items blocks */}
        {data?.map((item, index) => (
          <Link
            key={index}
            to={item.slug}
            className={`relative border rounded-lg shadow-sm w-fit md:w-[14rem] flex flex-col cursor-pointer bg-gray-50 text-gray-700 h-full gap-2 items-center text-center text-[10px] text-xs px-2 py-4 xl:p-4 hover:bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800 hover:text-white`}
          >
            <span className="text-3xl xl:text-4xl 2xl:text-5xl">
              {item.icon}
            </span>
            <span>{item.title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomeContent;
