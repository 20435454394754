// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards, excelModels } from "./dashboards";
import {
  FaChalkboardTeacher,
  FaGlobeAmericas,
  FaPercent,
} from "react-icons/fa";
import {
  FaBuildingShield,
  FaCalculator,
  FaDollarSign,
  FaHandHoldingDollar,
  FaMagnifyingGlassDollar,
  FaPeopleGroup,
  FaRecycle,
  FaTruckFast,
  FaUserShield,
  FaUserTie,
  FaRetweet,
  FaGears,
  FaWrench,
  FaGear,
  FaMap,
  FaTable,
  FaScrewdriverWrench,
  FaChartColumn,
  FaChartLine,
  FaUsersGear,
  FaMagnifyingGlassChart,
  FaWallet,
  FaPerson,
  FaDatabase,
  FaArrowTrendDown,
  FaList,
  FaRegAddressCard,
  FaCompassDrafting,
} from "react-icons/fa6";
import {
  GiMoneyStack,
  GiMountainClimbing,
  GiPriceTag,
  GiShieldEchoes,
  GiTakeMyMoney,
} from "react-icons/gi";
import {
  IoBarChart,
  IoBarChartOutline,
  IoSpeedometerOutline,
} from "react-icons/io5";
import {
  MdCurrencyExchange,
  MdOutlineSsidChart,
  MdPriceCheck,
} from "react-icons/md";
import { RiBankLine, RiShieldUserLine, RiUserSearchLine } from "react-icons/ri";
import { GoHomeFill } from "react-icons/go";
import {
  TbFilterDollar,
  TbHomeShield,
  TbMapSearch,
  TbShoppingCartSearch,
  TbTransform,
} from "react-icons/tb";
import { LuGoal, LuLayoutDashboard, LuShoppingCart } from "react-icons/lu";
import { HiMiniUserGroup } from "react-icons/hi2";
import { GiNotebook } from "react-icons/gi";
import { FiTarget } from "react-icons/fi";
import { RxLoop } from "react-icons/rx";
import { LiaCartArrowDownSolid } from "react-icons/lia";

// ***************************************************************  Image Import variables  ***************************************************************
// Sales Prophet
const salesfunnel_1 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/sales-prophet/salesfunnel_1.webp";
const salesfunnel_2 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/sales-prophet/salesfunnel_2.webp";

// UW Engine
const uwengine_framework =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/uw-engine/uwEngineFramework.webp";
const uwengine_performance_metrics_1 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/uw-engine/output_probability_distribution.webp";
const uwengine_performance_metrics_2 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/uw-engine/testing_probability_distribution.webp";
const uwengine_performance_metrics_3 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/uw-engine/cross_validation_8var.webp";
const uwengine_performance_metrics_4 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/uw-engine/cross_validation_6var.webp";

// Commission Intelligence
const CI_iterative_approach_retail =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/commission-intelligence/iterative_approach-retail.webp";
const CI_iterative_approach_wholesale =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/commission-intelligence/iterative_approach-retail.webp";
const CI_wholesale_opportunity =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/commission-intelligence/wholesale-opportunity.webp";

// Capmod
const CM_productivity_charts =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/capmod/productivity_charts.webp";
const CM_productivity_comparison =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/capmod/productivity_comparison.webp";
const CM_latent_capacity =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/capmod/latent_capacity.webp";
const CM_payband_productivity =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/capmod/payband_productivity.webp";

// Cartalyze
const Cartalyze_customer_profiler =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/cartalyze/customer-profile.webp";

// ***************************************************************   All Industries  ***************************************************************
export const individualLife = {
  title: "Individual Life",
  slug: "/individual-life",
  description: "",
  name: "individualLife",
  icon: <RiShieldUserLine />,
};

export const propertyCasualty = {
  title: "Property & Casualty",
  slug: "/property-and-casualty",
  description: "",
  name: "propertyCasualty",
  icon: <FaBuildingShield />,
};

export const personalLines = {
  title: "Personal Lines - P&C",
  slug: "/personal-lines-pnc",
  description: "",
  name: "personalLines",
  icon: <TbHomeShield />,
};

export const commercialLines = {
  title: "Commercial Lines - P&C",
  slug: "/commercial-lines-pnc",
  description: "",
  name: "commercialLines",
  icon: <FaBuildingShield />,
};

export const employeeBenefits = {
  title: "Employee Benefits",
  slug: "/employee-benefits",
  description: "",
  name: "employeeBenefits",
  icon: <FaUserShield />,
};

export const annuity = {
  title: "Annuity",
  slug: "/annuity",
  description: "",
  name: "annuity",
  icon: <GiTakeMyMoney />,
};

export const brokers = {
  title: "Brokers",
  slug: "/brokers",
  description: "",
  name: "brokers",
  icon: <FaUserTie />,
};

export const retailBrokers = {
  title: "Retail Brokers",
  slug: "/retail-brokers",
  description: "",
  name: "retailBrokers",
  icon: <FaUserTie />,
};

export const wholesaleBrokers = {
  title: "Wholesale Brokers",
  slug: "/wholesale-brokers",
  description: "",
  name: "wholeSaleBrokers",
  icon: <FaUserTie />,
};

export const wealthManagement = {
  title: "Wealth Management",
  slug: "/wealth-management",
  description: "",
  name: "wealthManagement",
  icon: <FaHandHoldingDollar />,
};

export const banking = {
  title: "Banking",
  slug: "/banking",
  description: "",
  name: "banking",
  icon: <RiBankLine />,
};

export const reinsurance = {
  title: "Reinsurance",
  slug: "/reinsurance",
  description: "",
  name: "reinsurance",
  icon: <GiShieldEchoes />,
};

export const ecommerceConsumerGoods = {
  title: "E-Commerce & Consumer Goods",
  slug: "/ecommerce-and-consumer-goods",
  description: "",
  name: "ecommerceConsumerGoods",
  icon: <LuShoppingCart />,
};

export const supplementalHealth = {
  title: "Supplemental Health",
  slug: "/supplemental-health",
  description: "",
  name: "supplementalHealth",
  icon: "",
};

export const worksite = {
  title: "Worksite",
  slug: "/worksite",
  description: "",
  name: "worksite",
  icon: "",
};

// ***************************************************************   All Solutions  ***************************************************************
// Sales Prophet™
export const salesProphet = {
  title: "Sales Prophet™",
  slug: "/sales-prophet",
  description:
    "Accurately estimate market size, identify client-specific sales opportunities, and strategically allocate sales goals across regions, products, and customer segments",
  icon: <FaGlobeAmericas />,
  domains: ["Distribution"],
  modules: [
    {
      title: "Market Opportunity Generator (MOG)",
      icon: <FaGlobeAmericas />,
      slug: "/sales-prophet",
      description:
        "Estimate granular market size and analyze client performance to develop sales potential at product, demographic, and zip-code levels",
      components: [
        {
          title: "Market Overview",
          icon: <FaGlobeAmericas />,
          slug: "/market-overview",
          description: "",
          industries: [
            individualLife,
            annuity,
            worksite,
            employeeBenefits,
            wealthManagement,
          ],
          dashboards: { ...dashboards.salesProphet.marketOverview },
        },
        {
          title: "Wallet Share Assessment",
          icon: <FaWallet />,
          slug: "/wallet-share-assessment",
          description: "",
          industries: [individualLife],
          dashboards: { ...dashboards.salesProphet.walletShareAssessment },
        },
        {
          title: "Sales Opportunity",
          icon: <FaMagnifyingGlassChart />,
          slug: "/sales-opportunity",
          description: "",
          industries: [individualLife],
          dashboards: { ...dashboards.salesProphet.salesOpportunity },
        },
        {
          title: "Agent Performance",
          icon: <FaUserTie />,
          slug: "/agent-performance",
          description:
            "Drill-down analysis to assess agent recruiting and productivity improvement potential",
          industries: [individualLife],
          dashboards: { ...dashboards.salesProphet.agentPerformance },
        },
      ],
    },
    {
      title: "Sales Planning",
      icon: <GiNotebook />,
      slug: "/sales-prophet",
      description:
        "Automatically set and assign sales goals and expense allowance by territory, product, and customer/size segments",
      components: [
        {
          title: "Intelligent Goal Setter",
          icon: <LuGoal />,
          slug: "/intelligent-goal-setter",
          description: "",
          industries: [individualLife, employeeBenefits, propertyCasualty],
          dashboards: { ...dashboards.salesProphet.intelligentGoalSetter },
        },
        {
          title: "Sales Funnel",
          icon: <TbFilterDollar />,
          slug: "/sales-funnel",
          description: "",
          images: [salesfunnel_1, salesfunnel_2],
          industries: [
            individualLife,
            annuity,
            propertyCasualty,
            employeeBenefits,
            reinsurance,
            brokers,
            wealthManagement,
          ],
        },
      ],
    },
    {
      title: "Territory Design",
      icon: <FaMap />,
      slug: "/territory-design",
      description:
        "Develop sales territories, sales organization structure, cost implications, and staffing recommendations based on market opportunities and organizational strategic goals",
      industries: [individualLife],
      dashboards: { ...dashboards.salesProphet.territoryDesign },
    },
    {
      title: "Territory Design Tool",
      icon: <FaCompassDrafting />,
      slug: "/territory-design-tool",
      description:
        "Dynamic, interactive territory visualization and design tool",
      industries: [individualLife],
    },
    {
      title: "Broker Score Card",
      slug: "/broker-score-card",
      description:
        "Displays key broker metrics, such as premiums, claims, policies, five-year aggregates, persistency rates, renewals, and more. Additionally, it includes a comprehensive client list for detailed insights.",
      dashboards: { ...dashboards.salesProphet.brokerScoreCard },
      icon: <FaRegAddressCard />,
      industries: [employeeBenefits],
    },
  ],
};

// Lead Prioritizer™
export const leadPrioritizer = {
  title: "Lead Prioritizer™",
  slug: "/lead-prioritizer",
  description:
    "Utilize pattern recognition algorithms to prioritize applications or work volumes, maximizing yield rates, optimizing workflow, and reducing unit costs",
  icon: <FaChalkboardTeacher />,
  domains: ["Underwriting"],
  modules: [
    {
      title: "Work Inventory",
      icon: <FaTable />,
      slug: "/work-inventory",
      description:
        "Create a comprehensive inventory of work items at the case level, detailing various case attributes and case scores",
      industries: [
        individualLife,
        employeeBenefits,
        commercialLines,
        personalLines,
      ],
    },
    {
      title: "Score Calculator",
      icon: <FaCalculator />,
      slug: "/score-calculator",
      description:
        "Instantly generate a real-time case score based on customer characteristics",
      industries: [
        individualLife,
        employeeBenefits,
        commercialLines,
        personalLines,
      ],
    },
    {
      title: "Attribute Analysis",
      icon: <FaScrewdriverWrench />,
      slug: "/attribute-analysis",
      description:
        "Provide insightful analysis by industry, product, geography, brokers, and combination of all factors",
      industries: [
        individualLife,
        employeeBenefits,
        commercialLines,
        personalLines,
      ],
      dashboards: { ...dashboards.leadPrioritizer.attributeAnalysis },
    },
    {
      title: "Priority Bin Dashboard",
      icon: <LuLayoutDashboard />,
      slug: "/priority-bin-dashboard",
      description:
        "Visualize sales activities with dashboards featuring priority status, total cases, and overall sales",
      industries: [individualLife, commercialLines, personalLines],
      dashboards: { ...dashboards.leadPrioritizer.priorityBinDashboard },
    },
    {
      title: "Score Distribution",
      icon: <FaChartColumn />,
      slug: "/score-distribution",
      description:
        "Explore case score distribution across products, brokers, underwriters, and other attributes",
      industries: [individualLife, employeeBenefits],
      dashboards: { ...dashboards.leadPrioritizer.scoreDistribution },
    },
  ],
};

// 4Qast™
export const forecast = {
  title: "4Qast™",
  slug: "/4qast",
  description:
    "Analyze historical data and apply statistical techniques to predict future results accurately Identify key trends and variance drivers for proactive decision-making",
  icon: <IoBarChart />,
  modules: [
    // {
    //   title: "Sales 4Qast",
    //   icon: <FaChartLine />,
    //   slug: "/sales-4qast",
    //   description:
    //     "Dynamically forecast sales and volumes with precision, considering business segments, product lines, geography, channels, and other crucial attributes",
    //   industries: [individualLife, ecommerceConsumerGoods, supplementalHealth],
    // },
    {
      title: "4Qast Dashboard",
      icon: <LuLayoutDashboard />,
      slug: "/4qast-dashboard",
      description:
        "Analyze various attributes of the forecast to gain insights into the factors influencing forecast behavior",
      industries: [
        individualLife,
        ecommerceConsumerGoods,
        supplementalHealth,
        commercialLines,
        personalLines,
      ],
      dashboards: { ...dashboards.forecast.sales4QastDashboard },
    },
    {
      title: "4Qast Drill Down",
      icon: <FaMagnifyingGlassChart />,
      slug: "/4qast-drill-down",
      description:
        "Drill down a level deeper to analyze factors influencing forecast behavior based on product, segment, lives, region, etc",
      industries: [
        individualLife,
        ecommerceConsumerGoods,
        supplementalHealth,
        commercialLines,
        personalLines,
      ],
      dashboards: { ...dashboards.forecast.sales4QastDrilldown },
    },
    // {
    //   title: "Attendance 4Qast",
    //   icon: <FaPerson />,
    //   slug: "/attendance-4qast",
    //   description:
    //     "Accurately and dynamically forecast attendance across business segments, product lines, geography, channels, and other key attributes",
    //   industries: [
    //     individualLife,
    //     annuity,
    //     propertyCasualty,
    //     employeeBenefits,
    //     reinsurance,
    //     brokers,
    //     wealthManagement,
    //     ecommerceConsumerGoods,
    //   ],
    // },
    // {
    //   title: "Attendance 4Qast Dashboard",
    //   icon: <LuLayoutDashboard />,
    //   slug: "/attendance-4qast-dashboard",
    //   description:
    //     "Analyze different attributes of the attendance forecast to discover insights about the factors influencing forecast behavior",
    //   industries: [
    //     individualLife,
    //     annuity,
    //     propertyCasualty,
    //     employeeBenefits,
    //     reinsurance,
    //     brokers,
    //     wealthManagement,
    //     ecommerceConsumerGoods,
    //   ],
    //   dashboards: { ...dashboards.forecast.attendance4QastDashboard },
    // },
  ],
};

// CapMod™
export const capMod = {
  title: "CapMod™",
  slug: "/capmod",
  description:
    "Optimally align skill sets and determine staffing requirements by function, considering factors such as work complexity, seasonality, and cross-training",
  icon: <FaPeopleGroup />,
  domains: ["Operations", "Claims", "IT", "Underwriting"],
  modules: [
    // {
    //   title: "Operations Staffing Model",
    //   icon: <FaUsersGear />,
    //   slug: "/operations-staffing-model",
    //   description:
    //     "Forecast future staffing needs based on historical productivity, staffing patterns, work complexity, and volume forecasting Strategize hiring and cross-training for effective workforce management",
    //   industries: [commercialLines],
    // },
    {
      title: "Operations Transformation Planning",
      icon: <TbTransform />,
      slug: "/operations-transformation-planning",
      description:
        "Build staffing scenarios for automation, offshoring, and projected growth rates Utilize stochastic and business plan forecasts for volumes, making decisions through cost-benefit analysis",
      industries: [
        // individualLife,
        // employeeBenefits,
        commercialLines,
        // personalLines,
      ],
      excelModels: {
        ...excelModels.capMod.OperationsTransformationPlanning,
      },
    },
    {
      title: "Claims Staffing Model",
      icon: <HiMiniUserGroup />,
      slug: "/capmod",
      description:
        "Forecast future staffing needs based on historical productivity, staffing patterns, work complexity, volume forecasting, and seasonality Develop strategic plans for hiring and cross-training",
      components: [
        {
          title: "Productivity Charts",
          icon: <IoBarChartOutline />,
          slug: "/productivity-charts",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
          images: [CM_productivity_charts],
        },
        {
          title: "Productivity Comparison",
          icon: <HiMiniUserGroup />,
          slug: "/productivity-comparison",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
          images: [CM_productivity_comparison],
        },
        {
          title: "Latent Capacity",
          icon: <GiMountainClimbing className="rotate-90" />,
          slug: "/latent-capacity",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
          images: [CM_latent_capacity],
        },
        {
          title: "Payband Productivity",
          icon: <GiMoneyStack />,
          slug: "/payband-productivity",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
          images: [CM_payband_productivity],
        },
      ],
    },
    {
      title: "NBUW Staffing Model",
      icon: <FaPeopleGroup />,
      slug: "/nbuw-staffing-model",
      description:
        "Analyze historical work volumes, staffing levels, and productivity to forecast future volumes and staffing needs for robust decision making.",
      dashboards: { ...dashboards.capmod.nbuwStaffingModel.industryAgnostic },
      industries: [
        individualLife,
        employeeBenefits,
        commercialLines,
        personalLines,
      ],
    },
    {
      title: "IT Staffing Model",
      icon: <FaDatabase />,
      slug: "/it-staffing-model",
      description:
        "Map skill sets to staff, project and visualize current utilization by project and skill set. Optimizes utilization by resequencing projects to ensure the most effective use of staff and skillsets.",
      dashboards: { ...dashboards.capmod.itStaffingModel.industryAgnostic },
      industries: [
        individualLife,
        annuity,
        employeeBenefits,
        commercialLines,
        personalLines,
        brokers,
      ],
    },
    {
      title: "Productivity Assessment",
      icon: <FaChartLine />,
      slug: "/capmod",
      description:
        "Utilize cohort analytics to identify productivity drivers and establish targeted productivity levels",
      components: [
        {
          title: "UW Performance Analysis",
          icon: <FaChartLine />,
          slug: "/uw-performance-analysis",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
          dashboards: {
            ...dashboards.capmod.uwPerformanceAnalysis.industryAgnostic,
          },
        },
        {
          title: "Competitive Landscape",
          icon: <MdOutlineSsidChart />,
          slug: "/competitive-landscape",
          description: "",
          industries: [
            individualLife,
            employeeBenefits,
            commercialLines,
            personalLines,
          ],
        },
      ],
    },
    // {
    //   title: "Resource Navigator",
    //   icon: <TbNavigationSearch />,
    //   slug: "/resource-navigator",
    //   description:
    //     "Analyze actual costs throughout the complete lifecycle of the product/commodity Identify cost and resource drivers at each stage for comprehensive lifecycle cost optimization",
    //   industries: [
    //     individualLife,
    //     employeeBenefits,
    //     commercialLines,
    //     personalLines,
    //   ],
    // },
  ],
};

// Returnify™
export const returnify = {
  title: "Returnify™",
  slug: "/returnify",
  description:
    "Predict product return propensity using historical data, uncover omni-channel opportunities, and identify future store locations with sales potential",
  icon: <MdCurrencyExchange />,
  domains: ["New Business"],
  modules: [
    {
      title: "Returns Propensity Score Calculator",
      icon: <FaCalculator />,
      slug: "/returns-propensity-score-calculator",
      description:
        "Develop real-time transaction return probabilities based on customer behavior and transaction attributes",
      industries: [ecommerceConsumerGoods],
    },
    {
      title: "Returns Propensity Dashboard",
      icon: <LuLayoutDashboard />,
      slug: "/returns-propensity-dashboard",
      description:
        "Visualize customer behavior across different channels to identify patterns in returns",
      industries: [ecommerceConsumerGoods],
      dashboards: { ...dashboards.returnify.returnsPropensityDashbaord },
    },
  ],
};

// Cartalyze™
export const cartalyze = {
  title: "Cartalyze™",
  slug: "/cartalyze",
  description:
    "Receive automatic product recommendations tailored to various demographics and life stages Design targeted pricing actions and promotional campaigns to enhance profitability and elevate marketing strategies",
  icon: <GiPriceTag />,
  modules: [
    {
      title: "Product Recommendation",
      icon: <TbShoppingCartSearch />,
      slug: "/product-recommendation",
      description:
        "Automatically suggest products and solutions tailored to individual life stages and priorities, aligned with demographic attributes",
      industries: [individualLife],
      // industries: [individualLife, ecommerceConsumerGoods],
    },
    {
      title: "Cross-sell Analyzer",
      icon: <LiaCartArrowDownSolid />,
      slug: "/cartalyze",
      description:
        "Leverage AI algorithms to intelligently suggest cross-sell and up-sell opportunities to existing customers",
      components: [
        {
          title: "Cross-sell Engine",
          icon: <LiaCartArrowDownSolid />,
          slug: "/cross-sell-engine",
          description: "",
          industries: [employeeBenefits],
          dashboards: { ...dashboards.cartalyze.crossSellEngine },
        },
        {
          title: "Cross-sell Dashboard",
          icon: <LuLayoutDashboard />,
          slug: "/cross-sell-dashboard",
          description: "",
          industries: [employeeBenefits],
          dashboards: { ...dashboards.cartalyze.crossSellDashboard },
        },
      ],
    },
    {
      title: "Pricing Optimizer",
      icon: <MdPriceCheck />,
      slug: "/pricing-optimizer",
      description:
        "Design targeted pricing actions and impactful promotional campaigns to increase profitability",
      industries: [ecommerceConsumerGoods],
      dashboards: { ...dashboards.cartalyze.pricingOptimizer },
    },
    {
      title: "Customer Profiler",
      icon: <RiUserSearchLine />,
      slug: "/customer-profiler",
      description:
        "Analyze buyer behavior patterns, identify target segments, develop personalized product recommendations, and pinpoint retention drivers",
      industries: [individualLife],
      images: [Cartalyze_customer_profiler],
    },
    // {
    //   title: "Store Site Identifier",
    //   icon: <TbMapSearch />,
    //   slug: "/store-site-identifier",
    //   description:
    //     "Identify and recommend optimal placements for new brick-and-mortar store locations",
    //   industries: [ecommerceConsumerGoods],
    // },
  ],
};

// Commission Intelligence
export const commissionOpt = (industry = null) => {
  return {
    title: "Commission Intelligence",
    slug: "/commission-intelligence",
    description:
      "Optimize commission structures by analyzing contracts and scenarios for both retail and wholesale brokerage",
    icon: <FaPercent />,
    domains: ["Broker"],
    modules: [
      // {
      //   title: "Commission Tool",
      //   icon: <FaCalculator />,
      //   slug: "/commission-tool",
      //   description:
      //     "Efficiently adjust contingent commissions across all carriers, ensuring ongoing refinement with each new contract",
      //   industries: [retailBrokers],
      // },
      {
        title: "Commission Tool",
        icon: <FaCalculator />,
        slug: "/commission-tool",
        description:
          "Efficiently adjust contingent commissions across all carriers with agency level flexibility, ensuring ongoing refinement with each new contract",
        industries: [retailBrokers],
      },
      {
        title: "Wholesale Opportunity",
        icon: <FaTruckFast />,
        slug: "/wholesale-opportunity",
        description:
          "Identify profitability scenarios for brokers in the wholesale business, optimizing potential opportunities",
        industries: [wholesaleBrokers],
        images: [CI_wholesale_opportunity],
      },
      {
        title: "Economic Analysis - Illustration",
        icon: <FaMagnifyingGlassDollar />,
        slug: "/economic-analysis",
        description:
          "Leverage economic analysis to improve profitability for insurance brokers through strategic adjustments",
        industries: [retailBrokers],
      },
      {
        title: "Spinnaker's Iterative Approach",
        icon: <RxLoop />,
        slug: "/spinnakers-iterative-approach",
        description:
          "Employ an iterative approach, keeping commissions optimized through automated data pipelines",
        industries: [retailBrokers, wholesaleBrokers],
        images: [
          industry === retailBrokers.title
            ? CI_iterative_approach_retail
            : industry === wholesaleBrokers.title
            ? CI_iterative_approach_wholesale
            : "",
        ],
      },
    ],
  };
};
export const commissionIntelligence = commissionOpt();
export const commissionOptimizerRetail = commissionOpt(retailBrokers.title);
export const commissionOptimizerWholesale = commissionOpt(
  wholesaleBrokers.title
);

// Renewals Optimizer
export const renewalsOptimizer = (industry = null) => {
  return {
    title: "Renewals Optimizer",
    slug: "/renewals-optimizer",
    description:
      "Enhance profitability by applying targeted renewal actions informed by historical sales and claims experience data",
    icon: <FaRetweet />,
    domains: ["New Business", "Renewals"],
    modules: [
      {
        title:
          (industry === employeeBenefits.title && "RTM Estimator") ||
          (industry === commercialLines.title && "RTF Estimator") ||
          "Renewals Estimator",
        icon: <IoSpeedometerOutline />,
        slug: "/renewals-estimator",
        description:
          "View recommended renewal rates at the sponsor level, with flexibility to adjust rates and assess implications on term rate sensitivity",
        industries: [employeeBenefits, commercialLines],
        dashboards: { ...dashboards.renewalsOptimizer.renewalsEstimator },
      },
      {
        title:
          (industry === employeeBenefits.title && "Target Sponsor List") ||
          (industry === commercialLines.title && "Target Policy Holder List") ||
          "Target List",
        icon: <FiTarget />,
        slug: "/target-list",
        description:
          "Identify persistently unprofitable sponsors for precise and targeted renewal actions",
        industries: [employeeBenefits, commercialLines],
        dashboards: { ...dashboards.renewalsOptimizer.targetList },
      },
      {
        title: "Renewals Dashboard",
        icon: <FaRecycle />,
        slug: "/renewals-dashboard",
        description:
          "Gain a comprehensive view of in-force data up for renewals and historical claims behavior",
        industries: [employeeBenefits, commercialLines],
        dashboards: { ...dashboards.renewalsOptimizer.renewalsDashboard },
      },
      {
        title: "Pricing & Economics",
        icon: <FaDollarSign />,
        slug: "/pricing-and-economics",
        description:
          "Evaluate the profitability implications of improving renewal rates against the formula/manual rate",
        industries: [commercialLines],
        // industries: [employeeBenefits, commercialLines],
        dashboards: { ...dashboards.renewalsOptimizer.pricingEconomics },
      },
    ],
  };
};
export const renewalsOpt = renewalsOptimizer();
export const renewalsOptCL = renewalsOptimizer(commercialLines.title);
export const renewalsOptEB = renewalsOptimizer(employeeBenefits.title);

// Scenario Planner
export const scenarioPlanner = {
  title: "Scenario Planner",
  slug: "/scenario-planner",
  description:
    "Assess and analyze scenarios pertaining to new initiatives to determine impact on financials, staffing and unit costs",
  icon: <FaCalculator />,
  modules: [
    {
      title: "Unit Cost",
      icon: <FaDollarSign />,
      slug: "/unit-cost",
      description:
        "Financial & Operational cost planning tool based to project year-over-year volume, sales and unit cost",
      industries: [
        individualLife,
        personalLines,
        commercialLines,
        employeeBenefits,
        ecommerceConsumerGoods,
      ],
      excelModels: { ...excelModels.sceanrioPlanner.unitCost },
    },
    {
      title: "Zero Based Budget",
      icon: <FaCalculator />,
      slug: "/zero-based-budget",
      description:
        "Establish targets and mechanisms for arriving at the end-state expense structure for ongoing business/entity",
      industries: [
        individualLife,
        personalLines,
        commercialLines,
        employeeBenefits,
        ecommerceConsumerGoods,
      ],
      excelModels: { ...excelModels.sceanrioPlanner.zeroBasedBudger },
    },
  ],
};

// Underwriter Engine
export const uwEngine = {
  title: "UW Engine",
  slug: "/uw-engine",
  description:
    "Efficiently assign risk ratings to prospective life customers purchasing transactional term products by leveraging minimally invasive customer and external data",
  icon: <FaGears />,
  domains: ["Underwriting"],
  modules: [
    {
      title: "Engine",
      icon: <FaCalculator />,
      slug: "/engine",
      description:
        "Produce real-time recommendations based on customer characteristics",
      industries: [individualLife],
    },
    {
      title: "Underwriting Engine Framework",
      icon: <FaWrench />,
      slug: "/underwriting-engine-framework",
      description: "Illustration of the algorithm used to develop the engine",
      industries: [individualLife],
      images: [uwengine_framework],
    },
    {
      title: "Performance Metrics",
      icon: <FaGear />,
      slug: "/performance-metrics",
      description:
        "Metrics designed to compare the model against actual outcomes",
      industries: [individualLife],
      images: [
        uwengine_performance_metrics_1,
        uwengine_performance_metrics_2,
        uwengine_performance_metrics_3,
        uwengine_performance_metrics_4,
      ],
    },
  ],
};

// Lapse Predictor
export const lapsePredictor = {
  title: "Lapse Predictor",
  slug: "/lapse-predictor",
  description: "",
  icon: <FaArrowTrendDown />,
  domains: [""],
  modules: [
    {
      title: "Renewal List",
      icon: <FaList />,
      slug: "/renewal-list",
      description:
        "Identify persistently unprofitable customers for targeted renewal action",
      industries: [
        // individualLife,
        employeeBenefits,
        // commercialLines,
        // personalLines,
      ],
    },
    {
      title: "Lapse Prediction Tool",
      icon: <FaCalculator />,
      slug: "/lapse-predictor-tool",
      description:
        "Identify cases with potential to lapse based on customer attributes",
      industries: [
        // individualLife,
        employeeBenefits,
        // commercialLines,
        // personalLines,
      ],
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Industries in Home  ***************************************************************
export const industries = [
  {
    ...individualLife,
    solutions: [
      salesProphet,
      leadPrioritizer,
      uwEngine,
      forecast,
      capMod,
      cartalyze,
      scenarioPlanner,
    ],
  },
  { ...annuity, solutions: [salesProphet, forecast, capMod] },
  {
    ...personalLines,
    solutions: [leadPrioritizer, forecast, capMod, cartalyze, scenarioPlanner],
  },
  {
    ...commercialLines,
    solutions: [
      salesProphet,
      leadPrioritizer,
      renewalsOpt,
      uwEngine,
      forecast,
      capMod,
      cartalyze,
      scenarioPlanner,
    ],
  },
  {
    ...employeeBenefits,
    solutions: [
      salesProphet,
      leadPrioritizer,
      renewalsOpt,
      uwEngine,
      forecast,
      capMod,
      cartalyze,
      scenarioPlanner,
    ],
  },
  { ...reinsurance, solutions: [cartalyze, scenarioPlanner] },
  {
    ...brokers,
    solutions: [
      commissionIntelligence,
      leadPrioritizer,
      cartalyze,
      scenarioPlanner,
    ],
  },
  {
    ...wealthManagement,
    solutions: [salesProphet, leadPrioritizer, cartalyze, scenarioPlanner],
  },
  // {
  //   ...banking,
  //   soluions: [],
  // },
  {
    ...ecommerceConsumerGoods,
    solutions: [returnify, cartalyze, scenarioPlanner],
  },
];

const industry = [
  individualLife,
  annuity,
  personalLines,
  commercialLines,
  employeeBenefits,
  reinsurance,
  brokers,
  wealthManagement,
  banking,
  ecommerceConsumerGoods,
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const solutions = [
  salesProphet,
  leadPrioritizer,
  uwEngine,
  renewalsOpt,
  commissionIntelligence,
  cartalyze,
  scenarioPlanner,
  forecast,
  capMod,
  returnify,
  lapsePredictor,
];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...solutions,
];
