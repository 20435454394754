import React, { useEffect, useState } from "react";
import API from "../../../api/api";
import Select from "react-select";
import {
  ageBin,
  createOptions,
  getIndustryBySicCode,
  getMonth,
  states,
} from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
import {
  PL_leadAction,
  PL_brokers,
  PL_products,
  PL_SC_formInputs,
} from "../../../data/modules/leadPrioritizer";
import RadarChart from "../../utils/RadarChart";
import { FaBookOpen } from "react-icons/fa6";
import SicCodeBox from "../../utils/SicCodeBox";
import { LuLoader2 } from "react-icons/lu";

const PL_ScoreCalculator = () => {
  const [inputFormData, setInputFormData] = useState(PL_SC_formInputs);
  const [scoreData, setScoreData] = useState(null);
  const [showSic, setShowSic] = useState(false);
  const [sic, setSic] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "sic") {
      let industry = getIndustryBySicCode(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target?.value),
        industry: industry,
      });
      setSic({ sic: e.target?.value, industry: industry });
    } else if (e.target?.name === "age") {
      let value = ageBin(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target?.value),
        ageBin: value,
      });
    } else if (e.target?.name === "month") {
      let value = getMonth(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value,
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target?.value,
      });
    }
  };

  // function to get score
  const getScore = async (e) => {
    e.preventDefault();
    setLoad(true);
    setShowSic(false);

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.values(inputFormData).forEach((value) => {
      if (!value) {
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    // runs only after all input are filled
    await API.post("/LP/PL/score-calculator", inputFormData)
      .then(({ data }) => {
        let scores = data.scores;
        let totalScore = 0;
        let keys = {};

        Object.entries(scores).forEach(([key, value]) => {
          totalScore += value["Score"];
          // console.log(key, value["Score"]);
          keys[key] = value["Percentile Rank"];
        });

        let scoreData = [
          {
            score: totalScore - 1559,
            action: PL_leadAction(totalScore - 1559),
          },
          {
            Age: keys["ageBin"],
            State: keys["state"],
            Product: keys["product"],
            "Received Month": keys["month"],
            Broker: keys["broker"],
            Industry: keys["sic"],
          },
        ];
        setLoad(false);
        setScoreData(scoreData);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  // reset form
  const resetForm = () => {
    setInputFormData(PL_SC_formInputs);
    setScoreData(null);
  };

  // update sic
  useEffect(() => {
    if (sic) {
      setInputFormData({
        ...inputFormData,
        sic: sic.sic,
        industry: sic.industry,
      });
      setShowSic(false);
    }
  }, [sic]);

  return (
    <div className="flex h-max gap-10">
      <div className="w-fit p-7 bg-white rounded-lg shadow border text-xs text-gray-600">
        <h2 className="text-base font-bold mb-10">
          Case Attributes:{" "}
          <span className={inputsFilled ? "text-red-500" : ""}>
            (Insert All Fields)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-10 justify-between">
              {/* Age */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="age">
                  Age:
                </label>
                <input
                  type="text"
                  name="age"
                  id="age"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.age || ""}
                  maxLength={2}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                />
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* State */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="state">
                  State:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="state"
                  options={createOptions(states)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "state")}
                  value={
                    inputFormData.state
                      ? createOptions([inputFormData.state])
                      : null
                  }
                ></Select>
              </div>
              {/* Product Type */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="product">
                  Product:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="product"
                  options={createOptions(PL_products)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "product")}
                  value={
                    inputFormData.product
                      ? createOptions([inputFormData.product])
                      : null
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Broker */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="broker">
                  Broker:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="broker"
                  options={createOptions(PL_brokers)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "broker")}
                  value={
                    inputFormData.broker
                      ? createOptions([inputFormData.broker])
                      : null
                  }
                ></Select>
              </div>
              {/* Month */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="month">
                  Received Month:
                </label>
                <input
                  type="month"
                  name="month"
                  id="month"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  onChange={(e) => handleOnChangeInput(e, "month")}
                />
              </div>
            </div>
            <div className="flex justify-between">
              {/* SIC Code */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="sic">
                  SIC Code:
                </label>
                <input
                  type="text"
                  name="sic"
                  id="sic"
                  onChange={(e) => handleOnChangeInput(e)}
                  onFocus={() => setShowSic(true)}
                  value={inputFormData.sic || ""}
                  maxLength={5}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-40"
                />
              </div>
              {/* Open Code Book */}
              <div className="flex flex-col gap-1 justify-end">
                <div
                  onClick={() => setShowSic(!showSic)}
                  className={`p-2 text-xl border rounded-md hover:bg-gray-500 hover:text-white cursor-pointer ${
                    showSic && "bg-gray-500 text-white"
                  }`}
                >
                  <FaBookOpen />
                </div>
                {/* Open Book */}
                <SicCodeBox show={showSic} setSic={setSic} sic={sic} />
              </div>
              {/* Industry */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="industry">
                  Industry:
                </label>
                <input
                  type="text"
                  name="industry"
                  id="industry"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.industry || ""}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  disabled
                />
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-5 py-2 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Get Score"
                onClick={(e) => getScore(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>
      {/* Output */}
      <div className="min-w-[700px] w-3/5 max-w-[850px] shadow border bg-white rounded-lg">
        <div className="p-3 flex gap-10 items-center justify-center text-center border-b text-xs">
          Range:
          <div className="flex gap-5 font-bold">
            <span className="text-red-500">({"<"}226) DTQ</span>
            <span className="text-amber-500">(227 - 300) Low Priority</span>
            <span className="text-blue-500">(301 - 424) Normal Priority</span>
            <span className="text-green-500">(425+) High Priority</span>
          </div>
        </div>
        {/* Percentile Contribution Chart */}
        {scoreData ? (
          <div className="">
            <RadarChart data={scoreData} module={"Lead Prioritizer"} />
          </div>
        ) : (
          <div className="flex justify-center items-center h-96">
            Output will be displayed here
          </div>
        )}
      </div>
    </div>
  );
};

export default PL_ScoreCalculator;
