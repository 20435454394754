import React from "react";

const Images = ({ data }) => {
  const toggleFullScreenOn = (index) => {
    document.getElementById(index).requestFullscreen();
  };

  const toggleFullScreenOff = () => {
    document.exitFullscreen();
  };

  return (
    <div
      className={`w-full items-center justify-center ${
        data.images?.length <= 1 ? "px-40 flex" : "p-5 grid gap-5 grid-cols-2"
      }`}
    >
      {data.images?.map((image, index) => (
        <div
          key={index}
          onClick={() => toggleFullScreenOn(index)}
          className="cursor-zoom-in"
        >
          <img
            id={index}
            src={image}
            alt={data.title + index}
            className="cursor-zoom-out"
            style={{ cursor: "zoom-out" }}
            onClick={() => toggleFullScreenOff()}
          />
        </div>
      ))}
    </div>
  );
};

export default Images;
