import React from "react";

const ToggleSidebarButton = ({ sidebar, setSidebar, enableOutput }) => {
  return (
    <div className="flex justify-end gap-x-3 text-base mb-4 select-none">
      <p
        onClick={() => setSidebar("input")}
        className={`px-4 py-1 rounded-md cursor-pointer from-gray-600 via-gray-700 to-gray-800 hover:shadow hover:border-gray-300 ease-in-out  hover:bg-gray-100 drop-shadow ${
          sidebar === "input"
            ? "bg-gradient-to-r text-white"
            : "bg-gray-100 text-gray-400"
        }`}
      >
        Input
      </p>
      <p
        onClick={() => (enableOutput ? setSidebar("output") : null)}
        className={`px-4 py-1 rounded-md from-gray-600 via-gray-700 to-gray-800 ease-in-out drop-shadow ${
          enableOutput && sidebar === "output"
            ? "bg-gradient-to-r text-white cursor-pointer hover:bg-gray-100 hover:shadow"
            : enableOutput
            ? "bg-orange-500 bg-opacity-25 text-gray-500 cursor-pointer hover:bg-tertiary hover:text-white hover:shadow"
            : "bg-gray-100"
        } `}
      >
        Output
      </p>
    </div>
  );
};

export default ToggleSidebarButton;
