//============================================================= Individual Life =============================================================

// form inputs
export const IL_Engine_formInputs = {
  age: "",
  ageBin: "",
  height: "",
  weight: "",
  bmi: "",
  bmiBin: "",
  gender: "",
  medicalHistory: "",
  motorViolation: "",
  systolicBP: "",
  SBP_Bin: "",
  DBP_Bin: "",
  diastolicBP: "",
  smoking: "",
  exercise: "",
};

// function to create a bin for systolic blood pressure
export const UW_IL_SBP = (sbp) => {
  let SBP_bin = "";
  if (sbp >= 50 && sbp <= 70) {
    SBP_bin = "50-70";
  } else if (sbp >= 71 && sbp <= 90) {
    SBP_bin = "71-90";
  } else if (sbp >= 91 && sbp <= 110) {
    SBP_bin = "91-110";
  } else if (sbp >= 111 && sbp <= 130) {
    SBP_bin = "111-130";
  } else if (sbp >= 131 && sbp <= 150) {
    SBP_bin = "131-150";
  } else if (sbp >= 151 && sbp <= 170) {
    SBP_bin = "151-170";
  } else {
    SBP_bin = "171-200";
  }

  return SBP_bin;
};

// function to create a bin for diastolic blood pressure
export const UW_IL_DBP = (dbp) => {
  let DBP_bin = "";
  if (dbp >= 0 && dbp <= 30) {
    DBP_bin = "0-30";
  } else if (dbp >= 31 && dbp <= 50) {
    DBP_bin = "31-50";
  } else if (dbp >= 51 && dbp <= 70) {
    DBP_bin = "51-70";
  } else if (dbp >= 71 && dbp <= 90) {
    DBP_bin = "71-90";
  } else if (dbp >= 91 && dbp <= 110) {
    DBP_bin = "91-110";
  } else if (dbp >= 111 && dbp <= 130) {
    DBP_bin = "111-130";
  } else {
    DBP_bin = "131-150";
  }

  return DBP_bin;
};
