import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../components/account/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { breadCrumbs } from "../data/data";

const SolutionPage = ({ solution }) => {
  const { setPageTitle } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    setPageTitle(solution.title);
    // eslint-disable-next-line
  }, [location]);

  return (
    <section className="p-5 pt-2 w-full min-h-[35rem]">
      <Breadcrumbs crumbs={breadCrumbs(solution)} />
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <div className="px-5 md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-full gap-5 transition-all duration-300 ease-in-out">
          {solution.modules.map((module, index) => (
            <Link
              key={index}
              to={
                module?.components
                  ? solution?.slug +
                    module?.components[0]?.industries[0]?.slug +
                    module?.components[0]?.slug
                  : solution?.slug + module?.industries[0]?.slug + module?.slug
              }
              className="w-full cursor-pointer h-full px-5 py-10 flex flex-col gap-2 text-gray-700 border relative bg-gray-50 hover:text-white hover:bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800 rounded-lg shadow-sm"
            >
              <span className="absolute text-4xl top-4 right-5 opacity-10">
                {solution.icon}
              </span>
              <span className="absolute text-3xl top-4 left-5">
                {module.icon}
              </span>
              <div className="flex flex-col gap-2">
                <h3 className="font-semibold text-base mt-5">{module.title}</h3>
              </div>
              <p className="text-xs">{module.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolutionPage;
