import React, { useState } from "react";
import { regions } from "../../../../data/modules/opsTransformationPlanning";
import InputField from "./InputField";
import Select from "react-select";
import { createOptions, selectedOption } from "../../../../data/staticData";
import { colourStyles } from "../../../utils/Functions";

const caseTypes = ["Submissions", "Inforce"];

const VGRFormInput = ({
  VGR_Inputs,
  handlePercentageInputChange,
  handleBackspaceForPercentage,
  targetState,
  defaultValues,
}) => {
  const [selectedCase, setSelectedCase] = useState("Submissions");
  return (
    <>
      <div className="flex items-center justify-between w-full sticky z-[3] top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Y-o-Y Business Volume Growth Rate %</h3>
        <div className="flex items-start gap-5">
          {/* Select Case filter */}
          <div className="flex gap-2 items-center justify-center text-xs">
            <label htmlFor="caseType">Filter by Case Type:</label>
            <Select
              name="caseType"
              options={createOptions(caseTypes)}
              className="w-40 text-xs"
              classNamePrefix={"inventory"}
              styles={colourStyles}
              defaultValue={selectedOption(selectedCase)}
              onChange={(e) => setSelectedCase(e.value)}
            />
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => defaultValues("VGR")}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>
      {/* Submissions */}
      {selectedCase === "Submissions" && (
        <div className="border lg:w-full xl:w-5/6 rounded-md overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-300">
              <tr className="border-b py-2">
                <th className="py-2 border-r" rowSpan={2}>
                  Region
                </th>
                <th className="py-2 border" colSpan={2}>
                  Current growth
                </th>
                <th className="py-2" colSpan={3}>
                  Desired growth
                </th>
              </tr>
              <tr className="border-b">
                <th className="py-2 border-r">2020-2021</th>
                <th className="py-2 border-r">2021-2022</th>
                <th className="py-2 border-r">2022-2023</th>
                <th className="py-2 border-r">2023-2024</th>
                <th className="py-2">2024-2025</th>
              </tr>
            </thead>
            <tbody>
              {regions.map((region, index) => (
                <tr key={index} className="text-center">
                  <td className="px-4 w-44 py-4 border-r">{region}</td>
                  <td className="px-4 w-44 border-r">
                    {VGR_Inputs["2021-2020 Submissions"][index]}%
                  </td>
                  <td className="px-4 w-44 border-r">
                    {VGR_Inputs["2022-2021 Submissions"][index]}%
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType={"percentage"}
                      name={"2023-2022 Submissions"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType={"percentage"}
                      name={"2024-2023 Submissions"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44">
                    <InputField
                      inputType={"percentage"}
                      name={"2025-2024 Submissions"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Inforce */}
      {selectedCase === "Inforce" && (
        <div className="border lg:w-full xl:w-5/6 rounded-md overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-300">
              <tr className="border-b py-2">
                <th className="py-2 border-r" rowSpan={2}>
                  Region
                </th>
                <th className="py-2 border" colSpan={2}>
                  Current growth
                </th>
                <th className="py-2" colSpan={3}>
                  Desired growth
                </th>
              </tr>
              <tr className="border-b">
                <th className="py-2 border-r">2020-2021</th>
                <th className="py-2 border-r">2021-2022</th>
                <th className="py-2 border-r">2022-2023</th>
                <th className="py-2 border-r">2023-2024</th>
                <th className="py-2">2024-2025</th>
              </tr>
            </thead>
            <tbody>
              {regions.map((region, index) => (
                <tr key={index} className="text-center">
                  <td className="px-4 w-44 py-4 border-r">{region}</td>
                  <td className="px-4 w-44 border-r">
                    {VGR_Inputs["2021-2020 Inforce"][index]}%
                  </td>
                  <td className="px-4 w-44 border-r">
                    {VGR_Inputs["2022-2021 Inforce"][index]}%
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType={"percentage"}
                      name={"2023-2022 Inforce"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44 border-r">
                    <InputField
                      inputType={"percentage"}
                      name={"2024-2023 Inforce"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                  <td className="px-4 w-44">
                    <InputField
                      inputType={"percentage"}
                      name={"2025-2024 Inforce"}
                      index={index}
                      handlePercentageInputChange={handlePercentageInputChange}
                      handleBackspaceForPercentage={
                        handleBackspaceForPercentage
                      }
                      targetState={targetState}
                      currentState={VGR_Inputs}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default VGRFormInput;
