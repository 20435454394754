export const LPT_product_line = [
  "DENTAL",
  "Life",
  "LTD",
  "STD",
  "SUPPLMNT",
  "VISION",
];

export const LPT_coverage = [
  "ACCIDENT",
  "BASICLFE",
  "BASICLTD",
  "BASICSTD",
  "CANCER",
  "CHLDCRTCLILL",
  "CHLDVOLADD",
  "CHLDVOLLFE",
  "CTPFML",
  "DENTALPPO",
  "DENTALPREPD",
  "DEPBASICLFE",
  "EMPADD",
  "EMPCRTCLILL",
  "EMPVOLADD",
  "HOSPITAL",
  "MAPFML",
  "MAXWELL",
  "STDDBL",
  "STDFMLA",
  "STDNYPFL",
  "STDTDB",
  "STDTDI",
  "VISIONBUYUP",
  "VISIONMAJOR",
  "VOLLTD",
  "VOLSTD",
];

export const LPT_coverage_filter = {
  DENTAL: [
    {
      label: "DENTAL 1",
      value: "DENTALPPO",
    },
    {
      label: "DENTAL 2",
      value: "DENTALPREPD",
    },
  ],
  Life: [
    {
      label: "Life 1",
      value: "ACCIDENT",
    },
    {
      label: "Life 2",
      value: "BASICLFE",
    },
    {
      label: "Life 3",
      value: "CANCER",
    },
    {
      label: "Life 4",
      value: "CHLDVOLLFE",
    },
    {
      label: "Life 5",
      value: "DEPBASICLFE",
    },
    {
      label: "Life 6",
      value: "HOSPITAL",
    },
  ],
  LTD: [
    {
      label: "LTD 1",
      value: "BASICLTD",
    },
    {
      label: "LTD 2",
      value: "VOLLTD",
    },
    {
      label: "LTD 3",
      value: "CHLDVOLADD",
    },
    {
      label: "LTD 3",
      value: "MAPFML",
    },
    {
      label: "LTD 4",
      value: "CTPFML",
    },
  ],
  STD: [
    {
      label: "STD 1",
      value: "BASICSTD",
    },
    {
      label: "STD 2",
      value: "STDDBL",
    },
    {
      label: "STD 3",
      value: "STDFMLA",
    },
    {
      label: "STD 4",
      value: "STDNYPFL",
    },
    {
      label: "STD 5",
      value: "STDTDB",
    },
    {
      label: "STD 6",
      value: "STDTDI",
    },
    {
      label: "STD 7",
      value: "VOLSTD",
    },
  ],
  VISION: [
    {
      label: "VISION 1",
      value: "VISIONBUYUP",
    },
    {
      label: "VISION 2",
      value: "VISIONMAJOR",
    },
    {
      label: "VISION 3",
      value: "MAXWELL",
    },
  ],
  SUPPLMNT: [
    {
      label: "SUPPLMNT 1",
      value: "MAXWELL",
    },
    {
      label: "SUPPLMNT 2",
      value: "CHLDCRTCLILL",
    },
    {
      label: "SUPPLMNT 3",
      value: "EMPCRTCLILL",
    },
    {
      label: "SUPPLMNT 4",
      value: "EMPADD",
    },
  ],
};

export const LPT_territory = [
  "Eastern Pennsylvania",
  "Florida",
  "Great Lakes",
  "Greater New York",
  "Illinois",
  "Lower Midwest",
  "Mid-Atlantic",
  "Mid-Central",
  "New England",
  "Northern California",
  "Northern Texas",
  "Pacific Northwest",
  "Southeast",
  "Southern California",
  "Southern Texas",
  "Southwest",
  "Upper Midwest",
];

export const LPT_renew_month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LPT_market_segment = ["Small Market", "Middle Market"];

export const LPT_sales_rep = [
  "Albright, Joel",
  "Ausink, Khynna",
  "Baker, Billy Joe",
  "Benner, Grant",
  "Bennis, Michael",
  "Boerner, Stephen",
  "Boulos, Sally",
  "Burckhardt, Eric",
  "Coope, James",
  "Drescher, Justin",
  "Duffy, Lauren",
  "Fishbune, Tyler",
  "Gail, Katie",
  "Ghizzoni, Michael",
  "Ghostlaw, Robert",
  "Hallemann, Emily",
  "Hasling, Joseph",
  "Hershberger, Matt",
  "Horton, Jonathan",
  "Hyatt, Robert",
  "Italiano, Christopher",
  "Leybourn, Jonathan",
  "Margiotti, Lisa",
  "Martell, Patrick",
  "McNulty, Michael",
  "Melanson, Christopher",
  "Mikles, Dylan",
  "Myers, Scott",
  "Naes, Allyson",
  "Nauta, Thomas",
  "Nguyen, Jimmy",
  "Olson, Dan",
  "Orr, Jonathan",
  "Pratt, Adam",
  "Scharl, Kristin",
  "Schumacher, Kirk",
  "Serrano, Gerardo",
  "Shields, Patrick",
  "Smith, Zachary",
  "Techtmann, Michael",
  "Wolff, Grace",
  "Wright, Jeffrey",
  "Weakland, Kyle",
];

export const LPT_sic_category = [
  "Nonclassifiable",
  "Apparel Stores",
  "Oil & Gas Extraction",
  "Physician Offices",
  "Highway & Street Construction",
  "Passenger Transportation",
  "Hotels and Lodging",
  "Hospitals",
  "Auto Dealerships & Gas Stations",
  "Freight Transportation & Warehousing",
  "Food & Apparel Manufacturing",
  "Chemical Manufacturing",
  "Computer Services",
  "Restaurants & Bars",
  "Equipment, Electronics & Heavy Machinery",
  "Other Manufacturing",
  "Justice & Public Safety",
  "Specialty Care Services",
  "Metal & Steel Manufacturing",
  "Timber & Print Manufacturing",
  "Travel Arrangement",
  "Business Services",
  "Libraries, Museums & Art Galleries",
  "Personal Services",
  "Wholesale Trade",
  "Vocational Schools",
  "Rubber, Plastic & Mineral Manufacturing",
  "Metal, Coal, and Mineral Mining",
  "Other Retail",
  "Entertainment & Recreation",
  "Professional Services",
  "Medical Laboratories",
  "Grocery & General Merchandise",
  "Home Furniture & Appliance Stores",
  "Elementary & Secondary Schools",
  "Communications",
  "Agriculture",
  "Electricity, Gas & Sanitation",
  "Financial Institutions",
  "Building Construction",
  "Special Trade Contractors",
  "Holding and Other Investments",
  "Repair Services",
  "Insurance",
  "Social Services",
  "Legal Services",
  "Real Estate",
  "Air Transportation",
  "Federal Programs",
  "Colleges & Universities",
  "Membership Organizations",
  "Government Offices",
  "Hardware & Building Materials",
];

export const CSVData = [
  {
    "Policy Number": 651570,
    "Renewal Rate": 1.041817993,
    "Rate Change": 0.910822825,
    "Loss Ratio": 0.222,
    "A/E Ratio": 0.507229974,
    Lives: 6,
    "Renewal Premium": 2409.6,
    "Product Line": "DENTAL  ",
    Coverage: "DENTALPPO   ",
    Territory: "Florida",
    "Renewal Month": "January",
    "Market Segment": "Small Market",
    "Sales Rep": "Monfred, Dominic",
    "SIC Category": "Membership Organizations",
  },
  {
    "Policy Number": 827585,
    "Renewal Rate": 1.303281715,
    "Rate Change": 0.998585253,
    "Loss Ratio": 0.05954,
    "A/E Ratio": 0.086175811,
    Lives: 18,
    "Renewal Premium": 6352.56,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Northern Texas",
    "Renewal Month": "June",
    "Market Segment": "Small Market",
    "Sales Rep": "Beaulieu, Kenneth",
    "SIC Category": "Professional Services",
  },
  {
    "Policy Number": 800689,
    "Renewal Rate": 1.042397278,
    "Rate Change": 1.0,
    "Loss Ratio": 0.39105,
    "A/E Ratio": 1.231431593,
    Lives: 6,
    "Renewal Premium": 3823.68,
    "Product Line": "DENTAL  ",
    Coverage: "DENTALPPO   ",
    Territory: "Great Lakes",
    "Renewal Month": "February",
    "Market Segment": "Small Market",
    "Sales Rep": "Downey, Brian",
    "SIC Category": "Professional Services",
  },
  {
    "Policy Number": 936501,
    "Renewal Rate": 0.843163539,
    "Rate Change": 1.0,
    "Loss Ratio": 0.0235,
    "A/E Ratio": 0.044157571,
    Lives: 8,
    "Renewal Premium": 1736.04,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Eastern Pennsylvania",
    "Renewal Month": "March",
    "Market Segment": "Small Market",
    "Sales Rep": "Dickinson, Johnathan",
    "SIC Category": "Other Retail",
  },
  {
    "Policy Number": 751786,
    "Renewal Rate": 1.065956426,
    "Rate Change": 1.035242443,
    "Loss Ratio": 0.208233333,
    "A/E Ratio": 0.508204237,
    Lives: 76,
    "Renewal Premium": 14396.04,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Eastern Pennsylvania",
    "Renewal Month": "June",
    "Market Segment": "Middle Market",
    "Sales Rep": "Orr, Jonathan",
    "SIC Category": "Legal Services",
  },
  {
    "Policy Number": 342329,
    "Renewal Rate": 1.641242675,
    "Rate Change": 1.0,
    "Loss Ratio": 0.26655,
    "A/E Ratio": 0.368830214,
    Lives: 23,
    "Renewal Premium": 8469.6,
    "Product Line": "DENTAL  ",
    Coverage: "DENTALPPO   ",
    Territory: "Southeast",
    "Renewal Month": "July",
    "Market Segment": "Small Market",
    "Sales Rep": "Albritton Jr, Sterling",
    "SIC Category": "Home Furniture & Appliance Stores",
  },
  {
    "Policy Number": 640910,
    "Renewal Rate": 0.932819035,
    "Rate Change": 1.168094924,
    "Loss Ratio": 0.2253,
    "A/E Ratio": 0.380561689,
    Lives: 167,
    "Renewal Premium": 11695.2,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Southeast",
    "Renewal Month": "January",
    "Market Segment": "Middle Market",
    "Sales Rep": "Funderburk, John",
    "SIC Category": "Special Trade Contractors",
  },
  {
    "Policy Number": 171391,
    "Renewal Rate": 1.043602133,
    "Rate Change": 1.066040566,
    "Loss Ratio": 1.36089104,
    "A/E Ratio": 3.514688676,
    Lives: 1308,
    "Renewal Premium": 84438.6,
    "Product Line": "Life",
    Coverage: "ACCIDENT    ",
    Territory: "Greater New York",
    "Renewal Month": "June",
    "Market Segment": "Middle Market",
    "Sales Rep": "Espinal, Jairo",
    "SIC Category": "Entertainment & Recreation",
  },
  {
    "Policy Number": 570420,
    "Renewal Rate": 1.09989134,
    "Rate Change": 1.058592567,
    "Loss Ratio": 1.3206,
    "A/E Ratio": 2.292476884,
    Lives: 19,
    "Renewal Premium": 13847.28,
    "Product Line": "DENTAL  ",
    Coverage: "DENTALPPO   ",
    Territory: "Illinois",
    "Renewal Month": "September",
    "Market Segment": "Middle Market",
    "Sales Rep": "Eichmann, Martin",
    "SIC Category": "Restaurants & Bars",
  },
  {
    "Policy Number": 984656,
    "Renewal Rate": 1.042157551,
    "Rate Change": 1.0,
    "Loss Ratio": 0.0193,
    "A/E Ratio": 0.037068334,
    Lives: 132,
    "Renewal Premium": 9617.28,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Southeast",
    "Renewal Month": "January",
    "Market Segment": "Small Market",
    "Sales Rep": "Albritton Jr, Sterling",
    "SIC Category": "Computer Services",
  },
  {
    "Policy Number": 479617,
    "Renewal Rate": 0.936128398,
    "Rate Change": 1.0,
    "Loss Ratio": 0.0062,
    "A/E Ratio": 0.010066329,
    Lives: 2,
    "Renewal Premium": 342.96,
    "Product Line": "VISION",
    Coverage: "VISIONMAJOR ",
    Territory: "Lower Midwest",
    "Renewal Month": "February",
    "Market Segment": "Small Market",
    "Sales Rep": "Wolf, Laura",
    "SIC Category": "Other Retail",
  },
  {
    "Policy Number": 943354,
    "Renewal Rate": 1.0,
    "Rate Change": 0.890412074,
    "Loss Ratio": 0.0,
    "A/E Ratio": 0.0,
    Lives: 854,
    "Renewal Premium": 77459.76,
    "Product Line": "STD",
    Coverage: "STDNYPFL    ",
    Territory: "Greater New York",
    "Renewal Month": "January",
    "Market Segment": "Middle Market",
    "Sales Rep": "Bellapianta, Morgan",
    "SIC Category": "Grocery & General Merchandise",
  },
  {
    "Policy Number": 152192,
    "Renewal Rate": 0.93464272,
    "Rate Change": 1.037181926,
    "Loss Ratio": 0.810306317,
    "A/E Ratio": 1.411472897,
    Lives: 7143,
    "Renewal Premium": 1555488.1200000001,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Southeast",
    "Renewal Month": "April",
    "Market Segment": "Middle Market",
    "Sales Rep": "Epps, Douglas",
    "SIC Category": "Rubber, Plastic & Mineral Manufacturing",
  },
  {
    "Policy Number": 621901,
    "Renewal Rate": 1.027331983,
    "Rate Change": 1.03390633,
    "Loss Ratio": 0.487326537,
    "A/E Ratio": 0.659206358,
    Lives: 635,
    "Renewal Premium": 64986.72,
    "Product Line": "Life",
    Coverage: "BASICLFE    ",
    Territory: "Greater New York",
    "Renewal Month": "January",
    "Market Segment": "Middle Market",
    "Sales Rep": "McNulty, Michael",
    "SIC Category": "Financial Institutions",
  },
  {
    "Policy Number": 627259,
    "Renewal Rate": 1.149407139,
    "Rate Change": 1.0,
    "Loss Ratio": 0.3047,
    "A/E Ratio": 0.495672368,
    Lives: 33,
    "Renewal Premium": 4455.24,
    "Product Line": "VISION",
    Coverage: "VISIONMAJOR ",
    Territory: "Upper Midwest",
    "Renewal Month": "January",
    "Market Segment": "Middle Market",
    "Sales Rep": "Malvick, Reed",
    "SIC Category": "Travel Arrangement",
  },
];

export const Lapse_requiredFieldNames = [
  "Policy Number",
  "Product Line",
  "Coverage",
  "Territory",
  "Renewal Month",
  "Market Segment",
  "Sales Rep",
  "Lives",
  "Loss Ratio",
  "Renewal Premium",
  "Renewal Rate",
  "Rate Change",
  "A/E Ratio",
  "SIC Category",
];

export const Lapse_tableColumns = [
  "Policy No",
  "Product Line",
  "Coverage",
  "Market Segment",
  "Industry",
  "Territory",
  "Renewal Month",
  "# Lives",
  "Renewal Premium",
  "Renewal Rate",
  "Rate Change",
  "A/E Ratio",
  "Loss Ratio",
  "Lapse Score",
  "Suggested Action",
];

export const Lapse_dbColumns = [
  "Policy Number",
  "Product Line",
  "Coverage",
  "Market Segment",
  "SIC Category",
  "Territory",
  "Renewal Month",
  "Lives",
  "Renewal Premium",
  "Renewal Rate",
  "Rate Change",
  "A/E Ratio",
  "Loss Ratio",
  "Lapse Score",
  "suggested_action",
];

export const LPT_initialInputData = {
  "Product Line": "",
  Coverage: "",
  Territory: "",
  "Renewal Month": "",
  "Market Segment": "",
  "Sales Rep": "",
  Lives: "",
  "Loss Ratio": 1,
  "Renewal Rate": 1,
  "Rate Change": 1,
  "A/E Ratio": 1,
  "Renewal Premium": "",
  "SIC Category": "",
};

export const LPT_action = (score) => {
  let action = "";
  if (score >= 750) {
    action = "High";
  } else if (score >= 500) {
    action = "Normal";
  } else if (score <= 499) {
    action = "Low";
  }
  return action;
};
