import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import SelectIndustry from "../../components/modules/SelectIndustry";
import { breadCrumbs } from "../../data/data";

const Worksite = ({ solution }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.modules.map((module) => {
      if (module.components) {
        module.components.map((component) => {
          if (component.slug.substring(1, component.slug.length) === slug) {
            setModules(module);
            setPageData(component);
            return setIsSlugCorrect(true);
          } else {
            return null;
          }
        });
      } else {
        if (module.slug.substring(1, module.slug.length) === slug) {
          setModules(solution);
          setPageData(module);
          return setIsSlugCorrect(true);
        } else {
          return null;
        }
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(
      `${modules?.title === solution?.title ? "" : `${modules?.title}: `} ${
        pageData?.title
      }`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData]);

  return (
    <>
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-2 w-full min-h-[35rem]">
          <div className="flex justify-between">
            <Breadcrumbs crumbs={crumbs} />
            <SelectIndustry
              industries={pageData.industries}
              selected={{
                label: "Worksite",
                value: "worksite",
              }}
            />
          </div>
          <div className="my-5">
            <Tabs data={modules} industry={"/worksite"} />
          </div>
          <div className={`w-full pb-10 h-full min-h-[35rem]`}>
            {/* Sales Prophet Modules */}
            {pageData.title === "Market Overview" && (
              <PowerBI reportId={pageData.dashboards.worksite.reportId} />
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Worksite;
